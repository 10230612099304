import React, { useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../../redux/hooks";
import axios, {CancelTokenSource} from "axios";
import {ICompanyDTO} from "../../../../../../../interfaces/ICompanyDTO";
import {TimeOfDay} from "../../../../../../../enums/TimeOfDay";
import {IAeroExpressVariant} from "../../../../../../../interfaces/IAeroExpressVariant";
import {DateService} from "../../../../../../../services/DateService";
import {RefsService} from "../../../../../../../services/RefsService";
import {TripService} from "../../../../../../../services/TripService";
import {AeroExpressComponent} from "../../../tickets/aeroExpressComponent";
import {MdMenu} from "../../../../../../../components/md-menu";
import {MdToggleButton} from "../../../../../../../components/md-toggle-button";
import {MdButton} from "../../../../../../../components/md-button";
import {MinusIcon} from "../../../../../../../icons/MinusIcon";
import {PlusIcon} from "../../../../../../../icons/PlusIcon";
import {SunriseIcon} from "../../../../../../../icons/SunriseIcon";
import {SunIcon} from "../../../../../../../icons/SunIcon";
import {MoonIcon} from "../../../../../../../icons/MoonIcon";
import {ScheduleIcon} from "../../../../../../../icons/ScheduleIcon";
import {CircleSpinnerIcon} from "../../../../../../../icons/CircleSpinnerIcon";
import {Guid} from "../../../../../../../utils/utils";
import "./style.scss";


export enum AeroExpressTariff {
    None = 0,
    Standard       = 1 << 0, // 0001
    StandardTwoWay = 1 << 1, // 0010
    Business       = 1 << 2, // 0100
    BusinessThanks = 1 << 3, // 1000
    BusinessTwoWay = 1 << 4, // 10000
    Family         = 1 << 5, // 100000
    FamilyTwoWay   = 1 << 6, // 1000000
    Domodedovo     = 1 << 7, // 10000000
    Subscription   = 1 << 8, // 100000000
    ALL = Standard
        | StandardTwoWay
        | Business
        | BusinessThanks
        | BusinessTwoWay
        | Family
        | FamilyTwoWay
        | Domodedovo
        | Subscription
}

export interface IAeroExpressRoute {
    id: string;
    passengers: number;
    tariff?: AeroExpressTariff;
    departDate?: string;
    departTimeOfDay?: TimeOfDay;
    departTime?: string;
    notes?: string;
}

interface ISearchResult {
    variant: IAeroExpressVariant;
    selectedTariffIndex: number;
}


export function SearchAeroExpressComponent (
    props: {
        selectedCompany: ICompanyDTO | undefined;
        selectVariantHandler: (variant: IAeroExpressVariant, tariffIndex: number, searchResponse: any) => Promise<void>;
    }
) {

    const {
        selectedCompany,
        selectVariantHandler
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const refsService = RefsService();

    const tripService = TripService();

    const dateService = DateService();


    const minPassengersCount = 1;
    const maxPassengersCount = 10;

    const [searchRoute, setSearchRoute] =
        useState<IAeroExpressRoute>(
            {
                id: Guid.newGuid(),
                passengers: minPassengersCount,
                tariff: AeroExpressTariff.None,
                departTimeOfDay: TimeOfDay.None,
                notes: ""
            }
        );

    const [departDateFocused, setDepartDateFocused] = useState<boolean>(false);

    const [providers, setProviders] = useState<string[]>([]);
    const [selectedProviders, setSelectedProviders] = useState<string[]>([]);

    const [variants, setVariants] = useState<ISearchResult[]>([]);
    const [searchPending, setSearchPending] = useState(false);
    const [searchNotFound, setSearchNotFound] = useState(false);
    const [searchCT, setSearchCT] = useState<CancelTokenSource|undefined>(undefined);

    const [searchResponse, setSearchResponse] = useState<any>(null);



    const days = [
        t("bookingPage.sundayShort"),
        t("bookingPage.mondayShort"),
        t("bookingPage.tuesdayShort"),
        t("bookingPage.wednesdayShort"),
        t("bookingPage.thursdayShort"),
        t("bookingPage.fridayShort"),
        t("bookingPage.saturdayShort"),
    ];

    const months = [
        t("bookingPage.januaryShort"),
        t("bookingPage.februaryShort"),
        t("bookingPage.marchShort"),
        t("bookingPage.aprilShort"),
        t("bookingPage.mayShort"),
        t("bookingPage.juneShort"),
        t("bookingPage.julyShort"),
        t("bookingPage.augustShort"),
        t("bookingPage.septemberShort"),
        t("bookingPage.octoberShort"),
        t("bookingPage.novemberShort"),
        t("bookingPage.decemberShort"),
    ];

    const [tariffOptions] = useState<Map<AeroExpressTariff, { name: string }>>(
        new Map([
            [AeroExpressTariff.Standard,        { name: t("bookingPage.tariffStandard") }],
            [AeroExpressTariff.StandardTwoWay,  { name: t("bookingPage.tariffStandardTwoWay") }],
            [AeroExpressTariff.Business,        { name: t("bookingPage.tariffBusiness") }],
            [AeroExpressTariff.BusinessThanks,  { name: t("bookingPage.tariffBusinessThanks") }],
            [AeroExpressTariff.BusinessTwoWay,  { name: t("bookingPage.tariffBusinessTwoWay") }],
            [AeroExpressTariff.Family,          { name: t("bookingPage.tariffFamily") }],
            [AeroExpressTariff.FamilyTwoWay,    { name: t("bookingPage.tariffFamilyTwoWay") }],
            [AeroExpressTariff.Domodedovo,      { name: t("bookingPage.tariffDomodedovo") }],
            [AeroExpressTariff.Subscription,    { name: t("bookingPage.tariffSubscription") }]
        ])
    );

    const [timeOfDayOptions] = useState<Map<TimeOfDay, { name: string, interval: number[] }>>(
        new Map([
            [TimeOfDay.None,      { name: t("bookingPage.anyTime"),         interval: [] }],
            [TimeOfDay.Exact,     { name: t("bookingPage.approximateTime"), interval: [] }],
            [TimeOfDay.Morning,   { name: t("bookingPage.morning"),         interval: [4, 12] }],
            [TimeOfDay.Afternoon, { name: t("bookingPage.afternoon"),       interval: [10, 16] }],
            [TimeOfDay.Evening,   { name: t("bookingPage.evening"),         interval: [16, 23] }]
        ])
    );



    const setPassengers = (passengers: number) => {
        setSearchRoute(route =>
            ({
                ...route,
                passengers: passengers
            })
        );
    }

    const setTariff = (tariff: AeroExpressTariff) => {
        setSearchRoute(route =>
            ({
                ...route,
                tariff: tariff
            })
        );
    }

    const setDepartDate = (date: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                departDate: date
            })
        );
    }

    const setDepartTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                departTimeOfDay: timeOfDay,
                departTime: timeOfDay == TimeOfDay.Exact && route.departTime == undefined ? "00:00" : route.departTime
            })
        );
    }

    const setDepartTime = (time: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                departTime: time
            })
        );
    }


    const departDateFocusHandler = async () => {
        setDepartDateFocused(true);
    }
    const departDateBlurHandler = async () => {
        setDepartDateFocused(false);
    }


    const getProviders = async () => {

    };


    const startSearch = async () => {
        setSearchPending(true);
        setSearchNotFound(false);
        setVariants([]);

        if (searchCT) {
            searchCT.cancel();
        }

        let cts = axios.CancelToken.source();
        setSearchCT(cts);

        const response = await tripService.getAeroExpress(
            [
                Object.assign({}, ({
                    ...searchRoute,
                }))
            ],
            selectedProviders,
            selectedCompany?.code ?? "",
            cts.token
        );

        setSearchCT(undefined);

        setSearchResponse(response);

        const variants = response?.variants ?? [];

        if (variants.length === 0) {
            setSearchNotFound(true);
        }

        setVariants(variants.map(variant => {
            return {
                variant: variant,
                selectedTariffIndex: 0
            } as ISearchResult
        }));

        setSearchPending(false);
    };

    const cancelSearch = () => {
        if (searchCT) {
            searchCT.cancel();
        }
    }


    // const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //     if (event.repeat) return;
    //     if (event.key === "Enter") {
    //         startSearch();
    //     }
    // }


    // TODO: change variant type
    // const selectVariant = async (variant: IAeroExpressVariant, tariffIndex: number) => {
    //     await selectVariantHandler(variant, tariffIndex, searchResponse);
    // }

    const selectVariant = async (variant: ISearchResult, variantIndex: number) => {
        await selectVariantHandler(variant.variant, variantIndex, searchResponse);
    }


    const checkDepartureDate = () => {
        const today = new Date();
        today.setHours(0,0,0,0);

        let departDateRange: number[] | undefined = undefined;
        if(searchRoute.departDate != undefined) {
            switch (searchRoute.departTimeOfDay) {
                case TimeOfDay.None:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T00:00:00").getTime(),
                        new Date(searchRoute.departDate + "T23:59:59").getTime()
                    ];
                    break;
                case TimeOfDay.Exact:
                    departDateRange = [new Date(searchRoute.departDate + "T" + searchRoute.departTime).getTime()];
                    break;
                case TimeOfDay.Morning:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T04:00:00").getTime(),
                        new Date(searchRoute.departDate + "T12:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Afternoon:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T10:00:00").getTime(),
                        new Date(searchRoute.departDate + "T16:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Evening:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T16:00:00").getTime(),
                        new Date(searchRoute.departDate + "T23:00:00").getTime(),
                    ];
                    break;
            }
        }

        let hasError = false;

        if(searchRoute.departDate != undefined) {
            if(new Date(searchRoute.departDate) < today) {
                hasError = true;
            }
        }

        return hasError;
    }


    useEffect(() => {
        getProviders();
    }, [selectedCompany]);


    return (
        <React.Fragment>
            <div className="trip-page-search-form">


                <div className="trip-page-search-form-header">

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {t("bookingPage.passengers")}: {searchRoute.passengers.toString().padStart(2, "0")}
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="passengers-input-control">
                            <MdButton
                                icon={
                                    <MinusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (searchRoute.passengers > minPassengersCount) {
                                        setPassengers(searchRoute.passengers - 1);
                                    }
                                }}
                            />

                            <input
                                type="number"
                                min={minPassengersCount}
                                max={maxPassengersCount}
                                value={searchRoute.passengers}
                                readOnly={true}
                            />

                            <MdButton
                                icon={
                                    <PlusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (searchRoute.passengers < maxPassengersCount) {
                                        setPassengers(searchRoute.passengers + 1);
                                    }
                                }}
                            />
                        </div>
                    </MdMenu>

                    {/*<MdMenu*/}
                    {/*    behaviour={'none'}*/}
                    {/*    header={(*/}
                    {/*        <div className="filter-menu-header">*/}
                    {/*            {*/}
                    {/*                (() => {*/}
                    {/*                    const options = Array.from(tariffOptions.entries());*/}

                    {/*                    const enabledOptions =*/}
                    {/*                        options.reduce((arr: { name: string }[], v) => {*/}
                    {/*                            const [variant, variantDetails] = v;*/}
                    {/*                            if(searchRoute.tariff === AeroExpressTariff.None || (searchRoute.tariff & variant) === variant) {*/}
                    {/*                                arr.push(variantDetails)*/}
                    {/*                            }*/}
                    {/*                            return arr;*/}
                    {/*                        }, []);*/}

                    {/*                    if(enabledOptions.length == 0 || enabledOptions.length == options.length) {*/}
                    {/*                        return t('bookingPage.anyTariff');*/}
                    {/*                    }*/}

                    {/*                    return enabledOptions[0].name+ (enabledOptions.length > 1 ? (" + " + (enabledOptions.length - 1)) : "")*/}
                    {/*                })()*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*    disabled={searchPending}*/}
                    {/*>*/}
                    {/*    <div className="filter-menu-content">*/}
                    {/*        {*/}
                    {/*            Array.from(tariffOptions.entries()).map((entry, entryIndex) => {*/}
                    {/*                const [ variant, variantDetails ] = entry;*/}
                    {/*                return (*/}
                    {/*                    <React.Fragment key={entryIndex}>*/}
                    {/*                        <MdToggleButton*/}
                    {/*                            buttonStyle={{*/}
                    {/*                                flex: 1,*/}
                    {/*                                minInlineSize: "fit-content",*/}
                    {/*                                display: "flex",*/}
                    {/*                                alignItems: "center",*/}
                    {/*                                padding: "5px 10px",*/}
                    {/*                                borderRadius: "4px",*/}
                    {/*                                minWidth: "max-content",*/}
                    {/*                                minHeight: "24px",*/}
                    {/*                                maxHeight: "52px",*/}
                    {/*                                fontSize: "12px",*/}
                    {/*                                color: (((searchRoute.tariff & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                border: (((searchRoute.tariff & variant) === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),*/}
                    {/*                            }}*/}
                    {/*                            inkStyle={{*/}
                    {/*                                backgroundColor: (((searchRoute.tariff & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                opacity: 0.1*/}
                    {/*                            }}*/}
                    {/*                            checked={((searchRoute.tariff & variant) === variant)}*/}
                    {/*                            onClick={() => {*/}
                    {/*                                if (((searchRoute.tariff & variant) !== variant)) {*/}
                    {/*                                    setTariff(searchRoute.tariff |= variant);*/}
                    {/*                                }*/}
                    {/*                                else {*/}
                    {/*                                    setTariff(searchRoute.tariff &= ~variant);*/}
                    {/*                                }*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            {*/}
                    {/*                                variantDetails.name*/}
                    {/*                            }*/}
                    {/*                        </MdToggleButton>*/}
                    {/*                    </React.Fragment>*/}
                    {/*                )*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</MdMenu>*/}

                </div>


                <div className="trip-page-search-form-content">

                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}
                         style={{
                             marginLeft: "6px",
                             minWidth: "280px",
                             backgroundColor: (checkDepartureDate() ? "rgba(255, 0, 0, 0.2)" : "transparent")
                        }}>
                        <label className={(departDateFocused || searchRoute.departDate != null ? "--displaced" : "")}>
                            {t("bookingPage.departDate")}
                        </label>
                        <input
                            className={(departDateFocused ? "--focused" : "")}
                            type="date"
                            min={dateService.dateToStringForDatePicker(new Date())}
                            value={searchRoute.departDate ?? ""}
                            onFocus={() => departDateFocusHandler()}
                            onBlur={() => departDateBlurHandler()}
                            onChange={(e) => setDepartDate(e.target.value)}
                            disabled={searchPending}
                        />
                        {
                            searchRoute.departTimeOfDay == TimeOfDay.Exact && (
                                <input
                                    className={(departDateFocused ? "--focused" : "")}
                                    type="time"
                                    value={searchRoute.departTime ?? ""}
                                    onFocus={() => departDateFocusHandler()}
                                    onBlur={() => departDateBlurHandler()}
                                    onChange={(e) => setDepartTime(e.target.value)}
                                    disabled={searchPending}
                                />
                            )
                        }
                        {
                            searchRoute.departDate != null && (
                                <div className={("trip-page-search-form-datetime-item" + (departDateFocused ? " --hidden" : ""))}>
                                    <div className="trip-page-search-form-datetime-item-date">
                                        {
                                            days[new Date(searchRoute.departDate).getDay()]
                                            + ", " + new Date(searchRoute.departDate).getDate().toString().padStart(2, "0") + " "
                                            + months[new Date(searchRoute.departDate).getMonth()]
                                            + " " + new Date(searchRoute.departDate).getFullYear()
                                        }
                                    </div>
                                    <div className="trip-page-search-form-datetime-item-time">
                                        {
                                            (() => {
                                                switch (searchRoute.departTimeOfDay) {
                                                    case TimeOfDay.Exact:
                                                        return (
                                                            <>
                                                                <span style={{ marginLeft: "16px" }}>
                                                                    {searchRoute.departTime?.toString() ?? "00:00"}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Morning:
                                                        return (
                                                            <>
                                                                <SunriseIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    { timeOfDayOptions.get(TimeOfDay.Morning)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ") }
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Afternoon:
                                                        return (
                                                            <>
                                                                <SunIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    { timeOfDayOptions.get(TimeOfDay.Afternoon)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ") }
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Evening:
                                                        return (
                                                            <>
                                                                <MoonIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    { timeOfDayOptions.get(TimeOfDay.Evening)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ") }
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.None:
                                                    default:
                                                        return "--:--"
                                                }
                                            })()
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <MdMenu
                            behaviour={'none'}
                            header={(
                                <div className="departure-time-type">
                                    {
                                        timeOfDayOptions.get(searchRoute.departTimeOfDay ?? TimeOfDay.None)?.name
                                    }
                                </div>
                            )}
                            disabled={searchPending}
                            style={{
                                "root": {
                                    position: 'absolute',
                                    bottom: "2px",
                                    right: "3px",
                                }
                            }}
                        >
                            <div className="departure-time-type-variants">
                                {
                                    Array.from(timeOfDayOptions.entries()).map((entry, entryIndex) => {
                                        const [ variant, variantDetails ] = entry;
                                        return (
                                            <React.Fragment key={entryIndex}>
                                                <MdToggleButton
                                                    buttonStyle={{
                                                        flex: 1,
                                                        minInlineSize: "fit-content",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "5px 10px",
                                                        borderRadius: "4px",
                                                        minWidth: "max-content",
                                                        minHeight: "24px",
                                                        maxHeight: "52px",
                                                        fontSize: "12px",
                                                        color: ((searchRoute.departTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                        border: ((searchRoute.departTimeOfDay === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                    }}
                                                    inkStyle={{
                                                        backgroundColor: ((searchRoute.departTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                        opacity: 0.1
                                                    }}
                                                    checked={(searchRoute.departTimeOfDay === variant)}
                                                    onClick={() => {
                                                        setDepartTimeOfDay(variant);
                                                    }}
                                                >
                                                    {
                                                        variantDetails.name
                                                    }
                                                </MdToggleButton>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </MdMenu>
                    </div>

                </div>


                <MdButton
                    className="search-button"
                    type="button"
                    style={{
                        "button": {
                            position: "absolute",
                            bottom: "-20px",
                            right: "30px",
                            borderWidth: 0,
                            backgroundColor: (searchPending ? "#B83E50" : "#3E5CB8"),
                            color: "#FFFFFF",
                            borderRadius: "8px",
                            minWidth: "160px",
                            height: "40px",
                            padding: "5px 15px",
                            fontWeight: 500,
                            fontSize: "12px",
                            textTransform: "capitalize",
                            alignItems: "center",
                            justifyContent: "center"
                        },
                        "text": {
                            width: "auto",
                            flex: 0,
                            color: "#FFFFFF"
                        },
                        "ink": {
                            backgroundColor: "rgba(255,255,255,.5)"
                        }
                    }}
                    disabled={(
                        searchRoute.departDate == undefined
                        || checkDepartureDate()
                    )}
                    onClick={() => {
                        if (!searchPending) {
                            startSearch()
                        } else {
                            cancelSearch();
                        }
                    }}
                >
                    {
                        searchPending
                            ? (
                                t("bookingPage.stop")
                            )
                            : (
                                "Поиск поездов"
                            )
                    }
                </MdButton>

            </div>


            <div className="trip-page-search-tools">
                {
                    authState.isSupportUser && (
                        <div className="trip-page-search-providers">
                            <span className="provider-list-label">{t("bookingPage.providers")}:</span>
                            {
                                providers.map((provider, providerIndex) => {
                                    const isSelected = selectedProviders.includes(provider);
                                    return (
                                        <React.Fragment key={"provider" + Guid.newGuid()}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "2px 8px",
                                                    borderRadius: "4px",
                                                    minWidth: "28px",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "10px",
                                                    color: (isSelected ? "#74B100" : "#C1C1C1"),
                                                    border: (isSelected ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (isSelected ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={isSelected}
                                                disabled={searchPending}
                                                onClick={() => {
                                                    if (isSelected) {
                                                        setSelectedProviders(prev => [...prev.filter(x => x != provider)]);
                                                    } else {
                                                        setSelectedProviders(prev => [...prev, provider]);
                                                    }
                                                }}
                                            >
                                                {
                                                    provider
                                                }
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>

            <div className="trip-page-search-results">

                {
                    (!searchPending && !searchNotFound && variants.length == 0) && (
                        <div className="trip-page-no-results-message">
                            <div className="trip-page-no-results-message-icon">
                                <ScheduleIcon
                                    width="50px"
                                    height="50px"
                                    style={{
                                        "path": {
                                            fill: "rgb(100 100 100)"
                                        }
                                    }}
                                />
                            </div>
                            <div className="trip-page-no-results-message-caption">
                                Список пуст
                            </div>
                            <div className="trip-page-no-results-message-content" style={{minWidth: "240px"}}>
                                Воспользуйтесь формой поиска чтобы найти поезда по заданным критериям.
                            </div>
                        </div>
                    )
                }

                {
                    searchPending && (
                        <div className="trip-page-no-results-message">
                            <div className="trip-page-no-results-message-icon">
                                <ScheduleIcon
                                    width="50px"
                                    height="50px"
                                    style={{
                                        "path": {
                                            fill: "rgb(100 100 100)"
                                        }
                                    }}
                                />
                                <CircleSpinnerIcon
                                    width="130px"
                                    height="130px"
                                    style={{
                                        "svg": {
                                            position: "absolute"
                                        },
                                        "circle": {
                                            stroke: "rgb(210 210 210)",
                                            strokeWidth: 1
                                        }
                                    }}
                                />
                            </div>
                            <div className="trip-page-no-results-message-caption">
                                Ищем билеты...
                            </div>
                            <div className="trip-page-no-results-message-content" style={{minWidth: "300px"}}>
                                Приозводим запрос к системе для получения наиболее подходящих для Вас результатов.
                            </div>
                        </div>
                    )
                }

                {
                    (!searchPending && searchNotFound && variants.length == 0) && (
                        <div className="trip-page-no-results-message">
                            <div className="trip-page-no-results-message-icon">
                                <ScheduleIcon
                                    width="50px"
                                    height="50px"
                                    style={{
                                        "path": {
                                            fill: "rgb(100 100 100)"
                                        }
                                    }}
                                />
                            </div>
                            <div className="trip-page-no-results-message-caption">
                                Список пуст
                            </div>
                            <div className="trip-page-no-results-message-content">
                                Поиск не дал результатов. Измените параметры или повторите поиск.
                            </div>
                        </div>
                    )
                }

                {
                    variants.length > 0 && (
                        <div className="trip-page-search-results-tickets">
                            {
                                variants.map((variant, variantIndex) =>
                                    <React.Fragment key={variantIndex}>
                                        <AeroExpressComponent
                                            variantInfo={variant.variant}
                                            variants={[variant.variant]}
                                            tariffSelection={false}
                                            selectable={true}
                                            callbacks={{
                                                select: () => {
                                                    selectVariant(variant, variantIndex)
                                                },
                                                changeTariff: (tariffIndex: number) => {
                                                    setVariants(prev => prev.map((v, i) => {
                                                        if(i == variantIndex) {
                                                            v.selectedTariffIndex = tariffIndex;
                                                        }
                                                        return v;
                                                    }))
                                                }
                                            }}
                                            //selectVariant={selectVariantHandler}
                                        />
                                    </React.Fragment>
                                )
                            }
                        </div>
                    )
                }

            </div>

        </React.Fragment>
    );
}