import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {AuthService} from "../../../services/AuthService";
import {ILoginRequest} from "../../../api/request/ILoginRequest";
import {LoginRequestValidator} from "../../../validators/LoginRequestValidator";
import {ValidationErrors} from "fluentvalidation-ts";
import {DataConvertService} from "../../../services/DataConvertService";
import {ErrorMessage} from "../../commonComponents/ErrorComponents/errorMessage/ErrorMessage";
import {MdTextField} from "../../../components/md-text-field";
import {MdButton} from "../../../components/md-button";
import {EyeOffIcon} from "../../../icons/EyeOffIcon";
import {EyeIcon} from "../../../icons/EyeIcon";
import {CircleSpinnerIcon} from "../../../icons/CircleSpinnerIcon";
import "./style.scss";


export function SignInPage() {

    const {getErrorByStatusCode} = DataConvertService();

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const loginRequestValidator = new LoginRequestValidator(t);

    const params = Object.fromEntries(searchParams.entries());

    const authService = AuthService();


    const [showPassword, setShowPassword] = useState<boolean>(false);

    const [inputEmail, setInputEmail] = useState<string>(params.email ?? "");
    const [inputPassword, setInputPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [validationError, setValidationError] = useState<ValidationErrors<ILoginRequest>>();
    const [submitPending, setSubmitPending] = useState(false);


    //TODO investigate for using other event
    const submitHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); //

        let authResult = 0;

        setValidationError({email: "", password: ""});

        const error = loginRequestValidator.validate({
            email: inputEmail,
            password: inputPassword,
        });

        if (error.email || error.password) {
            setSubmitPending(false);
            setValidationError(error);
            console.log("1 message")
            return;
        }

        setSubmitPending(true);
        setLoginError("");

        authResult = await authService.login(inputEmail, inputPassword);
        setSubmitPending(false);

        if (authResult === 200) {
            navigate("/cabinet");
        } else if (authResult === 400) {
            setLoginError(getErrorByStatusCode(400) ?? "");
        } else if (authResult === 500) {
            setLoginError(getErrorByStatusCode(500) ?? "");
        }
    };

    return (
        <>
            <form className="login-form">
                <div className="login-form-header">Вход в систему</div>

                <div className="login-form-body">

                    <div className="login-form-error-message">
                        {loginError && <ErrorMessage error={loginError}/>}
                    </div>

                    <MdTextField
                        type="email"
                        label={t("email")}
                        value={inputEmail}
                        invalid={(validationError != null && validationError.email != null && validationError.email.length > 0)}
                        errorMessage={(validationError?.email)}
                        onChange={(event) => {
                            setLoginError("");
                            setInputEmail(event.target.value);
                        }}
                    />

                    <div className="login-form-text-field-container">
                        <MdTextField
                            type={(showPassword ? "text" : "password")}
                            label={t("password")}
                            value={inputPassword}
                            invalid={(validationError != null && validationError.password != null && validationError.password.length > 0)}
                            errorMessage={(validationError?.password)}
                            onChange={(event) => {
                                setLoginError("");
                                setInputPassword(event.target.value);
                            }}
                        />

                        <MdButton
                            style={{
                                "button": {
                                    position: "absolute",
                                    top: "50%",
                                    right: "10px",
                                    transform: "translate(0, -50%)",
                                    margin: "-2px 0 0 0",
                                    borderWidth: 0,
                                    backgroundColor: "transparent",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "28px",
                                    padding: "2px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                },
                                "icon": {
                                    marginLeft: 0,
                                    marginRight: 0,
                                },
                            }}
                            onClick={() => setShowPassword(prev => !prev)}
                        >
                            {
                                showPassword
                                    ? (
                                        <EyeIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "path": {
                                                    fill: "rgba(0, 0, 0, 0.87)"
                                                }
                                            }}
                                        />
                                    )
                                    : (
                                        <EyeOffIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "path": {
                                                    fill: "rgba(0, 0, 0, 0.87)"
                                                }
                                            }}
                                        />
                                    )
                            }
                        </MdButton>
                    </div>


                    <MdButton
                        type="submit"
                        style={{
                            "button": {
                                width: "100%",
                                height: "40px",
                                fontSize: "14px",
                                backgroundColor: "#3E5CB8",
                                color: "#FFFFFF"
                            }
                        }}
                        disabled={submitPending}
                        onClick={(event) => submitHandler(event)}
                    >
                        {
                            submitPending
                                ? (
                                    <CircleSpinnerIcon
                                        width="18px"
                                        height="18px"
                                        style={{
                                            "circle": {
                                                stroke: "#FFFFFF"
                                            }
                                        }}
                                    />
                                )
                                : (
                                    t("description.login")
                                )
                        }
                    </MdButton>
                </div>

                <Link className="forgot-password-link" to="/recovery">
                    {t("description.forgot-password")}
                </Link>

                {/*<div className="sign-up-link">*/}
                {/*    {t("description.dont-have-acc") + " "}*/}
                {/*    <Link to="/sign-up">*/}
                {/*        {t("description.register-here")}*/}
                {/*    </Link>*/}
                {/*</div>*/}
            </form>
        </>
    );
}