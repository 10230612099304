import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const PlaidIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                  d="M26,7.5H15V20.67a3,3,0,0,1,1-.17h9a3,3,0,0,1,1,.17Zm2,16a3,3,0,0,1-3,3H8a1,1,0,0,1-1-1H5a1,1,0,0,1,0-2H7v-1H5a1,1,0,0,1,0-2H7v-1H5a1,1,0,0,1,0-2H7v-1H5a1,1,0,0,1,0-2H7v-1H5a1,1,0,0,1,0-2H7v-1H5a1,1,0,0,1,0-2H7a1,1,0,0,1,1-1h5a2,2,0,0,1,2-2H26a2,2,0,0,1,2,2Zm-14.83,1a3,3,0,0,1-.17-1V9.5H9v15ZM26,23.5a1,1,0,0,0-1-1H16a1,1,0,0,0,0,2h9A1,1,0,0,0,26,23.5Z"
                  style={style["path"]}
            />
        </svg>
    )
}