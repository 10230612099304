import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const Airline_S7_Icon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
         <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="-0.02 0 30 30"
            style={style["svg"]}>
                <rect
                    fill="#B8CF53"
                    width="29.98"
                    height="30"/>
                <path
                    fill="#FFFFFF"
                    d="M27.239,15.002c0,6.905-5.592,12.498-12.496,12.498c-6.906,0-12.504-5.593-12.504-12.498  C2.239,8.097,7.837,2.5,14.743,2.5C21.647,2.5,27.239,8.097,27.239,15.002"/>
                <path
                    fill="#B8CF53"
                    d="M13.832,8.479c-1.97,0-3.246,0.108-4.172,0.451c-1.553,0.605-2.626,1.875-2.626,3.542  c0,1.388,0.647,2.393,2.517,3.551c1.997,1.208,2.396,1.608,2.396,2.34c0,0.796-0.783,1.705-2.795,1.705  c-0.702,0-1.76-0.099-2.436-0.264l-0.322-0.081c-0.18,0.482-0.588,1.623-0.713,2.037L6,21.855c0.608,0.195,2.051,0.346,3.085,0.346  c3.439,0,5.491-2.078,5.491-4.253c0-1.338-0.606-2.354-2.659-3.635c-1.028-0.632-1.553-1.043-1.871-1.36  c-0.26-0.332-0.287-0.579-0.287-0.84c0-0.249,0.054-0.536,0.219-0.746c0.177-0.259,0.502-0.425,0.943-0.562  c0.454-0.15,1.292-0.154,2.6-0.154h1.217l0.87-2.173H13.832L13.832,8.479z M23.03,8.479h-6.116l-0.869,2.173h2.027  c1.363,0,1.627-0.03,1.969-0.03c-0.494,1.131-3.476,8.725-3.476,8.725h2.523c0,0,3.773-9.453,4.173-10.444  C23.372,8.681,23.319,8.479,23.03,8.479z"/>
        </svg>
    )
}