import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const InfotainmentServiceIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                d="M7,8H25a1,1,0,0,1,1,1V23a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V9A1,1,0,0,1,7,8ZM4,9A3,3,0,0,1,7,6H25a3,3,0,0,1,3,3V23a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3Zm10,3,6,4-6,4Z"
                style={style["path"]}
            />
        </svg>
    )
}