import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const VIPRoomIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.7,5C8.3,4.6,7.6,4.3,6.9,4.3S5.6,4.6,5.2,5l0,0C4.8,5.4,4.5,6.1,4.5,6.8s0.3,1.3,0.7,1.7s1.1,0.7,1.7,0.7   c0.7,0,1.3-0.3,1.8-0.7l0,0c0.4-0.4,0.7-1.1,0.7-1.7C9.4,6,9.1,5.4,8.7,5L8.7,5z"
                style={style["path"]}
            />
            <path
                d="M3.5,9.8l5.8,11.1c0.5,0.9,1.5,1.5,2.5,1.5h4.9"
                style={style["path"]}
            />
            <polyline
                points="8.3,10.4 12.9,19.1 17.5,19.1 24.9,23.3  "
                style={style["path"]}
            />
            <line
                x1="17.7"
                y1="19.2"
                x2="21.5"
                y2="27.7"
                style={style["path"]}
            />
            <path
                d="M14.4,8h1.6l1.3,4.6l0,0L18.6,8h1.5l-2,5.7h-1.9L14.4,8z"
                style={style["path"]}
            />
            <path
                d="M21.1,8h1.5v5.7h-1.5V8z"
                style={style["path"]}
            />
            <path
                d="M25.9,8c0.6,0,1.5,0,2.1,0.6c0.2,0.2,0.5,0.6,0.5,1.3c0,0.5-0.1,0.8-0.5,1.2c-0.6,0.6-1.4,0.7-1.9,0.7h-0.6v2H24V8H25.9z     M25.7,10.6H26c0.2,0,0.5,0,0.7-0.2C27,10.2,27,9.9,27,9.8c0-0.2,0-0.5-0.2-0.6c-0.2-0.1-0.5-0.1-0.7-0.1h-0.4V10.6z"
                style={style["path"]}
            />
        </svg>
    )
}