import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const Airline_N4_Icon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 30 30"
            style={style["svg"]}
        >
            <g clipRule="evenodd" fillRule="evenodd">
                <rect width="30" height="30"/>
                <path d="M0 0h30v30H0z" fill="#da3735"/>
                <path
                    d="M22.049 18.817h-7.471l-4.167-7.184-4.149 7.184H1l2.945-5.083c.808-1.419 2.604-2.55 4.005-2.55h7.471l4.149 7.202 4.167-7.202H29l-2.945 5.083c-.809 1.418-2.605 2.55-4.006 2.55z"
                    fill="#fff"/>
            </g>
        </svg>
    )
}