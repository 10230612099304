import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const BioToiletIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                d="M19.5,18V6h4V18h-4Zm-2,0V16H6.5V15a1,1,0,0,1,1-1h10V5a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H21.07c0,.1-.09.2-.14.3a6.17,6.17,0,0,1-1.43,1.78V28h-9V23.31a7.52,7.52,0,0,1-1.8-1.07A6.1,6.1,0,0,1,7.07,20.3c-.05-.1-.1-.2-.14-.3a5,5,0,0,1-.43-2h11Zm1.24,2H9.26a4,4,0,0,0,.69.68,5.55,5.55,0,0,0,1.33.78,2,2,0,0,1,1.22,1.85V26h5V22.08a2,2,0,0,1,.69-1.51A4.18,4.18,0,0,0,18.74,20Z"
                style={style["path"]}
            />
        </svg>
    )
}