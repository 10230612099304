import axios, {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import {
    BASE_URL,
    BASE_ROUTE_AUTH_SERVICE,
    BASE_ROUTE_GATEWAY_SERVICE,
    BASE_ROUTE_INTEROP_SERVICE,
} from "../constants/ApiRouteConstants";


export function newAbortSignal(timeoutMs: number) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);
    return abortController.signal;
}

export const AXIOS_INSTANCE_GATEWAY = "axiosInstanceGateway";
export const AXIOS_INSTANCE_WEB_AUTH = "axiosInstanceWebAuth";
export const AXIOS_INSTANCE_INTEROP = "axiosInstanceInterop";

type AxiosBaseServer = {
    baseServer: typeof AXIOS_INSTANCE_GATEWAY | typeof AXIOS_INSTANCE_WEB_AUTH | typeof AXIOS_INSTANCE_GATEWAY;
}

interface IAxiosInstanceStrategy {
    getAxiosInstance(baseServer?: string): AxiosInstance;
}

class AxiosInstanceContext implements IAxiosInstanceStrategy {
    // private currentState: AxiosBaseServer = {baseServer: "axiosInstanceGateway"}
    private readonly axiosInstanceGateway: AxiosInstance;
    private readonly axiosInstanceWebAuth: AxiosInstance;
    private readonly axiosInstanceInterop: AxiosInstance;


    constructor() {
        this.axiosInstanceGateway = axios.create(AxiosInstanceContext.getConfig(BASE_ROUTE_GATEWAY_SERVICE));
        this.axiosInstanceWebAuth = axios.create(AxiosInstanceContext.getConfig(BASE_ROUTE_AUTH_SERVICE));
        this.axiosInstanceInterop = axios.create(AxiosInstanceContext.getConfig(BASE_ROUTE_INTEROP_SERVICE));



    }

    // setCurrentState(baseServer: AxiosBaseServer) {
    //    this.currentState = baseServer
    // }

    getAxiosInstance(baseServer?: string): AxiosInstance {
        if (baseServer === AXIOS_INSTANCE_GATEWAY) {
            return this.axiosInstanceGateway;
        } else if (baseServer === AXIOS_INSTANCE_WEB_AUTH) {
            return this.axiosInstanceWebAuth;
        } else if (baseServer === AXIOS_INSTANCE_INTEROP) {
            return this.axiosInstanceInterop;
        }
        return this.axiosInstanceGateway;
    }

    private static getConfig(baseUrl: string | undefined) {

        return {
            //baseURL: "https://lk.turne-trans.ru",
            baseURL: baseUrl,
            headers: {
                'Access-Control-Allow-Origin': '*'
                //Authorization: "ae2b1fca515949e5d54fb22b8ed95575",
                // Host: "lk.turne-trans.ru",
                //Accept: AxiosHeaderValue;
                //"Content-Length": "",
                //"User-Agent": "",
                //"Content-Encoding": "",
            },
            withCredentials: true,

            //! turned off for testing
            //timeout: 10000,
            // responseType: "json", // default

            // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
            //xsrfCookieName: "XSRF-TOKEN", // default

            // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
            //xsrfHeaderName: "X-XSRF-TOKEN", // default

            // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
            //maxContentLength: 2000,

            // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
            // maxBodyLength: 2000,

            //TODO
            // cancelToken: new CancelToken(function (cancel) {}),
        };
    }

}

export const axiosContext = new AxiosInstanceContext();



const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        //responseType: "json", // By default.
        //xsrfCookieName: "XSRF-TOKEN", // By default. Is the name of the cookie to use as a value for xsrf token
        //xsrfHeaderName: "X-XSRF-TOKEN", // By default. Is the name of the http header that carries the xsrf token value
        //maxContentLength: 2000, // defines the max size of the http response content in bytes allowed in node.js
        //maxBodyLength: 2000, // (Node only option) defines the max size of the http request content in bytes allowed
        //timeout: 10000,
    },
    withCredentials: true,
    timeout: 60 * 1000
});


export { axiosInstance }