import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const Airline_SU_Icon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
	        width={width}
            height={height}
	        viewBox="0 0 30 30"
	        style={style["svg"]}
        >
                <rect fill="#004993" width="30" height="30"/>
                <path fill="#FFFFFF" d="M16.932,17.824c0.082-0.043,0.161-0.086,0.24-0.133l0.038-0.021c0.017-0.011,0.033-0.021,0.05-0.031
                    l0.047-0.03l0.318,0.515c-0.137,0.09-0.281,0.172-0.432,0.248l0.591,1.229l-0.757,0.287l-0.588-1.219
                    c-0.472,0.143-0.983,0.221-1.52,0.221c-0.54,0-1.056-0.078-1.53-0.224l-0.622,1.222l-0.701-0.257l0.565-1.269
                    c-0.157-0.08-0.308-0.168-0.449-0.263l0.33-0.505c0.682,0.441,1.512,0.7,2.406,0.7c0.34,0,0.671-0.038,0.987-0.108
                    c0.055-0.01,0.117-0.023,0.191-0.046C16.479,18.033,16.691,17.947,16.932,17.824L16.932,17.824z M18.127,13.663v-0.06
                    c0-1.757-1.184-3.209-2.722-3.441l0.036-0.051c1.833,0.107,3.292,1.599,3.403,3.552H18.127L18.127,13.663z M18.773,14.706
                    c-0.211,1.067-0.834,1.978-1.687,2.548c-0.383,0.247-0.815,0.436-1.253,0.543c-0.27,0.061-0.548,0.094-0.834,0.094
                    c-1.851,0-3.395-1.354-3.765-3.156l-9.729,0.377L0,13.982h11.149l0.683-0.006c0.169,1.744,1.509,3.104,3.139,3.104
                    c0.229,0,0.452-0.027,0.667-0.078l-1.398-2.902l-1.007,0.462l-0.549-1.305l2.605-1.188l1.042,1.071l-1.356,0.621l1.417,2.945
                    c0.94-0.522,1.61-1.55,1.719-2.757h0.74L30,13.982l-1.506,1.129L18.773,14.706L18.773,14.706z M18.402,16.624l3.301,1.272
                    l-1.65,0.377l-2.085-1.158L18.402,16.624L18.402,16.624z M11.554,16.624l-3.301,1.272l1.65,0.377l2.084-1.158L11.554,16.624
                    L11.554,16.624z M18.893,15.74l5.678,1.129l-1.969,0.754l-3.998-1.391L18.893,15.74L18.893,15.74z M11.091,15.74l-5.676,1.129
                    l1.969,0.754l3.997-1.391L11.091,15.74L11.091,15.74z M19.098,14.994l8.342,0.638l-1.883,0.956l-6.574-1.13L19.098,14.994
                    L19.098,14.994z M10.917,14.994l-8.34,0.638l1.883,0.956l6.573-1.13L10.917,14.994L10.917,14.994z" />
        </svg>
    )
}