// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  Device = Desktops
  Screen = 1281px to higher resolution desktops
*/
/*
  Device = Laptops, Desktops
  Screen = from 1025px to 1280px
*/
/*
  Device = Tablets, Ipads (portrait)
  Screen = from 768px to 1024px
*/
/*
  Device = Tablets, Ipads (landscape)
  Screen = from 768px to 1024px
*/
/*
  Device = Low Resolution Tablets, Mobiles (Landscape)
  Screen = from 481px to 767px
*/
/*
  Device = Most of the Smartphones Mobiles (Portrait)
  Screen = from 320px to 479px
*/`, "",{"version":3,"sources":["webpack://./src/scss/mixins.scss"],"names":[],"mappings":"AA2BA;;;CAAA;AAUA;;;CAAA;AAUA;;;CAAA;AAUA;;;CAAA;AAUA;;;CAAA;AAUA;;;CAAA","sourcesContent":["@mixin laptop() {\r\n  @media (max-width: 1000px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin tablet() {\r\n  @media (max-width: 770px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin iphoneSe() {\r\n  @media (max-width: 475px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n// @mixin iphoneSe() {\r\n//   @media (min-width: 320px) {\r\n//     @content;\r\n//   }\r\n// }\r\n\r\n\r\n\r\n\r\n/*\r\n  Device = Desktops\r\n  Screen = 1281px to higher resolution desktops\r\n*/\r\n@mixin desktop() {\r\n  @media (min-width: 1281px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n/*\r\n  Device = Laptops, Desktops\r\n  Screen = from 1025px to 1280px\r\n*/\r\n@mixin laptop() {\r\n  @media (min-width: 1025px) and (max-width: 1280px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n/*\r\n  Device = Tablets, Ipads (portrait)\r\n  Screen = from 768px to 1024px\r\n*/\r\n@mixin tabletPortrait() {\r\n  @media (min-width: 768px) and (max-width: 1024px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n/*\r\n  Device = Tablets, Ipads (landscape)\r\n  Screen = from 768px to 1024px\r\n*/\r\n@mixin tabletLandscape() {\r\n  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {\r\n    @content;\r\n  }\r\n}\r\n\r\n/*\r\n  Device = Low Resolution Tablets, Mobiles (Landscape)\r\n  Screen = from 481px to 767px\r\n*/\r\n@mixin mobilesLandscape() {\r\n  @media (min-width: 481px) and (max-width: 767px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n/*\r\n  Device = Most of the Smartphones Mobiles (Portrait)\r\n  Screen = from 320px to 479px\r\n*/\r\n@mixin mobilesPortrait() {\r\n  @media (min-width: 320px) and (max-width: 480px) {\r\n    @content;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
