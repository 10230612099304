import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import {CompanyService} from "../../../services/CompanyService";
import {TripService} from "../../../services/TripService";
import {IOrderDTO} from "../../../interfaces/IOrderDTO";
import {ICreateOrderRequest} from "../../../api/request/ICreateOrderRequest";
import {OrdersTableComponent} from "./ordersTableComponent";
import "./style.scss";


export function TripPage() {

    const [searchParams] = useSearchParams();
    const params = Object.fromEntries(searchParams.entries());

    const navigate = useNavigate();

    const authState = useAppSelector((state) => state.auth);

    const tripService = TripService();
    const companyService = CompanyService();

    const [createOrderPending, setCreateOrderPending] = useState<boolean>(false);

    const [orders, setOrders] = useState<IOrderDTO[]>([]);
    const [ordersPending, setOrdersPending] = useState(false);
    const [isOrdersNotFound, setIsOrdersNotFound] = useState(false);


    // TODO: maybe do it after company change?
    const checkCompany = async () => {
        let isCompanyExists = await companyService.isCompanyExists(authState.currentClientCode);
        if (!isCompanyExists) {
            await companyService.importCompany(authState.currentCompany);
        }
    }


    const onCreateOrderButtonClick = async () => {
        setCreateOrderPending(true);

        await checkCompany();

        const request = {
            code: authState.currentClientCode,
            orderNo: undefined,
            userId: authState.userId,
            initiator: 1, // LK
        } as ICreateOrderRequest;

        const orderDetails = await tripService.createOrder(request);

        // TODO: improve it (separate OrderInfo and OrderDetails)
        if(orderDetails != null) {
            await loadOrders();
            navigate("/cabinet/orders/" + orderDetails.id);
        }

        setCreateOrderPending(false);


    }
    const onViewOrderButtonClick = async (orderInfo: IOrderDTO) => {
        await checkCompany();
        navigate("/cabinet/orders/" + orderInfo.id);
    }
    const onRemoveOrderButtonClick = async (orderInfo: IOrderDTO) => {
        const response = await tripService.removeOrder(orderInfo.id);
        if(response != null) {
            await loadOrders();
        }
    }

    const loadOrders = async () => {
        setOrdersPending(true);
        setOrders([]);
        const response = await tripService.getOrders(authState.currentClientCode);
        if (response.length > 0) {
            setOrders(response);
            setIsOrdersNotFound(false);
        } else {
            setIsOrdersNotFound(true);
        }
        setOrdersPending(false);
    }


    useEffect(() => {
        if(params.clientCode != undefined) {
            companyService.makeActiveCompany(params.clientCode);
        }
    }, [params.clientCode]);

    useEffect(() => {
        loadOrders();
    }, [authState.currentClientCode]);


    return (
        <>
            {
                (params.clientCode == undefined || params.clientCode == authState.currentClientCode) && (
                    <OrdersTableComponent
                        orders={orders}
                        isLoading={ordersPending}
                        isSearchResultNotFound={isOrdersNotFound}
                        onCreateOrderButtonClick={onCreateOrderButtonClick}
                        onViewOrderButtonClick={onViewOrderButtonClick}
                        onRemoveOrderButtonClick={onRemoveOrderButtonClick}
                        createOrderPending={createOrderPending}
                        onLoadOrdersButtonClick={loadOrders}
                    />
                )
            }

        </>
    );
}
