import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const ConditionerIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                d="M17,5a1,1,0,0,0-2,0V9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42L14.59,12,12,14.59l-3.29-3.3a1,1,0,1,0-1.42,1.42L9.59,15H5a1,1,0,0,0,0,2H9.59l-2.3,2.29a1,1,0,1,0,1.42,1.42L12,17.41,14.59,20l-3.3,3.29a1,1,0,0,0,1.42,1.42L15,22.41V27a1,1,0,0,0,2,0V22.41l2.29,2.3a1,1,0,0,0,1.42-1.42L17.41,20,20,17.41l3.29,3.3a1,1,0,0,0,1.42-1.42L22.41,17H27a1,1,0,0,0,0-2H22.41l2.3-2.29a1,1,0,0,0-1.42-1.42L20,14.59,17.41,12l3.3-3.29a1,1,0,1,0-1.42-1.42L17,9.59ZM16,18.59,13.41,16,16,13.41,18.59,16Z"
                style={style["path"]}
            />
        </svg>
    )
}