import React, {
    useRef,
    useState,
    CSSProperties, useLayoutEffect, useEffect
} from "react";
import './style.scss'


interface IMdScrollViewer {
    children?: any;
    scrollToItem?: string;
    style?: { [selector: string]: CSSProperties }
}


export function MdScrollViewer({ scrollToItem, children = null, style = {}}: IMdScrollViewer) {
    const root = useRef(null);
    const scrollContainer = useRef(null);
    const content = useRef(null);

    const [shadowedTop, setShadowedTop] = useState<boolean>(false);
    const [shadowedLeft, setShadowedLeft] = useState<boolean>(false);
    const [shadowedRight, setShadowedRight] = useState<boolean>(false);
    const [shadowedBottom, setShadowedBottom] = useState<boolean>(false);


    const onScroll = () => {
        if(scrollContainer.current != null) {
            const element = scrollContainer.current as HTMLElement;
            setShadowedTop(element.scrollTop > 0);
            setShadowedLeft(element.scrollLeft > 0);
            setShadowedRight(element.scrollWidth > element.offsetWidth && element.scrollLeft != (element.scrollWidth - element.offsetWidth));
            setShadowedBottom(element.scrollHeight > element.offsetHeight && element.scrollTop != (element.scrollHeight - element.offsetHeight));
        }
    }


    useEffect(() => {
        if(scrollToItem != null) {
            const item = document.getElementById(scrollToItem);
            if (scrollContainer.current != null && item != null) {
                (scrollContainer.current as HTMLElement).scrollTop = item.offsetTop;
            }
        }
    }, [scrollToItem]);


    useEffect(() => {
        if (!content.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if(scrollContainer.current != null) {
                const element = scrollContainer.current as HTMLElement;
                setShadowedTop(element.scrollTop > 0);
                setShadowedLeft(element.scrollLeft > 0);
                setShadowedRight(element.scrollWidth > element.offsetWidth && element.scrollLeft != (element.scrollWidth - element.offsetWidth));
                setShadowedBottom(element.scrollHeight > element.offsetHeight && element.scrollTop != (element.scrollHeight - element.offsetHeight));
            }
        });
        resizeObserver.observe(content.current);
        return () => resizeObserver.disconnect();
    }, []);


    return (
        <div
            ref={root}
            className={(
                "md-scroll-viewer"
                + (shadowedTop ? " --shadowed-top" : "")
                + (shadowedLeft ? " --shadowed-left" : "")
                + (shadowedRight ? " --shadowed-right" : "")
                + (shadowedBottom ? " --shadowed-bottom" : "")
            )}
            style={style["root"]}
        >
            <div className="top-shadow"></div>
            <div className="left-shadow"></div>
            <div ref={scrollContainer} className="scrollable-content" style={style["content"]} onScroll={onScroll}>
                <div ref={content} style={{width: "fit-content", height: "fit-content", minWidth: "100%"}}>
                    {
                        children
                    }
                </div>
            </div>
            <div className="right-shadow"></div>
            <div className="bottom-shadow"></div>
        </div>
    );
}