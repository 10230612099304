import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const Airline_DP_Icon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 30 30"
            style={style["svg"]}>
	            <rect
                    x="0"
                    y="0"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#0598D5"
                    width="30"
                    height="30"
                />
	            <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#FFFFFF"
                    d="M10.06,15c0,1.952-1.582,3.53-3.531,3.53   C4.58,18.53,3,16.952,3,15c0-1.952,1.58-3.53,3.529-3.53C8.478,11.47,10.06,13.047,10.06,15L10.06,15z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#FFFFFF"
                    d="M18.531,15c0,1.952-1.582,3.53-3.53,3.53   c-1.949,0-3.53-1.578-3.53-3.53c0-1.952,1.581-3.53,3.53-3.53C16.949,11.47,18.531,13.047,18.531,15L18.531,15z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#FFFFFF"
                    d="M27,15c0,1.952-1.582,3.53-3.53,3.53   c-1.948,0-3.529-1.578-3.529-3.53c0-1.952,1.581-3.53,3.529-3.53C25.418,11.47,27,13.047,27,15L27,15z"
                />
        </svg>
    )
}