import React from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../redux/hooks";
import {
    ExchangeAllowance,
    IAviaRejsInfo,
    IAviaTariffInfo,
    IAviaVariant,
    LuggageAllowance,
    RefundAllowance
} from "../../../../../../interfaces/IAviaVariant";
import {MdMenu} from "../../../../../../components/md-menu";
import {MdToggleButton} from "../../../../../../components/md-toggle-button";
import {MdScrollViewer} from "../../../../../../components/md-scroll-viewer";
import {AirplaneIcon} from "../../../../../../icons/AirplaneIcon";
import {WarningRhombusIcon} from "../../../../../../icons/WarningRhombusIcon";
import {HandLuggageIcon} from "../../../../../../icons/air-services/HandLuggageIcon";
import {LuggageIcon} from "../../../../../../icons/air-services/LuggageIcon";
import {RefundIcon} from "../../../../../../icons/air-services/RefundIcon";
import {ExchangeIcon} from "../../../../../../icons/air-services/ExchangeIcon";
import {Airline_Default_Icon} from "../../../../../../icons/air-companies/Airline_Default_Icon";
import {Airline_SU_Icon} from "../../../../../../icons/air-companies/Airline_SU_Icon";
import {Airline_U6_Icon} from "../../../../../../icons/air-companies/Airline_U6_Icon";
import {Airline_N4_Icon} from "../../../../../../icons/air-companies/Airline_N4_Icon";
import {Airline_6R_Icon} from "../../../../../../icons/air-companies/Airline_6R_Icon";
import {Airline_S7_Icon} from "../../../../../../icons/air-companies/Airline_S7_Icon";
import {Airline_R3_Icon} from "../../../../../../icons/air-companies/Airline_R3_Icon";
import {Airline_5N_Icon} from "../../../../../../icons/air-companies/Airline_5N_Icon";
import {Airline_DP_Icon} from "../../../../../../icons/air-companies/Airline_DP_Icon";
import {CheckMarkIcon} from "../../../../../../icons/CheckMarkIcon";
import {Guid, Utils} from "../../../../../../utils/utils";
import "./style.scss";
import {WaitIcon} from "../../../../../../icons/WaitIcon";
import {AirplaneTakeOffIcon} from "../../../../../../icons/AirplaneTakeOffIcon";
import {AirplaneLandingIcon} from "../../../../../../icons/AirplaneLandingIcon";


enum FlightServices {
    HandLuggage,
    Luggage,
    Refund,
    Exchange
}

interface IFlightService {
    code: FlightServices,
    name: string,
    icon: JSX.Element
}


export interface IFlightComponentCallbacks {
    select?: () => void;
    changeTariff?: (index: number) => void;
}

export interface IFlightComponent {
    variantInfo: IAviaVariant;
    selectedTariffIndex: number;
    tariffSelection?: boolean;
    selectable?: boolean;
    disabled?: boolean;
    callbacks?: IFlightComponentCallbacks;
}


export function FlightComponent({
        variantInfo,
        selectedTariffIndex,
        tariffSelection,
        selectable,
        disabled,
        callbacks
    }: IFlightComponent ) {

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);


    // TODO: change to airCompanyCode
    const airlineLogoMap = new Map<string, JSX.Element>([
        ['аэрофлот - российские авиалинии', <Airline_SU_Icon />],
        ['уральские авиалинии',             <Airline_U6_Icon />],
        ['северный ветер норд винд',        <Airline_N4_Icon />],
        ['ак алроса',                       <Airline_6R_Icon />],
        ['сибирь',                          <Airline_S7_Icon />],
        ['якутия',                          <Airline_R3_Icon />],
        ['смартавиа',                       <Airline_5N_Icon />],
        ['победа',                          <Airline_DP_Icon />],
    ]);


    const tickets = variantInfo.rejsInfo;
    const tariffs = tickets.map(x => x.tarifs).flat();
    const selectedTariff = tariffs[selectedTariffIndex];


    const getServices = (tariff: IAviaTariffInfo) => {
        const services: IFlightService[] = [
            { code: FlightServices.HandLuggage,  name: "Ручная кладь",  icon: <HandLuggageIcon /> },
            { code: FlightServices.Luggage,      name: "Багаж",         icon: <LuggageIcon /> },
            { code: FlightServices.Refund,       name: "Возврат",       icon: <RefundIcon /> },
            { code: FlightServices.Exchange,     name: "Обмен",         icon: <ExchangeIcon /> },
        ]

        return services.flatMap(service => {
            if(service.code === FlightServices.HandLuggage) {
                return service;
            }
            else if(service.code === FlightServices.Luggage && (tariff.bagaj === LuggageAllowance.Allow)) {
                return service;
            }
            else if(service.code === FlightServices.Refund && (tariff.vozvrat === RefundAllowance.Allow || tariff.vozvrat === RefundAllowance.WithFee)) {
                return {
                    ...service,
                    name: (tariff.vozvrat === RefundAllowance.WithFee ? "Возврат со сбором" : service.name)
                };
            }
            else if(service.code === FlightServices.Exchange && (tariff.obmen === ExchangeAllowance.Allow || tariff.obmen === ExchangeAllowance.WithFee)) {
                return {
                    ...service,
                    name: (tariff.obmen === ExchangeAllowance.WithFee ? "Обмен со сбором" : service.name)
                };
            }
            return [];
        }) ?? [];
    }

    const roadmap = tickets.map((ticket, ticketIndex) => {
        const dateDepartment = new Date(ticket.dateDep.replace('Z', ''));
        //dateDepartment.setTime( dateDepartment.getTime() + dateDepartment.getTimezoneOffset()*60*1000 );

        const townDepartment = ticket.cityDep.replace(/ *\([^)]*\) */g, "");


        const dateArrival = new Date(ticket.dateArr.replace('Z', ''));
        //dateArrival.setTime( dateArrival.getTime() + dateArrival.getTimezoneOffset()*60*1000 );

        const townArrival = ticket.cityArr.replace(/ *\([^)]*\) */g, "");


        let flightWarning = null;

        let messages = [];


        if (ticketIndex > 0 && !ticket.isBackwardTicketHead) {
            const prevDirection = tickets[ticketIndex - 1];
            const prevCity = prevDirection.cityArr.replace(/ *\([^)]*\) */g, "");
            const prevDateArrival = new Date(prevDirection.dateArr.replace('Z', ''));

            let waitTime = Utils.getTimeDetails(dateDepartment.getTime() - prevDateArrival.getTime());

            messages.push("пересадка в аэропорту города " + prevCity + " с ожиданием " + (waitTime.days > 0 ? waitTime.days + "д " : "") + waitTime.hours + "ч " + waitTime.minutes + "м");

            // other checks...

            flightWarning = (
                <div className="flight-roadmap-item">

                    <div className="flight-roadmap-item-content flight-roadmap-validation">
                        <div className="flight-roadmap-validation-node">
                            <div
                                className="flight-roadmap-validation-node-button">
                                <WarningRhombusIcon
                                    width="18px"
                                    height="18px"
                                    style={{
                                        "path": {
                                            fill: "#F66000" //"#838383"//"#F66000"
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flight-roadmap-validation-message">
                            <div
                                className="flight-roadmap-validation-message-title">
                                Предупреждение
                            </div>
                            <ul
                                className="flight-roadmap-validation-message-content">
                                {
                                    messages.map(message => {
                                        return (
                                            <li key={"flight-warning-message" + Guid.newGuid()}>{message}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>


                </div>
            )
        }

        return (
            <div key={"flight-roadmap-item" + Guid.newGuid()}>
                {
                    ticket.isBackwardTicketHead && (
                        <div className="flight-roadmap-backward-gap">
                            <WaitIcon
                                width="16px"
                                height="16px"
                                style={{
                                    "path": {
                                        fill: "#888888"
                                    }
                                }}
                            />
                            <span>Ожидание обратного рейса</span>
                        </div>
                    )
                }
                {
                    flightWarning != null && (
                        flightWarning
                    )
                }
                <div className="flight-roadmap-item">
                    <div
                        className="flight-roadmap-item-start-node">
                        <button className={"flight-roadmap-item-start-node-button" + (ticketIndex == 0 || ticket.isBackwardTicketHead ? " --flight-begin" : "")}>
                        </button>
                        <div className="flight-roadmap-item-start-node-info">
                            <AirplaneTakeOffIcon
                                width="12px"
                                height="12px"
                            />
                            <div className={"flight-roadmap-item-start-node-time" + (ticketIndex == 0 || ticket.isBackwardTicketHead ? " --flight-begin" : "")}>
                                <span>
                                    {
                                        dateDepartment.getHours().toString().padStart(2, "0") + ":" + dateDepartment.getMinutes().toString().padStart(2, "0")
                                    }
                                </span>
                                <span>
                                    {
                                        dateDepartment.toLocaleString('default', {month: 'short'}).replace(".", "")
                                        + " "
                                        + dateDepartment.getDate().toString().padStart(2, "0")
                                    }
                                </span>
                            </div>

                            <div className="flight-roadmap-item-start-node-location">
                                <span>
                                    {
                                        ticket.airCodeDep
                                    }
                                </span>
                                <span>
                                    {
                                        townDepartment
                                    }
                                </span>
                            </div>
                        </div>
                    </div>

                    <div
                        className="flight-roadmap-item-content">
                        <div
                            className="flight-roadmap-airline-info">
                            <div
                                className="flight-roadmap-airline-logo">
                                {
                                    (() => {
                                        const logo = airlineLogoMap.get(ticket.airCompanyName.trim().toLowerCase());
                                        return logo ??
                                            <Airline_Default_Icon/>;
                                    })()
                                }
                            </div>
                            <div
                                className="flight-roadmap-airline-details">
                                <span
                                    className="flight-roadmap-airline-name">
                                    {
                                        ticket.airCompanyName
                                    }
                                </span>
                                <span
                                    className="flight-roadmap-airline-number">
                                    {
                                        ticket.flightNumber
                                    }
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flight-roadmap-item-end-node">
                        <button className={"flight-roadmap-item-end-node-button" + (ticketIndex == tickets.length - 1 || (tickets.length > ticketIndex + 1 && tickets[ticketIndex + 1].isBackwardTicketHead) ? " --flight-end" : "")}>
                        </button>
                        <div className="flight-roadmap-item-end-node-info">
                            <AirplaneLandingIcon
                                width="12px"
                                height="12px"
                            />
                            <div className={"flight-roadmap-item-end-node-time" + (ticketIndex == tickets.length - 1 || (tickets.length > ticketIndex + 1 && tickets[ticketIndex + 1].isBackwardTicketHead) ? " --flight-end" : "")}>
                                <span>
                                    {
                                        dateArrival.getHours().toString().padStart(2, "0") + ":" + dateArrival.getMinutes().toString().padStart(2, "0")
                                    }
                                </span>
                                <span>
                                    {
                                        dateArrival.toLocaleString('default', {month: 'short'}).replace(".", "")
                                        + " "
                                        + dateArrival.getDate().toString().padStart(2, "0")
                                    }
                                </span>
                            </div>

                            <div className="flight-roadmap-item-end-node-location">
                                <span>
                                    {
                                        ticket.airCodeArr
                                    }
                                </span>
                                <span>
                                    {
                                        townArrival
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    const tariffOptions = tickets[tickets.length - 1].tarifs.map((tariff, tariffIndex) => {
        return (
            <React.Fragment
                key={tariffIndex}>
                <MdToggleButton
                    buttonStyle={{
                        minInlineSize: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "4px",
                        padding: "10px",
                        backgroundColor: (selectedTariffIndex == tariffIndex ? "rgba(116, 177, 0, 0.05)" : "rgba(204, 204, 204, 0.1)"),
                        border: (selectedTariffIndex == tariffIndex ? "1px solid #74B100" : "1px solid #CCCCCC"),
                    }}
                    inkStyle={{
                        backgroundColor: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                        opacity: 0.1
                    }}
                    checked={selectedTariffIndex == tariffIndex}
                    onClick={() => {
                        if (selectedTariffIndex == tariffIndex) {
                            return;
                        }
                        if (callbacks?.changeTariff != null) {
                            callbacks.changeTariff(tariffIndex);
                        }
                    }}
                >

                    <div className="tariff-option">

                        <div className="tariff-option-name">
                            {
                                tariff.name
                            }
                        </div>

                        <div className="tariff-services">
                            {
                                getServices(tariff).map((service, serviceIndex) => {
                                    return (
                                        <div key={serviceIndex} className="tariff-service --allow" title={service.name}>
                                            {
                                                service.icon
                                            }
                                            <span>
                                                {
                                                    service.name
                                                }
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="tariff-option-price">
                            {
                                (tariff.priceWithSborTts).toLocaleString() + " ₽"
                            }
                        </div>

                    </div>

                </MdToggleButton>
            </React.Fragment>
        )
    })


    const groupDirs = () => {
        const groups: IAviaRejsInfo[][] = [];
        const group: IAviaRejsInfo[] = [];

        tickets.forEach(ticket => {
            if(!ticket.isBackwardTicketHead) {
                group.push(ticket);
            }
            else {
                groups.push(group.slice());
                group.splice(0, group.length);
                group.push(ticket);
            }
        });

        if(group.length > 0) {
            groups.push(group.slice());
            group.splice(0, group.length);
        }

        return groups;
    }


    const select = () => {
        if(callbacks?.select != null) {
            callbacks.select();
        }
    }


    return (
        <div className="flight-component">

            <div className="flight-component-details">

                {
                    groupDirs().map((segments, directionIndex) => {

                        const airCompanyNames = [...new Set(segments.map(x => x.airCompanyName))];

                        const airportCode0 = directionIndex == 0 ? segments[0].airCodeDep : segments[segments.length - 1].airCodeArr;
                        const airportCode1 = directionIndex == 0 ? segments[segments.length - 1].airCodeArr : segments[0].airCodeDep;

                        const airportCity0 = directionIndex == 0 ? segments[0].cityDep : segments[segments.length - 1].cityArr;
                        const airportCity1 = directionIndex == 0 ? segments[segments.length - 1].cityArr : segments[0].cityDep;

                        const airportTime0 = new Date(directionIndex == 0 ? segments[0].dateDep.replace('Z', '') : segments[segments.length - 1].dateArr.replace('Z', ''));
                        //airportTime0.setTime( airportTime0.getTime() + airportTime0.getTimezoneOffset()*60*1000 );

                        const airportTime1 = new Date(directionIndex == 0 ? segments[segments.length - 1].dateArr.replace('Z', '') : segments[0].dateDep.replace('Z', ''));
                        //airportTime1.setTime( airportTime1.getTime() + airportTime1.getTimezoneOffset()*60*1000 );

                        const flightNumber = directionIndex == 0 ? segments[0].flightNumber : segments[segments.length - 1].flightNumber;


                        return (
                            <React.Fragment key={directionIndex}>

                                {
                                    directionIndex == 0 && (
                                        <div className="flight-component-details-header">

                                            <div className="flight-component-air-company-list">
                                                <MdMenu
                                                    behaviour='none'
                                                    header={
                                                        <div className="flight-component-air-company-list-header">
                                                            <div className="flight-component-air-company-list-header-icons">
                                                                {
                                                                    airCompanyNames.slice().reverse().map(airCompany => {
                                                                        const logo = airlineLogoMap.get(airCompany.trim().toLowerCase());
                                                                        return logo ?? <Airline_Default_Icon/>;
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="flight-component-air-company-list-header-names">
                                                                {
                                                                    airCompanyNames.join(' • ')
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                >

                                                    <MdScrollViewer
                                                        style={{
                                                            "content": {
                                                                maxWidth: "378px",
                                                                maxHeight: "430px"
                                                            }
                                                        }}
                                                    >
                                                        <div className="flight-component-roadmap">
                                                            {
                                                                roadmap
                                                            }
                                                        </div>
                                                    </MdScrollViewer>

                                                </MdMenu>
                                            </div>

                                            <div className="flight-component-tariff-info">
                                                <span className="tariff-info-description">
                                                    {
                                                        selectedTariff.name
                                                    }
                                                </span>
                                                {
                                                    authState.isSupportUser && (
                                                        selectedTariff?.note.match("\\w\\:\\w+")
                                                    )
                                                }
                                            </div>

                                            <div className="flight-component-tariff-list">
                                                <MdMenu
                                                    header={
                                                        <div className="flight-component-tariff-list-header">
                                                            <div className="flight-component-tariff-list-header-icons">
                                                                {
                                                                    getServices(selectedTariff).map((service, serviceIndex) => {
                                                                        return (
                                                                            <div key={serviceIndex} title={service.name} className="tariff-icon">
                                                                                {
                                                                                    service.icon
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="flight-component-tariff-list-header-price">
                                                                {
                                                                    (selectedTariff.priceWithSborTts).toLocaleString() + " ₽"
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <MdScrollViewer
                                                        style={{
                                                            "content": {
                                                                maxWidth: "380px"
                                                            }
                                                        }}
                                                    >
                                                        <div className="tariff-options">
                                                            {
                                                                tariffSelection !== false
                                                                    ? (
                                                                        tariffOptions
                                                                    )
                                                                    : (
                                                                        tariffOptions.filter((x, i) => i == selectedTariffIndex)
                                                                    )
                                                            }
                                                        </div>
                                                    </MdScrollViewer>
                                                </MdMenu>
                                            </div>

                                        </div>
                                    )
                                }

                                <div className="flight-component-details-content">

                                    <div className="flight-path-details">
                                        <div className="flight-track">
                                            <hr style={{marginTop: "16px "}}/>
                                            <div className="flight-track-airplane">
                                                <AirplaneIcon
                                                    width="16px"
                                                    height="16px"
                                                    style={{
                                                        "svg": {
                                                            transform: (directionIndex == 0 ? "scaleX(1)" : "scaleX(-1)")
                                                        },
                                                        "path": {
                                                            fill: "#3E5CB8"
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <hr style={{marginTop: "16px "}}/>
                                        </div>
                                        {
                                            segments.length > 1
                                                ? (
                                                    <div className="flight-connections">
                                                        пересадки: {segments.length - 1}
                                                    </div>
                                                )
                                                : (
                                                    <div className="flight-number">
                                                        {
                                                            flightNumber
                                                        }
                                                    </div>
                                                )
                                        }
                                    </div>

                                    <div className="airport-details">

                                        <div className="airport-details-0">
                                            <div className="airport-details-0-code">
                                                {
                                                    airportCode0
                                                }
                                            </div>
                                            <div className="airport-details-0-city">
                                                {
                                                    airportCity0.match(/([^()])+|(\(.*\))/g)?.shift()
                                                }
                                            </div>
                                            <div className="airport-details-0-time">
                                                {
                                                    airportTime0.getHours().toString().padStart(2, "0") + ":" + airportTime0.getMinutes().toString().padStart(2, "0")
                                                    + ", "
                                                    + airportTime0.toLocaleString('default', {month: 'short'}).replace(".", "")
                                                    + " "
                                                    + airportTime0.getDate().toString().padStart(2, "0")
                                                }
                                            </div>
                                        </div>

                                        <div className="airport-details-gap">

                                        </div>

                                        <div className="airport-details-1">
                                            <div className="airport-details-1-code">
                                                {
                                                    airportCode1
                                                }
                                            </div>
                                            <div className="airport-details-1-city">
                                                {
                                                    airportCity1.match(/([^()])+|(\(.*\))/g)?.shift()
                                                }
                                            </div>
                                            <div className="airport-details-1-time">
                                                {
                                                    airportTime1.getHours().toString().padStart(2, "0") + ":" + airportTime1.getMinutes().toString().padStart(2, "0")
                                                    + ", "
                                                    + airportTime1.toLocaleString('default', {month: 'short'}).replace(".", "")
                                                    + " "
                                                    + airportTime1.getDate().toString().padStart(2, "0")
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </React.Fragment>
                        )
                    })
                }

            </div>

            {
                selectable === true && (
                    <div className="flight-component-actions-layer">
                        <div className="flight-component-actions" onClick={() => select()}>
                            <CheckMarkIcon/>
                        </div>
                    </div>
                )
            }

        </div>
    );
}