import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const BedSheetsIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                d="M4,8.5H6v11h7v-7H26a2,2,0,0,1,2,2v9H26v-2H6v2H4Zm5.5,10A2.5,2.5,0,1,0,7,16,2.5,2.5,0,0,0,9.5,18.5Zm16.5-4H15v5H26Z"
                style={style["path"]}
            />
        </svg>
    )
}