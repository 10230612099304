import {AxiosResponse, CancelToken} from "axios";
import {axiosInstance} from "./AxiosInstance";
import {
    BEARER,
    ROUTE_GET_PASSENGERS,
    ROUTE_CREATE_PASSENGER,
    ROUTE_UPDATE_PASSENGER,
    ROUTE_CREATE_PASSENGER_DOCUMENT,
    ROUTE_GET_PASSENGER_DOCUMENTS,
    ROUTE_GET_PASSENGER_DOCUMENT,
    ROUTE_GET_PASSENGER,
    ROUTE_BIND_PASSENGER_TO_COMPANY,
    ROUTE_GET_PASSENGER_BONUSES,
    ROUTE_UPDATE_PASSENGER_DOCUMENT,
    ROUTE_CREATE_PASSENGER_BONUS,
    ROUTE_UPDATE_PASSENGER_BONUS,
    ROUTE_SEARCH_PASSENGERS,
} from "../constants/ApiRouteConstants";
import {IPassengerDTO} from "../interfaces/IPassengerDTO";
import {ICreatePassengerRequest} from "./request/ICreatePassengerRequest";
import {ICreatePassengerDocumentRequest} from "./request/ICreatePassengerDocumentRequest";
import {IPassengerDocumentDTO, IPassengerDocumentResponse} from "../interfaces/IPassengerDocumentDTO";
import {IPassengerBonusDTO} from "../interfaces/IPassengerBonusDTO";
import {IUpdatePassengerDocumentRequest} from "./request/IUpdatePassengerDocumentRequest";
import {ICreatePassengerBonusRequest} from "./request/ICreatePassengerBonusRequest";
import {IUpdatePassengerBonusRequest} from "./request/IUpdatePassengerBonusRequest";
import {ILkPax} from "../interfaces/ILkPax";


export function EmployeesApi() {

    /**
     * GET method for obtaining list of passengers
     * @param {string} clientCode - active company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerDTO[], any>>} response with list of passengers
     */
    const getPassengers = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerDTO[], any>> => {
        return await axiosInstance.get<Array<IPassengerDTO>>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PASSENGERS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining list of passengers
     * @param {string} clientCode - active company code
     * @param {string} searchName - search name
     * @param {string} pageNumber - page number
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ILkPax[], any>>} response with list of passengers
     */
    const getPaxesByClientCodes = async (clientCode: string | null, searchName: string | null, pageNumber: number = 1, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ILkPax[], any>> => {
        return await axiosInstance.get<Array<ILkPax>>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_SEARCH_PASSENGERS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode,
                    searchName: searchName,
                    pageNumber: pageNumber
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining passenger by code
     * @param {string} paxCode - passenger code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerDTO, any>>} response with passenger details
     */
    const getPassenger = async (paxCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerDTO, any>> => {
        return await axiosInstance.get<IPassengerDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PASSENGER,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    paxCode: paxCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method to create a passenger
     * @param {ICreatePassengerRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ILkPax>>} response with passenger details
     */
    const createPassenger = async (request: ICreatePassengerRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ILkPax>> => {
        return await axiosInstance.post<ILkPax>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_PASSENGER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * PUT method to bind passenger to company
     * @param {string} clientCode - company code
     * @param {string} paxCode - passenger code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean>>} response with passenger binding result
     */
    const bindPassengerToCompany = async (clientCode: string, paxCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean>> => {
        return await axiosInstance.put<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_BIND_PASSENGER_TO_COMPANY,
            null,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode,
                    paxCode: paxCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * PUT method to update passenger data
     * @param {ICreatePassengerRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ILkPax>>} response with passenger details
     */
    const updatePassenger = async (request: ICreatePassengerRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ILkPax>> => {
        return await axiosInstance.put<ILkPax>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_UPDATE_PASSENGER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining list of passenger documents
     * @param {string} paxCode - passenger code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerDocumentResponse[], any>>} response with list of passenger documents
     */
    const getPassengerDocuments = async (paxCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerDocumentResponse[], any>> => {
        return await axiosInstance.get<Array<IPassengerDocumentResponse>>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PASSENGER_DOCUMENTS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    paxCode: paxCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining passenger document by code
     * @param {string} docCode - document code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerDocumentDTO, any>>} response with passenger document details
     */
    const getPassengerDocument = async (docCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerDocumentDTO, any>> => {
        return await axiosInstance.get<IPassengerDocumentDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PASSENGER_DOCUMENT,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    docCode: docCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method to create a passenger document
     * @param {ICreatePassengerDocumentRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerDocumentDTO>>} response with passenger document details
     */
    const createPassengerDocument = async (request: ICreatePassengerDocumentRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerDocumentDTO>> => {
        return await axiosInstance.post<IPassengerDocumentDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_PASSENGER_DOCUMENT,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method to update a passenger document
     * @param {IUpdatePassengerDocumentRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerDocumentDTO>>} response passenger document
     */
    const updatePassengerDocument = async (request: IUpdatePassengerDocumentRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerDocumentDTO>> => {
        return await axiosInstance.put<IPassengerDocumentDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_UPDATE_PASSENGER_DOCUMENT,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining list of passenger bonuses
     * @param {string} paxCode - passenger code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerBonusDTO[], any>>} response with list of passenger bonuses
     */
    const getPassengerBonuses = async (paxCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerBonusDTO[], any>> => {
        return await axiosInstance.get<Array<IPassengerBonusDTO>>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PASSENGER_BONUSES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    paxCode: paxCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method to create a passenger bonus
     * @param {ICreatePassengerBonusRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerBonusDTO>>} response with passenger bonus details
     */
    const createPassengerBonus = async (request: ICreatePassengerBonusRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerBonusDTO>> => {
        return await axiosInstance.post<IPassengerBonusDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_PASSENGER_BONUS,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method to update a passenger bonus
     * @param {IUpdatePassengerBonusRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerBonusDTO>>} response with passenger bonus details
     */
    const updatePassengerBonus = async (request: IUpdatePassengerBonusRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerBonusDTO>> => {
        return await axiosInstance.put<IPassengerBonusDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_UPDATE_PASSENGER_BONUS,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };


    return {
        getPassengers,
        getPaxesByClientCodes,
        getPassenger,
        createPassenger,
        updatePassenger,
        bindPassengerToCompany,
        createPassengerDocument,
        updatePassengerDocument,
        getPassengerDocuments,
        getPassengerDocument,
        getPassengerBonuses,
        createPassengerBonus,
        updatePassengerBonus
    };
}