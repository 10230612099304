import {ICompanyCfoDTO} from "./ICompanyCfoDTO";
import {ITripBonusDTO, ITripPassengerDTO, ITripPassportDTO} from "./IOrderDTO";
import {IAviaVariant} from "./IAviaVariant";
import {TripElementStatus} from "../enums/TripElementStatus";
import {ITrainVariant} from "./ITrainVariant";
import {IAeroExpressVariant} from "./IAeroExpressVariant";
import {ISearchAeroExpressResponse} from "../api/response/ISearchAeroExpressResponse";
import {Utils} from "../utils/utils";
import {
    AeroExpressTariff
} from "../pages/cabinetPages/orderPage/orderDetailsComponent/modals/searchTicketsModal/searchAeroExpressComponent";


export class Trip {
    id: string;
    name: string;
    index: number;
    /*isOpened: boolean;*/
    items: (FlightTripElement | TrainTripElement | AeroExpressTripElement)[];
    passengers: PassengerTripElement[];
    passengerSelectedItems: IPassengerSelectedItems[];
    financialSupportCenter?: ICompanyCfoDTO;

    constructor(
        id: string,
        name: string,
        index: number,
        /*isOpened: boolean,*/
        items: (FlightTripElement | TrainTripElement | AeroExpressTripElement)[] = [],
        passengers: PassengerTripElement[] = [],
        passengerSelectedItems: IPassengerSelectedItems[],
        financialSupportCenter?: ICompanyCfoDTO
    ) {
        this.id = id;
        this.name = name;
        this.index = index;
        /*this.isOpened = isOpened;*/
        this.items = items;
        this.passengers = passengers;
        this.passengerSelectedItems = passengerSelectedItems;
        this.financialSupportCenter = financialSupportCenter;
    }
}

export class PassengerTripElement {
    id: string;
    details: ITripPassengerDTO;
    passport?: ITripPassportDTO;
    bonus?: ITripBonusDTO;

    constructor(
        id: string,
        details: ITripPassengerDTO,
        passport?: ITripPassportDTO,
        bonus?: ITripBonusDTO
    ) {
        this.id = id;
        this.details = details;
        this.passport = passport;
        this.bonus = bonus;
    }
}
export interface IPassengerSelectedItems {
    paxId: string;
    passportId: string;
    bonusId?: string;
}


export class FlightTripElement {
    get hash(): number {
        return Utils.stringHash(
            this.variant.rejsInfo[0].airCodeDep
            + "-"
            + this.variant.rejsInfo[0].airCodeArr
            + "-"
            + this.variant.rejsInfo[0].flightNumber
            + "-"
            + this.variant.rejsInfo[0].dateDep
        );
    };
    id: string;
    timestamp: number;
    isSelected: boolean;
    variant: IAviaVariant;
    tariffIndex: number;
    searchResponse: string;
    pnrs: string | undefined;
    status: TripElementStatus;

    constructor(id: string, timestamp: number, variant: IAviaVariant, tariffIndex: number, searchResponse: string, pnrs: string | undefined, status: TripElementStatus) {
        this.id = id;
        this.timestamp = timestamp;
        this.isSelected = false;
        this.variant = variant;
        this.tariffIndex = tariffIndex;
        this.searchResponse = searchResponse;
        this.pnrs = pnrs;
        this.status = status;
    }
}
export class TrainTripElement {
    get hash(): number {
        return Utils.stringHash(
            this.variant.cityFrom
            + "-"
            + this.variant.cityTo
            + "-"
            + this.variant.trainNum
            + "-"
            + this.variant.departureAt
            + "-"
            + this.variant.departureShortOn
        );
    };
    id: string;
    timestamp: number;
    isSelected: boolean;
    variant: ITrainVariant;
    tariffIndex: number;
    searchResponse: any;
    pnrs: string | undefined;
    status: TripElementStatus;

    constructor(id: string, timestamp: number, variant: ITrainVariant, tariffIndex: number, searchResponse: any, pnrs: string | undefined, status: TripElementStatus) {
        this.id = id;
        this.timestamp = timestamp;
        this.isSelected = false;
        this.variant = variant;
        this.tariffIndex = tariffIndex;
        this.searchResponse = searchResponse;
        this.pnrs = pnrs;
        this.status = status;
    }
}
export class AeroExpressTripElement {
    get hash(): number {
        return Utils.stringHash(
            this.variant.routeName
            + "-"
            + this.variant.tariffName
            + "-"
            + this.variant.dateDep
            + "-"
            + this.variant.originStationCode
        );
    };
    id: string;
    timestamp: number;
    isSelected: boolean;
    variant: IAeroExpressVariant;
    variants: IAeroExpressVariant[];
    tariffIndex: number;
    tariffId: AeroExpressTariff;
    searchResponse: any;
    pnrs: string | undefined;
    status: TripElementStatus;

    constructor(id: string, timestamp: number, variant: IAeroExpressVariant, tariffIndex: number, tariffId: AeroExpressTariff, searchResponse: any, pnrs: string | undefined, status: TripElementStatus) {
        this.id = id;
        this.timestamp = timestamp;
        this.isSelected = false;
        this.variant = variant;
        this.variants = (JSON.parse(searchResponse) as ISearchAeroExpressResponse).variants ?? [];
        this.tariffIndex = tariffIndex;
        this.tariffId = tariffId;
        this.searchResponse = searchResponse;
        this.pnrs = pnrs;
        this.status = status;
    }
}