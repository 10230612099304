import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {IPassengerDTO} from "../../../../interfaces/IPassengerDTO";
import {MdTable} from "../../../../components/md-table";
import {MdCheckbox} from "../../../../components/md-checkbox";
import {MdButton} from "../../../../components/md-button";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {SearchIcon2} from "../../../../icons/SearchIcon2";
import "./style.scss";
import "../../../../scss/tables.scss";
import {
    CURRENT_REACT_ENVIROMENT,
    ENVIROMENT_PRODUCTION
} from "../../../../constants/ServiceCostants";


interface IEmployeeListComponent {
    employees: IPassengerDTO[];
    reloadEmployees?: () => void;
    employeesPending: boolean,
    isEmployeesNotFound: boolean,
    selectedEmployees?: IPassengerDTO[];
    setSelectedEmployees?: React.Dispatch<React.SetStateAction<IPassengerDTO[]>>;
    onItemClick?: (user: IPassengerDTO) => void;
    openCreateEmployeeModal: () => void;
    openEditEmployeeModal: (employee: IPassengerDTO) => void;
}

export function EmployeeListComponent(props: IEmployeeListComponent) {

    // get the passed properties and assign default values
    const {
        employees,
        reloadEmployees,
        employeesPending,
        isEmployeesNotFound,
        selectedEmployees,
        setSelectedEmployees,
        onItemClick,
        openCreateEmployeeModal,
        openEditEmployeeModal
    } = Object.assign({
        reloadEmployees: () => {},
        selectedEmployees: [],
        setSelectedEmployees: () => {},
        onItemClick: () => {},
    }, props);

    const {t} = useTranslation();


    const selectSingle = (employee: IPassengerDTO) => {
        setSelectedEmployees([...selectedEmployees, employee]);
    };

    const unselectSingle = (employee: IPassengerDTO) => {
        setSelectedEmployees((prev) =>
            prev.filter(x => x.paxCode !== employee.paxCode)
        );
    };

    const selectAll = () => {
        setSelectedEmployees([...employees]);
    }

    const unselectAll = () => {
        setSelectedEmployees([]);
    }


    return (
        <div className="employees-results">
            <div className="employees-results-header">
                <div className="employees-results-caption">
                    {`${t("employeesPage.employees")}`}
                    <span style={{display: (employeesPending ? "none" : "block")}}>{employees.length}</span>
                    <span className="employees-results-preloader"
                          style={{display: (employeesPending ? "flex" : "none")}}>
                        <div className="spinner-border employees-results-preloader-icon"></div>
                        <span className="employees-results-preloader-text">
                            {`${t("employeesPage.searching")}`}
                        </span>
                    </span>
                </div>
                <div className="employees-results-tools">
                    {
                        (CURRENT_REACT_ENVIROMENT !== ENVIROMENT_PRODUCTION) && (
                            <MdButton
                                style={{
                                    "button": {
                                        borderWidth: 0,
                                        backgroundColor: "rgb(233 233 233)",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        height: "35px",
                                        padding: "5px 15px"
                                    },
                                    "icon": {
                                        marginRight: "5px",
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(255,255,255,.5)"
                                    }
                                }}
                                disabled={employeesPending}
                                onClick={() => {
                                    if(CURRENT_REACT_ENVIROMENT !== ENVIROMENT_PRODUCTION) {
                                         openCreateEmployeeModal();
                                    }
                                }}
                            >
                                {`${t("employeesPage.create")}`}
                            </MdButton>
                        )
                    }
                </div>
            </div>

            {
                (!employeesPending && !isEmployeesNotFound && employees.length > 0) && (
                    <MdTable
                        columns={[
                            {
                                width: 45,
                                headerTemplate() {
                                    return (
                                        <MdCheckbox
                                            type="filled"
                                            checked={(selectedEmployees.length == employees.length && employees.length > 0)}
                                            style={{
                                                "input": {
                                                    width: "16px",
                                                    height: "16px",
                                                },
                                            }}
                                            onChecked={selectAll}
                                            onUnchecked={unselectAll}
                                        />
                                    )
                                },
                                cellTemplate(data: IPassengerDTO) {
                                    return (
                                        <MdCheckbox
                                            type="filled"
                                            checked={
                                                selectedEmployees.some((x: IPassengerDTO) => x.id === data.id)
                                            }
                                            style={{
                                                "input": {
                                                    width: "16px",
                                                    height: "16px",
                                                },
                                            }}
                                            onChanged={(checked: boolean) => {
                                                if (checked) {
                                                    selectSingle(data);
                                                } else {
                                                    unselectSingle(data);
                                                }
                                            }}
                                        />
                                    )
                                }
                            },
                            {
                                header: t("employeesPage.fullName"),
                                cellTemplate(data: IPassengerDTO) {
                                    return <div>{[data.last, data.first, data.middle].join(' ')}</div>
                                }
                            },
                            {
                                width: 150,
                                header: t("employeesPage.gender"),
                                cellTemplate(data: IPassengerDTO) {
                                    return <div>{(data.gender == "муж" ? "Мужчина" : "Женщина")}</div>
                                }
                            },
                            {
                                width: 100,
                                header: t("employeesPage.dateOfBirth"),
                                cellTemplate(data: IPassengerDTO) {
                                    return <div>{data.birthday.substring(0, 10)}</div>
                                }
                            },
                            {
                                width: 240,
                                header: t("employeesPage.email"),
                                cellTemplate(data: IPassengerDTO) {
                                    return <div>{data.email}</div>
                                }
                            }
                        ]}
                        itemSource={employees}
                        onPreviewRowSelect={(employee: IPassengerDTO) => {
                            if(CURRENT_REACT_ENVIROMENT !== ENVIROMENT_PRODUCTION) {
                                openEditEmployeeModal(employee);
                            }
                            return false;
                        }}
                        style={{
                            "tbody": {
                                maxHeight: "calc(100vh - 400px)"
                            }
                        }}
                    />
                )
            }

            {(isEmployeesNotFound) && (
                <div className="no-orders-message" style={{margin: "40px 0"}}>
                    <div className="no-orders-message-icon">
                        <SearchIcon2
                            width="50px"
                            height="50px"
                            style={{
                                "path": {
                                    fill: "rgb(100 100 100)"
                                }
                            }}
                        />
                    </div>
                    <div className="no-orders-message-caption">
                        {t("employeesPage.noEmployeesToDisplay")}
                    </div>
                    <div className="no-orders-message-content">
                        {t("employeesPage.createEmployeeOrSearchAgain")}
                    </div>
                    <div className="no-orders-message-button">
                        {
                            !employeesPending
                                ? (
                                    <MdButton
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "rgb(233 233 233)",
                                                color: "rgb(0 0 0)",
                                                borderRadius: "6px",
                                                minWidth: "92px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={() => reloadEmployees()}
                                    >
                                        {t("employeesPage.retry")}
                                    </MdButton>
                                )
                                : (
                                    <CircleSpinnerIcon
                                        width="18px"
                                        height="18px"
                                        style={{
                                            "circle": {
                                                stroke: "rgb(50 50 50)"
                                            }
                                        }}
                                    />
                                )
                        }
                    </div>
                </div>
            )}

        </div>
    );
}
