import React from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../redux/hooks";
import {
    CURRENT_REACT_ENVIROMENT,
    ENVIROMENT_PRODUCTION,
    ENVIROMENT_DEVELOPMENT,
    ENVIROMENT_STAGING
} from "../../constants/ServiceCostants";
import "./style.scss";


enum Pages {
    Account,
    Profile,
    Orders,
    Reports,
    Documents,
    Users,
    Employees,
    Support
}


export function CabinetLayout() {

    const {t} = useTranslation();

    const location = useLocation();

    const authState = useAppSelector(state => state.auth);


    const getActivePage = () => {
        const segments = location.pathname.split('/');

        if (location.pathname === "/cabinet/account") {
            return Pages.Account;
        }
        else if (segments.includes("cabinet") && segments.includes("profile")) {
            return Pages.Profile;
        }
        else if (segments.includes("cabinet") && segments.includes("orders")) {
            return Pages.Orders;
        }
        else if (segments.includes("cabinet") && segments.includes("reports")) {
            return Pages.Reports;
        }
        else if (segments.includes("cabinet") && segments.includes("documents")) {
            return Pages.Documents;
        }
        else if (segments.includes("cabinet") && segments.includes("users")) {
            return Pages.Users;
        }
        else if (segments.includes("cabinet") && segments.includes("employees")) {
            return Pages.Employees;
        }
        else if (segments.includes("cabinet") && segments.includes("support")) {
            return Pages.Support;
        }
    }


    return (
        <div className="cabinet-layout-container">

            <div className="sub-header">
                <div className="header-container"
                     style={{
                         justifyContent: "center",
                         flexWrap: "wrap",
                         gap: "5px"
                     }}>
                    <ul>

                        {
                            (CURRENT_REACT_ENVIROMENT == ENVIROMENT_DEVELOPMENT) && (
                                <li className={(getActivePage() === Pages.Account ? "--active" : "")}>
                                    <Link className="sub-header-item" to="/cabinet/account">
                                        {t("cabinetLeftMenu.account")}
                                    </Link>
                                </li>
                            )
                        }

                        {
                            (CURRENT_REACT_ENVIROMENT == ENVIROMENT_DEVELOPMENT) && (
                                <li className={(getActivePage() === Pages.Profile ? "--active" : "")}>
                                    <Link className="sub-header-item" to="/cabinet/profile">
                                        {t("cabinetLeftMenu.myProfile")}
                                    </Link>
                                </li>
                            )
                        }

                        <li className={(getActivePage() === Pages.Orders ? "--active" : "")}>
                            <Link className="sub-header-item" to="/cabinet/orders">
                                {t("cabinetLeftMenu.orders")}
                            </Link>
                        </li>

                        {
                            (CURRENT_REACT_ENVIROMENT == ENVIROMENT_DEVELOPMENT) && (
                                <li className={(getActivePage() === Pages.Reports ? "--active" : "")}>
                                    <Link className="sub-header-item" to="/cabinet/reports">
                                        {t("cabinetLeftMenu.reports")}
                                    </Link>
                                </li>
                            )
                        }

                        <li className={(getActivePage() === Pages.Documents ? "--active" : "")}>
                            <Link className="sub-header-item" to="/cabinet/documents">
                                {t("cabinetLeftMenu.accDocuments")}
                            </Link>
                        </li>

                        {
                            authState.isSupportUser && (
                                <li className={(getActivePage() === Pages.Users ? "--active" : "")}>
                                    <Link className="sub-header-item" to="/cabinet/users">
                                        {t("cabinetLeftMenu.users")}
                                    </Link>
                                </li>
                            )
                        }

                        <li className={(getActivePage() === Pages.Employees ? "--active" : "")}>
                            <Link className="sub-header-item" to="/cabinet/employees">
                                {t("cabinetLeftMenu.employees")}
                            </Link>
                        </li>

                        {
                            (authState.isSupportUser && (CURRENT_REACT_ENVIROMENT === ENVIROMENT_PRODUCTION && authState.email === 'lk-dev@turne-trans.ru' ? true : CURRENT_REACT_ENVIROMENT !== ENVIROMENT_PRODUCTION)) && (
                                <li className={(getActivePage() === Pages.Support ? "--active" : "")}>
                                    <Link className="sub-header-item" to="/cabinet/support">
                                        {t("cabinetLeftMenu.support")}
                                    </Link>
                                </li>
                            )
                        }

                    </ul>
                </div>
            </div>

            <div className="cabinet-view-container">
                <div className="cabinet-view-content">
                    <Outlet/>
                </div>
            </div>

        </div>
    );
}