import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const RZD_Icon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="150 0 702.6667 733.33331"
            style={style["svg"]}>
            <g transform="matrix(1.3333333,0,0,-1.3333333,0,733.33333)">
                <g transform="scale(0.1)">
                    <path
                        fill="#e31d25"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="M 7520,0 H 0 V 5500 H 7520 V 0"
                    />
                    <path
                        fill="#ffffff"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="m 4567.05,3364.64 v -66.05 h 568.75 c 37.63,0 94.7,0 132.46,-38.08 37.77,-37.82 37.77,-94.61 37.77,-132.71 v -568.13 c 0,-37.67 0,-94.79 -37.77,-132.46 -37.76,-37.91 -94.83,-37.91 -132.46,-37.91 h -274.74 c -47.4,0 -109.08,0 -132.7,52.19 -23.72,51.95 9.44,99.34 37.91,137.25 l 369.53,492.08 H 4567.05 L 4340.09,2767.9 c -37.96,-50.73 -70.98,-94.65 -70.98,-151.35 0,-56.88 30.39,-97.89 70.98,-151.67 l 57.02,-75.58 c 56.42,-75.72 113.45,-151.59 189.16,-189.45 75.95,-37.76 165.71,-37.76 288.97,-37.76 h 246.38 c 141.9,0 355.26,0 497.15,141.87 142.05,142.17 142.05,350.41 142.05,426.36 v 227.21 c 0,75.77 0,284.09 -142.05,426 -141.89,141.95 -355.25,141.95 -497.15,141.95 h -393.26 c -37.76,0 -85.21,0 -122.93,-37.72 -38.38,-37.92 -38.38,-85.12 -38.38,-123.12"
                    />
                    <path
                        fill="#ffffff"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="m 3885.44,3070.82 h 568.09 l -681.76,-909.01 h -568.23 l 681.9,909.01"
                    />
                    <path
                        fill="#ffffff"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="m 1669.36,2910.27 c 0,37.72 0,85.07 37.86,123.03 37.96,37.52 85.11,37.52 123.17,37.52 h 951.75 c 123.03,0 213.16,0 288.79,-37.52 75.82,-37.96 132.61,-113.77 189.72,-189.39 l 56.56,-76.01 c 40.26,-53.69 71.12,-94.65 71.12,-151.35 0,-56.88 -32.89,-100.85 -71.12,-151.67 l -227.26,-303.07 h -568.36 l 369.39,492.56 c 28.56,37.71 61.67,85.11 38.05,137.45 -23.86,52.09 -85.31,52.09 -132.94,52.09 H 2351.31 V 1707.44 H 1896.7 v 1136.47 h -227.34 v 66.36"
                    />
                </g>
            </g>
        </svg>
    )
}