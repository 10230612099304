import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const WashBasinIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                d="M17,4.5a3,3,0,0,0-3,3v2h2v-2a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v6H21v2H4.92a.92.92,0,0,0-.92.92A11.08,11.08,0,0,0,15.08,27.5h1.84A11.08,11.08,0,0,0,28,16.42a.92.92,0,0,0-.92-.92H26v-2H25v-6a3,3,0,0,0-3-3ZM6.06,17.5a9.09,9.09,0,0,0,9,8h1.84a9.09,9.09,0,0,0,9-8Z"
                style={style["path"]}
            />
        </svg>
    )
}