import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const FoodIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                d="M6,6v6.8A1.36,1.36,0,0,0,6,13H6v.5A5.33,5.33,0,0,0,6.21,15a5.49,5.49,0,0,0,4.29,3.91V26a1,1,0,0,0,2,0V18.91A5.49,5.49,0,0,0,16.79,15,5.33,5.33,0,0,0,17,13.5V13h0a1.36,1.36,0,0,0,0-.2V6a1,1,0,0,0-2,0v6.8a1.36,1.36,0,0,0,0,.2H12.5V6a1,1,0,0,0-2,0v7H8a1.36,1.36,0,0,0,0-.2V6A1,1,0,0,0,6,6Zm5.5,11a3.49,3.49,0,0,1-3.16-2h6.32A3.49,3.49,0,0,1,11.5,17ZM24,7H23a2,2,0,0,0-2,2v8h3ZM21,19h3v7a1,1,0,0,0,2,0V5H23a4,4,0,0,0-4,4V19Z"
                style={style["path"]}
            />
        </svg>
    )
}