export enum LuggageAllowance {
    Allow,
    Deny,
    Undefined
}

export enum RefundAllowance {
    Deny,
    Allow,
    WithFee,
    Undefined
}

export enum ExchangeAllowance {
    Deny,
    Allow,
    WithFee,
    Undefined
}

// TODO: rename to IFlightTariff
export interface IAviaTariffInfo {
    hasShtraf:boolean;
    klassList:string[];
    vozSettId:number;
    obmSettId:number;
    name: string|null;
    vozvrat: RefundAllowance;
    obmen: ExchangeAllowance;
    vozvratDo: string;
    obmenDo: string;
    vozvratPosle: string;
    obmenPosle: string;
    shtrafVozvratDo: number | null;
    shtrafVozvratPosle: number | null;
    shtrafObmenDo: number | null;
    shtrafObmenPosle: number | null;
    bonusMili: string;
    bagaj: LuggageAllowance;
    bagajInfo: string;
    price: number;
    priceWithSborTts: number;
    sborTts: number;
    fareBasis: string[];
    note:string
    commissionTts: number;
    gds: string;
    isSelected: boolean;
    rejsAir:any;
    VariantNumber:number;
    SegNumber: string | null;
}
// TODO: rename to IFlightSegment
export interface IAviaRejsInfo {
    airCompanyCode: string;
    airCompanyName: string;
    cityCodeDep: string;
    cityDep: string;
    airCodeDep: string;
    airDep: string;
    cityCodeArr: string;
    cityArr: string;
    airCodeArr: string;
    airArr: string;
    flightNumber: string;
    flightBoard: any;
    gds: string;
    estimated: string;
    totalTransfers: string;
    dateDep: string;
    dateArr: string;
    tarifs: IAviaTariffInfo[];
    isSelected: boolean;
    segNumber: string | null;
    variantNumber: number;
    isBackwardTicketHead?: boolean;
}
// TODO: rename to IFlightVariant
export interface IAviaVariant {
    segNumber: any;
    rejsInfo: IAviaRejsInfo[];
    isSelected: boolean;
    isNotReplayVisible: boolean;
    variantNumber: number;
    hideVariantNumber:boolean
}