export const BEARER = "Bearer ";
//Base routes
export const BASE_URL = process.env.REACT_APP_BASE_ROUTE;

//ToDo Update Rurtes
export const BASE_ROUTE_AUTH_SERVICE = process.env.REACT_APP_BASE_ROUTE_AUTH_SERVICE;
export const BASE_ROUTE_GATEWAY_SERVICE = process.env.REACT_APP_GATEWAY_ROUTE;
export const  BASE_ROUTE_INTEROP_SERVICE = process.env.REACT_APP_INTEROP_BASE_ROUTE;

// Hubs Routes
export const ROUTE_REPORTS_HUB  = "/api/v1/Edm/edmHub";
export const ROUTE_CHAT_HUB               = "/chatHub";
export const ROUTE_SEARCH_HUB               = "/api/v1/interop/searchHub";

// WebAuth Routes (Auth)
export const ROUTE_LOGIN                  = "/gateway/api/v1/webauth/auth/login";
export const ROUTE_RECOVERY_PASSWORD      = "/gateway/api/v1/webauth/auth/recovery";
export const ROUTE_CHANGE_PASSWORD        = "/gateway/api/v1/webauth/auth/changePassword";
export const ROUTE_REFRESH_TOKEN          = "/gateway/api/v1/webauth/auth/refresh-token";
export const ROUTE_REVOKE_TOKEN           = "/gateway/api/v1/webauth/auth/revoke-token";
export const ROUTE_LOGOUT                 = "/gateway/api/v1/webauth/auth/logout";
export const ROUTE_GET_USER_ACTIVE_STATE  = "/gateway/api/v1/webauth/auth/isActiveUser";
// WebAuth Routes (Orders)
export const ROUTE_GET_ORDERS                = "/gateway/api/v1/webauth/order/getClientOrders";
export const ROUTE_GET_ORDER_BY_ID           = "/gateway/api/v1/webauth/order/getOrderById";
export const ROUTE_CREATE_ORDER              = "/gateway/api/v1/webauth/order/createOrder";
export const ROUTE_REMOVE_ORDER              = "/gateway/api/v1/webauth/order/deleteOrder";
export const ROUTE_CREATE_TRIP               = "/gateway/api/v1/webauth/order/createTrip";
export const ROUTE_PATCH_TRIP                = "/gateway/api/v1/webauth/order/patchTrip";
export const ROUTE_REMOVE_TRIP               = "/gateway/api/v1/webauth/order/deleteTrip";
export const ROUTE_ADD_PAX_TO_TRIP           = "/gateway/api/v1/webauth/order/addPaxesInTrip";
export const ROUTE_PATCH_PAX_IN_TRIP         = "/gateway/api/v1/webauth/order/patchPaxContextInTrip";
export const ROUTE_REMOVE_PAX_FROM_TRIP      = "/gateway/api/v1/webauth/order/removePaxesFromTrip";
export const ROUTE_CREATE_TRIP_ITEM          = "/gateway/api/v1/webauth/order/createTripElement";
export const ROUTE_CREATE_FLIGHT_ITEM        = "/gateway/api/v1/webauth/order/createFlightElement";
export const ROUTE_CREATE_TRAIN_ITEM         = "/gateway/api/v1/webauth/order/createTrainElement";
export const ROUTE_CREATE_AERO_EXPRESS_ITEM  = "/gateway/api/v1/webauth/order/createAeroExpressElement";
export const ROUTE_PATCH_TRIP_ITEM           = "/gateway/api/v1/webauth/order/patchTripElement";
export const ROUTE_PATCH_FLIGHT_ITEM         = "/gateway/api/v1/webauth/order/patchFlightElement";
export const ROUTE_PATCH_TRAIN_ITEM          = "/gateway/api/v1/webauth/order/patchTrainElement";
export const ROUTE_PATCH_AERO_EXPRESS_ITEM   = "/gateway/api/v1/webauth/order/patchAeroexpressElement";
export const ROUTE_REMOVE_TRIP_ITEM          = "/gateway/api/v1/webauth/order/deleteTripElement";
// WebAuth Routes (Paxes)
export const ROUTE_IS_PAX_EXIST              = "/gateway/api/v1/webauth/pax/isExist";
export const ROUTE_ADD_PAX_PASSPORTS         = "/gateway/api/v1/webauth/pax/addPassports";
export const ROUTE_ADD_PAX_BONUSES           = "/gateway/api/v1/webauth/pax/addBonuses";
export const ROUTE_CREATE_PAX                = "/gateway/api/v1/webauth/pax/createPax";


export const ROUTE_SEND_ORDER_TO_TTS          = "/gateway/api/v1/interop/order/saveAnswer";
export const ROUTE_ORDER_BOOK                 = "/gateway/api/v1/interop/order/book";
export const ROUTE_ORDER_ISSUE                = "/gateway/api/v1/interop/order/issueOrder";


// WebAuth Routes (Support user)
export const ROUTE_ADD_SUPPORT_USER       = "/gateway/api/v1/webauth/supportUser/signup";
export const ROUTE_GET_ALL_SUPPORT_USERS  = "/gateway/api/v1/webauth/supportUser/getSupportUsers";
export const ROUTE_GET_SUPPORT_USER         = "/gateway/api/v1/interop/supportUsers/getSupportUser";
export const ROUTE_DELETE_SUPPORT_USER    = "/gateway/api/v1/webauth/supportUser/deleteSupportUser";

// Interop Routes (Pax)
export const ROUTE_GET_PASSENGERS              = "/gateway/api/v1/interop/pax/getpaxes";
export const ROUTE_SEARCH_PASSENGERS           = "/gateway/api/v1/interop/pax/getPaxesByClientCodes";
export const ROUTE_GET_PASSENGER               = "/gateway/api/v1/interop/pax/getPaxDetails";
export const ROUTE_CREATE_PASSENGER            = "/gateway/api/v1/interop/pax/createPax";
export const ROUTE_UPDATE_PASSENGER            = "/gateway/api/v1/interop/pax/updatePax";
export const ROUTE_BIND_PASSENGER_TO_COMPANY   = "/gateway/api/v1/interop/pax/bindPaxToCompany";
export const ROUTE_CREATE_PASSENGER_DOCUMENT   = "/gateway/api/v1/interop/pax/createPaxDoc";
export const ROUTE_CREATE_PASSENGER_BONUS      = "/gateway/api/v1/interop/pax/createPaxBonus";
export const ROUTE_UPDATE_PASSENGER_DOCUMENT   = "/gateway/api/v1/interop/pax/updatePaxDoc";
export const ROUTE_UPDATE_PASSENGER_BONUS      = "/gateway/api/v1/interop/pax/updatePaxBonus";
export const ROUTE_GET_PASSENGER_DOCUMENTS     = "/gateway/api/v1/interop/pax/getPaxDocuments";
export const ROUTE_GET_PASSENGER_BONUSES       = "/gateway/api/v1/interop/pax/getPaxBonuses";
export const ROUTE_GET_PASSENGER_DOCUMENT      = "/gateway/api/v1/interop/pax/getPaxDocument";
// Interop Routes (Flights)
export const ROUTE_GET_FLIGHT_PROVIDERS        = "/gateway/api/v1/interop/flights/getProviders"
export const ROUTE_GET_FLIGHTS                 = "/gateway/api/v1/interop/flights/search";
export const ROUTE_SEND_FLIGHTS_RESULT_TO_TTS  = "/gateway/api/v1/interop/flights/sendSearchResultToTts";

export const ROUTE_BOOK_FLIGHTS                 = "/gateway/api/v1/interop/flights/book";
export const ROUTE_ISSUE_FLIGHTS                = "/gateway/api/v1/interop/flights/issue";
// Interop Routes (Aero Express)
export const ROUTE_GET_AERO_EXPRESS             = "/gateway/api/v1/interop/AeroExpress/search";
// Interop Routes (Order)
export const ROUTE_IS_ORDER_ISSUED              = "/gateway/api/v1/interop/order/hasIssuedBso";
export const ROUTE_GET_ORDER_BOOKING_DETAILS    = "/gateway/api/v1/interop/order/getOrderBookingInfo";
export const ROUTE_GET_ORDER_ISSUE_DETAILS      = "/gateway/api/v1/interop/order/getOrderIssueDetails";
export const ROUTE_GET_ORDER_PDF_TICKET_NAMES   = "/gateway/api/v1/interop/order/getPdfTicketNames";
export const ROUTE_GET_ORDER_PDF_TICKET_ARCHIVE = "/gateway/api/v1/interop/order/getPdfTickets";
export const ROUTE_GET_CAN_ORDER_ISSUE           = "/gateway/api/v1/interop/order/canIssue"

// Interop Routes (Trains)
export const ROUTE_GET_TRAIN_PROVIDERS        = "/gateway/api/v1/interop/trains/getProviders"
export const ROUTE_GET_TRAINS                 = "/gateway/api/v1/interop/trains/search";
export const ROUTE_SEND_TRAINS_RESULT_TO_TTS  = "/gateway/api/v1/interop/trains/sendSearchResultToTts";
export const ROUTE_BOOK_TRAINS                = "/gateway/api/v1/interop/trains/book";
export const ROUTE_ISSUE_TRAINS               = "/gateway/api/v1/interop/trains/issue";
// Interop Routes (Companies)
export const ROUTE_SEARCH_COMPANIES_BY_NAME        = "/gateway/api/v1/interop/companies/searchCompaniesByName"
export const ROUTE_GET_COMPANY_INFO                = "/gateway/api/v1/interop/companies/getCompanyInfo";
export const ROUTE_GET_COMPANY_CFOs                = "/gateway/api/v1/interop/companies/getCompanyCfos";
// Interop Routes (Documents)
export const ROUTE_GET_ACCOUNTANT_DOCS               = "/gateway/api/v1/interop/docs/getAccountantDocuments"
export const ROUTE_GET_CUSTOMER_AIR_COMPANIES        = "/gateway/api/v1/interop/docs/getCustomerAirCompanies";
export const ROUTE_GET_CUSTOMER_FLIGHTS              = "/gateway/api/v1/interop/docs/getCustomerFlights";
export const ROUTE_GET_CUSTOMER_TICKET_ORDERS        = "/gateway/api/v1/interop/docs/getCustomerTicketOrders";
export const ROUTE_GET_CUSTOMER_ADDITIONAL_SERVICES  = "/gateway/api/v1/interop/docs/getCustomerAdditionalServices";
export const ROUTE_GET_STATEMENT_INCOMES             = "/gateway/api/v1/interop/docs/getStatementIncomes";
export const ROUTE_GET_STATEMENT_OPEN_BALANCE        = "/gateway/api/v1/interop/docs/getStatementOpenBalance";
export const ROUTE_GET_STATEMENT_CLOSE_BALANCE       = "/gateway/api/v1/interop/docs/getStatementCloseBalance";
export const ROUTE_GET_STATEMENT_SALES               = "/gateway/api/v1/interop/docs/getStatementSales";
export const ROUTE_GET_ALLOWED_PERSONS               = "/gateway/api/v1/interop/docs/getAllowedPerson";
export const ROUTE_GET_CLIENT_DEPT                   = "/gateway/api/v1/interop/docs/getDeptInfo";
export const ROUTE_GET_CLIENT_SALES_STRUCTURE        = "/gateway/api/v1/interop/docs/getSalesStructure";
export const ROUTE_GET_CLIENT_SALES                  = "/gateway/api/v1/interop/docs/getClientSales";
// Interop Routes (Support user)
export const ROUTE_GET_SUPPORT_USER_INTEROP  = "/gateway/api/v1/interop/supportUser/getSupportUser";

// Storage Routes (Orders)
export const ROUTE_GET_ORDER_PDF_TICKET   = "/gateway/api/v1/storage/edm/download";

// EDM Routes (Documents)
export const ROUTE_GET_EDM_UPLOADS       = "/gateway/api/v1/interop/edm/docs/uploads";
export const ROUTE_GENERATE_EDM_REPORTS  = "/gateway/api/v1/interop/edm/docs/send";

// TTS Sync Routes (Users)
export const ROUTE_GET_USERS                  = "/gateway/api/v1/webauth/ttsSync/getUsers";
export const ROUTE_GET_USER_ROLES             = "/gateway/api/v1/webauth/ttsSync/getUserRoles";
export const ROUTE_GET_USER_ROLES_IN_COMPANY  = "/gateway/api/v1/webauth/ttsSync/getUserRolesInCompany";
export const ROUTE_ADD_USER                   = "/gateway/api/v1/webauth/ttsSync/signup";
export const ROUTE_UPDATE_USER                = "/gateway/api/v1/webauth/ttsSync/updateUser";
export const ROUTE_ADD_DEFAULT_COMPANY_ROLES  = "/gateway/api/v1/webauth/ttsSync/addDefaultRoles"
// TTS Sync Routes (Companies)
export const ROUTE_IS_COMPANY_EXIST      = "/gateway/api/v1/webauth/ttsSync/isCompanyExists"
export const ROUTE_GET_COMPANY_BY_CODE   = "/gateway/api/v1/webauth/ttsSync/getCompany";
export const ROUTE_ADD_OR_UPDATE_COMPANY = "/gateway/api/v1/webauth/ttsSync/addOrUpdateCompany";
export const ROUTE_IMPORT_COMPANY        = "/gateway/api/v1/webauth/ttsSync/importCompany";
export const ROUTE_GET_ALL_COMPANIES     = "/gateway/api/v1/webauth/ttsSync/getAllCompanies";
export const ROUTE_GET_COMPANY_ROLES     = "/gateway/api/v1/webauth/ttsSync/getCompanyUserRoles";

export const ROUTE_GET_ALL_COUNTRIES         = "/gateway/api/v1/webrefs/country/getAllCountries";
export const ROUTE_GET_COUNTRIES             = "/gateway/api/v1/webrefs/country/searchCountries";
export const ROUTE_GET_REFS_AVIA_CITIES      = "/gateway/api/v1/webrefs/avia/searchCities";
export const ROUTE_GET_REFS_TRAIN_STATION    = "/gateway/api/v1/webrefs/train/searchstation";

// Chat Routes
export const ROUTE_GET_CHAT_ROOMS              = "/api/v1/webauth/room/getUserChatRooms";
export const ROUTE_CREATE_CHAT_ROOM            = "/api/v1/webauth/room/createRoom";
export const ROUTE_GET_CHAT_ROOM_BY_ID          = "/api/v1/webauth/room/getRoom";
export const ROUTE_GET_CHAT_ROOM_MESSAGES     = "/api/v1/webauth/message/getMessagesByRoomId";
export const ROUTE_SEND_CHAT_MESSAGE            = "/api/v1/webauth/message/send";
export const ROUTE_CONNECT_CHAT_SUPPORT_USER    = "/api/v1/webauth/room/connectionSupportInRoom";
export const ROUTE_DISCONNECT_CHAT_SUPPORT_USER = "/api/v1/webauth/room/disconnectionSupportFromRoom";
export const ROUTE_BOT_START_SESSION             = "/api/v1/webauth/bot/startSession"
export const ROUTE_BOT_GET_MESSAGE              = "/api/v1/webauth/bot/getMessage"
