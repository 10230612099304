import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../redux/hooks";
import {ModalContext} from "../../commonComponents/modal/ModalContext";
import {EmployeesService} from "../../../services/EmployeesService";
import {IPassengerDTO} from "../../../interfaces/IPassengerDTO";
import {EmployeeListComponent} from "./employeeListComponent";
import {EmployeeEditModal} from "./employeeEditModal";
import {EmployeeCreateModal} from "./employeeCreateModal";


export function EmployeesPage() {

  const {t} = useTranslation();

  const {close, showModal} = useContext(ModalContext);

  const authState = useAppSelector((state) => state.auth);

  const employeesService = EmployeesService();


  const [employees, setEmployees] = useState<IPassengerDTO[]>([]);
  const [employeesPending, setEmployeesPending] = useState(false);
  const [isEmployeesNotFound, setIsEmployeesNotFound] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<IPassengerDTO[]>([]);

  const employeeDetailsModal = useRef<{ open: (employee?: IPassengerDTO) => void, close: () => void }>();


  const loadEmployees = async () => {
    setEmployeesPending(true);
    setIsEmployeesNotFound(false);
    setSelectedEmployees([]);
    setEmployees([]);
    const response = await employeesService.getPassengers(authState.currentClientCode);
    if (response.length === 0) {
      setIsEmployeesNotFound(true);
    }
    setEmployees(response);
    setEmployeesPending(false);
  }





  const openCreateEmployeeModal = async () => {
    showModal((modalContext) => {
      return {
        title: t("employeesPage.newEmployee"),
        content: (
            <EmployeeCreateModal
                onSubmit={
                  async (result) => {
                    await loadEmployees();
                    modalContext.close();
                  }
                }
            />
        ),
        options: {
          closeOnClickOutside: false
        }
      }
    });
  }

  const openEditEmployeeModal = async (employee: IPassengerDTO) => {
    showModal((modalContext) => {
      return {
        title: t("employeesPage.editEmployee"),
        content: (
            <EmployeeEditModal
                code={employee.paxCode}
                onSubmit={
                  async (result) => {
                    await loadEmployees();
                    modalContext.close();
                  }
                }
            />
        ),
        options: {
          closeOnClickOutside: false
        }
      }
    });
  }




  const onEmployeeDetailsModalSubmit = async (result: boolean) => {
    if(result) {
      await loadEmployees();
    }
  }


  useEffect(() => {
    loadEmployees();
  }, [authState.currentClientCode]);


  return (
      <>

        <EmployeeListComponent
            employees={employees}
            employeesPending={employeesPending}
            isEmployeesNotFound={isEmployeesNotFound}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            onItemClick={(employee: IPassengerDTO) => employeeDetailsModal.current?.open(employee)}
            reloadEmployees={loadEmployees}
            openCreateEmployeeModal={openCreateEmployeeModal}
            openEditEmployeeModal={openEditEmployeeModal}
        />

      </>
  );
}
