import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {ModalContext} from "../../../../commonComponents/modal/ModalContext";
import {TripElementType} from "../../../../../enums/TripElementType";
import {TripElementStatus} from "../../../../../enums/TripElementStatus";
import {PassportType} from "../../../../../enums/PassportType";
import {ITripBonusDTO, ITripPassportDTO} from "../../../../../interfaces/IOrderDTO";
import {IAeroExpressVariant} from "../../../../../interfaces/IAeroExpressVariant";
import {ICompanyCfoDTO} from "../../../../../interfaces/ICompanyCfoDTO";
import {
    Trip,
    PassengerTripElement,
    FlightTripElement,
    TrainTripElement,
    AeroExpressTripElement
} from "../../../../../interfaces/Trip";
import {AeroExpressComponent} from "../tickets/aeroExpressComponent";
import {FlightComponent} from "../tickets/flightComponent";
import {TrainComponent} from "../tickets/trainComponent";
import {RemoveConfirmationModal} from "../modals/removeConfirmationModal";
import {MdButton} from "../../../../../components/md-button";
import {MdSelect} from "../../../../../components/md-select";
import {MdCheckbox} from "../../../../../components/md-checkbox";
import {CrossIcon} from "../../../../../icons/CrossIcon";
import {UserIconFilled} from "../../../../../icons/UserIconFilled";
import {CalendarIcon} from "../../../../../icons/CalendarIcon";
import {PlusIcon} from "../../../../../icons/PlusIcon";
import {LockIcon} from "../../../../../icons/LockIcon";
import {NotFoundIcon} from "../../../../../icons/NotFoundIcon";
import {RecycleBinIcon} from "../../../../../icons/RecycleBin";
import {Guid} from "../../../../../utils/utils";
import "./style.scss"


export interface ITripComponentCallbacks {
    removeTrip?: () => void;
    changeFinancialSupportCenter?: (value: ICompanyCfoDTO) => void;
    openPassengerSelectionModal?: () => void;
    changePassengerPassport?: (paxCode: string, passportInfo: ITripPassportDTO) => void;
    changePassengerBonus?: (paxCode: string, bonusInfo: ITripBonusDTO) => void;
    removePassenger?: (paxCode: string) => void;
    openTicketSearchModal?: () => void;
    toggleIsOpenItem?: (itemId: string, state: boolean) => void;
    toggleIsSelectedItem?: (itemId: string, state: boolean) => void;
    changeItemTariff?: (itemId: string, tariffIndex: number) => void;
    changeAeroExpressTariff?: (itemId: string, elementType: TripElementType, variant: IAeroExpressVariant) => void;
    removeItem?: (itemId: string, elementType: TripElementType) => void;
}

interface ITripComponent {
    tripDetails: Trip;
    financialSupportCenters: ICompanyCfoDTO[];
    disabled?: boolean;
    callbacks?: ITripComponentCallbacks;
}


export function TripComponent(props: ITripComponent) {

    const {
        tripDetails,
        financialSupportCenters,
        disabled,
        callbacks
    } = props;

    const {t} = useTranslation();

    const {close, showModal} = useContext(ModalContext);

    const passengers: PassengerTripElement[] = tripDetails.passengers;

    const items = tripDetails.items;


    const showRemoveElementConfirmation = (tripElement: PassengerTripElement | FlightTripElement | TrainTripElement | AeroExpressTripElement) => {
        showModal((modalContext) => {
            return {
                title: "Удаление элемента заказа",
                content: (
                    <RemoveConfirmationModal>
                        <div className="delete-confirmation-dialog">

                            <div className="delete-confirmation-dialog--icon">
                                <RecycleBinIcon
                                    width="20px"
                                    height="20px"
                                    style={{
                                        "path": {
                                            fill: "#853E42"
                                        }
                                    }}
                                />
                            </div>

                            <div className="delete-confirmation-dialog--content">
                            <span className="delete-confirmation-dialog--content-title">
                                Удалить элемент?
                            </span>

                                {/*{*/}
                                {/*    (tripItem instanceof FlightTripItem) && (*/}
                                {/*        <FlightComponentDetails*/}
                                {/*            variantInfo={tripItem.variant}*/}
                                {/*        />*/}
                                {/*    )*/}
                                {/*}*/}

                                {/*{*/}
                                {/*    (tripItem instanceof TrainTripItem) && (*/}
                                {/*        <TrainComponentDetails*/}
                                {/*            variantInfo={tripItem.variant}*/}
                                {/*        />*/}
                                {/*    )*/}
                                {/*}*/}

                                <span className="delete-confirmation-dialog--content-description">
                                Удаление элемента приведет к очистке всех данных связанных с ним. Уверены что хотите продолжить?
                            </span>

                                <div className="delete-confirmation-dialog--content-actions">
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                borderWidth: "1px",
                                                borderColor: "#AA3933",
                                                borderStyle: "solid",
                                                backgroundColor: "#DC2622",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={() => {
                                            if (tripElement instanceof FlightTripElement || tripElement instanceof TrainTripElement || tripElement instanceof AeroExpressTripElement) {
                                                if (callbacks?.removeItem != null) {
                                                    if (tripElement instanceof FlightTripElement) {
                                                        callbacks.removeItem(tripElement.id, TripElementType.Flight);
                                                    } else if (tripElement instanceof TrainTripElement) {
                                                        callbacks.removeItem(tripElement.id, TripElementType.Train);
                                                    } else if (tripElement instanceof AeroExpressTripElement) {
                                                        callbacks.removeItem(tripElement.id, TripElementType.AeroExpress);
                                                    }
                                                }
                                            } else if (tripElement instanceof PassengerTripElement) {
                                                if (callbacks?.removePassenger != null) {
                                                    callbacks.removePassenger(tripElement.details.code);
                                                }
                                            }
                                            modalContext.close();
                                        }}
                                    >
                                        {t("bookingPage.continue")}
                                    </MdButton>

                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                borderWidth: "1px",
                                                borderColor: "#EDF1F0",
                                                borderStyle: "solid",
                                                backgroundColor: "#FFFFFF",
                                                color: "#000000",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={() => {
                                            modalContext.close();
                                        }}
                                    >
                                        {t("bookingPage.cancel")}
                                    </MdButton>
                                </div>
                            </div>


                        </div>
                    </RemoveConfirmationModal>
                ),
                options: {
                    closeOnClickOutside: false
                }
            }
        });
    }


    // const tick = useCallback(() => {
    //     setTime(new Date().getTime());
    // }, []);
    //
    // useEffect(() => {
    //     const intervalID = setInterval(tick, 1000);
    //     return () => clearInterval(intervalID);
    // }, [tick])


    return (
        <div className="trip-builder-form-single"
             style={{
                 padding: "10px 15px 10px 15px",
             }}
        >

            <div className="trip-builder-form-content">

                {
                    financialSupportCenters.length > 0 && (
                        <div className="financial-support-center-list">
                            <MdSelect
                                displayMemberPath={"description"}
                                itemTemplate={(item: ICompanyCfoDTO) => {
                                    return (
                                        <div className="financial-support-center-list-item">
                                            <div className="financial-support-center-list-item-label">
                                                ЦФО
                                            </div>
                                            <div
                                                className="financial-support-center-list-item-description">
                                                {
                                                    item.description
                                                }
                                            </div>
                                        </div>
                                    )
                                }}
                                itemSource={financialSupportCenters}
                                selectedItem={(tripDetails.financialSupportCenter ?? financialSupportCenters[0])}
                                setSelectedItem={(financialSupportCenter: ICompanyCfoDTO) => {
                                    if (callbacks?.changeFinancialSupportCenter != null) {
                                        callbacks.changeFinancialSupportCenter(financialSupportCenter)
                                    }
                                }}
                                style={{
                                    "root": {
                                        flex: 1,
                                        margin: "5px 0",
                                        minWidth: "290px"
                                    }
                                }}
                                disabled={disabled}
                            />
                        </div>
                    )
                }

                <div className="trip-builder-form-content-gap">
                    <span>
                        <span>
                            {
                                tripDetails.items.some(item => item.status == TripElementStatus.Booked || item.status == TripElementStatus.Confirmed)
                                    ? (
                                        <LockIcon
                                            style={{
                                                svg: {
                                                    marginRight: "-5px"
                                                },
                                                path: {
                                                    fill: "#777e90"
                                                }
                                            }}
                                        />
                                    )
                                    : (
                                        <MdButton
                                            type="submit"
                                            style={{
                                                "button": {
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    borderWidth: 0,
                                                    backgroundColor: "#FFFFFF",
                                                    color: "rgb(0 0 0)",
                                                    borderRadius: "6px",
                                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                    marginRight: "0px",
                                                    border: "1px solid rgb(175 175 175)",
                                                    borderStyle: 'none',
                                                    width: "24px",
                                                    height: "24px",
                                                    padding: 0
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                }
                                            }}
                                            disabled={disabled}
                                            onClick={() => {
                                                if (callbacks?.openPassengerSelectionModal != null) {
                                                    callbacks.openPassengerSelectionModal()
                                                }
                                            }}
                                        >
                                            <PlusIcon
                                                width="12px"
                                                height="12px"
                                                style={{
                                                    "svg": {
                                                        marginTop: "-2px"
                                                    },
                                                    "path": {
                                                        strokeWidth: "3px",
                                                        stroke: "rgb(62 184 115)"
                                                    }
                                                }}
                                            />
                                        </MdButton>
                                    )
                            }
                        </span>
                        <span>Пассажиры</span>
                    </span>
                    <span></span>
                    <span>
                        {passengers.length}
                    </span>
                </div>

                <div className="flight-info-section" style={{flexDirection: "column"}}>
                    {
                        // TODO: map passengers to passenger components
                        passengers != null && passengers.length > 0 && (
                            <div className="trip-passengers-list">
                                {
                                    passengers
                                        .sort((a, b) => (a.details.code > b.details.code) ? 1 : ((b.details.code > a.details.code) ? -1 : 0))
                                        .map((passengerInfo, passengerInfoIndex) => {
                                            return (
                                                <React.Fragment key={passengerInfoIndex}>
                                                    <div className="trip-passengers-list-item"
                                                         key={"passenger" + Guid.newGuid()}>
                                                        <div className="passenger-item-icon">
                                                            <UserIconFilled
                                                                width="18px"
                                                                height="18px"
                                                                style={{
                                                                    "path": {
                                                                        fill: "#777E90"
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="passenger-item-details">
                                                            <div className="passenger-item-details-row">
                                                                <span className="passenger-fio">
                                                                    {
                                                                        (passengerInfo.details.lastName + " " + passengerInfo.details.firstName + " " + (passengerInfo.details.patronymic ?? ""))
                                                                            .toLowerCase()
                                                                            .split(' ')
                                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                            .join(' ')
                                                                    }
                                                                </span>
                                                                <span className="passenger-dob">
                                                                    <CalendarIcon
                                                                        width="10px"
                                                                        height="10px"
                                                                        style={{
                                                                            "svg": {
                                                                                marginTop: "2px"
                                                                            },
                                                                            "path": {
                                                                                fill: "#8E8E90"
                                                                            }
                                                                        }}
                                                                    />
                                                                    <span>
                                                                        {
                                                                            passengerInfo.details.dateOfBurn.split('T')[0]
                                                                        }
                                                                    </span>
                                                                 </span>
                                                            </div>

                                                            <div className="passenger-item-details-row">
                                                                <span className="passenger-fio-latin">
                                                                    {
                                                                        (passengerInfo.details.lastName + " " + passengerInfo.details.firstName + " " + (passengerInfo.details.patronymic ?? ""))
                                                                            .toLowerCase()
                                                                            .split(' ')
                                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                            .join(' ')
                                                                    }
                                                                </span>
                                                            </div>

                                                            <div className="passenger-item-details-row">
                                                                {
                                                                    passengerInfo.passport != null
                                                                        ? (
                                                                            <MdSelect
                                                                                displayMemberPath={"number"}
                                                                                itemTemplate={(item: ITripPassportDTO) => {
                                                                                    const isExpired =
                                                                                        item.type !== PassportType.Russian
                                                                                        && (new Date(item.expired ?? "").getTime() - new Date().getTime() < 0);
                                                                                    return (
                                                                                        <div
                                                                                            className={("passport-item" + (isExpired ? " --expired" : ""))}>
                                                                                            <div
                                                                                                className="passport-item-type">
                                                                                                {
                                                                                                    (() => {
                                                                                                        if (item.type == PassportType.Russian) {
                                                                                                            return "Паспорт РФ"
                                                                                                        } else if (item.type == PassportType.Foreign) {
                                                                                                            return "Загран."
                                                                                                        } else if (item.type == PassportType.International) {
                                                                                                            return "Международ."
                                                                                                        }
                                                                                                    })()
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="passport-item-number">
                                                                                                {
                                                                                                    item.number
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                item.type !== PassportType.Russian && (
                                                                                                    <div
                                                                                                        className="passport-item-expiry">
                                                                                                        {
                                                                                                            new Date(item.expired ?? "").toLocaleDateString()
                                                                                                        }
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }}
                                                                                itemSource={passengerInfo.details.passports}
                                                                                selectedItem={passengerInfo.passport ?? passengerInfo.details.passports[0]}
                                                                                setSelectedItem={(passport: ITripPassportDTO) => {
                                                                                    if (callbacks?.changePassengerPassport != null) {
                                                                                        callbacks.changePassengerPassport(passengerInfo.details.code, passport)
                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    "root": {
                                                                                        margin: "5px 0",
                                                                                        minWidth: "290px"
                                                                                    }
                                                                                }}
                                                                                disabled={disabled}
                                                                            />
                                                                        )
                                                                        : (
                                                                            <div
                                                                                className="trip-item-status-message error-status">
                                                                                    <span>
                                                                                        <NotFoundIcon
                                                                                            style={{
                                                                                                "svg": {
                                                                                                    margin: "-1px 5px 0 0",
                                                                                                    width: "12px",
                                                                                                    height: "12px"
                                                                                                },
                                                                                                "path": {
                                                                                                    fill: "#b12f00"
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </span>
                                                                                <span>{t("bookingPage.noPassportDetails")}</span>
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>
                                                            {/*<div className="passenger-item-details-row">*/}
                                                            {/*    {*/}
                                                            {/*        (passengerInfo.details.bonuses != null && passengerInfo.details.bonuses.length > 0) && (*/}
                                                            {/*            <MdSelect*/}
                                                            {/*                displayMemberPath={"number"}*/}
                                                            {/*                itemTemplate={(item: ITripBonusDTO) => {*/}
                                                            {/*                    return (*/}
                                                            {/*                        <div className="bonus-item">*/}
                                                            {/*                            <div*/}
                                                            {/*                                className="bonus-item-type">*/}
                                                            {/*                                {*/}
                                                            {/*                                    item.ak*/}
                                                            {/*                                }*/}
                                                            {/*                            </div>*/}
                                                            {/*                            <div*/}
                                                            {/*                                className="bonus-item-number">*/}
                                                            {/*                                {*/}
                                                            {/*                                    item.number*/}
                                                            {/*                                }*/}
                                                            {/*                            </div>*/}
                                                            {/*                        </div>*/}
                                                            {/*                    )*/}
                                                            {/*                }}*/}
                                                            {/*                itemSource={passengerInfo.details.bonuses}*/}
                                                            {/*                selectedItem={passengerInfo.bonus ?? passengerInfo.details.bonuses?.find(x => x !== undefined)}*/}
                                                            {/*                setSelectedItem={(bonus: ITripBonusDTO) => {*/}
                                                            {/*                    if (callbacks?.changePassengerBonus != null) {*/}
                                                            {/*                        callbacks.changePassengerBonus(passengerInfo.details.code, bonus)*/}
                                                            {/*                    }*/}
                                                            {/*                }}*/}
                                                            {/*                style={{*/}
                                                            {/*                    "root": {*/}
                                                            {/*                        margin: "5px 0",*/}
                                                            {/*                        minWidth: "290px"*/}
                                                            {/*                    }*/}
                                                            {/*                }}*/}
                                                            {/*                disabled={(canEdit == false)}*/}
                                                            {/*            />*/}
                                                            {/*        )*/}
                                                            {/*    }*/}
                                                            {/*</div>*/}
                                                        </div>
                                                        {
                                                            !tripDetails.items.some(item => item.status == TripElementStatus.Booked || item.status == TripElementStatus.Confirmed) && (
                                                                <MdButton
                                                                    style={{
                                                                        "button": {
                                                                            position: "absolute",
                                                                            top: "-5px",
                                                                            right: "-12px",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            borderWidth: 0,
                                                                            backgroundColor: "#FFFFFF",
                                                                            color: "rgb(0 0 0)",
                                                                            borderRadius: "12px",
                                                                            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                            marginRight: "0px",
                                                                            width: "24px",
                                                                            height: "24px",
                                                                            padding: 0
                                                                        },
                                                                        "ink": {
                                                                            backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                        },
                                                                        "icon": {
                                                                            marginLeft: 0,
                                                                            marginRight: 0,
                                                                        }
                                                                    }}
                                                                    disabled={disabled}
                                                                    onClick={() => {
                                                                        showRemoveElementConfirmation(passengerInfo);
                                                                    }}
                                                                >
                                                                    <CrossIcon
                                                                        width="14"
                                                                        height="14"
                                                                        style={{
                                                                            "path": {
                                                                                fill: "rgb(114, 28, 36)"
                                                                            }
                                                                        }}
                                                                    />
                                                                </MdButton>
                                                            )
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                }
                            </div>
                        )
                    }
                </div>

                <div className="trip-builder-form-content-gap">
                    <span>
                        <span>
                            <MdButton
                                type="submit"
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        border: "1px solid rgb(175 175 175)",
                                        borderStyle: 'none',
                                        width: "24px",
                                        height: "24px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(255,255,255,.5)"
                                    }
                                }}
                                disabled={disabled}
                                onClick={() => {
                                    if (callbacks?.openTicketSearchModal != null) {
                                        callbacks.openTicketSearchModal()
                                    }
                                }}
                            >
                                <PlusIcon
                                    width="12px"
                                    height="12px"
                                    style={{
                                        "svg": {
                                            marginTop: "-2px"
                                        },
                                        "path": {
                                            strokeWidth: "3px",
                                            stroke: "rgb(62 184 115)"
                                        }
                                    }}
                                />
                            </MdButton>
                        </span>
                        <span>Маршрут</span>
                    </span>
                    <span></span>
                    <span>
                        {items.length}
                    </span>
                </div>

                {
                    items
                        .sort((a, b) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0))
                        .map((tripItem, tripItemIndex) => {
                            if (tripItem instanceof FlightTripElement) {
                                return (
                                    <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                        <div className="ticket-list-item">
                                            <div>
                                                <MdCheckbox
                                                    type='filled'
                                                    checked={tripItem.isSelected}
                                                    style={{
                                                        "input": {
                                                            width: "18px",
                                                            height: "18px"
                                                        }
                                                    }}
                                                    disabled={disabled}
                                                    onChanged={(state) => {
                                                        if (callbacks?.toggleIsSelectedItem != null) {
                                                            callbacks.toggleIsSelectedItem(tripItem.id, state);
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <FlightComponent
                                                    variantInfo={tripItem.variant}
                                                    selectedTariffIndex={tripItem.tariffIndex}
                                                    tariffSelection={(tripItem.status != TripElementStatus.Booked && tripItem.status != TripElementStatus.Confirmed)}
                                                    disabled={disabled}
                                                    callbacks={{
                                                        changeTariff: (tariffIndex: number) => {
                                                            if (callbacks?.changeItemTariff != null) {
                                                                callbacks.changeItemTariff(tripItem.id, tariffIndex);
                                                            }
                                                        }
                                                    }}
                                                />

                                                {
                                                    tripItem.status == TripElementStatus.NotSet && (
                                                        <MdButton
                                                            style={{
                                                                "button": {
                                                                    position: "absolute",
                                                                    top: "-15px",
                                                                    right: "-15px",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    borderWidth: 0,
                                                                    backgroundColor: "#FFFFFF",
                                                                    color: "rgb(0 0 0)",
                                                                    borderRadius: "12px",
                                                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                    marginRight: "0px",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                    padding: 0
                                                                },
                                                                "ink": {
                                                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                },
                                                                "icon": {
                                                                    marginLeft: 0,
                                                                    marginRight: 0,
                                                                }
                                                            }}
                                                            disabled={disabled}
                                                            onClick={() => {
                                                                showRemoveElementConfirmation(tripItem);
                                                            }}
                                                        >
                                                            <CrossIcon
                                                                width="14"
                                                                height="14"
                                                                style={{
                                                                    "path": {
                                                                        fill: "rgb(114, 28, 36)"
                                                                    }
                                                                }}
                                                            />
                                                        </MdButton>
                                                    )
                                                }

                                                {
                                                    (tripItem.status === TripElementStatus.NotSet && passengers.length == 0) && (
                                                        <div className="trip-item-status-message error-status"
                                                             style={{top: "65px"}}>
                                                            <span>{t("bookingPage.noPassengers")}</span>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    (() => {
                                                        switch (tripItem.status) {
                                                            case TripElementStatus.Booked:
                                                                return (
                                                                    <div
                                                                        className="trip-item-status-message warn-status">
                                                                        <span>{t("bookingPage.booked")}</span>
                                                                        {/*<span className="trip-item-status-message-timer">*/}
                                                                        {/*    {*/}
                                                                        {/*        flightInfo.reservation?.items.map((x, xi) => {*/}
                                                                        {/*            let timeLimit = new Date(x.limit).getTime();*/}
                                                                        {/*            let timeLeft = timeLimit - time;*/}

                                                                        {/*            const {*/}
                                                                        {/*                days,*/}
                                                                        {/*                hours,*/}
                                                                        {/*                minutes,*/}
                                                                        {/*                seconds*/}
                                                                        {/*            } = Utils.getTimeDetails(timeLeft);*/}

                                                                        {/*            let daysLeft = Math.abs(days);*/}
                                                                        {/*            let hoursLeft = Math.abs(hours);*/}
                                                                        {/*            let minutesLeft = Math.abs(minutes);*/}
                                                                        {/*            let secondsLeft = Math.abs(seconds);*/}

                                                                        {/*            return (*/}
                                                                        {/*                <React.Fragment key={xi}>*/}
                                                                        {/*                    {*/}
                                                                        {/*                        timeLeft < 0 && (*/}
                                                                        {/*                            <span>срок истек</span>*/}
                                                                        {/*                        )*/}
                                                                        {/*                    }*/}
                                                                        {/*                    {*/}
                                                                        {/*                        timeLeft >= 0 && (*/}
                                                                        {/*                            <span>*/}
                                                                        {/*                                {*/}
                                                                        {/*                                    (daysLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                                                        {/*                                    (hoursLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                                                        {/*                                    (minutesLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                                                        {/*                                    (secondsLeft.toFixed(0).padStart(2, "0").substring(0, 2))*/}
                                                                        {/*                                }*/}
                                                                        {/*                            </span>*/}
                                                                        {/*                        )*/}
                                                                        {/*                    }*/}
                                                                        {/*                </React.Fragment>*/}
                                                                        {/*            )*/}
                                                                        {/*        })*/}
                                                                        {/*    }*/}
                                                                        {/*</span>*/}
                                                                    </div>
                                                                );
                                                            case TripElementStatus.Confirmed:
                                                                return (
                                                                    <div
                                                                        className="trip-item-status-message success-status">
                                                                        <span>{t("bookingPage.issued")}</span>
                                                                    </div>
                                                                );
                                                        }
                                                    })()
                                                }

                                            </div>

                                            <div>

                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            } else if (tripItem instanceof TrainTripElement) {
                                return (
                                    <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                        <div className="ticket-list-item">

                                            <div>
                                                {
                                                    tripItem.status != TripElementStatus.Confirmed && (
                                                        <MdCheckbox
                                                            type='filled'
                                                            checked={tripItem.isSelected}
                                                            style={{
                                                                "input": {
                                                                    width: "18px",
                                                                    height: "18px"
                                                                }
                                                            }}
                                                            disabled={disabled}
                                                            onChanged={(state) => {
                                                                if (callbacks?.toggleIsSelectedItem != null) {
                                                                    callbacks.toggleIsSelectedItem(tripItem.id, state);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>

                                            <div>
                                                <TrainComponent
                                                    variantInfo={tripItem.variant}
                                                    selectedTariffIndex={tripItem.tariffIndex}
                                                    tariffSelection={(tripItem.status != TripElementStatus.Booked && tripItem.status != TripElementStatus.Confirmed)}
                                                    disabled={disabled}
                                                    callbacks={{
                                                        changeTariff: (tariffIndex: number) => {
                                                            if (callbacks?.changeItemTariff != null) {
                                                                callbacks.changeItemTariff(tripItem.id, tariffIndex);
                                                            }
                                                        }
                                                    }}
                                                />

                                                {
                                                    tripItem.status == TripElementStatus.NotSet && (
                                                        <MdButton
                                                            icon={
                                                                <CrossIcon
                                                                    width="14"
                                                                    height="14"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "rgb(114, 28, 36)"
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            style={{
                                                                "button": {
                                                                    position: "absolute",
                                                                    top: "-15px",
                                                                    right: "-15px",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    borderWidth: 0,
                                                                    backgroundColor: "#FFFFFF",
                                                                    color: "rgb(0 0 0)",
                                                                    borderRadius: "12px",
                                                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                    marginRight: "0px",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                    padding: 0
                                                                },
                                                                "ink": {
                                                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                },
                                                                "icon": {
                                                                    marginLeft: 0,
                                                                    marginRight: 0,
                                                                }
                                                            }}
                                                            disabled={disabled}
                                                            onClick={() => {
                                                                showRemoveElementConfirmation(tripItem);
                                                            }}
                                                        />
                                                    )
                                                }

                                                {
                                                    (tripItem.status == TripElementStatus.NotSet && passengers.length == 0) && (
                                                        <div className="trip-item-status-message error-status"
                                                             style={{top: "55px"}}>
                                                            <span>{t("bookingPage.noPassengers")}</span>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    (() => {
                                                        switch (tripItem.status) {
                                                            case TripElementStatus.Booked:
                                                                return (
                                                                    <div
                                                                        className="trip-item-status-message warn-status">
                                                                        <span>{t("bookingPage.booked")}</span>
                                                                        {/*<span className="trip-item-status-message-timer">*/}
                                                                        {/*    {*/}
                                                                        {/*        flightInfo.reservation?.items.map((x, xi) => {*/}
                                                                        {/*            let timeLimit = new Date(x.limit).getTime();*/}
                                                                        {/*            let timeLeft = timeLimit - time;*/}

                                                                        {/*            const {*/}
                                                                        {/*                days,*/}
                                                                        {/*                hours,*/}
                                                                        {/*                minutes,*/}
                                                                        {/*                seconds*/}
                                                                        {/*            } = Utils.getTimeDetails(timeLeft);*/}

                                                                        {/*            let daysLeft = Math.abs(days);*/}
                                                                        {/*            let hoursLeft = Math.abs(hours);*/}
                                                                        {/*            let minutesLeft = Math.abs(minutes);*/}
                                                                        {/*            let secondsLeft = Math.abs(seconds);*/}

                                                                        {/*            return (*/}
                                                                        {/*                <React.Fragment key={xi}>*/}
                                                                        {/*                    {*/}
                                                                        {/*                        timeLeft < 0 && (*/}
                                                                        {/*                            <span>срок истек</span>*/}
                                                                        {/*                        )*/}
                                                                        {/*                    }*/}
                                                                        {/*                    {*/}
                                                                        {/*                        timeLeft >= 0 && (*/}
                                                                        {/*                            <span>*/}
                                                                        {/*                                {*/}
                                                                        {/*                                    (daysLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                                                        {/*                                    (hoursLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                                                        {/*                                    (minutesLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                                                        {/*                                    (secondsLeft.toFixed(0).padStart(2, "0").substring(0, 2))*/}
                                                                        {/*                                }*/}
                                                                        {/*                            </span>*/}
                                                                        {/*                        )*/}
                                                                        {/*                    }*/}
                                                                        {/*                </React.Fragment>*/}
                                                                        {/*            )*/}
                                                                        {/*        })*/}
                                                                        {/*    }*/}
                                                                        {/*</span>*/}
                                                                    </div>
                                                                );
                                                            case TripElementStatus.Confirmed:
                                                                return (
                                                                    <div
                                                                        className="trip-item-status-message success-status">
                                                                        <span>{t("bookingPage.issued")}</span>
                                                                    </div>
                                                                );
                                                        }
                                                    })()
                                                }

                                            </div>

                                            <div>

                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            } else if (tripItem instanceof AeroExpressTripElement) {
                                return (
                                    <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                        <div className="ticket-list-item">

                                            <div>
                                                {
                                                    tripItem.status != TripElementStatus.Confirmed && (
                                                        <MdCheckbox
                                                            type='filled'
                                                            checked={tripItem.isSelected}
                                                            style={{
                                                                "input": {
                                                                    width: "18px",
                                                                    height: "18px"
                                                                }
                                                            }}
                                                            disabled={disabled}
                                                            onChanged={(state) => {
                                                                if (callbacks?.toggleIsSelectedItem != null) {
                                                                    callbacks.toggleIsSelectedItem(tripItem.id, state);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>

                                            <div>
                                                <AeroExpressComponent
                                                    variantInfo={tripItem.variant}
                                                    variants={tripItem.variants}
                                                    tariffSelection={(tripItem.status != TripElementStatus.Booked && tripItem.status != TripElementStatus.Confirmed)}
                                                    disabled={disabled}
                                                    callbacks={{
                                                        changeTariff: (tariffIndex: number) => {
                                                            if (callbacks?.changeItemTariff != null) {
                                                                callbacks.changeItemTariff(tripItem.id, tariffIndex);
                                                            }
                                                        }
                                                    }}
                                                />

                                                {
                                                    tripItem.status == TripElementStatus.NotSet && (
                                                        <MdButton
                                                            style={{
                                                                "button": {
                                                                    position: "absolute",
                                                                    top: "-15px",
                                                                    right: "-15px",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    borderWidth: 0,
                                                                    backgroundColor: "#FFFFFF",
                                                                    color: "rgb(0 0 0)",
                                                                    borderRadius: "12px",
                                                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                    marginRight: "0px",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                    padding: 0
                                                                },
                                                                "ink": {
                                                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                },
                                                                "icon": {
                                                                    marginLeft: 0,
                                                                    marginRight: 0,
                                                                }
                                                            }}
                                                            disabled={disabled}
                                                            onClick={() => {
                                                                showRemoveElementConfirmation(tripItem);
                                                            }}
                                                        >
                                                            <CrossIcon
                                                                width="14"
                                                                height="14"
                                                                style={{
                                                                    "path": {
                                                                        fill: "rgb(114, 28, 36)"
                                                                    }
                                                                }}
                                                            />
                                                        </MdButton>
                                                    )
                                                }

                                                {
                                                    (tripItem.status == TripElementStatus.NotSet && passengers.length == 0) && (
                                                        <div className="trip-item-status-message error-status"
                                                             style={{top: "55px"}}>
                                                            <span>{t("bookingPage.noPassengers")}</span>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    (() => {
                                                        switch (tripItem.status) {
                                                            case TripElementStatus.Booked:
                                                                return (
                                                                    <div
                                                                        className="trip-item-status-message warn-status">
                                                                        <span>{t("bookingPage.booked")}</span>
                                                                        {/*<span className="trip-item-status-message-timer">*/}
                                                                        {/*    {*/}
                                                                        {/*        flightInfo.reservation?.items.map((x, xi) => {*/}
                                                                        {/*            let timeLimit = new Date(x.limit).getTime();*/}
                                                                        {/*            let timeLeft = timeLimit - time;*/}

                                                                        {/*            const {*/}
                                                                        {/*                days,*/}
                                                                        {/*                hours,*/}
                                                                        {/*                minutes,*/}
                                                                        {/*                seconds*/}
                                                                        {/*            } = Utils.getTimeDetails(timeLeft);*/}

                                                                        {/*            let daysLeft = Math.abs(days);*/}
                                                                        {/*            let hoursLeft = Math.abs(hours);*/}
                                                                        {/*            let minutesLeft = Math.abs(minutes);*/}
                                                                        {/*            let secondsLeft = Math.abs(seconds);*/}

                                                                        {/*            return (*/}
                                                                        {/*                <React.Fragment key={xi}>*/}
                                                                        {/*                    {*/}
                                                                        {/*                        timeLeft < 0 && (*/}
                                                                        {/*                            <span>срок истек</span>*/}
                                                                        {/*                        )*/}
                                                                        {/*                    }*/}
                                                                        {/*                    {*/}
                                                                        {/*                        timeLeft >= 0 && (*/}
                                                                        {/*                            <span>*/}
                                                                        {/*                                {*/}
                                                                        {/*                                    (daysLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                                                        {/*                                    (hoursLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                                                        {/*                                    (minutesLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                                                        {/*                                    (secondsLeft.toFixed(0).padStart(2, "0").substring(0, 2))*/}
                                                                        {/*                                }*/}
                                                                        {/*                            </span>*/}
                                                                        {/*                        )*/}
                                                                        {/*                    }*/}
                                                                        {/*                </React.Fragment>*/}
                                                                        {/*            )*/}
                                                                        {/*        })*/}
                                                                        {/*    }*/}
                                                                        {/*</span>*/}
                                                                    </div>
                                                                );
                                                            case TripElementStatus.Confirmed:
                                                                return (
                                                                    <div
                                                                        className="trip-item-status-message success-status">
                                                                        <span>{t("bookingPage.issued")}</span>
                                                                    </div>
                                                                );
                                                        }
                                                    })()
                                                }

                                            </div>

                                            <div>

                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <React.Fragment key={"trip-item" + Guid.newGuid()}>

                                    </React.Fragment>
                                )
                            }
                        })
                }

                <div className="order-summary-info">
                    {/*<div className="order-summary-info-item">*/}
                    {/*    <span>Стоимость билетов</span>*/}
                    {/*    <span></span>*/}
                    {/*    <span>*/}
                    {/*        {*/}
                    {/*            (*/}
                    {/*                items.reduce((a, b) => {*/}
                    {/*                    if (b instanceof FlightTripItem) {*/}
                    {/*                        return a + b.tariff.priceWithSborTts;*/}
                    {/*                    } else if (b instanceof TrainTripItem) {*/}
                    {/*                        return a + (b.tariff.price + b.tariff.sborTts);*/}
                    {/*                    } else {*/}
                    {/*                        return 0;*/}
                    {/*                    }*/}
                    {/*                }, 0)*/}
                    {/*            ).toLocaleString()*/}
                    {/*            + " ₽"*/}
                    {/*        }*/}
                    {/*    </span>*/}
                    {/*</div>*/}

                    <div className="order-summary-info-item">
                        <span style={{fontWeight: 500}}>Стоимость командировки</span>
                        <span></span>
                        <span>
                            {
                                passengers.length == 0
                                    ? (
                                        "Не определена"
                                    )
                                    : (
                                        (
                                            items.filter(x => x.isSelected).reduce((a, b) => {
                                                if (b instanceof FlightTripElement) {
                                                    const tariff = b.variant.rejsInfo[b.variant.rejsInfo.length - 1].tarifs[b.tariffIndex];
                                                    return a + tariff.priceWithSborTts * passengers.length;
                                                } else if (b instanceof TrainTripElement) {
                                                    const tariff = b.variant.categoryPrice[b.tariffIndex];
                                                    return a + (tariff.price + tariff.sborTts) * passengers.length;
                                                } else {
                                                    return 0;
                                                }
                                            }, 0)
                                        ).toLocaleString()
                                        + " ₽"
                                    )
                            }
                        </span>
                    </div>
                </div>

            </div>

        </div>
    );
}