import {AxiosResponse, CancelToken} from "axios";
import {axiosInstance} from "./AxiosInstance";
import {
    BEARER,
    ROUTE_ADD_PAX_TO_TRIP,
    ROUTE_CREATE_AERO_EXPRESS_ITEM,
    ROUTE_CREATE_FLIGHT_ITEM,
    ROUTE_CREATE_ORDER,
    ROUTE_CREATE_PAX,
    ROUTE_ADD_PAX_BONUSES,
    ROUTE_ADD_PAX_PASSPORTS,
    ROUTE_CREATE_TRAIN_ITEM,
    ROUTE_CREATE_TRIP,
    ROUTE_CREATE_TRIP_ITEM, ROUTE_GET_AERO_EXPRESS,
    ROUTE_GET_CAN_ORDER_ISSUE,
    ROUTE_GET_FLIGHT_PROVIDERS,
    ROUTE_GET_FLIGHTS,
    ROUTE_GET_ORDER_BOOKING_DETAILS,
    ROUTE_GET_ORDER_BY_ID,
    ROUTE_GET_ORDER_ISSUE_DETAILS,
    ROUTE_GET_ORDER_PDF_TICKET,
    ROUTE_GET_ORDER_PDF_TICKET_NAMES,
    ROUTE_GET_ORDERS,
    ROUTE_GET_TRAIN_PROVIDERS,
    ROUTE_GET_TRAINS,
    ROUTE_IS_ORDER_ISSUED,
    ROUTE_IS_PAX_EXIST,
    ROUTE_ORDER_BOOK,
    ROUTE_PATCH_PAX_IN_TRIP,
    ROUTE_ORDER_ISSUE,
    ROUTE_PATCH_TRIP,
    ROUTE_PATCH_TRIP_ITEM,
    ROUTE_REMOVE_ORDER,
    ROUTE_REMOVE_PAX_FROM_TRIP,
    ROUTE_REMOVE_TRIP,
    ROUTE_REMOVE_TRIP_ITEM,
    ROUTE_SEND_ORDER_TO_TTS, ROUTE_PATCH_FLIGHT_ITEM, ROUTE_PATCH_TRAIN_ITEM, ROUTE_PATCH_AERO_EXPRESS_ITEM,
} from "../constants/ApiRouteConstants";
import {ISearchFlightsRequest} from "./request/ISearchFlightsRequest";
import {ISearchTrainsRequest} from "./request/ISearchTrainsRequest";
import {IOrderBookRequest, IOrderIssueRequest} from "./request/IOrderBookRequest";
import {TimeOfDay} from "../enums/TimeOfDay";
import {ISearchFlightsResponse} from "./response/ISearchFlightsResponse";
import {ISearchTrainsResponse} from "./response/ISearchTrainsResponse";
import {IOrderBookResponse} from "./response/IBookFlightResponse";
import {
    IAeroExpressDTO,
    IFlightElementDTO,
    IOrderBookingDetails,
    IOrderDTO,
    IOrderIssueDetails,
    IOrderTicketPdf,
    ITrainElementDTO,
    ITripDTO,
    ITripElementDTO,
    ITripPassengerDTO,
} from "../interfaces/IOrderDTO";
import {IStorageKey} from "../interfaces/IStorageKey";
import {Railcar} from "../pages/cabinetPages/orderPage/orderDetailsComponent/modals/searchTicketsModal/searchTrainsComponent";
import {ICreateOrderRequest} from "./request/ICreateOrderRequest";
import {ICreateTripRequest} from "./request/ICreateTripRequest";
import {ITripPaxesRequest} from "./request/ITripPaxesRequest";
import {
    ICreateAeroExpressElementRequest,
    ICreateFlightElementRequest,
    ICreateTrainElementRequest, IPatchAeroExpressElementRequest, IPatchFlightElementRequest, IPatchTrainElementRequest,
    IPatchTripElementRequest,
    ITripElementRequest
} from "./request/ITripElementRequest";
import {TripElementType} from "../enums/TripElementType";
import {ICreateTripPassengerRequest} from "./request/ICreateTripPassengerRequest";
import {ICanIssieCheck} from "../interfaces/support/ICanIssieCheck";
import {ISearchAeroExpressRequest} from "./request/ISearchAeroExpressRequest";
import {ISearchAeroExpressResponse} from "./response/ISearchAeroExpressResponse";
import {Utils} from "../utils/utils";
import {IPatchTripRequest} from "./request/IPatchTripRequest";
import {IPatchTripPaxesRequest} from "./request/IPatchTripPaxesRequest";
import {IAddPaxPassportRequest, IAddPaxPassportsRequest} from "./request/IAddPaxPassportRequest";
import {IAddPaxBonusesRequest} from "./request/IAddPaxBonusesRequest";


export function TripApi() {


    /**
     * GET method for obtaining flight providers
     * @param {string} clientCode - active company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<string[], any>>} response with list of provider names
     */
    const getFlightProviders = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<string[], any>> => {
        return await axiosInstance.get<string[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_FLIGHT_PROVIDERS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for obtaining flights by requesting parameters
     * @param {ISearchFlightsRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ISearchFlightsResponse, any>>} response with list of flight variants
     */
    const getFlights = async (request: ISearchFlightsRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ISearchFlightsResponse, any>> => {
        return await axiosInstance.post<ISearchFlightsResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_FLIGHTS,
            {
                parameters: request.routes.map(x => {
                    return {
                        fare: x.class,
                        paxCount: x.passengers,
                        rtGroupId: x.id,
                        departDate: x.departDate,
                        exactTime: (x.departTimeOfDay == TimeOfDay.Exact ? (x.departDate + "T" + (x.departTime ?? "00:00:00")) : (x.departDate + "T00:00:00")),
                        timeSelection: (
                            x.departTimeOfDay == TimeOfDay.Morning
                            || x.departTimeOfDay == TimeOfDay.Afternoon
                            || x.departTimeOfDay == TimeOfDay.Evening
                            || x.departTimeOfDay == TimeOfDay.Exact
                        ) ? x.departTimeOfDay : undefined,
                        fromCity: {
                            code: x.origin.code,
                            name: x.origin.city_name ?? x.origin.name
                        },
                        toCity: {
                            code: x.direction.code,
                            name: x.direction.city_name ?? x.direction.name
                        },
                        flightOptions: (!Utils.isEmptyObject(x.flightOptions) ? x.flightOptions : undefined),

                        //isBaggageRequired: (x.baggage == BaggageAllowance.WithBaggage),
                        //hasReturn: (x.refund == RefundAllowance.WithRefund),
                        //hasExchange: (x.exchange == ExchangeAllowance.WithExchange),
                        //isBusynessOnly: (x.class == FlightClass.Business),

                        isBackwardTicket: x.isBackwardTicket,
                        notes: null
                    }
                }),
                providers: request.providers,
                clientCode: request.clientCode
            },
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                timeout: 1000 * 100,
                cancelToken: ct
            }
        );
    };


    /**
     * GET method for obtaining aero express by requesting parameters
     * @param {ISearchAeroExpressRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ISearchAeroExpressResponse, any>>} response with list of aero express variants
     */
    const getAeroExpress = async (request: ISearchAeroExpressRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ISearchAeroExpressResponse, any>> => {
        return await axiosInstance.post<ISearchAeroExpressResponse>(
            // TODO: change route to gateway
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_AERO_EXPRESS,
            {
                parameters: request.routes.map(x => {
                    return {
                        paxCount: x.passengers,
                        departDate: x.departDate,
                        //exactTime: (x.departTimeOfDay == TimeOfDay.Exact ? (x.departDate + "T" + (x.departTime ?? "00:00:00")) : (x.departDate + "T00:00:00")),
                        //timeSelection: (
                        //    x.departTimeOfDay == TimeOfDay.Morning
                        //    || x.departTimeOfDay == TimeOfDay.Afternoon
                        //    || x.departTimeOfDay == TimeOfDay.Evening
                        //    || x.departTimeOfDay == TimeOfDay.Exact
                        //) ? x.departTimeOfDay : undefined,
                        // fromCity: {
                        //     code: x.origin.code,
                        //     name: x.origin.city_name ?? x.origin.name
                        // },
                        // toCity: {
                        //     code: x.direction.code,
                        //     name: x.direction.city_name ?? x.direction.name
                        // },
                        notes: null
                    }
                }),
                providers: request.providers,
                clientCode: request.clientCode
            },
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                timeout: 1000 * 100,
                cancelToken: ct
            }
        );
    };


    /**
     * GET method for obtaining train providers
     * @param {string} clientCode - active company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<string[], any>>} response list of provider names
     */
    const getTrainProviders = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<string[], any>> => {
        return await axiosInstance.get<string[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_TRAIN_PROVIDERS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for obtaining trains by requesting parameters
     * @param {ISearchTrainsRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ISearchTrainsResponse, any>>} response with list of train variants
     */
    const getTrains = async (request: ISearchTrainsRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ISearchTrainsResponse, any>> => {
        return await axiosInstance.post<ISearchTrainsResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_TRAINS,
            {
                parameters: request.routes.map(x => {
                    return {
                        rtGroupId: x.id,

                        fare: (
                            x.railcar == undefined || x.railcar == Railcar.None || x.railcar == Railcar.Seat
                                ? x.class
                                : undefined),

                        departDate: x.departDate,

                        exactTime: (x.departTimeOfDay == TimeOfDay.Exact ? (x.departDate + "T" + (x.departTime ?? "00:00:00")) : (x.departDate + "T00:00:00")),
                        timeSelection: (
                            x.departTimeOfDay == TimeOfDay.Morning
                            || x.departTimeOfDay == TimeOfDay.Afternoon
                            || x.departTimeOfDay == TimeOfDay.Evening
                            || x.departTimeOfDay == TimeOfDay.Exact
                        ) ? x.departTimeOfDay : undefined,

                        //exactTime: (x.departDate != undefined && x.departTime != undefined ? x.departDate + "T" + x.departTime : undefined),
                        //selectedTime: (x.departTimeOfDay != undefined ? [TimeOfDay.Morning, TimeOfDay.Afternoon, TimeOfDay.Evening].indexOf(x.departTimeOfDay) : undefined),

                        fromCity: {
                            code: x.origin.express,
                            name: x.origin.name
                        },
                        toCity: {
                            code: x.direction.express,
                            name: x.direction.name
                        },

                        paxCount: x.passengers,

                        seatClass: x.railcar,

                        genderType: (
                            x.railcar == undefined || x.railcar == Railcar.None || x.railcar == Railcar.Coupe
                                ? x.coupe
                                : undefined),

                        seatType: (
                            x.railcar == undefined || x.railcar == Railcar.None || x.railcar == Railcar.Couchette || x.railcar == Railcar.Coupe
                                ? x.seat
                                : undefined),

                        placeType: (
                            ((x.railcar == undefined || x.railcar == Railcar.None || x.railcar == Railcar.Seat) && !Utils.isEmptyObject(x.placeType))
                                ? x.placeType
                                : undefined),

                        isSideSeat: (
                            x.railcar == undefined || x.railcar == Railcar.None || x.railcar == Railcar.Couchette
                                ? x.isSideSeat
                                : undefined),

                        // isCoupe:       (x.railcar === Railcar.None || x.railcar === Railcar.All || (x.railcar & Railcar.Coupe) === Railcar.Coupe) ? undefined : false,
                        // isCouchette:   (x.railcar === Railcar.None || x.railcar === Railcar.All || (x.railcar & Railcar.Couchette) === Railcar.Couchette) ? undefined : false,
                        // isSleepingCar: (x.railcar === Railcar.None || x.railcar === Railcar.All || (x.railcar & Railcar.Sleeping) === Railcar.Sleeping) ? undefined : false,
                        // isSittingCar:  (x.railcar === Railcar.None || x.railcar === Railcar.All || (x.railcar & Railcar.Seat) === Railcar.Seat) ? undefined : false,
                        //
                        // isManCoupe:    (x.coupe === CoupeType.None || x.coupe === CoupeType.All || (x.coupe & CoupeType.Man) === CoupeType.Man) ? undefined : false,
                        // isWomanCoupe:  (x.coupe === CoupeType.None || x.coupe === CoupeType.All || (x.coupe & CoupeType.Woman) === CoupeType.Woman) ? undefined : false,
                        // isFamilyCoupe: (x.coupe === CoupeType.None || x.coupe === CoupeType.All || (x.coupe & CoupeType.Family) === CoupeType.Family) ? undefined : false,
                        // isMixedCoupe:  (x.coupe === CoupeType.None || x.coupe === CoupeType.All || (x.coupe & CoupeType.Mixed) === CoupeType.Mixed) ? undefined : false,
                        //
                        // isWindowSeat: (x.seat === SeatType.None || x.seat === SeatType.All || (x.seat & SeatType.ByTheWindow) === SeatType.ByTheWindow) ? undefined : false,
                        // isBottom:     (x.seat === SeatType.None || x.seat === SeatType.All || (x.seat & SeatType.Bottom) === SeatType.Bottom) ? undefined : false,
                        // isTop:        (x.seat === SeatType.None || x.seat === SeatType.All || (x.seat & SeatType.Top) === SeatType.Top) ? undefined : false,
                        // isSideBottom: (x.seat === SeatType.None || x.seat === SeatType.All || (x.seat & SeatType.SideBottom) === SeatType.SideBottom) ? undefined : false,
                        // isSideTop:    (x.seat === SeatType.None || x.seat === SeatType.All || (x.seat & SeatType.SideTop) === SeatType.SideTop) ? undefined : false,

                        notes: null
                    }
                }),
                providers: request.providers,
                clientCode: request.clientCode
            },
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                timeout: 1000 * 100,
                cancelToken: ct
            }
        );
    };



    /**
     * POST method for creating order
     * @param {ICreateOrderRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrderDTO, any>>} response with order details
     */
    const createOrder = async (request: ICreateOrderRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrderDTO, any>> => {
        return await axiosInstance.post<IOrderDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_ORDER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * POST method for removing order
     * @param {string} id - order id
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with order details
     */
    const removeOrder = async (id: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        return await axiosInstance.delete(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_REMOVE_ORDER,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                  id
                },
                cancelToken: ct
            },
        );
    };



    /**
     * POST method for creating trip
     * @param {ICreateTripRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITripDTO, any>>} response with trip details
     */
    const createTrip = async (request: ICreateTripRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITripDTO, any>> => {
        return await axiosInstance.post<ITripDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_TRIP,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * PATCH method for patching trip
     * @param {IPatchTripRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITripDTO, any>>} response with trip details
     */
    const patchTrip = async (request: IPatchTripRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITripDTO, any>> => {
        return await axiosInstance.patch<ITripDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_PATCH_TRIP,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * DELETE method for removing trip
     * @param {string} id - trip id
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with deleting result
     */
    const removeTrip = async (id: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        return await axiosInstance.delete(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_REMOVE_TRIP,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                  id
                },
                cancelToken: ct
            },
        );
    };


    // TODO: move to employees api
    /**
     * GET method of checking existence of pax
     * @param {string} paxCode - passenger code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean, any>>} response with checking result
     */
    const isPaxExist = async (paxCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean, any>> => {
        return await axiosInstance.get<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_IS_PAX_EXIST,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    in_pax: paxCode
                },
                cancelToken: ct
            }
        );
    };

    // TODO: move to employees api
    /**
     * POST method for creating pax
     * @param {ICreateTripPassengerRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITripPassengerDTO, any>>} response with pax details
     */
    const createPax = async (request: ICreateTripPassengerRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITripPassengerDTO, any>> => {
        return await axiosInstance.post<ITripPassengerDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_PAX,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    // TODO: move to employees api
    /**
     * POST method for adding pax passports
     * @param {IAddPaxPassportsRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITripPassengerDTO, any>>} response with passenger details
     */
    const addPassports = async (request: IAddPaxPassportsRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITripPassengerDTO, any>> => {
        return await axiosInstance.post<ITripPassengerDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_PAX_PASSPORTS,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    // TODO: move to employees api
    /**
     * POST method for adding pax bonuses
     * @param {IAddPaxBonusesRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITripPassengerDTO, any>>} response with passenger details
     */
    const addBonuses = async (request: IAddPaxBonusesRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITripPassengerDTO, any>> => {
        return await axiosInstance.post<ITripPassengerDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_PAX_BONUSES,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };


    /**
     * PUT method for adding pax to trip item
     * @param {ITripPaxesRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITripPassengerDTO[], any>>} response with added passenger details
     */
    const addPaxesToTrip = async (request: ITripPaxesRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITripPassengerDTO[], any>> => {
        return await axiosInstance.put<ITripPassengerDTO[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_PAX_TO_TRIP,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * PUT method for patching paxes in trip item
     * @param {IPatchTripPaxesRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITripDTO, any>>} response with trip details
     */
    const patchPaxesInTrip = async (request: IPatchTripPaxesRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITripDTO, any>> => {
        return await axiosInstance.patch<ITripDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_PATCH_PAX_IN_TRIP,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * PUT method for removing pax from trip item
     * @param {ITripPaxesRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<string[], any>>} response with removed passenger ids
     */
    const removePaxesFromTrip = async (request: ITripPaxesRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<string[], any>> => {
        return await axiosInstance.put<string[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_REMOVE_PAX_FROM_TRIP,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };



    /**
     * POST method for creating trip item
     * @param {ITripElementRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITripElementDTO, any>>} response with trip item details
     */
    const createTripItem = async (request: ITripElementRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITripElementDTO, any>> => {
        return await axiosInstance.post<ITripElementDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_TRIP_ITEM,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * POST method for creating flight item
     * @param {ICreateFlightElementRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IFlightElementDTO, any>>} response with trip item details
     */
    const createFlightElement = async (request: ICreateFlightElementRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IFlightElementDTO, any>> => {
        return await axiosInstance.post<IFlightElementDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_FLIGHT_ITEM,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * POST method for creating train item
     * @param {ICreateTrainElementRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITrainElementDTO, any>>} response with trip item details
     */
    const createTrainElement = async (request: ICreateTrainElementRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITrainElementDTO, any>> => {
        return await axiosInstance.post<ITrainElementDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_TRAIN_ITEM,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * POST method for creating ero express item
     * @param {ICreateAeroExpressElementRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IAeroExpressDTO, any>>} response with trip item details
     */
    const createAeroExpressElement = async (request: ICreateAeroExpressElementRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IAeroExpressDTO, any>> => {
        return await axiosInstance.post<IAeroExpressDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_AERO_EXPRESS_ITEM,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    // /**
    //  * PUT method for patch trip element
    //  * @param {IPatchTripElementRequest} request - request data
    //  * @param {string} accessToken - access token
    //  * @param {CancelToken} ct - cancellation token
    //  * @return {Promise<AxiosResponse<ITripElementDTO, any>>} response with trip item details
    //  */
    // const patchTripElement = async (request: IPatchTripElementRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITripElementDTO, any>> => {
    //     return await axiosInstance.put<ITripElementDTO>(
    //         process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_PATCH_TRIP_ITEM,
    //         request,
    //         {
    //             headers: {
    //                 Authorization: BEARER + accessToken,
    //                 Auth: 123
    //             },
    //             cancelToken: ct
    //         },
    //     );
    // };

    /**
     * PATCH method for patching flight element
     * @param {IPatchFlightElementRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IFlightElementDTO, any>>} response with flight element details
     */
    const patchFlightElement = async (request: IPatchFlightElementRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IFlightElementDTO, any>> => {
        return await axiosInstance.patch<IFlightElementDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_PATCH_FLIGHT_ITEM,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * PATCH method for patching train element
     * @param {IPatchTrainElementRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITrainElementDTO, any>>} response with train element details
     */
    const patchTrainElement = async (request: IPatchTrainElementRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITrainElementDTO, any>> => {
        return await axiosInstance.patch<ITrainElementDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_PATCH_TRAIN_ITEM,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * PATCH method for patching aero express element
     * @param {IPatchAeroExpressElementRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IAeroExpressDTO, any>>} response with aero express element details
     */
    const patchAeroExpressElement = async (request: IPatchAeroExpressElementRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IAeroExpressDTO, any>> => {
        return await axiosInstance.patch<IAeroExpressDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_PATCH_AERO_EXPRESS_ITEM,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };

    /**
     * DELETE method for removing trip item
     * @param {string} id - trip item id
     * @param {TripElementType} elementType - trip item type
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with deleting result
     */
    const removeTripItem = async (id: string, elementType: TripElementType, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        return await axiosInstance.delete(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_REMOVE_TRIP_ITEM,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    id,
                    elementType
                },
                cancelToken: ct
            },
        );
    };



    /**
     * POST method for send order to TTS
     * @param {IOrderBookRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with booking details
     */
    const sendOrderToTTS = async (request: IOrderBookRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        return await axiosInstance.post<any>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_SEND_ORDER_TO_TTS,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            },
        );
    };



    /**
     * POST method for booking order
     * @param {IOrderBookRequest} request - request data
     * @param {string} accessToken - access token
     * @param {number} timeout - request timeout
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrderBookResponse, any>>} response with booking details
     */
    const bookOrder = async (request: IOrderBookRequest, accessToken: string, timeout: number, ct: CancelToken): Promise<AxiosResponse<IOrderBookResponse, any>> => {
        return await axiosInstance.post<IOrderBookResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ORDER_BOOK,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                timeout: timeout,
                cancelToken: ct
            },
        );
    };

    /**
     * POST method for issue order
     * @param {IOrderIssueRequest} request - request data
     * @param {string} accessToken - access token
     * @param {number} timeout - request timeout
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with issue details
     */
    const issueOrder = async (request: IOrderIssueRequest, accessToken: string, timeout: number, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        return await axiosInstance.post<any>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ORDER_ISSUE,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                timeout: timeout,
                cancelToken: ct
            }
        );
    };



    /**
     * GET method for obtaining list of orders
     * @param {string} clientCode - active company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrderDTO[], any>>} response with list of orders
     */
    const getOrders = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrderDTO[], any>> => {
        return await axiosInstance.get<IOrderDTO[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDERS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: clientCode // TODO: check this param on backend
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining order details
     * @param {string} id - order id
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrderDTO, any>>} response with order details
     */
    const getOrderById = async (id: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrderDTO, any>> => {
        return await axiosInstance.get<IOrderDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_BY_ID,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    //Auth: 123
                },
                params: {
                    orderId: id
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining state of issued order
     * @param {string} clientCode - active company code
     * @param {string} orderNo - order number
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean, any>>} response with state of issued order
     */
    const isOrderIssued = async (clientCode: string, orderNo: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean, any>> => {
        return await axiosInstance.get<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_IS_ORDER_ISSUED,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode, // TODO: check this param on backend
                    orderNo: orderNo,
                }
            }
        );
    };

    /**
     * GET method for obtaining order booking details
     * @param {string} clientCode - active company code
     * @param {number | undefined} orderNo - order number
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrderBookingDetails[], any>>} response with list of order booking details
     */
    const getOrderBookingDetails = async (clientCode: string, orderNo: number | undefined, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrderBookingDetails[], any>> => {
        return await axiosInstance.get<IOrderBookingDetails[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_BOOKING_DETAILS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode, // TODO: check this param on backend
                    orderNo: orderNo
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining order issue details
     * @param {string} clientCode - active company code
     * @param {string} orderNo - order number
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrderIssueDetails, any>>} response with order issue details
     */
    const getOrderIssueDetails = async (clientCode: string, orderNo: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrderIssueDetails, any>> => {
        return await axiosInstance.get<IOrderIssueDetails>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_ISSUE_DETAILS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode, // TODO: check this param on backend
                    orderNo: orderNo
                }
            }
        );
    };

    /**
     * GET method for obtaining list of pdf tickets data
     * @param {string} clientCode - active company code
     * @param {string} bsoNo - bso number
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrderTicketPdf[], any>>} response with list of pdf tickets data
     */
    const getPdfTickets = async (clientCode: string, bsoNo: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrderTicketPdf[], any>> => {
        return await axiosInstance.get<IOrderTicketPdf[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_PDF_TICKET_NAMES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode, // TODO: check this param on backend
                    bsoNo: bsoNo
                }
            }
        );
    };

    /**
     * POST method for obtaining pdf ticket file
     * @param {string} clientCode - active company code
     * @param {IStorageKey} key - storage key
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<Blob, any>>} response with pdf ticket file
     */
    const getPdfTicketFile = async (clientCode: string, key: IStorageKey, accessToken: string, ct: CancelToken): Promise<AxiosResponse<Blob, any>> => {
        return await axiosInstance.post<Blob>( // TODO: specify Response model
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_PDF_TICKET,
            key,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                responseType: "blob",
                params: {
                    clientCode: clientCode // TODO: check this param on backend
                },
                cancelToken: ct
            }
        );
    };


    /**
     * GET method for checking order for issue
     * @param {string} clientCode - active company code
     * @param {number} sum - order total amount
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICanIssieCheck, any>>} response with checking result
     */
    const canOrderIssue = async (clientCode: string, sum: number, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICanIssieCheck, any>> => {
        return await axiosInstance.get<ICanIssieCheck>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CAN_ORDER_ISSUE,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode,
                    sum: sum
                },
                cancelToken: ct
            }
        );
    };


    return {
        getFlightProviders,
        getFlights,

        getAeroExpress,

        getTrainProviders,
        getTrains,

        getOrders,
        getOrderById,
        isOrderIssued,
        getOrderBookingDetails,
        getOrderIssueDetails,
        getPdfTickets,
        getPdfTicketFile,

        createOrder,
        removeOrder,
        createTrip,
        patchTrip,
        removeTrip,
        createTripItem,
        createFlightElement,
        createTrainElement,
        createAeroExpressElement,
        removeTripItem,
        patchFlightElement,
        patchTrainElement,
        patchAeroExpressElement,
        isPaxExist,
        createPax,
        addPassports,
        addBonuses,
        addPaxesToTrip,
        patchPaxesInTrip,
        removePaxesFromTrip,
        sendOrderToTTS,
        bookOrder,
        issueOrder,

        canOrderIssue
    };
}