import axios, {CancelToken} from "axios";
import {useAppSelector} from "../redux/hooks";
import {RefsApi} from "../api/RefsApi";
import {ISearchAviaCityResponse} from "../api/response/ISearchAviaCityResponse";
import {ISearchTrainCityResponse} from "../api/response/ISearchTrainCityResponse";
import {store} from "../redux/store";
import {ICountryDTO} from "../interfaces/ICountryDTO";


export function RefsService() {

  const refsApi = RefsApi();

  /**
   * Method for obtaining all countries
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ICountryDTO[]|null>} list of countries. in case of errors return null.
   */
  const getAllCountries = async (ct: CancelToken = axios.CancelToken.source().token): Promise<ICountryDTO[]|null> => {
    try {
      const state = store.getState();
      const response = await refsApi.getAllCountries(state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return null;
    }
  }

  /**
   * Method for obtaining countries with airports by requested name
   * @param {string} text - requested text
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ICountryDTO[]>} list of countries. in case of errors return empty list.
   */
  const getCountries = async (text: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ICountryDTO[]> => {
    try {
      const state = store.getState();
      const response = await refsApi.getCountries(text, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining cities with airports by requested name
   * @param {string} text - requested text
   * @param {string} local - localization name
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ISearchAviaCityResponse[]>} list of cities. in case of errors return empty list.
   */
  const getAviaCities = async (text: string, local: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ISearchAviaCityResponse[]> => {
    try {
      const state = store.getState();
      const response = await refsApi.getAviaCities(text, local, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining cities with rail stations by requested name
   * @param {string} text - requested text
   * @param {string} local - localization name
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ISearchTrainCityResponse[]>} list of cities. in case of errors return empty list.
   */
  const getTrainCities = async (text: string, local: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ISearchTrainCityResponse[]> => {
    try {
      const state = store.getState();
      const response = await refsApi.getTrainCities(text, local, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }


  return {
    getAllCountries,
    getCountries,
    getAviaCities,
    getTrainCities
  };
}