// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.send-documents-form {
  display: flex;
  flex-direction: column;
  padding: 0 40px 20px 40px;
}

.send-documents-form-sections {
  max-height: 400px;
  overflow: visible;
}
.send-documents-form-sections .send-documents-form-section-title {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 0.5em 0;
  color: black;
  font-size: 14px;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
  font-style: oblique;
}
.send-documents-form-sections .send-documents-form-section-content {
  opacity: 1;
  transition: all 200ms linear;
  will-change: opacity, max-height;
  padding: 0 10px 0 0;
}

.send-documents-form-format-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.error-message {
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  padding: 10px;
  font-size: 12px !important;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/cabinetPages/documentsPage/documentSendComponent/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;AACF;AACE;EACE,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACE;EACE,UAAA;EACA,4BAAA;EACA,gCAAA;EACA,mBAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAAF;;AAGA;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,0BAAA;EACA,cAAA;EACA,yBAAA;EACA,qBAAA;EACA,kBAAA;AAAF","sourcesContent":[".send-documents-form {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 0 40px 20px 40px;\r\n}\r\n\r\n.send-documents-form-sections {\r\n  max-height: 400px;\r\n  overflow: visible;\r\n\r\n  .send-documents-form-section-title {\r\n    position: relative;\r\n    display: block;\r\n    text-align: left;\r\n    width: 100%;\r\n    padding: 0.5em 0;\r\n    color: black;\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    border: none;\r\n    background: none;\r\n    outline: none;\r\n    font-style: oblique;\r\n  }\r\n  .send-documents-form-section-content {\r\n    opacity: 1;\r\n    transition: all 200ms linear;\r\n    will-change: opacity, max-height;\r\n    padding: 0 10px 0 0;\r\n  }\r\n}\r\n\r\n.send-documents-form-format-list {\r\n  display: flex;\r\n  flex-direction: row;\r\n  gap: 10px;\r\n}\r\n\r\n.error-message {\r\n  width: 100%;\r\n  text-align: center;\r\n  margin: auto;\r\n  margin-top: 20px;\r\n  padding: 10px;\r\n  font-size: 12px!important;\r\n  color: #856404;\r\n  background-color: #fff3cd;\r\n  border-color: #ffeeba;\r\n  border-radius: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
