import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useAppSelector} from "../../../../redux/hooks";
import {ModalContext} from "../../../commonComponents/modal/ModalContext";
import {IPassengerDTO} from "../../../../interfaces/IPassengerDTO";
import {TripElementType} from "../../../../enums/TripElementType";
import {AeroExpressTariff} from "./modals/searchTicketsModal/searchAeroExpressComponent";
import {
    IAeroExpressDTO,
    IFlightElementDTO,
    IOrderDTO,
    ISelectedPassengerContextDTO, ITrainElementDTO,
    ITripBonusDTO,
    ITripDTO,
    ITripPassengerDTO,
    ITripPassportDTO
} from "../../../../interfaces/IOrderDTO";
import {TripElementStatus} from "../../../../enums/TripElementStatus";
import {IAddPaxPassportsRequest, PaxPassportType} from "../../../../api/request/IAddPaxPassportRequest";
import {IAddPaxBonusesRequest} from "../../../../api/request/IAddPaxBonusesRequest";
import {IOrderBookRequest, IOrderIssueRequest, ProviderTypes} from "../../../../api/request/IOrderBookRequest";
import {
    Trip,
    PassengerTripElement,
    FlightTripElement,
    TrainTripElement,
    AeroExpressTripElement
} from "../../../../interfaces/Trip";
import {ICompanyCfoDTO} from "../../../../interfaces/ICompanyCfoDTO";
import {ISearchAeroExpressResponse} from "../../../../api/response/ISearchAeroExpressResponse";
import {IAeroExpressVariant} from "../../../../interfaces/IAeroExpressVariant";
import {ISearchFlightsResponse} from "../../../../api/response/ISearchFlightsResponse";
import {ISearchTrainsResponse} from "../../../../api/response/ISearchTrainsResponse";
import {IAviaVariant} from "../../../../interfaces/IAviaVariant";
import {ITrainVariant} from "../../../../interfaces/ITrainVariant";
import {
    ICreateAeroExpressElementRequest,
    ICreateFlightElementRequest,
    ICreateFlightSegmentRequest,
    ICreateTrainElementRequest,
    IPatchAeroExpressElementRequest,
    IPatchFlightElementRequest,
    IPatchTrainElementRequest
} from "../../../../api/request/ITripElementRequest";
import {PassportType} from "../../../../enums/PassportType";
import {CompanyService} from "../../../../services/CompanyService";
import {TripService} from "../../../../services/TripService";
import {EmployeesService} from "../../../../services/EmployeesService";
import {PassengersSelectionModal} from "./modals/passengersSelectionModal";
import {SearchTicketsModal} from "./modals/searchTicketsModal";
import {TripComponent} from "./tripComponent";
import {MdProgressBar} from "../../../../components/md-progress-bar";
import {MdButton} from "../../../../components/md-button";
import {MdAccordion} from "../../../../components/md-accordion";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {CompanyIcon} from "../../../../icons/CompanyIcon";
import {CrossIcon} from "../../../../icons/CrossIcon";
import {RefreshIcon} from "../../../../icons/RefreshIcon";
import {Utils} from "../../../../utils/utils";
import "./style.scss";


export function OrderDetailsComponent(
    props: {

    }) {

    const {

    } = props;

    const { id } = useParams();

    const {t} = useTranslation();

    const {close, showModal} = useContext(ModalContext);


    const authState = useAppSelector((state) => state.auth);
    const selectedCompany = useAppSelector((state) => state.companyInfo) as any;


    const companyService = CompanyService();
    const passengersService = EmployeesService();
    const tripService = TripService();

    const [order, setOrder] = useState<IOrderDTO | undefined>(undefined);

    const [loadOrderPending, setLoadOrderPending] = useState<boolean>(false);

    const [financialSupportCenters, setFinancialSupportCenters] = useState<ICompanyCfoDTO[]>([]);
    const [selectedFinancialSupportCenter, setSelectedFinancialSupportCenter] = useState<ICompanyCfoDTO | undefined>(undefined);

    const [trips, setTrips] = useState<Trip[]>([]);
    const [removeTripPending, setRemoveTripPending] = useState<Set<string>>(() => new Set());
    const [removePaxPending, setRemovePaxPending] = useState<Set<string>>(() => new Set());

    const [orderAmount, setOrderAmount] = useState<number>(0);
    const [isLimitExceeded, setIsLimitExceeded] = useState<boolean>(false);

    const [bookingPending, setBookingPending] = useState(false);
    const [bookingComplete, setBookingComplete] = useState(false);
    const [bookingError, setBookingError] = useState(false);

    const [issuePending, setIssuePending] = useState(false);
    const [issueComplete, setIssueComplete] = useState(false);
    const [issueError, setIssueError] = useState(false);



    const loadOrder = async (id?: string) => {
        if(id != null) {
            const orderDetails = await tripService.getOrderById(id);
            if (orderDetails != null) {
                await loadFinancialSupportCenters();
                setOrder(orderDetails);
                mapTrips(orderDetails.trips);
            }
        }
        else {
            setOrder(undefined);
        }
    }

    const loadFinancialSupportCenters = async () => {
        const response = await companyService.getCompanyCfos(selectedCompany.code);
        setFinancialSupportCenters(response);
    }


    const mapTrips = (trips: ITripDTO[]) => {
        setTrips(trips.map((trip, tripIndex) => mapTrip(trip, tripIndex)));
    }

    const mapTrip = (trip: ITripDTO, tripIndex: number) => {
        return new Trip(
            trip.tripId,
            trip.tripName ?? "Командировка",
            tripIndex,
            mapTripElements(trip),
            mapTripPassengers(trip),
            trip.selectedPaxContextResponses.map(item => {
                return {
                    paxId: item.paxId,
                    passportId: item.passportId,
                    bonusId: item.bonusId
                }
            })
        )
    }


    const mapTripPassengers = (trip: ITripDTO) => {

        const tripPassengers = trip.paxes.map(passenger => mapTripPassenger(trip.selectedPaxContextResponses, passenger));

        tripPassengers.sort((a,b) =>  (a.details.lastName > b.details.lastName) ? 1 : ((b.details.lastName > a.details.lastName) ? -1 : 0))

        return tripPassengers;
    }

    const mapTripPassenger = (passengerSelectedItems: ISelectedPassengerContextDTO[], passenger: ITripPassengerDTO) => {
        const selectedItems = passengerSelectedItems.find(x => x.paxId == passenger.id);
        return new PassengerTripElement(
            passenger.id,
            passenger,
            passenger.passports.find(
            (x, i) => selectedItems != null
                ? x.id == selectedItems.passportId
                : x.type == PassportType.Russian) ?? passenger.passports.find(x => x !== undefined),
            passenger.bonuses?.find(
            (x, i) => selectedItems != null
                ? x.id == selectedItems.bonusId
                : i == 0) ?? passenger.bonuses?.find(x => x !== undefined),
       );
    }


    const mapTripElements = (trip: ITripDTO) => {

        const tripElements = [
            ...trip.flightElements.map(x      => mapFlightElement(x)),
            ...trip.trainElements.map(x       => mapTrainElement(x)),
            ...trip.aeroexpressElements.map(x => mapAeroExpressElement(x))
        ];

        tripElements.sort((a,b) =>  (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0))

        return tripElements;
    }

    const mapFlightElement = (flightElementDTO: IFlightElementDTO) => {
        const variant = (JSON.parse(flightElementDTO.searchResponse) as ISearchFlightsResponse).variants[0];
        const tariffIndex = variant.rejsInfo[variant.rejsInfo.length - 1].tarifs.findIndex(t => t.isSelected) ?? 0;

        return new FlightTripElement(
            flightElementDTO.id,
            (flightElementDTO.departure != null ? new Date(flightElementDTO.departure).getTime() : 0),
            variant,
            tariffIndex,
            flightElementDTO.searchResponse,
            flightElementDTO.pnrs,
            flightElementDTO.status
        );
    }

    const mapTrainElement = (trainElementDTO: ITrainElementDTO) => {
        const variant = (JSON.parse(trainElementDTO.searchResponse) as ISearchTrainsResponse).variants[0];
        const tariffIndex = variant.categoryPrice.findIndex(t => t.isSelected) ?? 0;

        return new TrainTripElement(
            trainElementDTO.id,
            (trainElementDTO.departure != null ? new Date(trainElementDTO.departure).getTime() : 0),
            variant,
            tariffIndex,
            trainElementDTO.searchResponse,
            trainElementDTO.pnrs,
            trainElementDTO.status
        );
    }

    const mapAeroExpressElement = (aeroExpressDTO: IAeroExpressDTO) => {
        const variants = (JSON.parse(aeroExpressDTO.searchResponse) as ISearchAeroExpressResponse).variants;
        const variant = (
            variants.find(x => x.isSelected)
            ?? variants.find(x => x.tariffId === aeroExpressDTO.tariff.toString())
        ) ?? variants[0];
        const tariffIndex = variants.findIndex(t => t.isSelected) ?? 0;

        return new AeroExpressTripElement(
            aeroExpressDTO.id,
            (aeroExpressDTO.dateDep != null ? new Date(aeroExpressDTO.dateDep).getTime() : 0),
            variant,
            tariffIndex,
            Number(variant.tariffId) as AeroExpressTariff,
            aeroExpressDTO.searchResponse,
            aeroExpressDTO.pnrs,
            aeroExpressDTO.status
        );
    }


    const createTrip = async () => {
        const trip = await tripService.createTrip({
            tripName: "Командировка" + ("-" + (trips.length + 1)),
            orderId: (order?.id ?? "")
        });
        if(trip != null) {
            setTrips(prev => [...prev, mapTrip(trip, prev.length)]);
        }
    }

    const removeTrip = async (tripId: string) => {
        setRemoveTripPending(prev => new Set(prev).add(tripId));

        const response = await tripService.removeTrip(tripId);

        if(response != null) {
            setTrips(prev => prev.filter(x => x.id != tripId));
        }

        setRemoveTripPending(prev => {
            const next = new Set(prev);
            next.delete(tripId);
            return next;
        });
    }


    const openPassengersSelectionModal = (tripId: string) => {
        const trip = trips.find(x => x .id == tripId);
        const tripPassengers = trip?.passengers ?? [];
        showModal((modalContext) => {
            return {
                title: (t("bookingPage.choosingPax") + (order?.orderNo != null ? " №" + order?.orderNo : "")),
                content: (
                    <PassengersSelectionModal
                        selectedCompany={selectedCompany}
                        tripPassengers={tripPassengers}
                        selectPassengersHandler={
                            async (passengers) => {
                                await addPassengers(tripId, passengers);
                                modalContext.close();
                            }
                        }
                    />
                ),
                options: {
                    closeOnClickOutside: false
                }
            }
        });
    }

    const addPassengers = async (tripId: string, passengers: IPassengerDTO[]) => {

        const existingPassengerCodes: string[] = [];

        for(let i = 0; i < passengers.length; i++) {
            const passenger = passengers[i];

            const isPaxExist = await tripService.isPaxExist(passenger.paxCode);

            if(!isPaxExist) {
                const request = {
                    code: passenger.paxCode,
                    clientCodes: [selectedCompany.code],
                    gender: undefined,
                    firstName: passenger.first,
                    lastName: passenger.last,
                    patronymic: passenger.middle,
                    dateOfBurn: passenger.birthday.substring(0, 10).split(".").reverse().join("-") + "T00:00:00",
                    passports: passenger.passports.map(passport => {
                        return {
                            number: passport.number,
                            expired: passport.expiry,
                            type: (() => {
                                if(passport.isInter) {
                                    return PassportType.International;
                                }
                                else if(passport.isForeign) {
                                    return PassportType.Foreign;
                                }
                                return PassportType.Russian;
                            })()
                        }
                    }),
                    bonuses: passenger.bonusCards.map(bonusCard => {
                        return {
                            name: bonusCard.name,
                            number: bonusCard.nomer,
                            ak: bonusCard.ak,
                            code: bonusCard.code,
                            fullPaxName: bonusCard.fio
                        }
                    })
                };

                const response = await tripService.createPax(request);

                if(response != null) {
                    existingPassengerCodes.push(response.code);
                }
            }
            else {
                existingPassengerCodes.push(passenger.paxCode);
            }
        }


        const response = await tripService.addPaxesToTrip({
            tripId: tripId,
            paxCodes: existingPassengerCodes
        });

        if (response != null) {
            setTrips(prev => prev.map(trip => {
                if (trip.id == tripId) {
                    trip.passengers = [
                        ...trip.passengers.filter(x => !response.some(y => y.id === x.id)),
                        ...response.map(x => mapTripPassenger(trip.passengerSelectedItems, x))
                    ]
                }
                return trip;
            }));
        }
    }

    const changePassengerPassport = async (tripId: string, paxCode: string, passport: ITripPassportDTO) => {

        const trip = trips.find(x => x .id == tripId);
        const passenger = trip?.passengers.find(x => x.details.code == paxCode);

        if(passenger != null) {
            const response = await tripService.patchPaxesInTrip({
                tripId: tripId,
                selectedPaxContextRequests: trip?.passengers.filter(x => x.passport != null).map(x => {
                    if(x.details.id == passenger.details.id) {
                        return {
                            paxId: passenger.details.id,
                            passportId: passport.id,
                            bonusId: passenger.bonus?.id
                        }
                    }
                    return {
                        paxId: x.details.id,
                        passportId: x.passport?.id,
                        bonusId: x.bonus?.id
                    }
                }) as ISelectedPassengerContextDTO[] ?? []
            });

            if (response != null) {
                setTrips(prev => prev.map((trip, tripIndex) => {
                    if(trip.id == tripId) {
                        return mapTrip(response, tripIndex);
                    }
                    return trip;
                }));
            }
        }
    }

    const changePassengerBonus = async (tripId: string, paxCode: string, bonus: ITripBonusDTO) => {
        const trip = trips.find(x => x .id == tripId);
        const passenger = trip?.passengers.find(x => x.details.code == paxCode);

        if(passenger != null) {
            const response = await tripService.patchPaxesInTrip({
                tripId: tripId,
                selectedPaxContextRequests: trip?.passengers.filter(x => x.passport != null).map(x => {
                    if(x.details.id == passenger.details.id) {
                        return {
                            paxId: passenger.details.id,
                            passportId: passenger.passport?.id,
                            bonusId: bonus?.id
                        }
                    }
                    return {
                        paxId: x.details.id,
                        passportId: x.passport?.id,
                        bonusId: x.bonus?.id
                    }
                }) as ISelectedPassengerContextDTO[] ?? []
            });

            if (response != null) {
                setTrips(prev => prev.map((trip, tripIndex) => {
                    if(trip.id == tripId) {
                        return mapTrip(response, tripIndex);
                    }
                    return trip;
                }));
            }
        }
    }

    const removePassenger = async (tripId: string, paxCode: string) => {

        setRemovePaxPending(prev => new Set(prev).add(paxCode));

        const response = await tripService.removePaxesFromTrip({
            tripId: tripId,
            paxCodes: [paxCode]
        });

        if (response != null) {
            setTrips(prev => prev.map(trip => {
                if(trip.id == tripId) {
                    trip.passengers = trip.passengers.filter(pi => pi.details.code !== paxCode);
                }
                return trip;
            }));
        }

        setRemovePaxPending(prev => {
            const next = new Set(prev);
            next.delete(tripId);
            return next;
        });
    }

    const syncPassengerPassportsWithTTS = async () => {
        const ttsPassengers = await passengersService.getPassengers(selectedCompany.code);

        let passengersMap: { tripId:string; passengers: PassengerTripElement[] }[] = [];

        for(let tripIndex = 0; tripIndex < trips.length; tripIndex++) {
            const trip = trips[tripIndex];
            const passengers: PassengerTripElement[] = [];
            for(let passengerIndex = 0; passengerIndex < trip.passengers.length; passengerIndex++) {
                const passenger = trip.passengers[passengerIndex];

                const ttsPassports = ttsPassengers.find(x => x.paxCode == passenger.details.code)?.passports ?? [];

                const addPassportsRequest: IAddPaxPassportsRequest = {
                    code: passenger.details.code,
                    passports: []
                };

                for(let i = 0; i < ttsPassports.length; i++) {
                    const ttsPassport = ttsPassports[i];
                    if(passenger.details.passports.find(x => x.number === ttsPassport.number) == null) {
                        // add passport
                        addPassportsRequest.passports.push({
                            number: ttsPassport.number,
                            expired: ttsPassport.expiry,
                            type: (() => {
                                if(ttsPassport.isForeign) {
                                    return PaxPassportType.foreign;
                                }
                                else if(ttsPassport.isInter) {
                                    return PaxPassportType.international;
                                }
                                else {
                                    return PaxPassportType.rus;
                                }
                            })()
                        })
                    }
                }

                if(addPassportsRequest.passports.length > 0) {
                    let passengerDTO = await tripService.addPassports(addPassportsRequest);
                    if(passengerDTO != null) {
                        passenger.details.passports = passengerDTO.passports;
                    }
                }



                const ttsBonusCards = ttsPassengers.find(x => x.paxCode == passenger.details.code)?.bonusCards ?? [];

                const addBonusesRequest: IAddPaxBonusesRequest = {
                    code: passenger.details.code,
                    bonuses: []
                };

                for(let i = 0; i < ttsBonusCards.length; i++) {
                    const ttsBonusCard = ttsBonusCards[i];
                    if(passenger.details.bonuses?.find(x => x.number === ttsBonusCard.nomer) == null) {
                        // add bonus
                        addBonusesRequest.bonuses.push({
                            name: ttsBonusCard.name,
                            number: ttsBonusCard.nomer,
                            ak: ttsBonusCard.ak,
                            code: ttsBonusCard.code,
                            fullPaxName: ttsBonusCard.fio
                        })
                    }
                }

                if(addBonusesRequest.bonuses.length > 0) {
                    let passengerDTO = await tripService.addBonuses(addBonusesRequest);
                    if(passengerDTO != null) {
                        passenger.details.bonuses = passengerDTO.bonuses;
                    }
                }



                if(passenger.passport == null) {
                    passenger.passport = passenger.details.passports.find(x => x.type == PassportType.Russian)
                        ?? passenger.details.passports.find(x => x !== undefined)
                }

                if(passenger.bonus == null) {
                    passenger.bonus = passenger.details.bonuses?.find(x => x !== undefined)
                }

                passengers.push(passenger)
            }

            passengersMap.push({
                tripId: trip.id,
                passengers: passengers
            });

        }

        setTrips(prev => prev.map(trip => {
            trip.passengers = passengersMap.find(x => x.tripId == trip.id)?.passengers ?? trip.passengers;
            return trip;
        }));
    }


    const openSearchTicketsModal = (tripId: string) => {
        showModal((modalContext) => {
            return {
                title: (t("bookingPage.ticketSearch") + (order?.orderNo != null ? " №" + order.orderNo : "")),
                content: (
                    <SearchTicketsModal
                        selectedCompany={selectedCompany}
                        selectFlightTicketHandler={
                            async (variant, tariffIndex, searchResponse) => {
                                await addFlightElement(tripId, variant, tariffIndex, searchResponse);
                                modalContext.close();
                            }
                        }
                        selectTrainTicketHandler={
                            async (variant, tariffIndex, searchResponse) => {
                                await addTrainElement(tripId, variant, tariffIndex, searchResponse);
                                modalContext.close();
                            }
                        }
                        selectAeroExpressTicketHandler={
                            async (variant, tariffIndex, searchResponse) => {
                                await addAeroExpressElement(tripId, variant, tariffIndex, searchResponse);
                                modalContext.close();
                            }
                        }
                    />
                ),
                options: {
                    closeOnClickOutside: false
                }
            }
        });
    }


    // TODO: sorting
    const addFlightElement = async (tripId: string, variant: IAviaVariant, selectedTariffIndex: number, searchResponse: ISearchFlightsResponse) => {

        const request = {
            tripId: tripId,
            elementType: TripElementType.Flight,
            status: TripElementStatus.NotSet,
            pnr: undefined,
            flightNumber:  [ ...new Set( variant.rejsInfo.map(x => x.flightNumber)) ].join(','),
            dateDep: variant.rejsInfo[0].dateDep,
            dateArr: variant.rejsInfo[variant.rejsInfo.length - 1].dateArr,
            createFlightSegmentRequests: variant.rejsInfo.map(segment => {
                return {
                    flightNumber: segment.flightNumber,
                    carriers: segment.airCompanyName,
                    carrierCode: segment.airCompanyCode,
                    airCodeDep: segment.airCodeDep,
                    cityDep: segment.cityDep,
                    dateDep: segment.dateDep,
                    airCodeArr: segment.airCodeArr,
                    cityArr: segment.cityArr,
                    dateArr: segment.dateArr,
                    isBackwardTicketHead: segment.isBackwardTicketHead ?? false,
                } as ICreateFlightSegmentRequest;
            }),
            searchResponse: JSON.stringify(
                {
                    requested: searchResponse.requested,
                    variants: [ {
                        ...variant,
                        rejsInfo: variant.rejsInfo.map((segment, segmentIndex) => {
                            if(segmentIndex === variant.rejsInfo.length - 1) {
                                segment.tarifs = segment.tarifs.map((tariff, tariffIndex) => {
                                    tariff.isSelected = tariffIndex == selectedTariffIndex;
                                    return tariff;
                                })
                            }
                            return segment;
                        })
                    } as IAviaVariant ]
                }
            )
        } as ICreateFlightElementRequest;

        const response = await tripService.createFlightElement(request);

        if(response != null) {
            const flightElement = mapFlightElement(response);
            setTrips(prev => prev.map(trip => {
                // TODO: sorting
                if(trip.id == tripId) {
                    trip.items = [
                        ...trip.items.filter(x => x.hash !== flightElement.hash),
                        flightElement
                    ]
                }
                return trip;
            }));
        }
    }

    const updateFlightElementTariff = async (tripId: string, elementId: string, selectedTariffIndex: number) => {
        const flightElement = trips.find(x => x.id == tripId)?.items.find(x => x.id == elementId);

        if(flightElement instanceof FlightTripElement) {

            let searchResponse = JSON.parse(flightElement.searchResponse) as ISearchFlightsResponse;
            let patchedSearchResponse = {
                requested: searchResponse.requested,
                variants: [
                    {
                        ...flightElement.variant,
                        rejsInfo: flightElement.variant.rejsInfo.map((segment, segmentIndex) => {
                            if(segmentIndex === flightElement.variant.rejsInfo.length - 1) {
                                segment.tarifs = segment.tarifs.map((tariff, tariffIndex) => {
                                    tariff.isSelected = tariffIndex == selectedTariffIndex;
                                    return tariff;
                                })
                            }
                            return segment;
                        })
                    } as IAviaVariant
                ]
            } as ISearchFlightsResponse;

            const request = {
                id: elementId,
                searchResponse: JSON.stringify(patchedSearchResponse)
            } as IPatchFlightElementRequest;

            const response = await tripService.patchFlightElement(request);

            if(response != null) {
                setTrips(prev => prev.map(trip => {
                    if(trip.id == tripId) {
                        trip.items = trip.items.map(tripItem => {
                            if(tripItem.id === elementId && tripItem instanceof FlightTripElement) {
                                return mapFlightElement(response);
                            }
                            return tripItem;
                        })
                    }
                    return trip;
                }))
            }
        }
    }


    // TODO: sorting
    const addTrainElement = async (tripId: string, variant: ITrainVariant, selectedTariffIndex: number, searchResponse: ISearchTrainsResponse) => {

        const request = {
            tripId: tripId,
            elementType: TripElementType.Train,
            status: TripElementStatus.NotSet,
            pnr: undefined,
            trainNumber: variant.trainNum,
            stationDep: variant.stationFrom,
            stationCodeDep: variant.codeStationFrom,
            dateDep: Utils.dateToLocalISO(Utils.parseTrainDateTime(variant.departureAt, variant.departureShortOn)), //.toISOString().replace('Z', ''),
            stationArr: variant.stationTo,
            stationCodeArr: variant.codeStationTo,
            dateArr: Utils.dateToLocalISO(Utils.parseTrainDateTime(variant.arriveShortOn, variant.arriveAtn)), //.toISOString(), //.replace('Z', ''),
            searchResponse: JSON.stringify(
                {
                    requested: searchResponse.requested,
                    variants: [ {
                        ...variant,
                        categoryPrice: variant.categoryPrice.map((tariff, tariffIndex) => {
                            tariff.isSelected = tariffIndex == selectedTariffIndex;
                            return tariff;
                        })
                    } as ITrainVariant ]
                }
            )
        } as ICreateTrainElementRequest;

        const response = await tripService.createTrainElement(request);

        if(response != null) {
            setTrips(prev => prev.map(trip => {
                const trainElement = mapTrainElement(response);
                // TODO: sorting
                if(trip.id == tripId) {
                    trip.items = [
                        ...trip.items.filter(x => x.hash !== trainElement.hash),
                        trainElement
                    ]
                }
                return trip;
            }));
        }
    }

    const updateTrainElementTariff = async (tripId: string, elementId: string, selectedTariffIndex: number) => {
        const trainElement = trips.find(x => x.id == tripId)?.items.find(x => x.id == elementId);

        if(trainElement instanceof TrainTripElement) {

            let searchResponse = JSON.parse(trainElement.searchResponse) as ISearchTrainsResponse;
            let patchedSearchResponse = {
                requested: searchResponse.requested,
                variants: [
                    {
                        ...trainElement.variant,
                        categoryPrice: trainElement.variant.categoryPrice.map((tariff, tariffIndex) => {
                            tariff.isSelected = tariffIndex == selectedTariffIndex;
                            return tariff;
                        })
                    } as ITrainVariant
                ]
            } as ISearchTrainsResponse;

            const request = {
                id: elementId,
                searchResponse: JSON.stringify(patchedSearchResponse)
            } as IPatchTrainElementRequest;

            const response = await tripService.patchTrainElement(request);

            if(response != null) {
                setTrips(prev => prev.map(trip => {
                    if(trip.id == tripId) {
                        trip.items = trip.items.map(tripItem => {
                            if(tripItem.id === elementId && tripItem instanceof TrainTripElement) {
                                return mapTrainElement(response);
                            }
                            return tripItem;
                        })
                    }
                    return trip;
                }))
            }
        }
    }


    // TODO: sorting
    const addAeroExpressElement = async (tripId: string, variant: IAeroExpressVariant, selectedTariffIndex: number, searchResponse: ISearchAeroExpressResponse) => {

        const request = {
            tripId: tripId,
            elementType: TripElementType.AeroExpress,
            status: TripElementStatus.NotSet,
            pnr: undefined,
            dateDep: variant.dateDep,
            confirmTill: variant.confirmTill,
            tariff: Number(variant.tariffId),
            searchResponse: JSON.stringify(
                {
                    requested: searchResponse.requested,
                    variants: searchResponse.variants.map((tariff, tariffIndex) => {
                        tariff.isSelected = tariffIndex == selectedTariffIndex;
                        return tariff;
                    })
                }
            )
        } as ICreateAeroExpressElementRequest;

        const response = await tripService.createAeroExpressElement(request);

        if(response != null) {
            setTrips(prev => prev.map(trip => {
                const aeroExpressElement = mapAeroExpressElement(response);
                // TODO: sorting
                if(trip.id == tripId) {
                    trip.items = [
                        ...trip.items.filter(x => x.hash !== aeroExpressElement.hash),
                        aeroExpressElement
                    ]
                }
                return trip;
            }));
        }
    }

    const updateAeroExpressElementTariff = async (tripId: string, elementId: string, selectedTariffIndex: number) => {
        const aeroExpressElement = trips.find(x => x.id == tripId)?.items.find(x => x.id == elementId);

        if(aeroExpressElement instanceof AeroExpressTripElement) {

            let searchResponse = JSON.parse(aeroExpressElement.searchResponse) as ISearchAeroExpressResponse;
            let patchedSearchResponse = {
                requested: searchResponse.requested,
                variants: searchResponse.variants.map((variant, variantIndex) => {
                    variant.isSelected = variantIndex == selectedTariffIndex;
                    return variant;
                })
            } as ISearchAeroExpressResponse;

            const request = {
                id: elementId,
                type: TripElementType.AeroExpress,
                searchResponse: JSON.stringify(patchedSearchResponse),
                tariff: Number(patchedSearchResponse.variants.find(x => x.isSelected)?.tariffId ?? aeroExpressElement.tariffId)
            } as IPatchAeroExpressElementRequest;

            const response = await tripService.patchAeroExpressElement(request);

            // TODO: find a way to handle response
            if(response != null) {
                setTrips(prev => prev.map(trip => {
                    if(trip.id == tripId) {
                        trip.items = trip.items.map(tripItem => {
                            if(tripItem.id === elementId && tripItem instanceof AeroExpressTripElement) {
                                return mapAeroExpressElement(response);
                            }
                            return tripItem;
                        })
                    }
                    return trip;
                }))
            }
        }
    }


    const removeTripElement = async (tripId: string, tripItemId: string, elementType: TripElementType) => {
        const response = await tripService.removeTripItem(tripItemId, elementType);
        if(response != null) {
            setTrips(prev => prev.map(trip => {
                if(trip.id == tripId) {
                    trip.items = trip.items.filter(x => x.id != tripItemId)
                }
                return trip;
            }));
        }
    }


    const toggleIsSelectedTripElement = (tripId: string, tripItemId: string, state: boolean) => {
        setTrips(prev => prev.map(trip => {
            if(trip.id == tripId) {
                trip.items = trip.items.map(tripItem => {
                    if(tripItem.id == tripItemId) {
                        tripItem.isSelected = state;
                    }
                    return tripItem;
                })
            }
            return trip;
        }))
    }


    const updateOrderAmount = async () => {
        let amount = 0;
        trips.forEach(trip => {
            trip.items.filter(x => x.isSelected).forEach(item => {
                if (item instanceof FlightTripElement) {
                    const tariff = item.variant.rejsInfo[item.variant.rejsInfo.length - 1].tarifs[item.tariffIndex];
                    amount += (tariff.priceWithSborTts * trip.passengers.length);
                } else if (item instanceof TrainTripElement) {
                    const tariff = item.variant.categoryPrice[item.tariffIndex];
                    amount += ((tariff.price + tariff.sborTts) * trip.passengers.length);
                } else {
                }
            })
        });
        setOrderAmount(amount);
    }


    const checkOrderLimit = async () => {
        const result = await tripService.canOrderIssue( selectedCompany?.code ?? "", orderAmount);
        if(result != null) {
            setIsLimitExceeded(!result.canIssue);
        }
    }


    const sendToTts = async () => {
        let flightTrips = trips.map(trip => {
            return {
                id: trip.id,
                index: trip.index,
                items: trip.items.filter(item => item instanceof FlightTripElement),
                passengers: trip.passengers
            } as Trip;
        }).filter(x => x.items.length > 0);

        let trainTrips = trips.map(trip => {
            return {
                id: trip.id,
                index: trip.index,
                items: trip.items.filter(item => item instanceof TrainTripElement),
                passengers: trip.passengers
            } as Trip;
        }).filter(x => x.items.length > 0);

        let aeroExpressTrips = trips.map(trip => {
            return {
                id: trip.id,
                index: trip.index,
                items: trip.items.filter(item => item instanceof AeroExpressTripElement),
                passengers: trip.passengers
            } as Trip;
        }).filter(x => x.items.length > 0);

        const bookRequest: IOrderBookRequest = {
            orderId: (order?.id ?? ""),
            orderNo: order?.orderNo,
            clientCode: selectedCompany?.code ?? "",
            flightBookModel: {
                mode: ProviderTypes.Flight,
                trips: flightTrips.map(trip => {
                    return (
                        {
                            id: trip.id,
                            paxes: trip.passengers.map(p => {
                                return {
                                    paxCode: p.details.code,
                                    selectedPassportNo: p.passport?.number ?? ""
                                }
                            }),
                            tripElements: trip.items.map(dir => {
                                const flightItem = dir as FlightTripElement;
                                let fareBasises = flightItem.variant.rejsInfo[flightItem.variant.rejsInfo.length - 1].tarifs[flightItem.tariffIndex].fareBasis;
                                return(
                                    {
                                        tripElementId: dir.id,
                                        fareBasises: fareBasises,
                                        searchResponse: JSON.parse(dir.searchResponse),
                                    }
                                )
                            }),
                        }
                    )
                })
            },
            trainBookModel: {
                mode: ProviderTypes.Train,
                trips: trainTrips.map(trip => {
                    return (
                        {
                            id: trip.id,
                            paxes: trip.passengers.map(p => {
                                return {
                                    paxCode: p.details.code,
                                    selectedPassportNo: p.passport?.number ?? ""
                                }
                            }),
                            tripElements: trip.items.map(dir => {
                                const flightItem = dir as TrainTripElement;
                                let fareBasises = [flightItem.variant.categoryPrice[flightItem.tariffIndex].fareBasis];
                                return(
                                    {
                                        tripElementId: dir.id,
                                        fareBasises: fareBasises,
                                        searchResponse: JSON.parse(dir.searchResponse),
                                    }
                                )
                            }),

                        }
                    )
                })
            },
            aeroExpressBookModel: {
                mode: ProviderTypes.AeroExpress,
                trips: aeroExpressTrips.map(trip => {
                    return (
                        {
                            id: trip.id,
                            paxes: trip.passengers.map(p => {
                                return {
                                    paxCode: p.details.code,
                                    selectedPassportNo: p.passport?.number ?? ""
                                }
                            }),
                            tripElements: trip.items.map(dir => {
                                const item = dir as AeroExpressTripElement;
                                return (
                                    {
                                        tripElementId: dir.id,
                                        fareBasises: [item.variant.tariffId.toString()],
                                        searchResponse: JSON.parse(dir.searchResponse),
                                    }
                                )
                            }),

                        }
                    )
                })
            }
        }

        const response = await tripService.sendOrderToTTS(bookRequest);
    }


    const bookSelected  = async () => {
        //const companyCheckResult = await checkCompany();

        // if (!companyCheckResult) {
        //     return;
        // }

        // update issue status
        setIssueError(false);
        setIssuePending(false);
        setIssueComplete(false);

        // update booking status
        setBookingError(false)
        setBookingPending(true);
        setBookingComplete(false);

        // filter trips with flight elements that are selected and not booked yet
        let flightTrips = trips.map(trip => {
            return {
                ...trip,
                items: trip.items.filter(item =>
                    item instanceof FlightTripElement
                    && item.status == TripElementStatus.NotSet
                    && item.isSelected
                ),
            } as Trip;

        }).filter(x => x.items.length > 0);

        // filter trips with flight elements that are selected and not booked yet
        let trainTrips = trips.map(trip => {
            return {
                ...trip,
                items: trip.items.filter(item =>
                    item instanceof TrainTripElement
                    && item.status == TripElementStatus.NotSet
                    && item.isSelected
                ),
            } as Trip;
        }).filter(x => x.items.length > 0);

        let aeroExpressTrips = trips.map(trip => {
            return {
                id: trip.id,
                index: trip.index,
                items: trip.items.filter(item =>
                    item instanceof AeroExpressTripElement
                    && item.status == TripElementStatus.NotSet
                    && item.isSelected
                ),
                passengers: trip.passengers
            } as Trip;
        }).filter(x => x.items.length > 0);

        // prepare booking model
        const bookRequest: IOrderBookRequest = {
            orderId: (order?.id ?? ""),
            orderNo: order?.orderNo,
            clientCode: selectedCompany?.code ?? "",
            flightBookModel: {
                mode: ProviderTypes.Flight,
                trips: flightTrips.map(trip => {
                    // trip data
                    return ({
                        id: trip.id,
                        // trip passengers data
                        paxes: trip.passengers.map(p => {
                            return {
                                paxCode: p.details.code,
                                selectedPassportNo: p.passport?.number ?? ""
                            }
                        }),
                        // trip elements data
                        tripElements: trip.items.map(dir => {
                            const item = dir as FlightTripElement;
                            const tariff = item.variant.rejsInfo[item.variant.rejsInfo.length - 1].tarifs[item.tariffIndex];
                            let fareBasises = tariff.fareBasis;
                            return({
                                tripElementId: dir.id,
                                fareBasises: fareBasises,
                                searchResponse: JSON.parse(dir.searchResponse)
                            })
                        }),
                    })
                })
            },
            trainBookModel: {
                mode: ProviderTypes.Train,
                trips: trainTrips.map(trip => {
                    // trip data
                    return ({
                        id: trip.id,
                        // trip passengers data
                        paxes: trip.passengers.map(p => {
                            return {
                                paxCode: p.details.code,
                                selectedPassportNo: p.passport?.number ?? ""
                            }
                        }),
                        // trip elements data
                        tripElements: trip.items.map(dir => {
                            const item = dir as TrainTripElement;
                            const tariff = item.variant.categoryPrice[item.tariffIndex];
                            let fareBasises = [tariff.fareBasis];
                            return({
                                tripElementId: dir.id,
                                fareBasises: fareBasises,
                                searchResponse: JSON.parse(dir.searchResponse)
                            })
                        }),
                    })
                })
            },
            aeroExpressBookModel: {
                mode: ProviderTypes.AeroExpress,
                trips: aeroExpressTrips.map(trip => {
                    return (
                        {
                            id: trip.id,
                            paxes: trip.passengers.map(p => {
                                return {
                                    paxCode: p.details.code,
                                    selectedPassportNo: p.passport?.number ?? ""
                                }
                            }),
                            tripElements: trip.items.map(dir => {
                                const item = dir as AeroExpressTripElement;
                                return (
                                    {
                                        tripElementId: dir.id,
                                        fareBasises: [item.variant.tariffId.toString()],
                                        searchResponse: JSON.parse(dir.searchResponse),
                                    }
                                )
                            }),

                        }
                    )
                })
            }
        }


        let bookingTrips = [...flightTrips, ...trainTrips];
        let timeout = 0;
        for(let i = 0; i < bookingTrips.length; i++) {
            let bookingTrip = bookingTrips[i];
            timeout += bookingTrip.passengers.length * (bookingTrip.items.length) * 30 * 1000;
        }
        timeout = Math.max(timeout, 100 * 1000);
        console.warn('Timeout for booking = ' + Math.round(timeout / 1000) + 'sec');


        // book order
        const response = await tripService.bookOrder(bookRequest, timeout);
        // check response
        const result = !Utils.isEmpty(response);

        // refresh order details
        await loadOrder(order?.id);

        // update booking status
        setBookingPending(false);
        setBookingError(!result);
        setBookingComplete(result);
    }

    const issueSelected = async () => {
        //const companyCheckResult = await checkCompany();

        // if (!companyCheckResult) {
        //     return;
        // }

        // update booking status
        setBookingError(false);
        setBookingPending(false);
        setBookingComplete(false);

        // update issue status
        setIssueError(false)
        setIssuePending(true);
        setIssueComplete(false);


        // filter trips with flight elements that are selected and booked already
        let flightTrips = trips.map(trip => {
            return {
                ...trip,
                items: trip.items.filter(item =>
                    item instanceof FlightTripElement
                    && item.status == TripElementStatus.Booked
                    && item.isSelected
                ),
            } as Trip;

        }).filter(x => x.items.length > 0);

        // filter trips with train elements that are selected and booked already
        let trainTrips = trips.map(trip => {
            return {
                ...trip,
                items: trip.items.filter(item =>
                    item instanceof TrainTripElement
                    && item.status == TripElementStatus.Booked
                    && item.isSelected
                ),
            } as Trip;
        }).filter(x => x.items.length > 0);

        // filter trips with aero express elements that are selected and booked already
        let aeroExpressTrips = trips.map(trip => {
            return {
                ...trip,
                items: trip.items.filter(item =>
                    item instanceof AeroExpressTripElement
                    && item.status == TripElementStatus.Booked
                    && item.isSelected
                ),
            } as Trip;
        }).filter(x => x.items.length > 0);

        // prepare issue model
        const issueRequest: IOrderIssueRequest = {
            orderId: (order?.id ?? ""),
            orderNo: order?.orderNo,
            clientCode: selectedCompany?.code ?? "",
            // who first Fights or Trains ???
            trips: [...flightTrips, ...trainTrips, ...aeroExpressTrips].map(trip => {
                // trip data
                return ({
                    tripId: trip.id,
                    toIssueElements: trip.items.map(item => {
                        let isFlight = (item instanceof FlightTripElement);
                        let isTrain  = (item instanceof TrainTripElement);
                        let isAeroExpress  = (item instanceof AeroExpressTripElement);
                        return {
                            tripElementId: item.id,
                            type: (isFlight ? TripElementType.Flight : TripElementType.Train),
                            flightPnrs: (isFlight ? (item.pnrs?.split(";") ?? []) : []),
                            issueFlights: isFlight,  // temporary
                            trainPnrs: (isTrain ? (item.pnrs?.split(";") ?? []) : []),
                            issueTrain: isTrain,    // temporary
                            issueAeroexpress: isAeroExpress,    // temporary
                            aeroexpressPnrs: (isAeroExpress ? (item.pnrs?.split(";") ?? []) : []),
                        };
                    })
                })
            })
        }


        let issuingTrips = [...flightTrips, ...trainTrips];
        let timeout = 0;
        for(let i = 0; i < issuingTrips.length; i++) {
            let issuingTrip = issuingTrips[i];
            timeout += issuingTrip.passengers.length * (issuingTrip.items.length) * 30 * 1000;
        }
        timeout = Math.max(timeout, 100 * 1000);
        console.warn('Timeout for issue = ' + Math.round(timeout / 1000) + 'sec');


        // book order
        const response = await tripService.issueOrder(issueRequest, timeout);
        // check response
        const result = !Utils.isEmpty(response);

        // refresh order details
        await loadOrder(order?.id);

        // update issue status
        setIssuePending(false);
        setIssueError(!result);
        setIssueComplete(result);
    }


    useEffect(() => {
        loadOrder(id);
    }, [id]);

    useEffect(() => {
        updateOrderAmount();
    }, [trips]);

    useEffect(() => {
        checkOrderLimit();
    }, [orderAmount]);


    return (
        <React.Fragment>
            {
                (!loadOrderPending && order != null) && (
                    <div className="trip-builder-form-single">

                        <div className="trip-builder-form-header">

                            <div className="selected-company-details">
                                <div className="company-item">
                                    <div className="company-item-icon">
                                        <CompanyIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "path": {
                                                    fill: "#777E90"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="company-item-details">
                                        <div className="company-item-details-row">
                                            <div className="company-item-name">
                                                {selectedCompany?.fullName}
                                            </div>
                                        </div>
                                        <div className="company-item-details-row">
                                            <div className="company-item-inn">
                                                {selectedCompany?.inn}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="company-item-tools">

                                    </div>
                                </div>
                            </div>

                            {
                                trips.some(trip => trip.passengers.some(passenger => passenger.passport == null)) && (
                                    <div className="passengers-error-message">
                                        <span>
                                            {t("bookingPage.noPassportDetailsError")}
                                        </span>
                                        <span>
                                            <MdButton
                                                tooltip={t('bookingPage.synchronizationWithTTS')}
                                                style={{
                                                    "button": {
                                                        width: "18px",
                                                        height: "18px",
                                                        border: "none",
                                                        padding: 0,
                                                        justifyContent: "center",
                                                        backgroundColor: "transparent",
                                                    },
                                                    "ink": {
                                                        backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                    }
                                                }}
                                                onClick={() => syncPassengerPassportsWithTTS()}
                                            >
                                                <RefreshIcon
                                                    width="12"
                                                    height="12"
                                                    style={{
                                                        "path": {
                                                            fill: "#856404"
                                                        }
                                                    }}
                                                />
                                            </MdButton>
                                        </span>
                                    </div>
                                )
                            }

                            {
                                (bookingError && !issueError) && (
                                    <div className="booking-error-message">
                                        <span>{t("bookingPage.bookingError")}</span>
                                        <MdButton
                                            style={{
                                                "button": {
                                                    width: "18px",
                                                    height: "18px",
                                                    border: "none",
                                                    padding: 0,
                                                    justifyContent: "center",
                                                    backgroundColor: "transparent",
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                }
                                            }}
                                            onClick={() => setBookingError(false)}
                                        >
                                            <CrossIcon
                                                width="16"
                                                height="16"
                                                style={{
                                                    "path": {
                                                        fill: "#856404"
                                                    }
                                                }}
                                            />
                                        </MdButton>
                                    </div>
                                )
                            }

                            {
                                issueError && (
                                    <div className="booking-error-message">
                                        <span>{t("bookingPage.issueError")}</span>
                                        <MdButton
                                            style={{
                                                "button": {
                                                    width: "18px",
                                                    height: "18px",
                                                    border: "none",
                                                    padding: 0,
                                                    justifyContent: "center",
                                                    backgroundColor: "transparent",
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                }
                                            }}
                                            onClick={() => setIssueError(false)}
                                        >
                                            <CrossIcon
                                                width="16"
                                                height="16"
                                                style={{
                                                    "path": {
                                                        fill: "#856404"
                                                    }
                                                }}
                                            />
                                        </MdButton>
                                    </div>
                                )
                            }

                            {/*{*/}
                            {/*    (bookingComplete && !issueComplete) && (*/}
                            {/*        <div className="booking-complete-message">*/}
                            {/*            {t("bookingPage.bookingComplete")}*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*}*/}

                            {/*{*/}
                            {/*    issueComplete && (*/}
                            {/*        <div className="booking-complete-message">*/}
                            {/*            {t("bookingPage.issueComplete")}*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*}*/}

                        </div>

                        <React.Fragment>
                            {
                                loadOrderPending && (
                                    <div className="trip-builder-preloader">
                                        <MdProgressBar/>
                                        <span>{t('bookingPage.loadingData')}..</span>
                                    </div>
                                )
                            }

                            {
                                !loadOrderPending && (
                                    <React.Fragment>

                                        <MdAccordion
                                            sections={
                                                (
                                                    trips.map((trip, tripIndex) => {
                                                        return ({
                                                            header: (
                                                                <div className="trip-header">
                                                                    <>
                                                                        <span>
                                                                            {trip.name}
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                !trip.items.some(item => item.status == TripElementStatus.Booked || item.status == TripElementStatus.Confirmed) && (
                                                                                    removeTripPending.has(trip.id)
                                                                                        ? (
                                                                                            <CircleSpinnerIcon
                                                                                                width="18px"
                                                                                                height="18px"
                                                                                            />
                                                                                        )
                                                                                        : (
                                                                                            <MdButton
                                                                                                icon={
                                                                                                    <CrossIcon
                                                                                                        width="14px"
                                                                                                        height="14px"
                                                                                                        style={{
                                                                                                            "path": {
                                                                                                                fill: "rgb(114, 28, 36)"
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                                style={{
                                                                                                    "button": {
                                                                                                        alignItems: "center",
                                                                                                        justifyContent: "center",
                                                                                                        borderWidth: 0,
                                                                                                        backgroundColor: "#FFFFFF",
                                                                                                        color: "rgb(0 0 0)",
                                                                                                        borderRadius: "6px",
                                                                                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                                                        margin: 0,
                                                                                                        width: "24px",
                                                                                                        height: "24px",
                                                                                                        padding: 0
                                                                                                    },
                                                                                                    "ink": {
                                                                                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                                                    }
                                                                                                }}
                                                                                                disabled={(bookingPending || issuePending)}
                                                                                                onClick={(event) => {
                                                                                                    event.preventDefault();
                                                                                                    event.stopPropagation();
                                                                                                    removeTrip(trip.id)
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                )
                                                                            }
                                                                        </span>
                                                                    </>
                                                                </div>
                                                            ),
                                                            content: (
                                                                <React.Fragment key={tripIndex}>
                                                                    <TripComponent
                                                                        tripDetails={trip}
                                                                        disabled={(bookingPending || issuePending)}
                                                                        financialSupportCenters={financialSupportCenters}
                                                                        // TODO: process trip changes inside component, except remove trip?
                                                                        callbacks={{
                                                                            removeTrip: () => {
                                                                                removeTrip(trip.id);
                                                                            },
                                                                            openPassengerSelectionModal: () => {
                                                                                openPassengersSelectionModal(trip.id);
                                                                            },
                                                                            openTicketSearchModal: () => {
                                                                                openSearchTicketsModal(trip.id)
                                                                            },
                                                                            changePassengerPassport: (paxCode: string, passport: ITripPassportDTO) => {
                                                                                changePassengerPassport(trip.id, paxCode, passport);
                                                                            },
                                                                            changePassengerBonus: (paxCode: string, bonus: ITripBonusDTO) => {
                                                                                changePassengerBonus(trip.id, paxCode, bonus);
                                                                            },
                                                                            removePassenger: (paxCode: string) => {
                                                                                removePassenger(trip.id, paxCode);
                                                                            },
                                                                            toggleIsSelectedItem: (itemId: string, state: boolean) => {
                                                                                toggleIsSelectedTripElement(trip.id, itemId, state);
                                                                            },
                                                                            changeItemTariff: async (itemId: string, tariffIndex: number) => {
                                                                                const tripElement = trip.items.find(x => x.id == itemId);

                                                                                if (tripElement instanceof FlightTripElement) {
                                                                                    await updateFlightElementTariff(trip.id, itemId, tariffIndex);
                                                                                } else if (tripElement instanceof TrainTripElement) {
                                                                                    await updateTrainElementTariff(trip.id, itemId, tariffIndex);
                                                                                } else if (tripElement instanceof AeroExpressTripElement) {
                                                                                    await updateAeroExpressElementTariff(trip.id, itemId, tariffIndex);
                                                                                }

                                                                                //changeTripElementTariff(trip.id, itemId, tariffIndex);
                                                                            },
                                                                            removeItem: (itemId: string, elementType: TripElementType) => {
                                                                                removeTripElement(trip.id, itemId, elementType);
                                                                            }
                                                                        }}
                                                                    />
                                                                </React.Fragment>
                                                            ),
                                                            isOpen: true
                                                        })
                                                    })
                                                )
                                            }
                                            style={{
                                                "root": {
                                                    width: "100%"
                                                }
                                            }}
                                        />

                                        <div className="trip-add-button">
                                            <MdButton
                                                type="submit"
                                                style={{
                                                    "button": {
                                                        borderWidth: 0,
                                                        backgroundColor: "rgb(253 253 253)",
                                                        color: "rgb(123 123 123)",
                                                        border: "1px solid rgb(123 123 123)",
                                                        borderStyle: "dashed",
                                                        borderRadius: "6px",
                                                        height: "30px",
                                                        padding: "5px 15px"
                                                    },
                                                    "ink": {
                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                    }
                                                }}
                                                disabled={bookingPending}
                                                onClick={() => createTrip()}
                                            >
                                                Добавить командировку
                                            </MdButton>
                                        </div>


                                        {
                                            <div className="order-summary-info">

                                                <div className="order-summary-info-item">
                                                    <span>{t("bookingPage.numberOfTrips")}</span>
                                                    <span></span>
                                                    <span>
                                                        {
                                                            trips.filter(trip => trip.items.some(item => item.isSelected)).length
                                                        }
                                                    </span>
                                                </div>

                                                <div className="order-summary-info-item">
                                                    <span style={{fontWeight: 500}}>{t("bookingPage.orderTotalAmount")}</span>
                                                    <span></span>
                                                    <span>
                                                        {
                                                            trips.some(trip => trip.passengers.length == 0)
                                                                ? (
                                                                    "Не определена"
                                                                )
                                                                : (
                                                                    (() => {
                                                                        let total = 0;
                                                                        trips.forEach(trip => {
                                                                            trip.items.filter(x => x.isSelected).forEach(item => {
                                                                                if (item instanceof FlightTripElement) {
                                                                                    const tariff = item.variant.rejsInfo[item.variant.rejsInfo.length - 1].tarifs[item.tariffIndex];
                                                                                    total += (tariff.priceWithSborTts * trip.passengers.length);
                                                                                } else if (item instanceof TrainTripElement) {
                                                                                    const tariff = item.variant.categoryPrice[item.tariffIndex];
                                                                                    total += ((tariff.price + tariff.sborTts) * trip.passengers.length);
                                                                                } else {
                                                                                }
                                                                            })
                                                                        });
                                                                        return total.toLocaleString() + " ₽";
                                                                    })()
                                                                )
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        }

                                        {
                                            order?.orderNo !== null && (
                                                <div className="order-tools">
                                                    <MdButton
                                                        type="submit"
                                                        style={{
                                                            "button": {
                                                                alignSelf: "center",
                                                                borderWidth: 0,
                                                                backgroundColor: "#a9a9a9",
                                                                color: "#FFFFFF",
                                                                borderRadius: "6px",
                                                                height: "35px",
                                                                padding: "5px 15px"
                                                            },
                                                            "ink": {
                                                                backgroundColor: "rgba(255,255,255,.5)"
                                                            }
                                                        }}
                                                        onClick={() => sendToTts()}
                                                    >
                                                        {t("bookingPage.save")}
                                                    </MdButton>
                                                </div>
                                            )
                                        }

                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>

                        {
                            !loadOrderPending && (
                                <div className="trip-builder-form-footer">

                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                alignSelf: "center",
                                                width: "200px",
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        disabled={(
                                            isLimitExceeded
                                            || bookingPending
                                            || issuePending
                                            || trips.length == 0
                                            || trips.some(trip => trip.passengers.some(passenger => passenger.passport == null))
                                            || trips.some(trip => trip.passengers.length == 0 && trip.items.some(item => item.isSelected && item.status == TripElementStatus.NotSet))
                                            || !trips.map(trip => trip.items.filter(item => item.isSelected)).flat().some(item => item.status == TripElementStatus.NotSet)
                                        )}
                                        onClick={bookSelected}
                                    >
                                        {(t("bookingPage.bookSelected") + " (" + trips.reduce(function (total, trip) {
                                            return total + trip.items.filter(item => item.status == TripElementStatus.NotSet && item.isSelected).length;
                                        }, 0) + ")")}
                                    </MdButton>

                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                alignSelf: "center",
                                                width: "200px",
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        disabled={(
                                            isLimitExceeded
                                            || bookingPending
                                            || issuePending
                                            || trips.length == 0
                                            || trips.some(trip => trip.passengers.some(passenger => passenger.passport == null))
                                            || trips.some(trip => trip.passengers.length == 0 && trip.items.some(item => item.isSelected && item.status == TripElementStatus.Booked))
                                            || !trips.map(trip => trip.items.filter(item => item.isSelected)).flat().some(item => item.status == TripElementStatus.Booked)
                                        )}
                                        onClick={issueSelected}
                                    >
                                        {(t("bookingPage.issueSelected") + " (" + trips.reduce(function (total, trip) {
                                            return total + trip.items.filter(item => item.status == TripElementStatus.Booked && item.isSelected).length;
                                        }, 0) + ")")}
                                    </MdButton>

                                </div>
                            )
                        }

                    </div>
                )
            }
        </React.Fragment>
    );
}