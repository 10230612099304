export interface IAddPaxPassportsRequest {
    code: string;
    passports: IAddPaxPassportRequest[];
}

export interface IAddPaxPassportRequest {
    number: string;
    expired?: string;
    type: PaxPassportType;
}

export enum PaxPassportType {
    rus,
    foreign,
    international
}