import React, {createContext, useEffect, useState} from "react";
import {MdModal} from "../../../components/md-modal";
import {Guid} from "../../../utils/utils";


interface IModalPayload {
    title:   string;
    content: React.ReactNode;
    options?: {
        closeOnClickOutside: boolean;
    }
}

interface IModalState {
    id:      string;
    title:   string;
    content: React.ReactNode;
    options?: {
        closeOnClickOutside: boolean;
    }
}

interface IModalContext {
    open:  () => void;
    close: () => void;
}

interface IModalFactoryContext {
    // TODO: remove, stay only showModal method
    isOpen: (id: string) => boolean;
    open:   (id: string) => void;
    close:  (id: string) => void;
    showModal: (contextBuilder: (modalContext: IModalContext) => IModalPayload) => void;
}

export const ModalContext = createContext<IModalFactoryContext>({
    // TODO: remove, stay only showModal method
    isOpen: (id: string) => { },
    open:   (id: string) => { },
    close:  (id: string) => { },
    showModal: (contextBuilder: (modalContext: IModalContext) => IModalPayload) => { },
} as IModalFactoryContext);

export const ModalState = ({children}: { children: React.ReactNode }) => {

    // TODO: remove, stay only modals collection
    const [opened, setOpened] = useState<string[]>([]);
    const [modals, setModals] = useState<IModalState[]>([]);

    const isOpen = (id: string) => {
        return opened.includes(id);
    }

    const open = (id: string) => {
        setOpened(prev => [...prev.filter(x => x !== id), id]);
    }

    const close = (id: string) => {
        setOpened(prev => prev.filter(x => x !== id));
    }


    const showModal = (contextBuilder: (modalContext: IModalContext) => IModalPayload) => {
        const id = Guid.newGuid();

        const {
            title,
            content,
            options
        } = contextBuilder({
            open: () => open(id),
            close: () => close(id)
        });

        const modalState = {
            id: id,
            title: title,
            content: content,
            options: options
        } as IModalState;

        setModals(prev => [
            ...prev,
            modalState
        ]);

        open(id);
    }

    const dispose = (id: string) => {
        setModals(prev => prev.filter(x => x.id !== id));
    }

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.repeat) return;
        if (event.key === "Escape") {
            if (opened.length > 0) {
                close(opened[opened.length - 1]);
            }
        }
    };


    useEffect(() => {
        document.addEventListener("keydown", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
    }, [onKeyDown]);


    return (
        <ModalContext.Provider value={{isOpen, open, close, showModal}}>
            <>
                {children}
                <div className="modal-list">
                    {
                        modals.map((modal, itemIndex) => {
                            return (
                                <MdModal
                                    id={modal.id}
                                    title={modal.title}
                                    closeOnClickOutside={(modal.options?.closeOnClickOutside !== false)}
                                    closeHandler={() => dispose(modal.id)}
                                >
                                    {modal.content}
                                </MdModal>
                            )
                        })
                    }
                </div>
            </>
        </ModalContext.Provider>
    );
};