// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-component {
  padding: 10px 20px 20px 20px;
}
.dialog-component .delete-confirmation-dialog {
  display: flex;
  flex-direction: row;
  width: 80vw;
  max-width: 500px;
}
.dialog-component .delete-confirmation-dialog .delete-confirmation-dialog--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #FFF2F2;
}
.dialog-component .delete-confirmation-dialog .delete-confirmation-dialog--content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 20px;
}
.dialog-component .delete-confirmation-dialog .delete-confirmation-dialog--content .delete-confirmation-dialog--content-title {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.dialog-component .delete-confirmation-dialog .delete-confirmation-dialog--content .delete-confirmation-dialog--content-description {
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #4e4e4e;
}
.dialog-component .delete-confirmation-dialog .delete-confirmation-dialog--content .delete-confirmation-dialog--content-actions {
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: end;
}`, "",{"version":3,"sources":["webpack://./src/pages/cabinetPages/orderPage/orderDetailsComponent/modals/removeConfirmationModal/style.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;AACN;AAEI;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,eAAA;AAAN;AAEM;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AAAR;AAGM;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADR;AAIM;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,oBAAA;AAFR","sourcesContent":[".dialog-component {\r\n  padding: 10px 20px 20px 20px;\r\n\r\n  .delete-confirmation-dialog {\r\n    display: flex;\r\n    flex-direction: row;\r\n    width: 80vw;\r\n    max-width: 500px;\r\n\r\n    .delete-confirmation-dialog--icon {\r\n      display: flex;\r\n      align-items: center;\r\n      justify-content: center;\r\n      width: 50px;\r\n      height: 50px;\r\n      border-radius: 25px;\r\n      background-color: #FFF2F2;\r\n    }\r\n\r\n    .delete-confirmation-dialog--content {\r\n      flex: 1;\r\n      display: flex;\r\n      flex-direction: column;\r\n      gap: 5px;\r\n      padding: 0 20px;\r\n\r\n      .delete-confirmation-dialog--content-title {\r\n        font-weight: 500;\r\n        font-size: 14px;\r\n        color: #000000;\r\n      }\r\n\r\n      .delete-confirmation-dialog--content-description {\r\n        font-weight: 300;\r\n        font-size: 12px;\r\n        line-height: 20px;\r\n        color: #4e4e4e;\r\n      }\r\n\r\n      .delete-confirmation-dialog--content-actions {\r\n        margin: 10px 0 0 0;\r\n        display: flex;\r\n        flex-direction: row;\r\n        gap: 10px;\r\n        align-items: center;\r\n        justify-content: end;\r\n      }\r\n\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
