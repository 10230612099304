import React, {useEffect, useState} from "react";
import {Guid} from "../../../utils/utils";
import "./style.scss";

// интерфейс варианта ответа для вопроса типа radio
interface Option<T> {
    name: string;
    value: T;
}

// базовй класс вопроса
class Question<T> {
    // автогенерируемый guid
    id: string;
    // текст вопроса
    label: string;
    // выбранное значение
    value?: T;
    // описание своего варианта ответа
    notes?: string;
    // значение выбранного варианта которое триггерит ввод своего ответа ("Другое")
    notesTrigger?: T;

    constructor(label: string) {
        this.id = Guid.newGuid();
        this.label = label;
    }

    // метод определения валидности вопроса
    public isValid() {
        return !(this.value == null || this.notesTrigger === this.value && (this.notes?.trim().length ?? 0) === 0);
    }
}

// вопрос с вариантами ответов
class RadioQuestion<T> extends Question<T> {
    // список вариантов ответов
    options: Option<T>[];

    constructor(label: string, options: Option<T>[], notesTrigger?: T) {
        super(label);
        this.options = options;
        this.notesTrigger = notesTrigger;
    }
}

// группа вопросов с общим описанием и вложенной иерархией вопросов
class GroupQuestion<T extends Question<any>[]> {
    // автогенерируемый guid
    id: string;
    // текст вопроса
    label: string;
    // список вопросов
    questions: T;

    constructor(label: string, questions: T) {
        this.id = Guid.newGuid();
        this.label = label;
        this.questions = questions;
    }

    // метод определения валидности вопроса
    public isValid() {
        return !(this.questions.some(q => !q.isValid()));
    }
}

// вопрос с выбором значение в заданном интервале
class ScaleQuestion<T extends number> extends Question<T> {
    // минимальное значение
    min: number;
    // максимальное значение
    max: number;
    // набор вариантов (генерируется на основе минимального и максимального значения)
    range: number[];

    constructor(label: string, min: T, max: T) {
        super(label);
        this.min = min;
        this.max = max;
        this.value = min;
        this.range = Array.from({length: this.max - this.min + 1}, (_, i) => i + this.min);
    }
}



enum QuestionType {
    Radio,
    Scale,
    Group
}

interface IRadioQuestion {
    type: number,
    label: string,
    options: Array<{
        name: string;
        value: any;
    }>,
    noteTrigger?: any;
}

interface IScaleQuestion {
    type: number,
    label: string,
    min: number;
    max: number;
}

interface IGroupQuestion {
    type: number,
    label: string,
    questions: (IRadioQuestion | IScaleQuestion | IGroupQuestion)[];
}

interface SurveyInput {
    questions: (IRadioQuestion | IScaleQuestion | IGroupQuestion)[];
}



export function RadioQuestionComponent(props: {
    question: RadioQuestion<any>,
    onChange: () => void;
}) {

    const { question, onChange } = props;

    return (
        <div className="survey-form-input-radio-wrapper">

            <label className="survey-form-label">
                {question.label}
            </label>

            <div className="survey-form-options">
                <div className="survey-form-radio-flex">
                    {
                        question.options.map(option => {
                            return (
                                <div className="survey-form-radio-group">
                                    <label className="survey-form-radio-label">
                                        <input className="survey-form-input-radio"
                                               type="radio"
                                               name={question.id}
                                               onChange={(event) => {
                                                   if (event.target.checked) {
                                                       question.value = option.value;
                                                       onChange();
                                                   }
                                               }}
                                        />
                                        <span
                                            className="survey-form-radio-checkmark"></span>
                                        <span> {option.name} </span>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    (question.notesTrigger != null && question.value === question.notesTrigger) && (
                        <div className="survey-form-textarea">
                            <textarea
                                className="survey-form-input"
                                rows={6}
                                placeholder="Введите текст..."
                                value={question.notes}
                                onChange={(event) => {
                                    question.notes = event.target.value;
                                    onChange();
                                }}
                            />
                        </div>
                    )
                }
            </div>

        </div>
    )
}


export function ScaleQuestionComponent(props: {
    question: ScaleQuestion<any>,
    onChange: () => void;
}) {

    const {question, onChange} = props;

    return (
        <div className="survey-form-input-group">

            <label className="survey-form-label">
                {question.label}
            </label>

            <div className="survey-form-options">

                <select className="survey-form-select"
                    name={question.id}
                    onChange={(event) => {
                        question.value = question.range[event.target.selectedIndex];
                        onChange();
                    }}
                >
                    {
                        question.range.map(option => {
                            return (
                                <option value={option}>
                                    {option.toString()}
                                </option>
                            )
                        })
                    }
                </select>

            </div>

        </div>
    )
}


export function GroupQuestionComponent(props: {
    question: GroupQuestion<any>,
    onChange: () => void;
}) {

    const { question, onChange } = props;

    return (
        <div className="survey-form-input-group">
            <label htmlFor="name" className="survey-form-label">
                {question.label}
            </label>

            <div className="survey-form-options">

                {
                    question.questions.map((subQuestion: Question<any>, subQuestionIndex: number) => {
                        return (
                            <div className="survey-form-input-radio-wrapper">

                                <label htmlFor="ans" className="survey-form-label">
                                    {subQuestion.label}
                                </label>

                                <div className="survey-form-options">

                                    {
                                        (subQuestion instanceof RadioQuestion) && (
                                            <div className="survey-form-radio-flex"
                                                 style={{flexDirection: "row"}}>
                                                {
                                                    subQuestion.options.map((option, optionIndex) => {
                                                        return (
                                                            <div
                                                                className="survey-form-radio-group">
                                                                <label
                                                                    className="survey-form-radio-label">
                                                                    <input
                                                                        className="survey-form-input-radio"
                                                                        type="radio"
                                                                        name={(question.id + "[" + subQuestionIndex + "]")}
                                                                        onChange={(event) => {
                                                                            subQuestion.value = option.value;
                                                                            onChange();
                                                                        }}
                                                                    />
                                                                    <span className="survey-form-radio-checkmark"></span>
                                                                    <span> {option.name} </span>
                                                                </label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }

                                </div>

                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}



export function SurveyPage() {

    const json: SurveyInput = {
        questions: [
            {
                type: 0, // QuestionType.Radio
                label: "За какой услугой Вы к нам обращались?",
                options: [
                    {name: "Авиа/жд билеты", value: 0},         // Services.FlightsAndTrains
                    {name: "Проживание в гостинице", value: 1}, // Services.Hotel
                    {name: "Трансфер", value: 2},               // Services.Transfer
                    {name: "Билеты на автобус", value: 3},      // Services.Bus
                    {name: "Такси", value: 4},                  // Services.Taxi
                    {name: "Другое", value: 5}                  // Services.Other
                ],
                noteTrigger: 5 // Services.Other
            },
            {
                type: 1, // QuestionType.Scale
                label: "Насколько быстро мы отреагировали на ваш запрос?",
                min: 1,
                max: 10
            },
            {
                type: 1, // QuestionType.Scale
                label: "Оцените качество оказанной услуги",
                min: 1,
                max: 10
            },
            {
                type: 2, // QuestionType.Group
                label: "Довольны ли Вы работой оператора?",
                questions: [
                    {
                        type: 0, // QuestionType.Radio
                        label: "Компетентность",
                        options: [
                            {name: "Да", value: true},
                            {name: "Нет", value: false},
                        ]
                    },
                    {
                        type: 0, // QuestionType.Radio
                        label: "Вежливость",
                        options: [
                            {name: "Да", value: true},
                            {name: "Нет", value: false},
                        ]
                    },
                    {
                        type: 0, // QuestionType.Radio
                        label: "Скорость",
                        options: [
                            {name: "Да", value: true},
                            {name: "Нет", value: false},
                        ]
                    },
                    {
                        type: 0, // QuestionType.Radio
                        label: "Корректность обработки запроса",
                        options: [
                            {name: "Да", value: true},
                            {name: "Нет", value: false},
                        ]
                    }
                ]
            },
            {
                type: 0, // QuestionType.Radio
                label: "Есть ли аспекты нашего сервиса, которые, по Вашему мнению, можно улучшить?",
                options: [
                    {name: "Все хорошо ничего менять не надо", value: 0}, // Improvements.None
                    {name: "Другое", value: 1}                            // Improvements.Other
                ],
                noteTrigger: 1 // Improvements.Other
            },
            {
                type: 0, // QuestionType.Radio
                label: "Можем ли мы связаться с Вами, если захотим уточнить информацию по Вашим ответам?",
                options: [
                    {name: "Да", value: true},
                    {name: "Нет", value: false}
                ]
            },
        ]
    }


    function isRadioQuestion(object: any): object is IRadioQuestion {
        return object.type == QuestionType.Radio && 'options' in object;
    }

    function isScaleQuestion(object: any): object is IScaleQuestion {
        return object.type == QuestionType.Scale && 'min' in object && 'max' in object;
    }

    function isGroupQuestion(object: any): object is IGroupQuestion {
        return object.type == QuestionType.Group && 'questions' in object;
    }


    const mapQuestion = (question: IRadioQuestion | IScaleQuestion | IGroupQuestion): Question<any> | null => {
        if (isRadioQuestion(question)) {
            return new RadioQuestion(
                question.label,
                question.options.map(o => {
                    return ({
                        name: o.name,
                        value: o.value
                    });
                }) ?? [],
                question.noteTrigger
            )
        }
        else if (isScaleQuestion(question)) {
            return new ScaleQuestion(
                question.label,
                question.min,
                question.max
            )
        }
        else if (isGroupQuestion(question)) {
            return new GroupQuestion(
                question.label,
                question.questions?.flatMap(q => {
                    return mapQuestion(q) ?? [];
                }) ?? []
            )
        }
        return null;
    }


    const [questions, setQuestions] = useState<Question<any>[]>([]);

    const updateSurvey = (question: Question<any>, questionIndex: number) => {
        setQuestions(prev => prev.map((q, i) => {
            if(i == questionIndex) {
                return question;
            }
            return q;
        }))
    }


    useEffect(() => {
        setQuestions(json.questions.flatMap(q => mapQuestion(q) ?? []));
    }, []);


    console.log(questions)


    return (
        <div className="survey-form-wrapper">
            <div className="survey-form-container">

                <div className="survey-form">

                    <div className="survey-form-title">Добрый день!</div>

                    <div className="survey-form-description">
                        <span>
                            Вы воспользовались услугами по организации деловых поездок Корпоративного Альянса "Турне-Транс".
                        </span>
                        <span>
                            Пожалуйста, поделитесь своими впечатлениями о Вашем опыте взаимодействия с нашей компанией.
                        </span>
                        <span>
                            Это поможет нам понять,всё ли мы делаем правильно. Опрос не займет много времени.
                        </span>
                    </div>


                    {
                        questions.map((question, questionIndex) => {
                            if(question instanceof RadioQuestion) {
                                return (
                                    <RadioQuestionComponent
                                        question={question}
                                        onChange={() => updateSurvey(question, questionIndex)}
                                    />
                                );
                            }
                            else if (question instanceof ScaleQuestion) {
                                return (
                                    <ScaleQuestionComponent
                                        question={question}
                                        onChange={() => updateSurvey(question, questionIndex)}
                                    />
                                )
                            }
                            else if (question instanceof GroupQuestion) {
                                return (
                                    <GroupQuestionComponent
                                        question={question}
                                        onChange={() => updateSurvey(question, questionIndex)}
                                    />
                                )
                            }
                        })
                    }


                    <button
                        className="survey-form-btn"
                        disabled={questions.some(q => !q.isValid())}>
                        Отправить
                    </button>

                </div>

            </div>

        </div>
    );
}
