import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const Airline_5N_Icon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 30 30"
            style={style["svg"]}>
                <rect
                    width="30"
                    height="30"
                />
                <path
                    fill="#360f74"
                    d="M0 0h30v30H0z"
                />
                <path
                    fill="#fff"
                    d="M10.782 9.819C15.47 6.901 20.1 3.884 24.807 1c.101.021.302.064.404.084.039.59-.208 1.143-.332 1.709-.548 1.928-1.021 3.877-1.581 5.802-.173.653-.838 1.055-1.483 1.095-1.994.208-3.99.409-5.988.565.024.062.075.186.1.248 1.896.218 3.793.456 5.689.676.432-.002.77.392.706.819-.176 1.224-.627 2.388-.918 3.586-.282 1.044-.49 2.112-.898 3.117-.268-.071-.528-.177-.734-.362-2.82-2.304-5.659-4.587-8.489-6.883-.372-.314-.798-.575-1.095-.965-.013-.351.353-.499.594-.672zM9.366 11.603c.098-.073.293-.222.389-.295 3.365 2.694 6.705 5.417 10.055 8.13 0 .081-.002.242-.002.323-2.139 1.54-4.442 2.837-6.647 4.283-2.527 1.605-5.062 3.194-7.591 4.795-.223.186-.516.163-.785.156.021-.759.27-1.479.46-2.206.511-1.767.922-3.564 1.446-5.327.15-.468.535-.836.969-1.048 2.176-.257 4.36-.449 6.536-.719-2-.511-4.089-.537-6.114-.913-.306-.208-.571-.582-.402-.965.548-2.074 1.121-4.143 1.686-6.214z"/>
        </svg>
    )
}