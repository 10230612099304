import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const Airline_6R_Icon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 30 30"
            style={style["svg"]}
        >
            <path
                fill="#002159"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.01.01h30v30h-30z"/>
            <path
                fill="#fff"
                fillRule="evenodd"
                clipRule="evenodd"
                d="m7.13 9.81-1.32.03-2.63 2.36.01 1.03 2.25-.83 1.69-2.59zm-.52 5.77-2.7-.01L7 16.6l-.39-1.02zm.07-.14 4.29-2.16-1.53-2.07-3.87 1.28 1.11 2.95zm8.33.66 3.82-2.79-3.82-1.8-3.82 1.8 3.82 2.79zm6.57-7.3-6.56-1.19L8.45 8.8l-1.1.94 2.14 1.34 5.52.31 5.52-.31 2.14-1.34-1.09-.94zm2.63 1.04-1.32-.03 1.67 2.59 2.25.83.01-1.03-2.61-2.36zm1.91 5.74-2.7.01-.39 1.03 3.09-1.04zm-7.07-2.3 4.29 2.16 1.11-2.95-3.87-1.28-1.53 2.07zm-4.04 11.03-.09-.06-11.43-8.69-.02-.04-.39-2.2v-.01l-.02-1.14c0-.02.01-.04.02-.05l2.66-2.39c0-.01.01-.01.02-.01L8.4 8.69h.01L15 7.49h.02l6.59 1.19h.02l2.63 1.03c.01 0 .01.01.02.01l2.66 2.39c.01.01.02.03.02.05l-.01 1.14v.01l-.39 2.2-.01.02-.02.02-11.43 8.69-.09.07m7.53-7.22-6.33 5.1-1.03 1.83 4.73-3.59 2.63-3.34zm-6.35 4.9 2.76-4.91h-3.86l1.1 4.91zm-2.38.2-6.33-5.1 2.63 3.34 4.73 3.59-1.03-1.83zm-2.73-5.11 2.76 4.91 1.1-4.91h-3.86zM15.01 16.18zM15.01 17.02z"/>
        </svg>
    )
}