import React from "react";
import {useAppSelector} from "../../../../../../redux/hooks";
import {useTranslation} from "react-i18next";
import {IAeroExpressVariant} from "../../../../../../interfaces/IAeroExpressVariant";
import {TripService} from "../../../../../../services/TripService";
import {MdMenu} from "../../../../../../components/md-menu";
import {MdToggleButton} from "../../../../../../components/md-toggle-button";
import {MdScrollViewer} from "../../../../../../components/md-scroll-viewer";
import {CheckMarkIcon} from "../../../../../../icons/CheckMarkIcon";
import {AeroExpress_Icon} from "../../../../../../icons/train-companies/AeroExpress_Icon";
import {Guid} from "../../../../../../utils/utils";
import "./style.scss";


export function AeroExpressComponent(
    props: {
        variantInfo: IAeroExpressVariant;
        variants: IAeroExpressVariant[];
        tariffSelection?: boolean;
        selectable?: boolean;
        disabled?: boolean;
        callbacks?: {
            select?: () => void;
            changeTariff?: (index: number) => void
        };
    }
) {

    const {
        variantInfo,
        variants,
        tariffSelection,
        selectable,
        disabled,
        callbacks
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const selectedCompany = useAppSelector((state) => state.companyInfo) as any;

    const tripService = TripService();

    const ticket = variantInfo;
    const tariffs = variants.length > 0 ? variants : [ticket];


    // const receiveTariffs = async () => {
    //     setTariffVariants([]);
    //     setTariffsNotFound(false);
    //     setTariffsPending(true);
    //
    //     const response = await tripService.getAeroExpress(
    //         [
    //             {
    //                 id: Guid.newGuid(),
    //                 passengers: 1,
    //                 departDate: aeroExpressInfo.variant.dateDep,
    //             }
    //         ],
    //         [],
    //         selectedCompany?.code ?? ""
    //     );
    //
    //     if(response != null) {
    //         setTariffVariants(response.variants);
    //     }
    //     else {
    //         setTariffVariants([]);
    //         setTariffsNotFound(true);
    //     }
    //
    //     setTariffsPending(false);
    // }

    const tariffOptions = tariffs.map((tariff, tariffIndex) => {
        return (
            <React.Fragment
                key={"tariff" + Guid.newGuid()}>
                <MdToggleButton
                    buttonStyle={{
                        minInlineSize: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "4px",
                        padding: "10px",
                        backgroundColor: (ticket.tariffId == tariff.tariffId ? "rgba(116, 177, 0, 0.05)" : "rgba(204, 204, 204, 0.1)"),
                        border: (ticket.tariffId == tariff.tariffId ? "1px solid #74B100" : "1px solid #CCCCCC"),
                    }}
                    inkStyle={{
                        backgroundColor: (ticket.tariffId == tariff.tariffId ? "#74B100" : "#C1C1C1"),
                        opacity: 0.1
                    }}
                    checked={ticket.tariffId == tariff.tariffId}
                    onClick={() => {
                        if (ticket.tariffId == tariff.tariffId) {
                            return;
                        }
                        if (callbacks?.changeTariff != null) {
                            callbacks.changeTariff(tariffIndex);
                        }
                    }}
                >

                    <div className="tariff-option">

                        <div
                            className="tariff-option-name">
                            {
                                tariff.tariffName
                            }
                        </div>

                        <div className="tariff-services">
                            {/*{*/}
                            {/*    getServices(tariff).map(service => {*/}
                            {/*        return (*/}
                            {/*            <div className="tariff-service --allow" title={service.name}>*/}
                            {/*                {*/}
                            {/*                    service.icon*/}
                            {/*                }*/}
                            {/*                <span>*/}
                            {/*                    {*/}
                            {/*                        service.name*/}
                            {/*                    }*/}
                            {/*                </span>*/}
                            {/*            </div>*/}
                            {/*        )*/}
                            {/*    })*/}
                            {/*}*/}
                        </div>

                        <div
                            className="tariff-option-price">
                            {
                                (tariff.priceWithFee).toLocaleString() + " ₽"
                            }
                        </div>


                    </div>

                </MdToggleButton>
            </React.Fragment>
        )
    })

    const select = () => {
        if (callbacks?.select != null) {
            callbacks.select();
        }
    }


    return (
        <div className="aero-express-component">

            <div className="aero-express-component-details">

                <div className="aero-express-component-details-header">

                    <div className="aero-express-component-railway-company-list">
                        <MdMenu
                            header={
                                <div className="aero-express-component-railway-company-list-header">
                                    <div className="aero-express-component-railway-company-list-header-icons">
                                        <AeroExpress_Icon />
                                    </div>
                                    <div className="aero-express-component-railway-company-list-header-names">
                                        Аэроэкспресс
                                    </div>
                                </div>
                            }
                        >

                            <MdScrollViewer
                                style={{
                                    "content": {
                                        maxWidth: "378px",
                                        //maxHeight: "150px"
                                    }
                                }}
                            >
                                <div className="train-component-roadmap">
                                    {
                                        //...
                                    }
                                </div>
                            </MdScrollViewer>

                        </MdMenu>
                    </div>

                    {/*<div className="aero-express-component-tariff-info">*/}
                    {/*    <span className="tariff-info-description">*/}
                    {/*        {*/}
                    {/*            selectedTariff.tariffId*/}
                    {/*        }*/}
                    {/*    </span>*/}
                    {/*    {*/}
                    {/*        authState.isSupportUser && (*/}
                    {/*            selectedTariff?.tariffType*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*</div>*/}

                    {
                        (tariffSelection !== false)
                        ? (
                            <div className="aero-express-component-tariff-list">
                                <MdMenu
                                    header={
                                        <div className="aero-express-component-tariff-list-header">
                                            <div className="aero-express-component-tariff-list-header-icons">
                                                <div className="tariff-icon" title={ticket.description}>
                                                    {ticket.tariffName}
                                                </div>
                                            </div>
                                            <div className="aero-express-component-tariff-list-header-price">
                                                {
                                                    (ticket.priceWithFee).toLocaleString() + " ₽"
                                                }
                                            </div>
                                        </div>
                                    }
                                >
                                    <MdScrollViewer
                                        style={{
                                            "content": {
                                                maxHeight: "264px"
                                            }
                                        }}
                                    >
                                        <div className="tariff-options">
                                            {
                                                tariffOptions
                                            }
                                        </div>
                                    </MdScrollViewer>
                                </MdMenu>
                            </div>
                        )
                        : (
                                <div className="aero-express-component-selected-tariff">
                                    <div className="aero-express-component-selected-tariff-header">
                                        <div className="aero-express-component-selected-tariff-header-name">
                                            <div className="tariff-icon" title={ticket.description}>
                                                {ticket.tariffName}
                                            </div>
                                        </div>
                                        <div className="aero-express-component-selected-tariff-header-price">
                                            {
                                                (ticket.priceWithFee).toLocaleString() + " ₽"
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                    }

                </div>

                <div className="aero-express-component-details-content">

                    <div className="aero-express-description">

                        {
                            ticket.description
                        }

                    </div>

                </div>

            </div>

            {
                selectable === true && (
                    <div className="aero-express-component-actions-layer">
                        <div className="aero-express-component-actions" onClick={() => select()}>
                            <CheckMarkIcon/>
                        </div>
                    </div>
                )
            }

        </div>
    );
}