import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const Airline_R3_Icon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="-290.5 368.5 30 30"
            style={style["svg"]}>
                <rect
                    x="-290.5"
                    y="368.5"
                    fill="#164193"
                    width="30"
                    height="30"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#FFFFFF"
                    d="M-264.5,386.5c-0.684,0.729-4.292,3.78-4.889,3.197  c-0.628-0.615,2.889-5.999,3.352-6.533c0.626-0.724,2.292-3.877-0.571-5.253c-1.093,0.665-3.908,3.661-4.352,3.798  c-6.369,3.96-8.235,2.047-6.29-1.139c1.504-2.467,6.022-5.22,9.766-4.433c0.075-0.023-0.135,0.241-0.173,0.574  c-0.07,0.617,0.126,1.386,1.53,0.406c1.017-0.709,3.268-1.672,2.452-3.088c-0.088-0.153-0.812-0.729-1.646-1.095  c-0.647-0.282-1.411-0.387-1.673-0.399c-2.723-0.128-3.847,0.01-7.656,1.75c-2.73,1.246-9.062,7.083-4.583,11.311  c1.839,1.735,6.048-0.684,7.739-1.888c0.134-0.096-0.2,0.337-0.406,0.55c-1.857,1.562-4.375,3.208-6.403,4.116  c-2.305,1.029-2.072-0.601-3.413-0.519c-1.573-0.471-6.411,3.253-5.463,4.517c0.814,1.087,2.449,1.378,3.817,0.915  c4.246-1.435,9.031-4.841,13.027-8.765c-0.879,2.166-3.443,4.449-2.768,7.352c0.842,3.611,7.234-1.067,7.777-2.058  C-265.322,389.842-262.5,386.5-264.5,386.5z"
                />
        </svg>
    )
}