import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const TVIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                d="M21.73,6.69a1,1,0,1,0-1.46-1.38l-4,4.28L11.69,5.28a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L13.76,10H5a1,1,0,0,0-1,1V26a1,1,0,0,0,1,1H27a1,1,0,0,0,1-1V11a1,1,0,0,0-1-1H18.59ZM6,12V25H26V12Zm14,6.5L14,23V14Z"
                style={style["path"]}
            />
        </svg>
    )
}