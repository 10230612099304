import React, {useContext, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {useTranslation} from "react-i18next";
import {setGkCompanies} from "../../../redux/authSlice";
import {CompanyService} from "../../../services/CompanyService";
import {CompaniesSearchComponent} from "./companySearchComponent";
import {CompanyTableComponent} from "./companyTableComponent";
import {
    CURRENT_REACT_ENVIROMENT,
    ENVIROMENT_PRODUCTION,
    ENVIROMENT_STAGING,
    TTS_ADMIN_ROLE
} from "../../../constants/ServiceCostants";
import {ModalContext} from "../../commonComponents/modal/ModalContext";
import {MdModal} from "../../../components/md-modal";
import {CompanyAddComponent} from "./companyAddComponent";
import {Guid} from "../../../utils/utils";
import {CompanyInfoProfileComponent} from "./companyInfoProfileComponent";
import {ICompanyDTO} from "../../../interfaces/ICompanyDTO";
import "./style.scss";


export function MyProfilePage() {

    const {t} = useTranslation();
    const authState = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const currentCompanyState = useAppSelector((state) => state.companyInfo);
    // const {
    //     getCompaniesInfoToCompanyGroup,
    //     getClientListForTtsAdmin,
    //     makeActiveCompany,
    //     addNewCompany,
    //     importNewCompany
    // } = CompanyService();

    const companyService = CompanyService();

    const {open, close} = useContext(ModalContext);
    const [addCompanyModalId] = useState(Guid.newGuid());
    const [addCompanyPending, setAddCompanyPending] = useState(false);
    const [addCompanyError, setAddCompanyError] = useState(false);

    //const [importCompanyPending, setImportCompanyPending] = useState(false);
    //const [importCompanyError, setImportCompanyError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isSearchResultNotFound, setIsSearchResultNotFound] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [companies,setCompanies] = useState<ICompanyDTO[]>([]);

    const isDevelopmentEnv = () => {
        return !(CURRENT_REACT_ENVIROMENT === ENVIROMENT_STAGING || CURRENT_REACT_ENVIROMENT === ENVIROMENT_PRODUCTION)
    }

    const searchCompaniesByName = async () => {
        setCompanies([]);
        setIsLoading(true);
        setIsSearchResultNotFound(false);
        dispatch(setGkCompanies([]));

        // if (companyName.length < 3) { // ???
        //     setIsLoading(false);
        //     return;
        // }

        console.log("before requesrt myProfilePage")
        const response = await companyService.searchCompaniesByName(companyName);
        //const responce2 =await searchCompaniesByName();
        console.log("after requesrt myProfilePage responce0",response)

        if(response.length > 0) {
            setCompanies(response)
            //dispatch(setGkCompanies(response));
        } else {
            setIsSearchResultNotFound(true);
        }

        setIsLoading(false);
    };

    const openCompanyAddModal = () => {
        open(addCompanyModalId);
    }

    const onAddModalOpenedHandler = async () => {
        setAddCompanyError(false);
    };

    const addCompanyHandler = async (company: ICompanyDTO, callback: Function) => {
        setAddCompanyError(false);
        setAddCompanyPending(true);
        try {
            const result = await companyService.addOrUpdateCompany(company);
            if(result != null) {
                closeCompanyAddModal();
                callback();
                // TODO update list of companies
            } else {
                setAddCompanyError(true);
            }
        } catch (ex) {
            setAddCompanyError(true);
        }
        setAddCompanyPending(false);
    };

    const onAddModalClosedHandler = async () => {
        setAddCompanyError(false);
    };

    const closeCompanyAddModal = () => {
        close(addCompanyModalId);
    }

    const importCompanyHandler = async (company: ICompanyDTO) => {
        //setImportCompanyError(false);
        //setImportCompanyPending(true);


        try {
            const result = await companyService.importCompany(company);
            if(result != null) {
                // TODO update list of companies
                await companyService.addDefaultCompanyRoles(company.code);
                alert(`Company ${company.fullName} have imported.`)
            }
            else {
                //setImportCompanyError(true);
            }
        } catch (ex) {
            //setImportCompanyError(true);
        }
        // setImportCompanyPending(false);
    };

    useEffect(() => {

    }, [companyName]);

    return (
        <>
            <div className="company-profile-info-row" style={{marginBottom: "10px"}}>
                <div className="company-profile-info-block">
                    <label>{t("myProfilePage.currentCompany") + ":"}</label>
                    <div>{currentCompanyState.fullName}</div>
                </div>
                {/*<div className="my-profile-vertical-border"></div>*/}

                {currentCompanyState.agreementNo && <>
                    <div className="my-profile-vertical-border"></div>
                    <div className="company-profile-info-block">
                        <label>{t("myProfilePage.agreement") + ":"}</label>
                        <div>{currentCompanyState.agreementNo}</div>
                    </div>

                    <div className="my-profile-vertical-border"></div>

                    <div className="company-profile-info-block">
                        <div typeof='date'>
                            <label>{t("myProfilePage.agreementFrom") + ":"}</label>&nbsp; {currentCompanyState.agreementFrom}
                        </div>
                        <div typeof='date'>
                            <label>{t("myProfilePage.agreementTill") + ":"}</label>&nbsp; {currentCompanyState.agreementTill}
                        </div>
                    </div>
                </>
                }

                {currentCompanyState.address &&
                    <>
                        <div className="my-profile-vertical-border"></div>
                        <div className="company-profile-info-block">

                            <label>{t("myProfilePage.address")+":"}</label>
                            <div>&nbsp; {currentCompanyState.address}</div>
                        </div>
                    </>
                }

            </div>

            {/*  Old component remove later Changed -04.09.2024 */}
            {/*<div className="company-profile-info-row" style={{marginBottom: "10px"}}>*/}
            {/*    <div className="my-profile-info-sub-block">*/}
            {/*        <label>{t("myProfilePage.currentCompany")}</label>*/}
            {/*        <hr/>*/}
            {/*        <div>{currentCompanyState.fullName}</div>*/}
            {/*    </div>*/}

            {/*    {currentCompanyState.agreementNo && <>*/}
            {/*        <div className="my-profile-info-sub-block">*/}
            {/*            <label>{t("myProfilePage.agreement")}</label>*/}
            {/*            <div>&nbsp; {currentCompanyState.agreementNo}</div>*/}
            {/*        </div>*/}
            {/*        <div className="my-profile-info-sub-block">*/}
            {/*            <label>{t("myProfilePage.agreementFrom")}</label>*/}
            {/*            <div>&nbsp; {currentCompanyState.agreementFrom}</div>*/}
            {/*        </div>*/}

            {/*        <div className="my-profile-info-sub-block">*/}
            {/*            <label>{t("myProfilePage.agreementTill")}</label>*/}
            {/*            <div>&nbsp; {currentCompanyState.agreementTill}</div>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*    }*/}
            {/*    {currentCompanyState.address && <div className="my-profile-info-sub-block">*/}
            {/*        <label>{t("myProfilePage.address")+":"}</label>*/}
            {/*        <div>&nbsp; {currentCompanyState.address}</div>*/}
            {/*    </div>}*/}
            {/*</div>*/}

            {/*<CompanyTableComponent*/}
            {/*    gkName={"MMP"}*/}
            {/*    companies={authState.gkCompanies}*/}
            {/*    activeCompany={authState.currentClientCode}*/}
            {/*    makeActiveCompany={makeActive}*/}
            {/*    isLoading={isLoading}*/}
            {/*    isSearchResultNotFound={isSearchResultNotFound}*/}
            {/*    openCompanyImportModal={openCompanyImportModal}*/}
            {/*    openCompanyAddModal={openCompanyAddModal}*/}
            {/*    updateDataHandler={updateGroupCompanyData}*/}
            {/*/>*/}

            {
                (authState.userRole === TTS_ADMIN_ROLE || isDevelopmentEnv()) && (
                    <CompaniesSearchComponent
                        name={companyName}
                        nameHandler={setCompanyName}
                        searchHandler={searchCompaniesByName}
                     isSearching ={isLoading}/>
                )
            }
            {/*{isLoading && <LoadingComponent message={"Ищем компании..."} />}*/}
            {companies.length>0  && <CompanyInfoProfileComponent companies={companies} importCompany={importCompanyHandler}/> }

            <MdModal
                id={addCompanyModalId}
                title="Новая компания"
                openHandler={onAddModalOpenedHandler}
                closeHandler={onAddModalClosedHandler}>
                <CompanyAddComponent
                    pending={addCompanyPending}
                    error={addCompanyError}
                    addHandler={addCompanyHandler}
                />
            </MdModal>
        </>
    );
}
