import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const TaxiIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                d="M8.6,19.9c-0.3-0.3-0.8-0.5-1.3-0.5s-0.9,0.2-1.2,0.5l0,0c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,0.9,0.5,1.2    c0.3,0.3,0.8,0.5,1.2,0.5c0.5,0,0.9-0.2,1.3-0.5l0,0c0.3-0.3,0.5-0.8,0.5-1.2C9.1,20.6,8.9,20.2,8.6,19.9L8.6,19.9z"
                style={style["path"]}
            />
            <path
                d="M25.8,19.9c-0.3-0.3-0.8-0.5-1.3-0.5s-0.9,0.2-1.2,0.5l0,0c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,0.9,0.5,1.2    c0.3,0.3,0.8,0.5,1.2,0.5c0.5,0,0.9-0.2,1.3-0.5l0,0c0.3-0.3,0.5-0.8,0.5-1.2C26.3,20.6,26.1,20.2,25.8,19.9L25.8,19.9z"
                style={style["path"]}
            />

            <rect
                x="3.7"
                y="17.8"
                width="24.6"
                height="6.5"
                style={style["path"]}
            />
            <polygon
                points="22.8,11.8 9.2,11.8 6.2,17.8 25.8,17.8  "
                style={style["path"]}
            />
            <polyline
                points="9.9,24.2 9.9,27.8 6.1,27.8 6.1,24.2   "
                style={style["path"]}
            />
            <polyline
                points="25.9,24.2 25.9,27.8 22.1,27.8 22.1,24.2   "
                style={style["path"]}
            />
            <rect
                x="9.8"
                y="4.2"
                width="2.5"
                height="2.5"
                style={style["path"]}
            />
            <rect
                x="14.5"
                y="4.2"
                width="2.5"
                height="2.5"
                style={style["path"]}
            />
            <rect
                x="19.3"
                y="4.2"
                width="2.5"
                height="2.5"
                style={style["path"]}
            />
            <rect
                x="12.2"
                y="6.8"
                width="2.5"
                height="2.5"
                style={style["path"]}
            />
            <rect
                x="16.9"
                y="6.8"
                width="2.5"
                height="2.5"
                style={style["path"]}
            />
        </svg>
    )
}