import {axiosInstance} from "./AxiosInstance";
import {AxiosResponse, CancelToken} from "axios";
import {
    BEARER,
    ROUTE_ADD_OR_UPDATE_COMPANY,
    ROUTE_GET_COMPANY_INFO,
    ROUTE_SEARCH_COMPANIES_BY_NAME,
    ROUTE_GET_COMPANY_BY_CODE,
    ROUTE_GET_ALL_COMPANIES,
    ROUTE_IMPORT_COMPANY,
    ROUTE_ADD_DEFAULT_COMPANY_ROLES,
    ROUTE_IS_COMPANY_EXIST,
    ROUTE_GET_COMPANY_ROLES,
    ROUTE_GET_ALLOWED_PERSONS, ROUTE_GET_COMPANY_CFOs,
} from "../constants/ApiRouteConstants";
import {IAddCompanyRequest} from "./request/IAddCompanyRequest";
import {IImportCompanyRequest} from "./request/IImportCompanyRequest";
import {ICompanyDTO} from "../interfaces/ICompanyDTO";
import {IAllowedPerson} from "../interfaces/companyInfoInterfaces/IAllowedPerson";
import {IRoleDTO} from "../interfaces/IRoleDTO";
import {ICompanyCfoDTO} from "../interfaces/ICompanyCfoDTO";


export function CompanyApi() {

    /**
     * GET method for checking the existence of a company
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean>>} response with the result of the checking
     */
    const isCompanyExists = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean>> => {
        return await axiosInstance.get<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_IS_COMPANY_EXIST,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining company data
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyDTO>>} response with company data
     */
    const getCompany = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyDTO>> => {
        return  await axiosInstance.get<ICompanyDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_COMPANY_BY_CODE,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code,
                },
            }
        );
    };

    /**
     * GET method for obtaining information about a company
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyDTO>>} response with information about a company
     */
    const getCompanyInfo = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyDTO>> => {
        return await axiosInstance.get<ICompanyDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_COMPANY_INFO,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining a list of companies by name
     * @param {string} name - company name
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyDTO[]>>} response with a list of companies
     */
    const searchCompaniesByName = async (name: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyDTO[]>> => {
        return await axiosInstance.get<ICompanyDTO[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_SEARCH_COMPANIES_BY_NAME,
            {
                    headers: {
                        Authorization: BEARER + accessToken
                    },
                    params: {
                        name: name
                    }
            }
        );
    };

    /**
     * GET method for obtaining a list of companies
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyDTO[]>>} response with a list of companies
     */
    const getAllCompanies = async (accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyDTO[]>> => {
        return await axiosInstance.get<ICompanyDTO[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ALL_COMPANIES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    }

    /**
     * PUT method for add or update company
     * @param {IAddCompanyRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyDTO>>} response with the company data
     */
    const addOrUpdateCompany = async (request: IAddCompanyRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyDTO>> => {
        return await axiosInstance.put<ICompanyDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_OR_UPDATE_COMPANY,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for add or update company
     * @param {IImportCompanyRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyDTO>>} response with the company data
     */
    const importCompany = async (request: IImportCompanyRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyDTO>> => {
        return await axiosInstance.post<ICompanyDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_IMPORT_COMPANY,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for add company roles
     * @param {string} clientCode - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any>>} response with result data
     */
    const addDefaultCompanyRoles = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any>> => {
        return await axiosInstance.put<any>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_DEFAULT_COMPANY_ROLES,
            JSON.stringify(clientCode),
            {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining list of company roles
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IRoleDTO[]>>} response with list of company roles
     */
    const getCompanyRoles = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IRoleDTO[]>> => {
        return await axiosInstance.get<IRoleDTO[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_COMPANY_ROLES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code
                },
                cancelToken: ct
            }
        );
    }

    /**
     * GET method for obtaining the allowed persons of a company
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IAllowedPerson[]>>} response with a list of allowed persons of a company
     */
    const getAllowedPersons = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IAllowedPerson[]>> => {
        return await axiosInstance.get<IAllowedPerson[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ALLOWED_PERSONS, {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: code
                },
                cancelToken: ct
            }
        );
    }

    /**
     * GET method for obtaining a list of financial support centers of a company
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyCfoDTO[]>>} response with a list of financial support centers of a company
     */
    const getCompanyCfos = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyCfoDTO[]>> => {
        return await axiosInstance.get<ICompanyCfoDTO[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_COMPANY_CFOs, {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code
                },
                cancelToken: ct
            }
        );
    }


    return {
        isCompanyExists,
        getCompany,
        getCompanyInfo,
        searchCompaniesByName,
        getAllCompanies,
        addOrUpdateCompany,
        importCompany,
        addDefaultCompanyRoles,
        getCompanyRoles,
        getAllowedPersons,
        getCompanyCfos
    };
}