import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const ShowerIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                d="M7,7H25V9H7ZM27,9v6a2,2,0,0,1-2,2H17a2,2,0,0,1-2-2V12a3,3,0,0,1,.17-1H7V27H5V7A2,2,0,0,1,7,5H25a2,2,0,0,1,2,2Zm-9,2h7v4H17V12A1,1,0,0,1,18,11Zm6,10a1,1,0,1,0-1-1A1,1,0,0,0,24,21Zm1,5a1,1,0,1,1-1-1A1,1,0,0,1,25,26Zm-4-2a1,1,0,1,0-1-1A1,1,0,0,0,21,24Zm-2-4a1,1,0,1,1-1-1A1,1,0,0,1,19,20Zm-1,7a1,1,0,1,0-1-1A1,1,0,0,0,18,27Z"
                style={style["path"]}
            />
        </svg>
    )
}