import React, { useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../../redux/hooks";
import axios, {CancelTokenSource} from "axios";
import {ICompanyDTO} from "../../../../../../../interfaces/ICompanyDTO";
import {IAviaVariant} from "../../../../../../../interfaces/IAviaVariant";
import {TimeOfDay} from "../../../../../../../enums/TimeOfDay";
import {DateService} from "../../../../../../../services/DateService";
import {RefsService} from "../../../../../../../services/RefsService";
import {TripService} from "../../../../../../../services/TripService";
import {FlightComponent} from "../../../tickets/flightComponent";
import {MdMenu} from "../../../../../../../components/md-menu";
import {MdToggleButton} from "../../../../../../../components/md-toggle-button";
import {MdSelect} from "../../../../../../../components/md-select";
import {SwitchIcon} from "../../../../../../../icons/SwitchIcon";
import {MdButton} from "../../../../../../../components/md-button";
import {MinusIcon} from "../../../../../../../icons/MinusIcon";
import {PlusIcon} from "../../../../../../../icons/PlusIcon";
import {SunriseIcon} from "../../../../../../../icons/SunriseIcon";
import {SunIcon} from "../../../../../../../icons/SunIcon";
import {MoonIcon} from "../../../../../../../icons/MoonIcon";
import {ScheduleIcon} from "../../../../../../../icons/ScheduleIcon";
import {CircleSpinnerIcon} from "../../../../../../../icons/CircleSpinnerIcon";
import {Guid, Utils} from "../../../../../../../utils/utils";
import "./style.scss";


export enum TripType {
    OneWay,
    TwoWay
}

export enum FlightClass {
    None,
    Economy,
    Business,
    FirstClass
}

export enum BaggageAllowance {
    None           = 0,      // 0
    WithoutBaggage    = 1 << 0, // 1
    WithBaggage = 1 << 1, // 2
    All = WithoutBaggage | WithBaggage  // 3
}

export enum RefundAllowance {
    None          = 0,      // 0
    WithoutRefund    = 1 << 0, // 1
    WithRefund = 1 << 1, // 2
    All = WithoutRefund | WithRefund  // 3
}

export enum ExchangeAllowance {
    None          = 0,      // 0
    WithoutExchange    = 1 << 0, // 1
    WithExchange = 1 << 1, // 2
    All = WithoutExchange | WithExchange  // 3
}

interface ISearchResult {
    variant: IAviaVariant;
    selectedTariffIndex: number;
}

// TODO: rename to FlightAutoCompleteItem
interface IAutoCompleteItem {
    name: string;
    country_name: string;
    country_code: string;
    city_name: string;
    main_airport_name: string;
    code: string;
}

export interface IFlightRoute {
    id: string;
    tripType: TripType;
    passengers: number;
    class?: FlightClass;
    flightOptions: {
        hasExchange?: boolean;
        hasReturn?: boolean;
        isBaggageRequired?: boolean;
        baggageCount?: number;
        baggageWeight?: number;
    };
    origin: IAutoCompleteItem;
    direction: IAutoCompleteItem;
    departDate?: string;
    departTimeOfDay?: TimeOfDay;
    departTime?: string;
    returnDate?: string;
    returnTimeOfDay?: TimeOfDay;
    returnTime?: string;
    isBackwardTicket?: boolean;
    notes: string;
}


export function SearchFlightsComponent(
    props: {
        selectedCompany: ICompanyDTO | undefined;
        selectVariantHandler: (variant: IAviaVariant, tariffIndex: number, searchResponse: any) => Promise<void>;
    }
) {

    const {
        selectedCompany,
        selectVariantHandler
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const refsService = RefsService();

    const tripService = TripService();

    const dateService = DateService();


    const minPassengersCount = 1;
    const maxPassengersCount = 6;

    const [searchRoute, setSearchRoute] =
        useState<IFlightRoute>(
            {
                id: Guid.newGuid(),
                tripType: TripType.OneWay,
                passengers: minPassengersCount,
                class: undefined,
                flightOptions: { },
                //baggage: BaggageAllowance.None,
                //refund: RefundAllowance.None,
                //exchange: ExchangeAllowance.None,
                origin: {
                    name: "",
                    country_code: "",
                    country_name: "",
                    city_name: "",
                    main_airport_name: "",
                    code: "",
                },
                direction: {
                    name: "",
                    country_code: "",
                    country_name: "",
                    city_name: "",
                    main_airport_name: "",
                    code: "",
                },
                departTimeOfDay: TimeOfDay.None,
                returnTimeOfDay: TimeOfDay.None,
                notes: ""
            }
        );

    const [originFocused, setOriginFocused] = useState<boolean>(false);
    const [directionFocused, setDirectionFocused] = useState<boolean>(false);
    const [autoComplete, setAutoComplete] = useState<IAutoCompleteItem[]>([]);
    const [departDateFocused, setDepartDateFocused] = useState<boolean>(false);
    const [returnDateFocused, setReturnDateFocused] = useState<boolean>(false);

    const [providersPending, setProvidersPending] = useState<boolean>(false);
    const [isProvidersNotFound, setIsProvidersNotFound] = useState<boolean>(false);
    const [providers, setProviders] = useState<string[]>([]);
    const [selectedProviders, setSelectedProviders] = useState<string[]>([]);

    const [searchCT, setSearchCT] = useState<CancelTokenSource|undefined>(undefined);
    const [searchPending, setSearchPending] = useState(false);
    const [searchNotFound, setSearchNotFound] = useState(false);
    const [variants, setVariants] = useState<ISearchResult[]>([]);

    const [searchResponse, setSearchResponse] = useState<any>(null);



    const days = [
        t("bookingPage.sundayShort"),
        t("bookingPage.mondayShort"),
        t("bookingPage.tuesdayShort"),
        t("bookingPage.wednesdayShort"),
        t("bookingPage.thursdayShort"),
        t("bookingPage.fridayShort"),
        t("bookingPage.saturdayShort"),
    ];

    const months = [
        t("bookingPage.januaryShort"),
        t("bookingPage.februaryShort"),
        t("bookingPage.marchShort"),
        t("bookingPage.aprilShort"),
        t("bookingPage.mayShort"),
        t("bookingPage.juneShort"),
        t("bookingPage.julyShort"),
        t("bookingPage.augustShort"),
        t("bookingPage.septemberShort"),
        t("bookingPage.octoberShort"),
        t("bookingPage.novemberShort"),
        t("bookingPage.decemberShort"),
    ];

    const [tripTypeOptions] = useState<Map<TripType, { name: string }>>(
        new Map([
            [TripType.OneWay,  { name: t("bookingPage.oneWayTrip") }],
            [TripType.TwoWay,  { name: t("bookingPage.roundTrip") }]
        ])
    );

    const [flightClassOptions] = useState<Map<FlightClass, { name: string }>>(
        new Map([
            [FlightClass.Economy,     { name: t("bookingPage.economyClass") }],
            [FlightClass.Business,    { name: t("bookingPage.businessClass")  }],
            [FlightClass.FirstClass,  { name: t("bookingPage.firstClass")  }]
        ])
    );

    const [baggageOptions] = useState<Map<BaggageAllowance, { name: string }>>(
        new Map([
            [BaggageAllowance.WithoutBaggage,  { name: t("bookingPage.withoutBaggage")  }],
            [BaggageAllowance.WithBaggage,   { name: t("bookingPage.withBaggage") }]
        ])
    );

    const [refundOptions] = useState<Map<RefundAllowance, { name: string }>>(
        new Map([
            [RefundAllowance.WithoutRefund,  { name: t("bookingPage.withoutRefund")  }],
            [RefundAllowance.WithRefund,     { name: t("bookingPage.withRefund") }]
        ])
    );

    const [exchangeOptions] = useState<Map<ExchangeAllowance, { name: string }>>(
        new Map([
            [ExchangeAllowance.WithoutExchange,  { name: t("bookingPage.withoutExchange")  }],
            [ExchangeAllowance.WithExchange,     { name: t("bookingPage.withExchange") }]
        ])
    );

    const [timeOfDayOptions] = useState<Map<TimeOfDay, { name: string, interval: number[] }>>(
        new Map([
            [TimeOfDay.None,      { name: t("bookingPage.anyTime"),         interval: [] }],
            [TimeOfDay.Exact,     { name: t("bookingPage.approximateTime"), interval: [] }],
            [TimeOfDay.Morning,   { name: t("bookingPage.morning"),         interval: [4, 12] }],
            [TimeOfDay.Afternoon, { name: t("bookingPage.afternoon"),       interval: [10, 16] }],
            [TimeOfDay.Evening,   { name: t("bookingPage.evening"),         interval: [16, 23] }]
        ])
    );



    const setTripType = (tripType: TripType) => {
        setSearchRoute(route =>
            ({
                ...route,
                tripType: tripType,
                returnDate: (tripType == TripType.TwoWay ? route.returnDate: undefined),
                returnTime: (tripType == TripType.TwoWay ? route.returnTime: undefined),
            })
        );
    }

    const setPassengers = (passengers: number) => {
        setSearchRoute(route =>
            ({
                ...route,
                passengers: passengers
            })
        );
    }

    const setClass = (flightClass?: FlightClass) => {
        setSearchRoute(route =>
            ({
                ...route,
                class: flightClass
            })
        );
    }

    const setBaggage = (baggage?: boolean) => { // BaggageAllowance) => {
        setSearchRoute(route =>
            ({
                ...route,
                flightOptions: {
                    ...route.flightOptions,
                    isBaggageRequired: baggage
                }
            })
        );
    }

    const setRefund = (refund?: boolean) => { // RefundAllowance) => {
        setSearchRoute(route =>
            ({
                ...route,
                flightOptions: {
                    ...route.flightOptions,
                    hasReturn: refund
                }
            })
        );
    }

    const setExchange = (exchange?: boolean) => { // ExchangeAllowance) => {
        setSearchRoute(route =>
            ({
                ...route,
                flightOptions: {
                    ...route.flightOptions,
                    hasExchange: exchange
                }
            })
        );
    }

    const setOrigin = (origin: IAutoCompleteItem) => {
        setSearchRoute(route =>
            ({
                ...route,
                origin: origin
            })
        );
    }

    const switchCities = () => {
        setSearchRoute(route =>
            ({
                ...route,
                origin: route.direction,
                direction: route.origin
            })
        );
    }

    const setDirection = (direction: IAutoCompleteItem) => {
        setSearchRoute(route =>
            ({
                ...route,
                direction: direction
            })
        );
    }

    const setDepartDate = (date: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                departDate: date,
                returnDate: (route.returnDate != undefined && date != undefined && new Date(route.returnDate) < new Date(date) ? undefined : route.returnDate)
            })
        );
    }

    const setDepartTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                departTimeOfDay: timeOfDay,
                departTime: timeOfDay == TimeOfDay.Exact && route.departTime == undefined ? "00:00" : route.departTime
            })
        );
    }

    const setDepartTime = (time: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                departTime: time
            })
        );
    }

    const setReturnDate = (date: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                returnDate: date
            })
        );
    }

    const setReturnTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                returnTimeOfDay: timeOfDay,
                returnTime: timeOfDay == TimeOfDay.Exact && route.returnTime == undefined ? "00:00" : route.returnTime
            })
        );
    }

    const setReturnTime = (time: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                returnTime: time
            })
        );
    }


    const originFocusHandler = async () => {
        setOriginFocused(true);
        setAutoComplete([]);
        const originText = [searchRoute.origin.name, searchRoute.origin.city_name].find(x => x != null && x.length > 0);
        if(originText == null) {
            return;
        }
        const autoCompleteItems = await refsService.getAviaCities(originText, "ru");
        setAutoComplete(autoCompleteItems);
    }
    const originBlurHandler = async () => {
        setOriginFocused(false);
    }
    const originTextChangeHandler = async (text: string) => {
        if(Utils.isEmpty(text)) {
            setAutoComplete([]);
            return;
        }
        const autoCompleteItems = await refsService.getAviaCities(text, "ru");
        setAutoComplete(autoCompleteItems);
    }

    const directionFocusHandler = async () => {
        setDirectionFocused(true);
        setAutoComplete([]);
        const directionText = [searchRoute.direction.name, searchRoute.direction.city_name].find(x => x != null && x.length > 0);
        if(directionText == null) {
            return;
        }
        const autoCompleteItems = await refsService.getAviaCities(directionText, "ru");
        setAutoComplete(autoCompleteItems);
    }
    const directionBlurHandler = async () => {
        setDirectionFocused(false);
    }
    const directionTextChangeHandler = async (text: string) => {
        if(Utils.isEmpty(text)) {
            setAutoComplete([]);
            return;
        }
        const autoCompleteItems = await refsService.getAviaCities(text, "ru");
        setAutoComplete(autoCompleteItems);
    }

    const departDateFocusHandler = async () => {
        setDepartDateFocused(true);
    }
    const departDateBlurHandler = async () => {
        setDepartDateFocused(false);
    }

    const returnDateFocusHandler = async () => {
        setReturnDateFocused(true);
    }
    const returnDateBlurHandler = async () => {
        setReturnDateFocused(false);
    }


    const getProviders = async () => {
        setProvidersPending(true);
        setIsProvidersNotFound(false);
        setProviders([]);
        const response = await tripService.getFlightProviders(selectedCompany?.code ?? "");
        const providers = response ?? [];
        if (providers.length === 0) {
            setIsProvidersNotFound(true);
        }
        setProviders(providers);
        setProvidersPending(false);
    };


    const startSearch = async () => {
        setSearchPending(true);
        setSearchNotFound(false);
        setVariants([]);

        if (searchCT !== undefined) {
            searchCT.cancel();
        }

        let cts = axios.CancelToken.source();
        setSearchCT(cts);

        const response = await tripService.getFlights(
            (
                searchRoute.tripType == TripType.TwoWay
                    ?
                        [
                            Object.assign({}, ({
                                ...searchRoute,
                            })),
                            Object.assign({}, ({
                                ...searchRoute,
                                origin: searchRoute.direction,
                                direction: searchRoute.origin,
                                departDate: searchRoute.returnDate,
                                departTimeOfDay: searchRoute.returnTimeOfDay,
                                departTime: searchRoute.returnTime,
                                isBackwardTicket: true
                            }))
                        ]
                    :
                        [
                            Object.assign({}, ({
                                ...searchRoute,
                            }))
                        ]
            ),
            selectedProviders,
            selectedCompany?.code ?? "",
            cts.token
        );

        setSearchCT(undefined);

        setSearchResponse(response);

        const variants = response?.variants ?? [];

        if(variants.length === 0) {
            setSearchNotFound(true);
        }

        variants.sort(function(a,b){
            return new Date(a.rejsInfo[0].dateDep).getTime() - new Date(b.rejsInfo[0].dateDep).getTime();
        });

        setVariants(
            variants.map(variant => {
                return {
                    variant: variant,
                    selectedTariffIndex: 0
                } as ISearchResult
            })
        );

        setSearchPending(false);
    };

    const cancelSearchFlights = () => {
        if (searchCT !== undefined) {
            searchCT.cancel();
        }
    }


    // const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //     if (event.repeat) return;
    //     if (event.key === "Enter") {
    //         startSearch();
    //     }
    // }


    const selectVariant = async (variant: ISearchResult) => {
        await selectVariantHandler(variant.variant, variant.selectedTariffIndex, searchResponse);
    }


    const checkDates = () => {
        const today = new Date();
        today.setHours(0,0,0,0);

        let departDateRange: number[] | undefined = undefined;
        if(searchRoute.departDate != undefined) {
            switch (searchRoute.departTimeOfDay) {
                case TimeOfDay.None:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T00:00:00").getTime(),
                        new Date(searchRoute.departDate + "T23:59:59").getTime()
                    ];
                    break;
                case TimeOfDay.Exact:
                    departDateRange = [new Date(searchRoute.departDate + "T" + searchRoute.departTime).getTime()];
                    break;
                case TimeOfDay.Morning:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T04:00:00").getTime(),
                        new Date(searchRoute.departDate + "T12:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Afternoon:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T10:00:00").getTime(),
                        new Date(searchRoute.departDate + "T16:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Evening:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T16:00:00").getTime(),
                        new Date(searchRoute.departDate + "T23:00:00").getTime(),
                    ];
                    break;
            }
        }

        let returnDateRange: number[] | undefined = undefined;
        if(searchRoute.returnDate != undefined) {
            switch (searchRoute.returnTimeOfDay) {
                case TimeOfDay.None:
                    returnDateRange = [
                        new Date(searchRoute.returnDate + "T00:00:00").getTime(),
                        new Date(searchRoute.returnDate + "T23:59:59").getTime()
                    ];
                    break;
                case TimeOfDay.Exact:
                    returnDateRange = [new Date(searchRoute.returnDate + "T" + searchRoute.returnTime).getTime()];
                    break;
                case TimeOfDay.Morning:
                    returnDateRange = [
                        new Date(searchRoute.returnDate + "T04:00:00").getTime(),
                        new Date(searchRoute.returnDate + "T12:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Afternoon:
                    returnDateRange = [
                        new Date(searchRoute.returnDate + "T10:00:00").getTime(),
                        new Date(searchRoute.returnDate + "T16:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Evening:
                    returnDateRange = [
                        new Date(searchRoute.returnDate + "T16:00:00").getTime(),
                        new Date(searchRoute.returnDate + "T23:00:00").getTime(),
                    ];
                    break;
            }
        }

        let hasError = false;

        if(searchRoute.departDate != undefined) {
            if(new Date(searchRoute.departDate) < today) {
                hasError = true;
            }
            if(departDateRange != undefined && returnDateRange != undefined) {
                if(returnDateRange.every(r => departDateRange?.every(d => r <= d))) {
                    hasError = true;
                }
            }
        }

        return hasError;
    }


    useEffect(() => {
        getProviders();
    }, [selectedCompany]);


    return (
        <React.Fragment>
            <div className="trip-page-search-form">


                <div className="trip-page-search-form-header">

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    tripTypeOptions.get(searchRoute.tripType)?.name
                                }
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">
                            {
                                Array.from(tripTypeOptions.entries()).map((entry, entryIndex) => {
                                    const [variant, variantDetails] = entry;
                                    return (
                                        <React.Fragment key={entryIndex}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: ((searchRoute.tripType === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: ((searchRoute.tripType === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: ((searchRoute.tripType === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={(searchRoute.tripType === variant)}
                                                onClick={() => {
                                                    setTripType(variant);
                                                }}
                                            >
                                                {
                                                    variantDetails.name
                                                }
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {t("bookingPage.passengers")}: {searchRoute.passengers.toString().padStart(2, "0")}
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="passengers-input-control">
                            <MdButton
                                icon={
                                    <MinusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (searchRoute.passengers > minPassengersCount) {
                                        setPassengers(searchRoute.passengers - 1);
                                    }
                                }}
                            />

                            <input
                                type="number"
                                min={minPassengersCount}
                                max={maxPassengersCount}
                                value={searchRoute.passengers}
                                readOnly={true}
                            />

                            <MdButton
                                icon={
                                    <PlusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (searchRoute.passengers < maxPassengersCount) {
                                        setPassengers(searchRoute.passengers + 1);
                                    }
                                }}
                            />
                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    flightClassOptions.get(searchRoute.class ?? FlightClass.None)?.name ?? t("bookingPage.anyClass")
                                }
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">
                            {
                                Array.from(flightClassOptions.entries()).map((entry, entryIndex) => {
                                    const [variant, variantDetails] = entry;
                                    return (
                                        <React.Fragment key={entryIndex}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: (searchRoute.class == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: (searchRoute.class == variant ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (searchRoute.class == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={(searchRoute.class == variant)}
                                                onClick={() => {
                                                    if ((searchRoute.class != variant)) {
                                                        setClass(variant);
                                                    } else {
                                                        setClass(undefined);
                                                    }
                                                }}
                                            >
                                                {
                                                    variantDetails.name
                                                }
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </MdMenu>

                    {/*<MdMenu*/}
                    {/*    behaviour={'none'}*/}
                    {/*    header={(*/}
                    {/*        <div className="filter-menu-header">*/}
                    {/*            {*/}
                    {/*                flightClassOptions.get(searchRoute.class)?.name ?? t("bookingPage.anyClass")*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*    disabled={searchPending}*/}
                    {/*>*/}
                    {/*    <div className="filter-menu-content">*/}
                    {/*        {*/}
                    {/*            Array.from(flightClassOptions.entries()).map((entry, entryIndex) => {*/}
                    {/*                const [ variant, variantDetails ] = entry;*/}
                    {/*                return (*/}
                    {/*                    <React.Fragment key={entryIndex}>*/}
                    {/*                        <MdToggleButton*/}
                    {/*                            buttonStyle={{*/}
                    {/*                                flex: 1,*/}
                    {/*                                minInlineSize: "fit-content",*/}
                    {/*                                display: "flex",*/}
                    {/*                                alignItems: "center",*/}
                    {/*                                padding: "5px 10px",*/}
                    {/*                                borderRadius: "4px",*/}
                    {/*                                minWidth: "max-content",*/}
                    {/*                                minHeight: "24px",*/}
                    {/*                                maxHeight: "52px",*/}
                    {/*                                fontSize: "12px",*/}
                    {/*                                color: ((searchRoute.class & variant) == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                border: ((searchRoute.class & variant) == variant ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),*/}
                    {/*                            }}*/}
                    {/*                            inkStyle={{*/}
                    {/*                                backgroundColor: ((searchRoute.class & variant) == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                opacity: 0.1*/}
                    {/*                            }}*/}
                    {/*                            checked={(searchRoute.class & variant) == variant}*/}
                    {/*                            onClick={() => {*/}
                    {/*                                if ((searchRoute.class & variant) != variant) {*/}
                    {/*                                    setClass(searchRoute.class |= variant);*/}
                    {/*                                }*/}
                    {/*                                else {*/}
                    {/*                                    setClass(searchRoute.class &= ~variant);*/}
                    {/*                                }*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            {*/}
                    {/*                                variantDetails.name*/}
                    {/*                            }*/}
                    {/*                        </MdToggleButton>*/}
                    {/*                    </React.Fragment>*/}
                    {/*                )*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</MdMenu>*/}

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    t("bookingPage.additionally")
                                }
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">
                            {
                                <React.Fragment>

                                    <MdToggleButton
                                        buttonStyle={{
                                            flex: 1,
                                            minInlineSize: "fit-content",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "5px 10px",
                                            borderRadius: "4px",
                                            minWidth: "max-content",
                                            minHeight: "24px",
                                            maxHeight: "52px",
                                            fontSize: "12px",
                                            color: ((searchRoute.flightOptions.hasReturn == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                            border: ((searchRoute.flightOptions.hasReturn == true) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                        }}
                                        inkStyle={{
                                            backgroundColor: ((searchRoute.flightOptions.hasReturn == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                            opacity: 0.1
                                        }}
                                        checked={(searchRoute.flightOptions.hasReturn == true)}
                                        onClick={() => {
                                            if ((searchRoute.flightOptions.hasReturn !== true)) {
                                                setRefund(true);
                                            } else {
                                                setRefund(undefined);
                                            }
                                        }}
                                    >
                                        {
                                            t("bookingPage.withRefund")
                                        }
                                    </MdToggleButton>

                                    <MdToggleButton
                                        buttonStyle={{
                                            flex: 1,
                                            minInlineSize: "fit-content",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "5px 10px",
                                            borderRadius: "4px",
                                            minWidth: "max-content",
                                            minHeight: "24px",
                                            maxHeight: "52px",
                                            fontSize: "12px",
                                            color: ((searchRoute.flightOptions.hasExchange == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                            border: ((searchRoute.flightOptions.hasExchange == true) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                        }}
                                        inkStyle={{
                                            backgroundColor: ((searchRoute.flightOptions.hasExchange == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                            opacity: 0.1
                                        }}
                                        checked={(searchRoute.flightOptions.hasExchange == true)}
                                        onClick={() => {
                                            if ((searchRoute.flightOptions.hasExchange !== true)) {
                                                setExchange(true);
                                            } else {
                                                setExchange(undefined);
                                            }
                                        }}
                                    >
                                        {
                                            t("bookingPage.withExchange")
                                        }
                                    </MdToggleButton>

                                    <MdToggleButton
                                        buttonStyle={{
                                            flex: 1,
                                            minInlineSize: "fit-content",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "5px 10px",
                                            borderRadius: "4px",
                                            minWidth: "max-content",
                                            minHeight: "24px",
                                            maxHeight: "52px",
                                            fontSize: "12px",
                                            color: ((searchRoute.flightOptions.isBaggageRequired == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                            border: ((searchRoute.flightOptions.isBaggageRequired == true) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                        }}
                                        inkStyle={{
                                            backgroundColor: ((searchRoute.flightOptions.isBaggageRequired == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                            opacity: 0.1
                                        }}
                                        checked={(searchRoute.flightOptions.isBaggageRequired == true)}
                                        onClick={() => {
                                            if ((searchRoute.flightOptions.isBaggageRequired !== true)) {
                                                setBaggage(true);
                                            } else {
                                                setBaggage(undefined);
                                            }
                                        }}
                                    >
                                        {
                                            t("bookingPage.withBaggage")
                                        }
                                    </MdToggleButton>

                                    {/*TODO: baggageCount, baggageWeight*/}

                                </React.Fragment>
                            }
                        </div>
                    </MdMenu>

                    {/*<MdMenu*/}
                    {/*    behaviour={'none'}*/}
                    {/*    header={(*/}
                    {/*        <div className="filter-menu-header">*/}
                    {/*            {*/}
                    {/*                baggageOptions.get(searchRoute.baggage)?.name ?? t("bookingPage.anyBaggage")*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*    disabled={searchPending}*/}
                    {/*>*/}
                    {/*    <div className="filter-menu-content">*/}
                    {/*        {*/}
                    {/*            Array.from(baggageOptions.entries()).map((entry, entryIndex) => {*/}
                    {/*                const [ variant, variantDetails ] = entry;*/}
                    {/*                return (*/}
                    {/*                    <React.Fragment key={entryIndex}>*/}
                    {/*                        <MdToggleButton*/}
                    {/*                            buttonStyle={{*/}
                    {/*                                flex: 1,*/}
                    {/*                                minInlineSize: "fit-content",*/}
                    {/*                                display: "flex",*/}
                    {/*                                alignItems: "center",*/}
                    {/*                                padding: "5px 10px",*/}
                    {/*                                borderRadius: "4px",*/}
                    {/*                                minWidth: "max-content",*/}
                    {/*                                minHeight: "24px",*/}
                    {/*                                maxHeight: "52px",*/}
                    {/*                                fontSize: "12px",*/}
                    {/*                                color: ((searchRoute.baggage & variant) == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                border: ((searchRoute.baggage & variant) == variant ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),*/}
                    {/*                            }}*/}
                    {/*                            inkStyle={{*/}
                    {/*                                backgroundColor: ((searchRoute.baggage & variant) == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                opacity: 0.1*/}
                    {/*                            }}*/}
                    {/*                            checked={(searchRoute.baggage & variant) == variant}*/}
                    {/*                            onClick={() => {*/}
                    {/*                                if ((searchRoute.baggage & variant) != variant) {*/}
                    {/*                                    setBaggage(searchRoute.baggage |= variant);*/}
                    {/*                                }*/}
                    {/*                                else {*/}
                    {/*                                    setBaggage(searchRoute.baggage &= ~variant);*/}
                    {/*                                }*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            {*/}
                    {/*                                variantDetails.name*/}
                    {/*                            }*/}
                    {/*                        </MdToggleButton>*/}
                    {/*                    </React.Fragment>*/}
                    {/*                )*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</MdMenu>*/}

                    {/*<MdMenu*/}
                    {/*    behaviour={'none'}*/}
                    {/*    header={(*/}
                    {/*        <div className="filter-menu-header">*/}
                    {/*            {*/}
                    {/*                refundOptions.get(searchRoute.refund)?.name ?? t("bookingPage.anyRefund")*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*    disabled={searchPending}*/}
                    {/*>*/}
                    {/*    <div className="filter-menu-content">*/}
                    {/*        {*/}
                    {/*            Array.from(refundOptions.entries()).map((entry, entryIndex) => {*/}
                    {/*                const [ variant, variantDetails ] = entry;*/}
                    {/*                return (*/}
                    {/*                    <React.Fragment key={entryIndex}>*/}
                    {/*                        <MdToggleButton*/}
                    {/*                            buttonStyle={{*/}
                    {/*                                flex: 1,*/}
                    {/*                                minInlineSize: "fit-content",*/}
                    {/*                                display: "flex",*/}
                    {/*                                alignItems: "center",*/}
                    {/*                                padding: "5px 10px",*/}
                    {/*                                borderRadius: "4px",*/}
                    {/*                                minWidth: "max-content",*/}
                    {/*                                minHeight: "24px",*/}
                    {/*                                maxHeight: "52px",*/}
                    {/*                                fontSize: "12px",*/}
                    {/*                                color: ((searchRoute.refund & variant) == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                border: ((searchRoute.refund & variant) == variant ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),*/}
                    {/*                            }}*/}
                    {/*                            inkStyle={{*/}
                    {/*                                backgroundColor: ((searchRoute.refund & variant) == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                opacity: 0.1*/}
                    {/*                            }}*/}
                    {/*                            checked={(searchRoute.refund & variant) == variant}*/}
                    {/*                            onClick={() => {*/}
                    {/*                                if ((searchRoute.refund & variant) != variant) {*/}
                    {/*                                    setRefund(searchRoute.refund |= variant);*/}
                    {/*                                }*/}
                    {/*                                else {*/}
                    {/*                                    setRefund(searchRoute.refund &= ~variant);*/}
                    {/*                                }*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            {*/}
                    {/*                                variantDetails.name*/}
                    {/*                            }*/}
                    {/*                        </MdToggleButton>*/}
                    {/*                    </React.Fragment>*/}
                    {/*                )*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</MdMenu>*/}

                    {/*<MdMenu*/}
                    {/*    behaviour={'none'}*/}
                    {/*    header={(*/}
                    {/*        <div className="filter-menu-header">*/}
                    {/*            {*/}
                    {/*                exchangeOptions.get(searchRoute.exchange)?.name ?? t("bookingPage.anyExchange")*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*    disabled={searchPending}*/}
                    {/*>*/}
                    {/*    <div className="filter-menu-content">*/}
                    {/*        {*/}
                    {/*            Array.from(exchangeOptions.entries()).map((entry, entryIndex) => {*/}
                    {/*                const [ variant, variantDetails ] = entry;*/}
                    {/*                return (*/}
                    {/*                    <React.Fragment key={entryIndex}>*/}
                    {/*                        <MdToggleButton*/}
                    {/*                            buttonStyle={{*/}
                    {/*                                flex: 1,*/}
                    {/*                                minInlineSize: "fit-content",*/}
                    {/*                                display: "flex",*/}
                    {/*                                alignItems: "center",*/}
                    {/*                                padding: "5px 10px",*/}
                    {/*                                borderRadius: "4px",*/}
                    {/*                                minWidth: "max-content",*/}
                    {/*                                minHeight: "24px",*/}
                    {/*                                maxHeight: "52px",*/}
                    {/*                                fontSize: "12px",*/}
                    {/*                                color: ((searchRoute.exchange & variant) == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                border: ((searchRoute.exchange & variant) == variant ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),*/}
                    {/*                            }}*/}
                    {/*                            inkStyle={{*/}
                    {/*                                backgroundColor: ((searchRoute.exchange & variant) == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                opacity: 0.1*/}
                    {/*                            }}*/}
                    {/*                            checked={(searchRoute.exchange & variant) == variant}*/}
                    {/*                            onClick={() => {*/}
                    {/*                                if ((searchRoute.exchange & variant) != variant) {*/}
                    {/*                                    setExchange(searchRoute.exchange |= variant);*/}
                    {/*                                }*/}
                    {/*                                else {*/}
                    {/*                                    setExchange(searchRoute.exchange &= ~variant);*/}
                    {/*                                }*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            {*/}
                    {/*                                variantDetails.name*/}
                    {/*                            }*/}
                    {/*                        </MdToggleButton>*/}
                    {/*                    </React.Fragment>*/}
                    {/*                )*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</MdMenu>*/}

                </div>


                <div className="trip-page-search-form-content">

                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}>
                        <label className={((originFocused || (searchRoute.origin.city_name ?? searchRoute.origin.name).length > 0 ? "--displaced" : ""))}>
                            {t("commonText.from")}
                        </label>
                        <MdSelect
                            isEditable={true}
                            text={(searchRoute.origin.city_name ?? searchRoute.origin.name)}
                            setText={(value) => setOrigin(autoComplete.find(x => x.city_name == value || x.name == value) ?? {
                                name: "",
                                country_code: "",
                                country_name: "",
                                city_name: value,
                                main_airport_name: "",
                                code: ""
                            })}
                            selectedItem={searchRoute.origin}
                            setSelectedItem={(item) => setOrigin(item)}
                            onFocus={() => originFocusHandler()}
                            onBlur={() => originBlurHandler()}
                            itemSource={autoComplete}
                            displayMemberPath={"name"}
                            selectedItemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.main_airport_name ?? item.name), item.country_name].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item"
                                         style={{padding: '26px 10px 6px 20px'}}>
                                        <div className="trip-page-search-form-city-item-header">
                                            <div className="trip-page-search-form-city-item-name">
                                                {item.city_name ?? item.name}
                                            </div>
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.code}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {
                                                item.country_code.length > 0 && (
                                                    <span
                                                        className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>
                                                )
                                            }
                                            <div className="trip-page-search-form-city-item-details-text">
                                                {
                                                    (details.length > 0 ? details.join(', ') : "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            itemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.main_airport_name ?? item.name), item.country_name].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item">
                                        <div className="trip-page-search-form-city-item-header">
                                            <div className="trip-page-search-form-city-item-name">
                                                {item.city_name ?? item.name}
                                            </div>
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.code}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {
                                                item.country_code.length > 0 && (
                                                    <div className="trip-page-search-form-city-item-flag">
                                                        <span
                                                            className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>
                                                    </div>
                                                )
                                            }
                                            <div className="trip-page-search-form-city-item-details-text">
                                                {
                                                    (details.length > 0 ? details.join(', ') : "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            onTextChange={(value) => originTextChangeHandler(value)}
                            style={{
                                "root": {
                                    width: "100%",
                                    height: "100%",
                                    margin: 0,
                                    border: "none"
                                },
                                "text-field": {
                                    padding: "7px 10px 6px 20px",
                                    height: "100%",
                                    fontWeight: 500,
                                    fontSize: "13px"
                                },
                                "toggle-button-icon": {
                                    display: "none"
                                }
                            }}
                        />
                    </div>


                    <div className="trip-page-search-form-switch-cities-control">
                        <MdButton
                            style={{
                                "button": {
                                    position: 'absolute',
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%) rotate(-90deg)",
                                    borderWidth: 0,
                                    backgroundColor: "transparent",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "30px",
                                    padding: "5px",
                                    zIndex: 1
                                },
                                "icon": {
                                    marginRight: "5px",
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            tabIndex={-1}
                            disabled={searchPending}
                            onClick={() => switchCities()}
                        >
                            <SwitchIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "path": {
                                        stroke: "rgb(100, 100, 100)",
                                        fill: "rgb(100, 100, 100)"
                                    }
                                }}
                            />
                        </MdButton>
                    </div>


                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}>
                        <label
                            className={((directionFocused || (searchRoute.direction.city_name ?? searchRoute.direction.name).length > 0 ? "--displaced" : ""))}>
                            {t("commonText.to")}
                        </label>
                        <MdSelect
                            isEditable={true}
                            text={(searchRoute.direction.city_name ?? searchRoute.direction.name)}
                            setText={(value) => setDirection(autoComplete.find(x => x.city_name == value || x.name == value) ?? {
                                name: "",
                                country_code: "",
                                country_name: "",
                                city_name: value,
                                main_airport_name: "",
                                code: ""
                            })}
                            selectedItem={searchRoute.direction}
                            setSelectedItem={(item) => setDirection(item)}
                            onFocus={() => directionFocusHandler()}
                            onBlur={() => directionBlurHandler()}
                            itemSource={autoComplete}
                            displayMemberPath={"name"}
                            selectedItemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.main_airport_name ?? item.name), item.country_name].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item"
                                         style={{padding: '26px 10px 6px 20px'}}>
                                        <div className="trip-page-search-form-city-item-header">
                                            <div className="trip-page-search-form-city-item-name">
                                                {item.city_name ?? item.name}
                                            </div>
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.code}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {
                                                item.country_code.length > 0 && (
                                                    <span
                                                        className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>
                                                )
                                            }
                                            <div className="trip-page-search-form-city-item-details-text">
                                                {
                                                    (details.length > 0 ? details.join(', ') : "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            itemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.main_airport_name ?? item.name), item.country_name].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item">
                                        <div className="trip-page-search-form-city-item-header">
                                            <div className="trip-page-search-form-city-item-name">
                                                {item.city_name ?? item.name}
                                            </div>
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.code}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {
                                                item.country_code.length > 0 && (
                                                    <div className="trip-page-search-form-city-item-flag">
                                                        <span
                                                            className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>
                                                    </div>
                                                )
                                            }
                                            <div className="trip-page-search-form-city-item-details-text">
                                                {
                                                    (details.length > 0 ? details.join(', ') : "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            onTextChange={(value) => directionTextChangeHandler(value)}
                            style={{
                                "root": {
                                    width: "100%",
                                    height: "100%",
                                    margin: 0,
                                    border: "none"
                                },
                                "text-field": {
                                    padding: "7px 10px 6px 20px",
                                    height: "100%",
                                    fontWeight: 500,
                                    fontSize: "13px"
                                },
                                "toggle-button-icon": {
                                    display: "none"
                                }
                            }}
                        />
                    </div>


                    <div
                        className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}
                        style={{
                            marginLeft: "6px",
                            minWidth: "280px",
                            backgroundColor: (checkDates() ? "rgba(255, 0, 0, 0.2)" : "transparent")
                        }}
                    >
                        <label
                            className={(departDateFocused || searchRoute.departDate != null ? "--displaced" : "")}
                        >
                            {t("bookingPage.departDate")}
                        </label>
                        <input
                            className={(departDateFocused ? "--focused" : "")}
                            type="date"
                            min={dateService.dateToStringForDatePicker(new Date())}
                            value={searchRoute.departDate ?? ""}
                            onFocus={() => departDateFocusHandler()}
                            onBlur={() => departDateBlurHandler()}
                            onChange={(e) => setDepartDate(e.target.value)}
                            disabled={searchPending}
                        />
                        {
                            (searchRoute.departTimeOfDay == TimeOfDay.Exact) && (
                                <input
                                    className={(departDateFocused ? "--focused" : "")}
                                    type="time"
                                    value={searchRoute.departTime ?? ""}
                                    onFocus={() => departDateFocusHandler()}
                                    onBlur={() => departDateBlurHandler()}
                                    onChange={(e) => setDepartTime(e.target.value)}
                                    disabled={searchPending}
                                />
                            )
                        }
                        {
                            searchRoute.departDate != null && (
                                <div
                                    className={("trip-page-search-form-datetime-item" + (departDateFocused ? " --hidden" : ""))}>
                                    <div className="trip-page-search-form-datetime-item-date">
                                        {
                                            days[new Date(searchRoute.departDate).getDay()]
                                            + ", " + new Date(searchRoute.departDate).getDate().toString().padStart(2, "0") + " "
                                            + months[new Date(searchRoute.departDate).getMonth()]
                                            + " " + new Date(searchRoute.departDate).getFullYear()
                                        }
                                    </div>
                                    <div className="trip-page-search-form-datetime-item-time">
                                        {
                                            (() => {
                                                switch (searchRoute.departTimeOfDay) {
                                                    case TimeOfDay.Exact:
                                                        return (
                                                            <>
                                                                <span style={{marginLeft: "16px"}}>
                                                                    {searchRoute.departTime?.toString() ?? "00:00"}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Morning:
                                                        return (
                                                            <>
                                                                <SunriseIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    {timeOfDayOptions.get(TimeOfDay.Morning)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Afternoon:
                                                        return (
                                                            <>
                                                                <SunIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    {timeOfDayOptions.get(TimeOfDay.Afternoon)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Evening:
                                                        return (
                                                            <>
                                                                <MoonIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    {timeOfDayOptions.get(TimeOfDay.Evening)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.None:
                                                    default:
                                                        return "--:--"
                                                }
                                            })()
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <MdMenu
                            behaviour={'none'}
                            header={(
                                <div className="departure-time-type">
                                    {
                                        timeOfDayOptions.get(searchRoute.departTimeOfDay ?? TimeOfDay.None)?.name
                                    }
                                </div>
                            )}
                            style={{
                                "root": {
                                    position: 'absolute',
                                    bottom: "2px",
                                    right: "3px",
                                }
                            }}
                            disabled={searchPending}
                        >
                            <div className="departure-time-type-variants">
                                {
                                    Array.from(timeOfDayOptions.entries()).map((entry, entryIndex) => {
                                        const [variant, variantDetails] = entry;
                                        return (
                                            <React.Fragment key={entryIndex}>
                                                <MdToggleButton
                                                    buttonStyle={{
                                                        flex: 1,
                                                        minInlineSize: "fit-content",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "5px 10px",
                                                        borderRadius: "4px",
                                                        minWidth: "max-content",
                                                        minHeight: "24px",
                                                        maxHeight: "52px",
                                                        fontSize: "12px",
                                                        color: ((searchRoute.departTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                        border: ((searchRoute.departTimeOfDay === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                    }}
                                                    inkStyle={{
                                                        backgroundColor: ((searchRoute.departTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                        opacity: 0.1
                                                    }}
                                                    checked={(searchRoute.departTimeOfDay === variant)}
                                                    onClick={() => {
                                                        setDepartTimeOfDay(variant);
                                                    }}
                                                >
                                                    {
                                                        variantDetails.name
                                                    }
                                                </MdToggleButton>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </MdMenu>
                    </div>


                    {
                        (searchRoute.tripType == TripType.TwoWay) && (
                            <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}
                                 style={{
                                     marginLeft: "6px",
                                     minWidth: "280px",
                                     backgroundColor: (checkDates() ? "rgba(255, 0, 0, 0.2)" : "transparent")
                                 }}>
                                <label
                                    className={(returnDateFocused || searchRoute.returnDate != null ? "--displaced" : "")}>
                                    {t("bookingPage.returnDate")}
                                </label>
                                <input
                                    className={(returnDateFocused ? "--focused" : "")}
                                    type="date"
                                    min={dateService.dateToStringForDatePicker(new Date())}
                                    value={searchRoute.returnDate ?? ""}
                                    onFocus={() => returnDateFocusHandler()}
                                    onBlur={() => returnDateBlurHandler()}
                                    onChange={(e) => setReturnDate(e.target.value)}
                                    disabled={searchPending}
                                />
                                {
                                    (searchRoute.returnTimeOfDay == TimeOfDay.Exact) && (
                                        <input
                                            className={(returnDateFocused ? "--focused" : "")}
                                            type="time"
                                            value={searchRoute.returnTime ?? ""}
                                            onFocus={() => returnDateFocusHandler()}
                                            onBlur={() => returnDateBlurHandler()}
                                            onChange={(e) => setReturnTime(e.target.value)}
                                            disabled={searchPending}
                                        />
                                    )
                                }
                                {
                                    searchRoute.returnDate != null && (
                                        <div
                                            className={("trip-page-search-form-datetime-item" + (returnDateFocused ? " --hidden" : ""))}>
                                            <div className="trip-page-search-form-datetime-item-date">
                                                {
                                                    days[new Date(searchRoute.returnDate).getDay()]
                                                    + ", " + new Date(searchRoute.returnDate).getDate().toString().padStart(2, "0") + " "
                                                    + months[new Date(searchRoute.returnDate).getMonth()]
                                                    + " " + new Date(searchRoute.returnDate).getFullYear()
                                                }
                                            </div>
                                            <div className="trip-page-search-form-datetime-item-time">
                                                {
                                                    (() => {
                                                        switch (searchRoute.returnTimeOfDay) {
                                                            case TimeOfDay.Exact:
                                                                return (
                                                                    <>
                                                                        <span style={{marginLeft: "16px"}}>
                                                                            {searchRoute.returnTime?.toString() ?? "00:00"}
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.Morning:
                                                                return (
                                                                    <>
                                                                        <SunriseIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            {timeOfDayOptions.get(TimeOfDay.Morning)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.Afternoon:
                                                                return (
                                                                    <>
                                                                        <SunIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            {timeOfDayOptions.get(TimeOfDay.Afternoon)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.Evening:
                                                                return (
                                                                    <>
                                                                        <MoonIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            {timeOfDayOptions.get(TimeOfDay.Evening)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.None:
                                                            default:
                                                                return "--:--"
                                                        }
                                                    })()
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <MdMenu
                                    behaviour={'none'}
                                    header={(
                                        <div className="return-time-type">
                                            {
                                                timeOfDayOptions.get(searchRoute.returnTimeOfDay ?? TimeOfDay.None)?.name
                                            }
                                        </div>
                                    )}
                                    disabled={searchPending}
                                    style={{
                                        "root": {
                                            position: 'absolute',
                                            bottom: "2px",
                                            right: "3px",
                                        }
                                    }}
                                >
                                    <div className="return-time-type-variants">
                                        {
                                            Array.from(timeOfDayOptions.entries()).map((entry, entryIndex) => {
                                                const [variant, variantDetails] = entry;
                                                return (
                                                    <React.Fragment key={entryIndex}>
                                                        <MdToggleButton
                                                            buttonStyle={{
                                                                flex: 1,
                                                                minInlineSize: "fit-content",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                padding: "5px 10px",
                                                                borderRadius: "4px",
                                                                minWidth: "max-content",
                                                                minHeight: "24px",
                                                                maxHeight: "52px",
                                                                fontSize: "12px",
                                                                color: ((searchRoute.returnTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                border: ((searchRoute.returnTimeOfDay === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                            }}
                                                            inkStyle={{
                                                                backgroundColor: ((searchRoute.returnTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                opacity: 0.1
                                                            }}
                                                            checked={(searchRoute.returnTimeOfDay === variant)}
                                                            onClick={() => {
                                                                setReturnTimeOfDay(variant);
                                                            }}
                                                        >
                                                            {
                                                                variantDetails.name
                                                            }
                                                        </MdToggleButton>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </MdMenu>
                            </div>
                        )
                    }

                </div>


                <MdButton
                    className="search-button"
                    type="button"
                    style={{
                        "button": {
                            position: "absolute",
                            bottom: "-20px",
                            right: "30px",
                            borderWidth: 0,
                            backgroundColor: (searchPending ? "#B83E50" : "#3E5CB8"),
                            color: "#FFFFFF",
                            borderRadius: "8px",
                            minWidth: "160px",
                            height: "40px",
                            padding: "5px 15px",
                            fontWeight: 500,
                            fontSize: "12px",
                            textTransform: "capitalize",
                            alignItems: "center",
                            justifyContent: "center"
                        },
                        "text": {
                            width: "auto",
                            flex: 0,
                            color: "#FFFFFF"
                        },
                        "ink": {
                            backgroundColor: "rgba(255,255,255,.5)"
                        }
                    }}
                    disabled={(
                        searchRoute.origin.city_name?.length == 0
                        || searchRoute.direction.city_name?.length == 0
                        || searchRoute.departDate == undefined
                        || searchRoute.tripType == TripType.TwoWay && searchRoute.returnDate == undefined
                        || checkDates()
                    )}
                    onClick={() => {
                        if (!searchPending) {
                            startSearch()
                        } else {
                            cancelSearchFlights();
                        }
                    }}
                >
                    {
                        searchPending
                            ? (
                                t("bookingPage.stop")
                            )
                            : (
                                t("bookingPage.searchFlights")
                            )
                    }
                </MdButton>

            </div>


            <div className="trip-page-search-tools">
                {
                    authState.isSupportUser && (
                        <div className="trip-page-search-providers">
                            <span className="provider-list-label">{t("bookingPage.providers")}:</span>
                            {
                                providers.map((provider, providerIndex) => {
                                    const isSelected = selectedProviders.includes(provider);
                                    return (
                                        <React.Fragment key={"provider" + Guid.newGuid()}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "2px 8px",
                                                    borderRadius: "4px",
                                                    minWidth: "28px",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "10px",
                                                    color: (isSelected ? "#74B100" : "#C1C1C1"),
                                                    border: (isSelected ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (isSelected ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={isSelected}
                                                disabled={searchPending}
                                                onClick={() => {
                                                    if (isSelected) {
                                                        setSelectedProviders(prev => [...prev.filter(x => x != provider)]);
                                                    } else {
                                                        setSelectedProviders(prev => [...prev, provider]);
                                                    }
                                                }}
                                            >
                                                {
                                                    provider
                                                }
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>

            {/*<FlightListComponent*/}
            {/*    searchPending={searchPending}*/}
            {/*    searchNotFound={searchNotFound}*/}
            {/*    variants={variants}*/}
            {/*    searchResponse={searchResponse}*/}
            {/*    selectVariantHandler={selectVariant}*/}
            {/*/>*/}

            <div className="trip-page-search-results">

                {
                    (!searchPending && !searchNotFound && variants.length == 0) && (
                        <div className="trip-page-no-results-message">
                            <div className="trip-page-no-results-message-icon">
                                <ScheduleIcon
                                    width="50px"
                                    height="50px"
                                    style={{
                                        "path": {
                                            fill: "rgb(100 100 100)"
                                        }
                                    }}
                                />
                            </div>
                            <div className="trip-page-no-results-message-caption">
                                Список пуст
                            </div>
                            <div className="trip-page-no-results-message-content" style={{minWidth: "240px"}}>
                                Воспользуйтесь формой поиска чтобы найти перелеты по заданным критериям.
                            </div>
                        </div>
                    )
                }

                {
                    searchPending && (
                        <div className="trip-page-no-results-message">
                            <div className="trip-page-no-results-message-icon">
                                <ScheduleIcon
                                    width="50px"
                                    height="50px"
                                    style={{
                                        "path": {
                                            fill: "rgb(100 100 100)"
                                        }
                                    }}
                                />
                                <CircleSpinnerIcon
                                    width="130px"
                                    height="130px"
                                    style={{
                                        "svg": {
                                            position: "absolute"
                                        },
                                        "circle": {
                                            stroke: "rgb(210 210 210)",
                                            strokeWidth: 1
                                        }
                                    }}
                                />
                            </div>
                            <div className="trip-page-no-results-message-caption">
                                Ищем билеты...
                            </div>
                            <div className="trip-page-no-results-message-content" style={{minWidth: "300px"}}>
                                Приозводим запрос к системе для получения наиболее подходящих для Вас результатов.
                            </div>
                        </div>
                    )
                }

                {
                    (!searchPending && searchNotFound && variants.length == 0) && (
                        <div className="trip-page-no-results-message">
                            <div className="trip-page-no-results-message-icon">
                                <ScheduleIcon
                                    width="50px"
                                    height="50px"
                                    style={{
                                        "path": {
                                            fill: "rgb(100 100 100)"
                                        }
                                    }
                                    }
                                />
                            </div>
                            <div className="trip-page-no-results-message-caption">
                                Список пуст
                            </div>
                            <div className="trip-page-no-results-message-content">
                                Поиск не дал результатов. Измените параметры или повторите поиск.
                            </div>
                        </div>
                    )
                }

                {
                    variants.length > 0 && (
                        <div className="trip-page-search-results-tickets">
                            {
                                variants.map((variant, variantIndex) => {
                                    return (
                                        <React.Fragment key={variantIndex}>
                                            <FlightComponent
                                                variantInfo={variant.variant}
                                                selectedTariffIndex={variant.selectedTariffIndex}
                                                selectable={true}
                                                callbacks={{
                                                    select: () => {
                                                        selectVariant(variant)
                                                    },
                                                    changeTariff: (tariffIndex: number) => {
                                                        setVariants(prev => prev.map((v, i) => {
                                                            if(i == variantIndex) {
                                                                v.selectedTariffIndex = tariffIndex;
                                                            }
                                                            return v;
                                                        }))
                                                    }
                                                }}
                                                //selectVariant={selectVariantHandler}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    )
                }

            </div>

        </React.Fragment>
    );
}