import React from "react";
import "./style.scss";


export function RemoveConfirmationModal (
    props: {
        children?: any;
    }
) {

    const {
        children,
    } = props;

    return (
        <div className="dialog-component">
            {children}
        </div>
    );
}