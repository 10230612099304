import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ICompanyDTO} from "../../../../../../interfaces/ICompanyDTO";
import {IPassengerDTO} from "../../../../../../interfaces/IPassengerDTO";
import {PassengerTripElement} from "../../../../../../interfaces/Trip";
import {EmployeesService} from "../../../../../../services/EmployeesService";
import {MdButton} from "../../../../../../components/md-button";
import {MdList} from "../../../../../../components/md-list";
import {MdCheckbox} from "../../../../../../components/md-checkbox";
import {CircleSpinnerIcon} from "../../../../../../icons/CircleSpinnerIcon";
import {MagnifierIcon} from "../../../../../../icons/MagnifierIcon";
import {CalendarIcon} from "../../../../../../icons/CalendarIcon";
import {Guid} from "../../../../../../utils/utils";
import "./style.scss"
import {MdScrollViewer} from "../../../../../../components/md-scroll-viewer";


export function PassengersSelectionModal (
    props: {
        selectedCompany: ICompanyDTO | undefined,
        tripPassengers: PassengerTripElement[],
        selectPassengersHandler?: (passengers: IPassengerDTO[]) => Promise<void>;
    }
){

    const {
        selectedCompany,
        tripPassengers,
        selectPassengersHandler
    } = props;

    const {t} = useTranslation();

    const passengersService = EmployeesService();

    const [passengers, setPassengers] = useState<IPassengerDTO[]>([]);
    const [searchPending, setSearchPending] = useState(false);
    const [isSearchNotFound, setIsSearchNotFound] = useState(false);

    const [selectedPassengers, setSelectedPassengers] = useState<IPassengerDTO[]>([]);

    const [passengersSearchText, setPassengersSearchText] = useState("");
    const filteredPassengers =
        [
            ...(passengers
                .filter(x => !tripPassengers.some(y => x.paxCode == y.details.code))
                .filter(x => x.fio.toUpperCase().includes(passengersSearchText.toUpperCase()))),
            ...(passengers
                .filter(x => tripPassengers.some(y => x.paxCode == y.details.code))
                .filter(x => x.fio.toUpperCase().includes(passengersSearchText.toUpperCase()))),
        ];


    const onSearchBarKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.repeat) return;
        if (event.key === "Enter") {
            searchPassengers();
        }
    }

    const searchPassengers = async () => {
        setSearchPending(true);
        setIsSearchNotFound(false);
        setPassengers([]);
        setSelectedPassengers([]);

        const response = await passengersService.getPassengers(selectedCompany?.code ?? "");

        if (response.length > 0) {
            const passengerDTOs = response.sort((a, b) => (a.fio > b.fio) ? 1 : ((b.fio > a.fio) ? -1 : 0));
            setPassengers(passengerDTOs);
        } else {
            setIsSearchNotFound(true);
        }

        setSearchPending(false);
    };


    const onPassengerSelect = (passengerDTO: IPassengerDTO) => {
        setSelectedPassengers(prevState => [...prevState.filter(x => x.paxCode != passengerDTO.paxCode), passengerDTO]);
    }

    const onPassengerUnselect = (passengerDTO: IPassengerDTO) => {
        setSelectedPassengers(prevState => prevState.filter(x => x.paxCode != passengerDTO.paxCode));
    }


    const onSubmit = () => {
        if(selectPassengersHandler != null) {
            selectPassengersHandler(selectedPassengers);
        }
    }


    useEffect(() => {
        searchPassengers();
    }, []);


    return (
        <div className="passengers-selection-container">
            {
                !searchPending && (
                    <div className="passengers-selection-search-bar">
                        <input
                            type="text"
                            value={passengersSearchText}
                            placeholder={t("commonText.search")}
                            onChange={(e) => {
                                setPassengersSearchText(e.target.value);
                            }}
                            onKeyDown={onSearchBarKeyDown}/>
                        <MagnifierIcon
                            width="14px"
                            height="14px"
                            style={{
                                "svg": {
                                    position: "absolute",
                                    top: "50%",
                                    left: "12px",
                                    transform: "translate(0, -50%)"
                                },
                                "path": {
                                    fill: "#CCCCCC"
                                }
                            }}
                        />
                    </div>
                )
            }

            <div className="passengers-selection-search-results">
                {
                    searchPending && (
                        <div className="passengers-loading-results-message">
                            <CircleSpinnerIcon
                                width="14px"
                                height="14px"
                                style={{
                                    "circle": {
                                        stroke: "#A0A1A6"
                                    }
                                }}
                            />
                            {t("bookingPage.searching")}
                        </div>
                    )
                }

                {
                    filteredPassengers.length > 0 && (
                        <>
                            <div className="passengers-selection-search-results-title">
                                {t("bookingPage.searchResult")} ({filteredPassengers.length})
                            </div>

                            <MdScrollViewer>
                                {
                                    filteredPassengers.map(passenger => {
                                        const isSelected =
                                            selectedPassengers.some(x => x.paxCode == passenger.paxCode)
                                            || tripPassengers.some(x => x.details.code == passenger.paxCode);
                                        return (
                                            <button
                                                key={"passenger" + Guid.newGuid()}
                                                className={"passenger-item-button" + (isSelected ? " --active" : "")}
                                                disabled={tripPassengers.some(x => x.details.code == passenger.paxCode)}
                                                onClick={() => {
                                                    if (isSelected) {
                                                        onPassengerUnselect(passenger);
                                                    } else {
                                                        onPassengerSelect(passenger);
                                                    }
                                                }}>
                                                <div className="passenger-item-button-checkbox">
                                                    <MdCheckbox
                                                        type="filled"
                                                        checked={isSelected}
                                                        style={{
                                                            "input": {
                                                                width: "16px",
                                                                height: "16px"
                                                            },
                                                            "label": {
                                                                pointerEvents: "none"
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="passenger-item-button-details">
                                                    <div className="passenger-item-button-details-row">
                                                        <span className="passenger-fio">
                                                            {
                                                                passenger.fio
                                                                    .toLowerCase()
                                                                    .split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="passenger-item-button-details-row">
                                                        <CalendarIcon
                                                            width="10px"
                                                            height="10px"
                                                            style={{
                                                                "svg": {
                                                                    marginTop: "2px"
                                                                },
                                                                "path": {
                                                                    fill: "#8E8E90"
                                                                }
                                                            }}
                                                        />
                                                        <span
                                                            className="passenger-dob">{passenger.passportInfo.dob.split('T')[0]}</span>
                                                    </div>
                                                </div>
                                                <div className="passenger-item-button-icon">

                                                </div>
                                            </button>
                                        )
                                    })
                                }
                            </MdScrollViewer>
                        </>
                    )
                }

                {
                    (isSearchNotFound || (!searchPending && filteredPassengers.length === 0)) && (
                        <div className="passengers-selection-search-no-results-message">
                            {t("bookingPage.passengersNotFound")}
                        </div>
                    )
                }
            </div>

            <div className="passengers-selection-footer">
                <MdButton
                    type="submit"
                    style={{
                        "button": {
                            borderWidth: 0,
                            backgroundColor: "#3E5CB8",
                            color: "#FFFFFF",
                            borderRadius: "6px",
                            height: "35px",
                            padding: "5px 15px"
                        },
                        "ink": {
                            backgroundColor: "rgba(255,255,255,.5)"
                        }
                    }}
                    disabled={(selectedPassengers.length == 0)}
                    onClick={() => onSubmit()}
                >
                    {t("bookingPage.saveSelection")}
                </MdButton>
            </div>
        </div>
    );
}