import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const PressIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                d="M11,6V22H25V6ZM10,4A1,1,0,0,0,9,5V23a1,1,0,0,0,1,1H26a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1Zm3,5a1,1,0,0,1,1-1h8a1,1,0,0,1,0,2H14A1,1,0,0,1,13,9Zm1,3a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm-1,5a1,1,0,0,1,1-1h3a1,1,0,0,1,0,2H14A1,1,0,0,1,13,17ZM7,9A1,1,0,0,0,5,9V25a3,3,0,0,0,3,3H22a1,1,0,0,0,0-2H8a1,1,0,0,1-1-1Z"
                style={style["path"]}
            />
        </svg>
    )
}