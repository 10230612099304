import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const Airline_U6_Icon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
	        width={width}
            height={height}
	        viewBox="-297.5 435.5 30 30"
	        style={style["svg"]}
        >
            <rect x="-297.5" y="435.5" fill="#E30421" width="30" height="30"/>
            <path fill="#FFFFFF"
                  d="M-269.6,453.999c0,0-1.984,0-2.977,0c-2.106-2.404-5.926-5.064-10.486-7.544v0.001
                    c-0.226-0.123-0.458-0.243-0.687-0.365c0.229,0.121,0.46,0.242,0.687,0.364c1.644-1.051,3.158-1.739,4.309-1.739
                    c0.043,0,0.086,0.002,0.128,0.004c3.043,0.141,3.06,6.268,3.06,6.268s3.122-6.783,0.334-10.195h0.001
                    c-0.443-0.54-1.052-0.792-1.79-0.792c-2.087-0.001-5.206,2.013-8.52,5.173l0,0c-1.919-0.951-3.918-1.841-5.928-2.624
                    c0,0,2.271,1.435,5.067,3.468c-1.487,1.491-2.999,3.198-4.458,5.039c-0.553-2.322,2.391-5.707,2.391-5.707s-7.328,3.549-6.915,7.399
                    c0.1,0.915,0.684,1.649,1.646,2.217c-0.526,0.771-1.035,1.552-1.526,2.341c0,0,0.775-0.841,2.004-2.084
                    c1.711,0.814,4.646,1.778,7.782,1.778c2.797,0,5.691-0.885,9.013-1.487c0,0,0,0,0,0.002l0.001-0.002
                    c0.198,0.409,0.332,0.801,0.385,1.168c0.454,3.057-5.465,4.258-5.465,4.258s7.719,0.631,10.2-2.146
                    c0.942-1.056,0.75-2.792-0.242-3.792C-269.6,453.999-271.583,454.999-269.6,453.999z M-277.222,454.271
                    c-0.369,0.004-0.743,0.006-1.12,0.006c-4.711,0-9.933-0.41-11.816-2.072c1.749-1.632,3.746-3.373,5.664-4.766
                    C-281.708,449.573-278.792,452.083-277.222,454.271L-277.222,454.271c0.173,0.239,0.328,0.476,0.466,0.705
                    C-276.895,454.745-277.048,454.51-277.222,454.271z"/>
        </svg>
    )
}