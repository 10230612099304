import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const HandLuggageIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9 8.15137H7.1V20.001H16.9V8.15137ZM18.1 19.9998C20.2629 19.9467 22 18.1767 22 16.001L22 12.1514C22 9.97566 20.2629 8.20566 18.1 8.15259V19.9998ZM2.00001 12.1514C2.00001 9.97566 3.73708 8.20567 5.9 8.15259V19.9998C3.73707 19.9467 2 18.1767 2 16.001L2.00001 12.1514Z"
                style={style["path"]}
            />
            <path
                stroke="currentColor"
                strokeWidth="0.2"
                d="M8.50398 6.77629L8.49683 6.88298H8.60376H9.35206H9.44362L9.45167 6.79178C9.55782 5.5907 10.6003 4.894 11.8963 4.894C13.1918 4.894 14.2358 5.59061 14.3598 6.79324L14.369 6.88298H14.4592H15.2075H15.3157L15.3072 6.77519C15.2394 5.90783 14.8312 5.23633 14.2144 4.78375C13.5997 4.3327 12.7826 4.10209 11.8963 4.10209C11.0101 4.10209 10.1928 4.33268 9.58038 4.78386C8.96573 5.23666 8.56213 5.90856 8.50398 6.77629Z"
                style={style["path"]}
            />
        </svg>
    )
}