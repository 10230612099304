import React from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../redux/hooks";
import {ITrainTariffInfo, ITrainVariant} from "../../../../../../interfaces/ITrainVariant";
import {MdMenu} from "../../../../../../components/md-menu";
import {MdToggleButton} from "../../../../../../components/md-toggle-button";
import {MdScrollViewer} from "../../../../../../components/md-scroll-viewer";
import {TrainIcon3} from "../../../../../../icons/TrainIcon3";
import {CheckMarkIcon} from "../../../../../../icons/CheckMarkIcon";
import {RZD_Icon} from "../../../../../../icons/train-companies/RZD_Icon";
import {Railway_Default_Icon} from "../../../../../../icons/train-companies/Railway_Default_Icon";
import {ConditionerIcon} from "../../../../../../icons/train-services/ConditionerIcon";
import {BioToiletIcon} from "../../../../../../icons/train-services/BioToiletIcon";
import {BedSheetsIcon} from "../../../../../../icons/train-services/BedSheetsIcon";
import {AnimalIcon} from "../../../../../../icons/train-services/AnimalIcon";
import {WashBasinIcon} from "../../../../../../icons/train-services/WashBasinIcon";
import {ShowerIcon} from "../../../../../../icons/train-services/ShowerIcon";
import {TVIcon} from "../../../../../../icons/train-services/TVIcon";
import {FoodIcon} from "../../../../../../icons/train-services/FoodIcon";
import {HygienicKitIcon} from "../../../../../../icons/train-services/HygienicKitIcon";
import {PressIcon} from "../../../../../../icons/train-services/PressIcon";
import {InfotainmentServiceIcon} from "../../../../../../icons/train-services/InfotainmentServiceIcon";
import {VIPRoomIcon} from "../../../../../../icons/train-services/VIPRoomIcon";
import {TaxiIcon} from "../../../../../../icons/train-services/TaxiIcon";
import {PlaidIcon} from "../../../../../../icons/train-services/PlaidIcon";
import {Guid, Utils} from "../../../../../../utils/utils";
import "./style.scss";


enum TrainServices {
    Conditioner,
    BioToilet,
    WashBasin,
    Shower,
    TV,
    Feeding,
    HygienicKit,
    BedSheets,
    Press,
    InfotainmentService,
    Animals,
    VIPRoom,
    Taxi,
    Plaid
}

interface ITrainService {
    code: TrainServices,
    name: string,
    icon: JSX.Element
}


export interface ITrainComponentCallbacks {
    select?: () => void;
    changeTariff?: (index: number) => void;
}

export interface ITrainComponent {
    variantInfo: ITrainVariant;
    selectedTariffIndex: number;
    tariffSelection?: boolean;
    selectable?: boolean;
    disabled?: boolean;
    callbacks?: ITrainComponentCallbacks;
}


export function TrainComponent({
    variantInfo,
    selectedTariffIndex,
    tariffSelection,
    selectable,
    disabled,
    callbacks
}: ITrainComponent ) {

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);


    // TODO: change to trainCompanyCode
    const trainCarrierLogoMap = new Map<string, JSX.Element>([
        ['российские железные дороги', <RZD_Icon />],
    ]);


    const tickets = [variantInfo];
    const tariffs = tickets.map(x => x.categoryPrice).flat();
    const selectedTariff = tariffs[selectedTariffIndex];

    const trainCompanyNames = ["Российские железные дороги"];


    const getServices = (tariff: ITrainTariffInfo) => {
        const services: ITrainService[] = [
            { code: TrainServices.Conditioner,          name: "Кондиционер",         icon: <ConditionerIcon /> },
            { code: TrainServices.BioToilet,            name: "Биотуалет",           icon: <BioToiletIcon /> },
            { code: TrainServices.WashBasin,            name: "Умывальник",          icon: <WashBasinIcon /> },
            { code: TrainServices.Shower,               name: "Душ",                 icon: <ShowerIcon /> },
            { code: TrainServices.TV,                   name: "Телевизор",           icon: <TVIcon /> },
            { code: TrainServices.Feeding,              name: "Питание",             icon: <FoodIcon /> },
            { code: TrainServices.HygienicKit,          name: "Дорожный набор",      icon: <HygienicKitIcon /> },
            { code: TrainServices.BedSheets,            name: "Постельное белье",    icon: <BedSheetsIcon /> },
            { code: TrainServices.Press,                name: "Пресса",              icon: <PressIcon /> },
            { code: TrainServices.InfotainmentService,  name: "ИРС \"Попутчик\"",    icon: <InfotainmentServiceIcon /> },
            { code: TrainServices.Animals,              name: "Перевозка животных",  icon: <AnimalIcon /> },
            { code: TrainServices.VIPRoom,              name: "VIP комната",         icon: <VIPRoomIcon /> },
            { code: TrainServices.Taxi,                 name: "Такси",               icon: <TaxiIcon /> },
            { code: TrainServices.Plaid,                name: "Плед",                icon: <PlaidIcon /> },
        ]

        // https://www.rzd.ru/ru/9317
        const servicesMap = new Map<string, TrainServices[]>([
            // Вагоны класса «Люкс» (VIP)
            [
                '1А', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.WashBasin,
                    TrainServices.Shower,
                    TrainServices.TV,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals,
                    TrainServices.VIPRoom,
                    TrainServices.Taxi
                ]
            ],
            [
                '1И', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.WashBasin,
                    TrainServices.Shower,
                    TrainServices.TV,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals,
                    TrainServices.VIPRoom,
                    TrainServices.Taxi
                ]
            ],
            [
                '1М', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.WashBasin,
                    TrainServices.Shower,
                    TrainServices.TV,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals,
                    TrainServices.VIPRoom,
                    TrainServices.Taxi
                ]
            ],
            // Вагоны СВ или РИЦ (2-местные)
            [
                '1Б', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals,
                    TrainServices.VIPRoom
                ]
            ],
            [
                '1Э', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.WashBasin,
                    TrainServices.Shower,
                    TrainServices.TV,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals,
                    TrainServices.VIPRoom
                ]
            ],
            [
                '1Т', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.WashBasin,
                    TrainServices.Shower,
                    TrainServices.TV,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals,
                    TrainServices.VIPRoom
                ]
            ],
            [
                '1Ф', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals,
                    TrainServices.VIPRoom
                ]
            ],
            [
                '1Х', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals,
                    TrainServices.VIPRoom
                ]
            ],
            [
                '1У', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.BedSheets,
                    TrainServices.Animals
                ]
            ],
            [
                '1Л', [
                    TrainServices.BioToilet,
                    TrainServices.Conditioner,
                    TrainServices.BedSheets,
                    TrainServices.Animals
                ]
            ],
            [
                '1Д', [
                    TrainServices.BioToilet,
                    TrainServices.Conditioner,
                    TrainServices.BedSheets,
                    TrainServices.Animals,
                ]
            ],
            // Купейные вагоны
            [
                '2А', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals
                ]
            ],
            [
                '2Э', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals
                ]
            ],
            [
                '2Т', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals
                ]
            ],
            [
                '2Ф', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals
                ]
            ],
            [
                '2Х', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.HygienicKit,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals
                ]
            ],
            [
                '2Б', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.BedSheets,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals
                ]
            ],
            [
                '2К', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.BedSheets,
                    TrainServices.Animals
                ]
            ],
            [
                '2Ш', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.BedSheets,
                    TrainServices.Animals
                ]
            ],
            [
                '2У', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.BedSheets,
                    TrainServices.Animals
                ]
            ],
            [
                '2Ц', [
                    TrainServices.Conditioner,
                    TrainServices.BedSheets,
                    TrainServices.Press,
                    TrainServices.BedSheets,
                    TrainServices.InfotainmentService,
                    TrainServices.Animals
                ]
            ],
            [
                '2Н', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.BedSheets,
                    TrainServices.Animals
                ]
            ],
            [
                '2Л', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.BedSheets,
                    TrainServices.Animals
                ]
            ],
            [
                '2Д', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.BedSheets,
                    TrainServices.Animals
                ]
            ],
            // Плацкартные вагоны
            [
                '3Э', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '3Т', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '3Д', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '3У', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '3Л', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '3Б', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '3П', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            // Вагоны с местами для сидения
            [
                '1Р', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.Press,
                    TrainServices.Plaid,
                    TrainServices.Animals,
                    TrainServices.InfotainmentService,
                    TrainServices.VIPRoom
                ]
            ],
            [
                '1В', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.Press,
                    TrainServices.Plaid,
                    TrainServices.Animals,
                    TrainServices.InfotainmentService
                ]
            ],
            [
                '1С', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            // [ // Невский Экспресс
            //     '2Р', [
            //         TrainServices.Conditioner,
            //         TrainServices.BioToilet,
            //         TrainServices.Feeding,
            //         TrainServices.HygienicKit,
            //         TrainServices.Press,
            //         TrainServices.Plaid,
            //         TrainServices.Animals
            //     ]
            // ],
            // [ // Невский Экспресс
            //     '2Ю', [
            //         TrainServices.Conditioner,
            //         TrainServices.BioToilet,
            //         TrainServices.Feeding,
            //         TrainServices.HygienicKit,
            //         TrainServices.Press,
            //         TrainServices.Plaid,
            //         TrainServices.Animals
            //     ]
            // ],
            // [ // Аврора
            //     '2Р', [
            //         TrainServices.Conditioner,
            //         TrainServices.BioToilet,
            //         TrainServices.HygienicKit,
            //         TrainServices.Press,
            //         TrainServices.Animals,
            //         TrainServices.InfotainmentService
            //     ]
            // ],
            // [ // Аврора
            //     '2Ю', [
            //         TrainServices.Conditioner,
            //         TrainServices.BioToilet,
            //         TrainServices.HygienicKit,
            //         TrainServices.Press,
            //         TrainServices.Animals,
            //         TrainServices.InfotainmentService
            //     ]
            // ],
            [
                '2Р', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Press,
                    TrainServices.Animals,
                    TrainServices.InfotainmentService
                ]
            ],
            [
                '2Ю', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Press,
                    TrainServices.Animals,
                    TrainServices.InfotainmentService
                ]
            ],
            [
                '2Я', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Feeding,
                    TrainServices.HygienicKit,
                    TrainServices.Press,
                    TrainServices.Plaid,
                    TrainServices.Animals,
                    TrainServices.InfotainmentService
                ]
            ],
            [
                '2С', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '2Ж', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '2В', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '2Е', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '3Ж', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '3С', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            // Общие вагоны
            [
                '3О', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
            [
                '3Р', [
                    TrainServices.Conditioner,
                    TrainServices.BioToilet,
                    TrainServices.Animals
                ]
            ],
        ]);

        const serviceCode = tariff.foundInfo.substring(0, selectedTariff.foundInfo.indexOf("/"));

        return servicesMap.get(serviceCode)?.flatMap(service => {
           return  services.find(x => x.code === service) ?? [];
        }) ?? [];
    }


    const roadmap = tickets.map((ticket, ticketIndex) => {

        const stationCode0 = ticket.codeStationFrom;
        const stationCity0 = ticket.cityFrom.replace(/ *\([^)]*\) */g, "");
        const stationTime0 = Utils.parseTrainDateTime(ticket.departureAt, ticket.departureShortOn);

        const stationCode1 = ticket.codeStationTo;
        const stationCity1 = ticket.cityTo.replace(/ *\([^)]*\) */g, "");
        const stationTime1 = Utils.parseTrainDateTime(ticket.arriveShortOn, ticket.arriveAtn);

        const trainNumber = ticket.trainNum;


        return (
            <div key={"train-roadmap-item" + Guid.newGuid()}>

                <div className="train-roadmap-item">
                    <div className="train-roadmap-item-start-node">
                        <button className="train-roadmap-item-start-node-button --train-begin">
                        </button>
                        <div className="train-roadmap-item-start-node-location">
                            <span>
                                {
                                    stationCode0
                                }
                            </span>
                            <span>
                                {
                                    stationCity0
                                }
                            </span>
                        </div>
                    </div>

                    <div className="train-roadmap-item-content">
                        <div className="train-roadmap-railway-info">
                            <div className="train-roadmap-railway-logo">
                                {
                                    (() => {
                                        const logo = trainCarrierLogoMap.get(trainCompanyNames[0].trim().toLowerCase());
                                        return logo ?? <Railway_Default_Icon />;
                                    })()
                                }
                            </div>
                            <div className="train-roadmap-railway-details">
                                <span
                                    className="train-roadmap-railway-name">
                                    {
                                        trainCompanyNames[0]
                                    }
                                </span>
                                <span className="train-roadmap-train-number">
                                    {
                                        trainNumber
                                    }
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="train-roadmap-item-end-node">
                        <button className="train-roadmap-item-end-node-button --train-end">
                        </button>
                        <div className="train-roadmap-item-end-node-location">
                            <span>
                                {
                                    stationCode1
                                }
                            </span>
                            <span>
                                {
                                    stationCity1
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    const tariffOptions = tickets[tickets.length - 1].categoryPrice.map((tariff, tariffIndex) => {
        return (
            <React.Fragment
                key={"tariff" + Guid.newGuid()}>
                <MdToggleButton
                    buttonStyle={{
                        minInlineSize: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "4px",
                        padding: "10px",
                        backgroundColor: (selectedTariffIndex == tariffIndex ? "rgba(116, 177, 0, 0.05)" : "rgba(204, 204, 204, 0.1)"),
                        border: (selectedTariffIndex == tariffIndex ? "1px solid #74B100" : "1px solid #CCCCCC"),
                    }}
                    inkStyle={{
                        backgroundColor: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                        opacity: 0.1
                    }}
                    checked={selectedTariffIndex == tariffIndex}
                    onClick={() => {
                        if (selectedTariffIndex == tariffIndex) {
                            return;
                        }
                        if (callbacks?.changeTariff != null) {
                            callbacks.changeTariff(tariffIndex);
                        }
                    }}
                >

                    <div className="tariff-option">

                        <div
                            className="tariff-option-name">
                            {
                                tariff.categoryName
                            }
                        </div>

                        <div className="tariff-services">
                            {
                                getServices(tariff).map(service => {
                                    return (
                                        <div className="tariff-service --allow" title={service.name}>
                                            {
                                                service.icon
                                            }
                                            <span>
                                                {
                                                    service.name
                                                }
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div
                            className="tariff-option-price">
                            {
                                (tariff.price + tariff.sborTts).toLocaleString() + " ₽"
                            }
                        </div>


                    </div>

                </MdToggleButton>
            </React.Fragment>
        )
    })


    const select = () => {
        if (callbacks?.select != null) {
            callbacks.select();
        }
    }


    return (
        <div className="train-component">

            <div className="train-component-details">

                {
                    tickets.map((direction, directionIndex) => {

                        const stationCode0 = directionIndex == 0 ? direction.stationFrom : direction.stationTo;
                        const stationCode1 = directionIndex == 0 ? direction.stationTo : direction.stationFrom;

                        const stationCity0 = directionIndex == 0 ? direction.cityFrom : direction.cityTo;
                        const stationCity1 = directionIndex == 0 ? direction.cityTo : direction.cityFrom;

                        const stationTime0 = directionIndex == 0 ? Utils.parseTrainDateTime(direction.departureAt, direction.departureShortOn) : Utils.parseTrainDateTime(direction.arriveShortOn, direction.arriveAtn);
                        const stationTime1 = directionIndex == 0 ? Utils.parseTrainDateTime(direction.arriveShortOn, direction.arriveAtn) : Utils.parseTrainDateTime(direction.departureAt, direction.departureShortOn);

                        const trainNumber = direction.trainNum;


                        return (
                            <React.Fragment>

                                {
                                    directionIndex == 0 && (
                                        <div className="train-component-details-header">

                                            <div className="train-component-railway-company-list">
                                                <MdMenu
                                                    behaviour='none'
                                                    header={
                                                        <div className="train-component-railway-company-list-header">
                                                            <div className="train-component-railway-company-list-header-icons">
                                                                {
                                                                    trainCompanyNames.slice().reverse().map(trainCompany => {
                                                                        const logo = trainCarrierLogoMap.get(trainCompany.trim().toLowerCase());
                                                                        return logo ?? <Railway_Default_Icon />;
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="train-component-railway-company-list-header-names">
                                                                {
                                                                    trainCompanyNames.join(' • ')
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                >

                                                    <MdScrollViewer
                                                        style={{
                                                            "content": {
                                                                maxWidth: "378px",
                                                                //maxHeight: "150px"
                                                            }
                                                        }}
                                                    >
                                                        <div className="train-component-roadmap">
                                                            {
                                                                roadmap
                                                            }
                                                        </div>
                                                    </MdScrollViewer>

                                                </MdMenu>
                                            </div>

                                            <div className="train-component-tariff-info">
                                                <span className="tariff-info-description">
                                                    {
                                                        selectedTariff.categoryName
                                                    }
                                                </span>
                                                {
                                                    authState.isSupportUser && (
                                                        selectedTariff?.foundInfo
                                                    )
                                                }
                                            </div>

                                            <div className="train-component-tariff-list">
                                                <MdMenu
                                                    header={
                                                        <div className="train-component-tariff-list-header">
                                                            <div className="train-component-tariff-list-header-icons">
                                                                {
                                                                    getServices(selectedTariff).map(service => {
                                                                        return (
                                                                            <div className="tariff-icon" title={service.name}>
                                                                                {
                                                                                    service.icon
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="train-component-tariff-list-header-price">
                                                                {
                                                                    (selectedTariff.price + selectedTariff.sborTts).toLocaleString() + " ₽"
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <MdScrollViewer
                                                        style={{
                                                            "content": {
                                                                maxWidth: "380px"
                                                            }
                                                        }}
                                                    >
                                                        <div className="tariff-options">
                                                            {
                                                                tariffSelection !== false
                                                                    ? (
                                                                        tariffOptions
                                                                    )
                                                                    : (
                                                                        tariffOptions.filter((x, i) => i == selectedTariffIndex)
                                                                    )
                                                            }
                                                        </div>
                                                    </MdScrollViewer>
                                                </MdMenu>
                                            </div>

                                        </div>
                                    )
                                }

                                <div className="train-component-details-content">

                                    <div className="train-path-details">
                                        <div className="train-track">
                                            <hr style={{marginTop: "16px "}}/>
                                            <div className="train-track-icon">
                                                <TrainIcon3
                                                    width="18px"
                                                    height="18px"
                                                    style={{
                                                        "svg": {
                                                            transform: (directionIndex == 0 ? "scaleX(1)" : "scaleX(-1)")
                                                        },
                                                        "path": {
                                                            fill: "#3E5CB8"
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <hr style={{marginTop: "16px "}}/>
                                        </div>
                                        <div className="train-number">
                                            {
                                                trainNumber
                                            }
                                        </div>
                                    </div>

                                    <div className="station-details">

                                        <div className="station-details-0">
                                            <div className="station-details-0-code">
                                                {
                                                    stationCode0
                                                }
                                            </div>
                                            <div className="station-details-0-city">
                                                {
                                                    stationCity0.match(/([^()])+|(\(.*\))/g)?.shift()
                                                }
                                            </div>
                                            <div className="station-details-0-time">
                                                {
                                                    stationTime0.getHours().toString().padStart(2, "0") + ":" + stationTime0.getMinutes().toString().padStart(2, "0")
                                                    + ", "
                                                    + stationTime0.toLocaleString('default', {month: 'short'}).replace(".", "")
                                                    + " "
                                                    + stationTime0.getDate().toString().padStart(2, "0")
                                                }
                                            </div>
                                        </div>

                                        <div className="station-details-gap">

                                        </div>

                                        <div className="station-details-1">
                                            <div className="station-details-1-code">
                                                {
                                                    stationCode1
                                                }
                                            </div>
                                            <div className="station-details-1-city">
                                                {
                                                    stationCity1.match(/([^()])+|(\(.*\))/g)?.shift()
                                                }
                                            </div>
                                            <div className="station-details-1-time">
                                                {
                                                    stationTime1.getHours().toString().padStart(2, "0") + ":" + stationTime1.getMinutes().toString().padStart(2, "0")
                                                    + ", "
                                                    + stationTime1.toLocaleString('default', {month: 'short'}).replace(".", "")
                                                    + " "
                                                    + stationTime1.getDate().toString().padStart(2, "0")
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </React.Fragment>
                        )
                    })
                }

            </div>

            {
                selectable === true && (
                    <div className="train-component-actions-layer">
                        <div className="train-component-actions" onClick={() => select()}>
                            <CheckMarkIcon/>
                        </div>
                    </div>
                )
            }

        </div>
    );
}