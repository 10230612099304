import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const HygienicKitIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                d="M8,5H7V26a1,1,0,0,0,2,0V15h4V5H8Zm1,8h2V7H9Zm8-1V25h6V12H17Zm0-2H16a1,1,0,0,0-1,1V26a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V11a1,1,0,0,0-1-1H23V6a1,1,0,0,0-1-1H18a1,1,0,0,0-1,1Zm2,0h2V7H19Z"
                style={style["path"]}
            />
        </svg>
    )
}