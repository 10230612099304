// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.accordion {
  background-color: #f2ffea;
  color: #444;
  cursor: alias;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border: solid 3px #f2ffea;
  transition: 1.5s;
}
.accordion:active::after {
  content: "−";
}
.accordion:hover {
  border: solid 3px #6ea64a;
  border-radius: 15px;
}

.active {
  background-color: white;
}

.accordion:before {
  content: "+";
  color: #777;
  font-weight: bold;
  float: right;
  left: 95%;
  margin-left: 5px;
  position: absolute;
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}`, "",{"version":3,"sources":["webpack://./src/pages/cabinetPages/documentsPage/documentFilesComponent/style.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,yBAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,yBAAA;EACA,gBAAA;AAEF;AAAE;EACE,YAAA;AAEJ;AACE;EACE,yBAAA;EACA,mBAAA;AACJ;;AAGA;EACE,uBAAA;AAAF;;AAGA;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;EACA,SAAA;EACA,gBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,eAAA;EACA,uBAAA;EACA,aAAA;EACA,gBAAA;EACA,oCAAA;AAAF","sourcesContent":[".accordion {\r\n  background-color: #f2ffea;\r\n  color: #444;\r\n  cursor: alias;\r\n  padding: 18px;\r\n  width: 100%;\r\n  border: none;\r\n  text-align: left;\r\n  outline: none;\r\n  font-size: 15px;\r\n  border: solid 3px #f2ffea;\r\n  transition: 1.5s;\r\n\r\n  &:active::after {\r\n    content: \"\\2212\";\r\n  }\r\n\r\n  &:hover {\r\n    border: solid 3px #6ea64a;\r\n    border-radius: 15px;\r\n  }\r\n}\r\n\r\n.active {\r\n  background-color: white;\r\n}\r\n\r\n.accordion:before {\r\n  content: \"\\002B\";\r\n  color: #777;\r\n  font-weight: bold;\r\n  float: right;\r\n  left: 95%;\r\n  margin-left: 5px;\r\n  position: absolute;\r\n}\r\n\r\n.panel {\r\n  padding: 0 18px;\r\n  background-color: white;\r\n  max-height: 0;\r\n  overflow: hidden;\r\n  transition: max-height 0.2s ease-out;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
