// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-orders-message {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.no-orders-message .no-orders-message-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-image: linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243) 100%);
}
.no-orders-message .no-orders-message-caption {
  font-size: 14px;
  font-weight: 500;
  color: #27292A;
}
.no-orders-message .no-orders-message-content {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 300;
  color: #3C4145;
  max-width: 200px;
  text-align: center;
}
.no-orders-message .no-orders-message-button {
  margin-top: 40px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/cabinetPages/orderPage/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EAGA,yHAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAAJ;AAGE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIE;EACE,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ","sourcesContent":[".no-orders-message {\r\n  position: relative;\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  margin-top: 100px;\r\n\r\n  .no-orders-message-icon {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 120px;\r\n    height: 120px;\r\n    border-radius: 60px;\r\n    background-image: -moz-linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);\r\n    background-image: -webkit-linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);\r\n    background-image: linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);\r\n  }\r\n\r\n  .no-orders-message-caption {\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n    color: #27292A;\r\n  }\r\n\r\n  .no-orders-message-content {\r\n    margin-top: 10px;\r\n    font-size: 12px;\r\n    font-weight: 300;\r\n    color: #3C4145;\r\n    max-width: 200px;\r\n    text-align: center;\r\n  }\r\n\r\n  .no-orders-message-button {\r\n    margin-top: 40px;\r\n    min-height: 35px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
