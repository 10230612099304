import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const LuggageIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.3806 5H7.61935C6.19677 5 5 6.15556 5 7.57778V17.2779C5 18.2159 5.79582 19.3 6.64812 19.8199C6.87124 19.956 7.13448 20.0001 7.39583 20.0001H8.5H10H11H13.5H14L15.5 20.0001H16.5H16.6125C16.8685 20.0001 17.1262 19.958 17.3461 19.8271C18.2144 19.3101 19 18.2203 19 17.2779V7.57778C19 6.15556 17.8032 5 16.3806 5ZM8.47742 1.64444C8.47742 1.28889 8.77097 1 9.13226 1H14.8903C15.2516 1 15.5452 1.28889 15.5452 1.64444V4H14.5V2.00006H9.5V3.00006V3.96117V4H8.47742V1.64444ZM10 8.5C10 8.22386 10.2239 8 10.5 8C10.7762 8 11 8.22386 11 8.5V16.5C11 16.7761 10.7762 17 10.5 17C10.2239 17 10 16.7761 10 16.5V8.5ZM13.5 8C13.2239 8 13 8.22386 13 8.5V16.5C13 16.7761 13.2239 17 13.5 17C13.7761 17 14 16.7761 14 16.5V8.5C14 8.22386 13.7761 8 13.5 8Z"
                style={style["path"]}
            />
        </svg>
    )
}