import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {Gender} from "../../../../enums/Gender";
import {IPassengerDTO} from "../../../../interfaces/IPassengerDTO";
import {PassengerDocumentType} from "../../../../enums/PassengerDocumentType";
import {ICountryDTO} from "../../../../interfaces/ICountryDTO";
import {IPassengerDocumentDTO, IPassengerDocumentResponse} from "../../../../interfaces/IPassengerDocumentDTO";
import {IPassengerBonusDTO} from "../../../../interfaces/IPassengerBonusDTO";
import {ICreatePassengerRequest} from "../../../../api/request/ICreatePassengerRequest";
import {IUpdatePassengerDocumentRequest} from "../../../../api/request/IUpdatePassengerDocumentRequest";
import {ICreatePassengerDocumentRequest} from "../../../../api/request/ICreatePassengerDocumentRequest";
import {ICreatePassengerBonusRequest} from "../../../../api/request/ICreatePassengerBonusRequest";
import {IUpdatePassengerBonusRequest} from "../../../../api/request/IUpdatePassengerBonusRequest";
import {EmployeesService} from "../../../../services/EmployeesService";
import {RefsService} from "../../../../services/RefsService";
import {DateService} from "../../../../services/DateService";
import {MdScrollViewer} from "../../../../components/md-scroll-viewer";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import {MdButton} from "../../../../components/md-button";
import {MdSlider} from "../../../../components/md-slider";
import {MdSelect} from "../../../../components/md-select";
import {CrossIcon} from "../../../../icons/CrossIcon";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {NotFoundIcon} from "../../../../icons/NotFoundIcon";
import {SearchIcon2} from "../../../../icons/SearchIcon2";
import {WarningIcon} from "../../../../icons/WarningIcon";
import {Guid} from "../../../../utils/utils";
import "./style.scss";


interface IEmployeeDocument {
    id            : string;
    details       : IPassengerDocumentResponse;
    initialState? : IPassengerDocumentResponse;
}

interface IEmployeeBonus {
    id            : string;
    details       : IPassengerBonusDTO;
    initialState? : IPassengerBonusDTO;
}


export function EmployeeCreateModal (
    props: {
        onSubmit: (result: boolean) => void;
    }
){

    const { onSubmit } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const refsService = RefsService();
    const employeesService = EmployeesService();

    const {dateToStringForDatePicker} = DateService();

    //const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(axios.CancelToken.source());

    const [activeSlide, setActiveSlide] = useState(0);

    const [countries, setCountries] = useState<ICountryDTO[]>([]);

    const [searchCountryText, setSearchCountryText] = useState<string>("");
    const [filteredCountries, setFilteredCountries] = useState<ICountryDTO[]>([]);

    const [employee, setEmployee] = useState<IPassengerDTO|undefined>(undefined);

    const [code,              setCode]              = useState<string>("");
    const [firstName,         setFirstName]         = useState<string>("");
    const [lastName,          setLastName]          = useState<string>("");
    const [patronymic,        setPatronymic]        = useState<string>("");
    const [firstNameEng,      setFirstNameEng]      = useState<string>("");
    const [lastNameEng,       setLastNameEng]       = useState<string>("");
    const [patronymicEng,     setPatronymicEng]     = useState<string>("");
    const [dateOfBurn,        setDateOfBurn]        = useState<string>(dateToStringForDatePicker(new Date()));
    const [phone,             setPhone]             = useState<string>("");
    const [email,             setEmail]             = useState<string>("");
    const [countryCode,       setCountryCode]       = useState<string>("");
    const [employeeDocuments, setEmployeeDocuments] = useState<IEmployeeDocument[]>([]);
    const [employeeBonuses,   setEmployeeBonuses]   = useState<IEmployeeBonus[]>([]);
    const [gender,            setGender]            = useState<boolean>(true);

    const [loadCountriesPending,   setLoadCountriesPending] = useState(false);
    const [loadCountriesError,     setLoadCountriesError]   = useState(false);
    const [createEmployeePending,  setCreateEmployeePending]  = useState(false);
    const [createEmployeeError,    setCreateEmployeeError]    = useState(false);
    const [saveEmployeePending,    setSaveEmployeePending]  = useState(false);
    const [saveEmployeeError,      setSaveEmployeeError]    = useState(false);
    const [bindEmployeePending,    setBindEmployeePending]  = useState(false);
    const [bindEmployeeError,      setBindEmployeeError]    = useState(false);
    const [saveDocumentsPending,   setSaveDocumentsPending] = useState(false);
    const [saveDocumentsError,     setSaveDocumentsError]   = useState(false);
    const [saveBonusesPending,     setSaveBonusesPending]   = useState(false);
    const [saveBonusesError,       setSaveBonusesError]     = useState(false);


    const loadCountries = async () => {
        setLoadCountriesPending(true);

        const response = await refsService.getAllCountries();

        if(response !== null) {
            setLoadCountriesError(false);
            setLoadCountriesPending(false);
            setCountries(response);
            setFilteredCountries(response);
        }
        else {
            setLoadCountriesError(true);
            setLoadCountriesPending(false);
        }
    }


    const changeSearchCountryText = async (text: string) => {
        if(text.trim().length > 0) {
            const countriesResponse = await refsService.getCountries(text);
            setFilteredCountries(countriesResponse);
        }
        else {
            const countriesResponse = await refsService.getAllCountries();
            setFilteredCountries(countriesResponse ?? []);
        }
    }


    const hasDetailsChanges = () => {
        return employee !== undefined && (
            employee.first !== firstName
            || employee.last !== lastName
            || employee.middle !== patronymic
            || employee.firstLatinShot !== firstNameEng
            || employee.lastLatin !== lastNameEng
            //|| employee.middleLatinShot !== patronymicEng
            || employee.birthday !== dateOfBurn
            || employee.countryCode !== countryCode
            || employee.phoneNumber !== phone
            || employee.email !== email
            || employee.gender !== (gender ? "муж" : "жен")
        )

    }

    const createEmployee = async () => {
        setCreateEmployeePending(true);

        const country = countries.find(x => x.countryAlpha2 == countryCode);

        const request = {
            firstName: firstName,
            lastName: lastName,
            patronymic: patronymic,
            engFirstName: firstNameEng,
            engLastName: lastNameEng,
            //engPatronymic: patronymicEng,
            dob: dateOfBurn,
            gender: gender ? Gender.Male : Gender.Female,
            email: email,
            phoneNumber: phone,
            countryId: country?.countryId,
            countryCode: country?.countryAlpha2
        } as ICreatePassengerRequest;

        const response = await employeesService.createPassenger(request);

        if(response !== null) {
            setCode(response.code ?? "");

            const details = {
                first          : response.firstName,
                last           : response.lastName,
                middle         : response.patronymic,
                firstLatinShot : response.engFirstName ?? "",
                lastLatin      : response.engLastName ?? "",
                //middleLatinShot: response.engPatronymic,
                // TODO: check date format at this point
                birthday       : response.dob?.split('T')[0] ?? "",
                countryCode    : response.countryCode,
                phoneNumber    : response.phoneNumber,
                email          : response.email,
                gender         : response.paxGender == Gender.Male ? "муж" : "жен"
            } as IPassengerDTO;

            setEmployee(details);

            setFirstName(response.firstName);
            setLastName(response.lastName);
            setPatronymic(response.patronymic);
            setFirstNameEng(response.engFirstName ?? "");
            setLastNameEng(response.engLastName ?? "");
            //setPatronymicEng(response.middleLatinShot);
            setDateOfBurn(response.dob?.split('T')[0] ?? "");
            setCountryCode(response.countryCode);
            setPhone(response.phoneNumber);
            setEmail(response.email);
            setGender(response.paxGender == Gender.Male);

            await employeesService.bindPassengerToCompany(authState.currentClientCode, response.code ?? "");

            setCreateEmployeeError(false);

            setActiveSlide(1);
        }
        else {
            setCreateEmployeeError(true);
        }

        setCreateEmployeePending(false);
    }

    const saveEmployee = async () => {
        setSaveEmployeePending(true);

        const country = countries.find(x => x.countryAlpha2 == countryCode);

        const request = {
            paxCode: code,
            firstName: firstName,
            lastName: lastName,
            patronymic: patronymic,
            engFirstName: firstNameEng,
            engLastName: lastNameEng,
            //engPatronymic: patronymicEng,
            dob: dateOfBurn,
            gender: gender ? Gender.Male : Gender.Female,
            email: email,
            phoneNumber: phone,
            countryId: country?.countryId,
            countryCode: country?.countryAlpha2
        } as ICreatePassengerRequest;

        const response = await employeesService.updatePassenger(request);

        if (response !== null) {

            setEmployee(prev => {
                if (prev !== undefined) {
                    return {
                        ...prev,
                        first          : response.firstName,
                        last           : response.lastName,
                        middle         : response.patronymic,
                        firstLatinShot : response.engFirstName ?? "",
                        lastLatin      : response.engLastName ?? "",
                        //middleLatinShot: response.engPatronymic,
                        // TODO: check date format at this point
                        birthday       : response.dob?.split('T')[0] ?? "",
                        countryCode    : response.countryCode,
                        phoneNumber    : response.phoneNumber,
                        email          : response.email,
                        gender         : response.paxGender == Gender.Male ? "муж" : "жен"
                    }
                }
                return undefined;
            });

            setSaveEmployeeError(false);
        }
        else {
            setSaveEmployeeError(true);
        }

        setSaveEmployeePending(false);
    }


    const addDocument = () => {
        setEmployeeDocuments(prev => [
            ...prev,
            {
                id: Guid.newGuid(),
                details: {
                    id: "",
                    paxCode: "",
                    numberDoc: "",
                    hasCopy: false,
                    type: PassengerDocumentType.RussianPassport,
                    // TODO: set initial value of expired date
                    expiredDate: ""
                },
                initialState: undefined
            }
        ]);
    }

    const changeDocument = (id: string, details: IPassengerDocumentResponse) => {
        setEmployeeDocuments(prev => prev.map(document => {
            if(document.id === id) {
                document.details = details;
            }
            return document;
        }));
    }

    const removeDocument = (id: string) => {
        setEmployeeDocuments(prev => prev.filter(x => x.id !== id));
    }

    const hasDocumentsChanges = () => {
        return (
            employeeDocuments.some(x =>
                    x.initialState === undefined
                    || (
                        x.details.type !== x.initialState.type
                        || x.details.numberDoc !== x.initialState.numberDoc
                        || x.details.expiredDate !== x.initialState.expiredDate
                    )
            )
        )
    }

    const saveDocuments = async () => {
        setSaveDocumentsPending(true);

        const documentsToUpdate = employeeDocuments.filter(document => {
            return (
                document.initialState !== undefined && (
                    document.details.type !== document.initialState.type
                    || document.details.numberDoc !== document.initialState.numberDoc
                    || document.details.expiredDate !== document.initialState.expiredDate
                )
            )
        });

        const documentsToCreate = employeeDocuments.filter(document =>
            document.initialState === undefined
        );

        let hasErrors = false;

        for (const document of documentsToUpdate) {
            const request = {
                docCode: document.details.id,
                numberDoc: document.details.numberDoc,
                hasCopy: document.details.hasCopy,
                paxDocType: document.details.type,
                expiredDate: (document.details.type !== PassengerDocumentType.BirthCertificate ? document.details.expiredDate : undefined)
            } as IUpdatePassengerDocumentRequest;

            const response = await employeesService.updatePassengerDocument(request);

            if (response !== null) {
                const details = {
                    id           : response.id,
                    // TODO: paxCode and parentCode is the same?????
                    paxCode      : response.parentCode,
                    // TODO: numberDoc and code is the same?????
                    numberDoc    : response.code,
                    hasCopy      : response.hasCopy,
                    type         : response.docType,
                    expiredDate  : response.expiredDate
                } as IPassengerDocumentResponse;
                setEmployeeDocuments(prev => {
                    return prev.map(x => {
                        if(x.id === document.id) {
                            return {
                                id: document.id,
                                details      : Object.assign({}, details),
                                initialState : Object.assign({}, details)
                            } as IEmployeeDocument;
                        }
                        return x;
                    })
                });
            } else {
                hasErrors = true;
            }
        }

        for (const document of documentsToCreate) {
            const request = {
                paxCode: code,
                numberDoc: document.details.numberDoc,
                hasCopy: document.details.hasCopy,
                paxDocType: document.details.type,
                expiredDate: (document.details.type !== PassengerDocumentType.BirthCertificate ? document.details.expiredDate : undefined)
            } as ICreatePassengerDocumentRequest;

            const response = await employeesService.createPassengerDocument(request);

            if (response !== null) {
                const details = {
                    id           : response.id,
                    // TODO: paxCode and parentCode is the same?????
                    paxCode      : response.parentCode,
                    // TODO: numberDoc and code is the same?????
                    numberDoc    : response.code,
                    hasCopy      : response.hasCopy,
                    type         : response.docType,
                    expiredDate  : response.expiredDate
                } as IPassengerDocumentResponse;
                setEmployeeDocuments(prev => {
                    return prev.map(x => {
                        if(x.id === document.id) {
                            return {
                                id: document.id,
                                details      : Object.assign({}, details),
                                initialState : Object.assign({}, details)
                            } as IEmployeeDocument;
                        }
                        return x;
                    })
                });
            } else {
                hasErrors = true;
            }
        }

        setSaveDocumentsError(hasErrors);
        setSaveDocumentsPending(false);
    }


    const addBonus = () => {
        setEmployeeBonuses(prev => [
            ...prev,
            {
                id: Guid.newGuid(),
                details: {
                    id: "",
                    ak: "",
                    number: "",
                    fullName: "",
                    paxCode: code
                },
                initialState: undefined
            }
        ]);
    }

    const changeBonus = (id: string, details: IPassengerBonusDTO) => {
        setEmployeeBonuses(prev => prev.map(bonus => {
            if(bonus.id === id) {
                bonus.details = details;
            }
            return bonus;
        }))
    }

    const removeBonus = (id: string) => {
        setEmployeeBonuses(prev => prev.filter(x => x.id !== id));
    }

    const hasBonusesChanges = () => {
        return (
            employeeBonuses.some(x =>
                    x.initialState === undefined
                    || (
                        x.details.ak !== x.initialState.ak
                        || x.details.number !== x.initialState.number
                        //|| x.details.fullName !== x.initialState.fullName
                    )
            )
        )
    }

    const saveBonuses = async () => {
        setSaveBonusesPending(true);

        const bonusesToUpdate = employeeBonuses.filter(bonus => {
            return (
                bonus.initialState !== undefined && (
                    bonus.details.ak !== bonus.initialState.ak
                    || bonus.details.number !== bonus.initialState.number
                    //|| bonus.details.fullName !== bonus.initialState.fullName
                )
            )
        });

        const bonusesToCreate = employeeBonuses.filter(bonus =>
            bonus.initialState === undefined
        );

        let hasErrors = false;

        for (const bonus of bonusesToUpdate) {
            const request = {
                id: bonus.details.id,
                number: bonus.details.number,
                ak: bonus.details.ak
            } as IUpdatePassengerBonusRequest;

            const response = await employeesService.updatePassengerBonus(request);

            if (response !== null) {
                setEmployeeBonuses(prev => {
                    return prev.map(x => {
                        if(x.id === bonus.id) {
                            return {
                                id: bonus.id,
                                details      : Object.assign({}, response),
                                initialState : Object.assign({}, response)
                            } as IEmployeeBonus;
                        }
                        return x;
                    })
                });
            } else {
                hasErrors = true;
            }
        }

        for (const bonus of bonusesToCreate) {
            const request = {
                paxCode: code,
                number: bonus.details.number,
                ak: bonus.details.ak
            } as ICreatePassengerBonusRequest;

            const response = await employeesService.createPassengerBonus(request);

            if (response !== null) {
                setEmployeeBonuses(prev => {
                    return prev.map(x => {
                        if(x.id === bonus.id) {
                            return {
                                id: bonus.id,
                                details      : Object.assign({}, response),
                                initialState : Object.assign({}, response)
                            } as IEmployeeBonus;
                        }
                        return x;
                    })
                });
            } else {
                hasErrors = true;
            }
        }

        setSaveBonusesError(hasErrors);
        setSaveBonusesPending(false);
    }


    const submitHandler = async () => {

    }

    useEffect(() => {
        setSaveDocumentsError(false);
    }, [employeeDocuments]);

    useEffect(() => {
        setSaveBonusesError(false);
    }, [employeeBonuses]);


    useEffect(() => {
        loadCountries();
    }, []);


    return (
        <div className="employee-details-form" style={{
            maxWidth: "900px",
            maxHeight: "600px"
        }}>

            <MdSlider
                header={
                    <></>
                }
                slides={[
                    {
                        content: (
                            <div className="employee-details-form-slide">

                                        <span className="employee-details-form-slide-title">
                                            <span>Основная информация</span>
                                            {
                                                (!saveEmployeePending && !saveEmployeeError && hasDetailsChanges()) && (
                                                    <div className="unsaved-changes-message">
                                                        <WarningIcon
                                                            width="18px"
                                                            height="18px"
                                                            style={{
                                                                "path": {
                                                                    stroke: "#ff8400"
                                                                }
                                                            }}
                                                        />
                                                        <span>Зарегистрированы несохраненные изменения</span>
                                                        <MdButton
                                                            style={{
                                                                "button": {
                                                                    width: "auto",
                                                                    height: "14px",
                                                                    border: "none",
                                                                    padding: 0,
                                                                    justifyContent: "center",
                                                                    backgroundColor: "transparent",
                                                                    color: "#000000",
                                                                    textDecoration: "underline"
                                                                },
                                                                "ink": {
                                                                    backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                                }
                                                            }}
                                                            onClick={() => saveEmployee()}
                                                        >
                                                            Сохранить
                                                        </MdButton>
                                                    </div>
                                                )
                                            }

                                            {
                                                (saveEmployeePending) && (
                                                    <div className="saving-changes-pending-message">
                                                        <CircleSpinnerIcon
                                                            width="20px"
                                                            height="20px"
                                                            style={{
                                                                "circle": {
                                                                    stroke: "#606060"
                                                                }
                                                            }}
                                                        />
                                                        Сохраняем изменения..
                                                    </div>
                                                )
                                            }

                                            {
                                                (!saveEmployeePending && saveEmployeeError) && (
                                                    <div className="saving-changes-error-message">
                                                        <NotFoundIcon
                                                            width="14px"
                                                            height="14px"
                                                            style={{
                                                                "path": {
                                                                    fill: "#b12f00"
                                                                }
                                                            }}
                                                        />
                                                        <span>Не удалось сохранить изменения</span>
                                                        <MdButton
                                                            style={{
                                                                "button": {
                                                                    width: "auto",
                                                                    height: "14px",
                                                                    border: "none",
                                                                    padding: 0,
                                                                    justifyContent: "center",
                                                                    backgroundColor: "transparent",
                                                                    color: "#000000",
                                                                    textDecoration: "underline"
                                                                },
                                                                "ink": {
                                                                    backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                                }
                                                            }}
                                                            onClick={() => saveEmployee()}
                                                        >
                                                            Повторить
                                                        </MdButton>
                                                    </div>
                                                )
                                            }
                                        </span>

                                <MdScrollViewer
                                    style={{
                                        "content": {
                                            padding: "20px 0"
                                        }
                                    }}
                                >

                                    <div className="employee-details-form-sections">

                                        <div className="employee-details-form-section" style={{marginTop: "0px"}}>
                                            <span className="employee-details-form-section-title">{t("employeesPage.fullNameRus")}</span>
                                            <div className="employee-details-form-section-content">
                                                <div className="employee-details-form-fields-group">
                                                    <div className="employee-details-form-input-field">
                                                        <input
                                                            style={{
                                                                width: "170px",
                                                                fontSize: "12px",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            type="text"
                                                            placeholder={t("employeesPage.lastName")}
                                                            value={lastName}
                                                            onChange={(e) => {
                                                                setLastName(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="employee-details-form-input-field">
                                                        <input
                                                            style={{
                                                                width: "170px",
                                                                fontSize: "12px",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            type="text"
                                                            placeholder={t("employeesPage.firstName")}
                                                            value={firstName}
                                                            onChange={(e) => {
                                                                setFirstName(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="employee-details-form-input-field">
                                                        <input
                                                            style={{
                                                                width: "170px",
                                                                fontSize: "12px",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            type="text"
                                                            placeholder={t("employeesPage.patronymic")}
                                                            value={patronymic}
                                                            onChange={(e) => {
                                                                setPatronymic(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="employee-details-form-section" style={{marginTop: "10px"}}>
                                            <span className="employee-details-form-section-title">{t("employeesPage.fullNameEng")}</span>
                                            <div className="employee-details-form-section-content">
                                                <div className="employee-details-form-fields-group">
                                                    <div className="employee-details-form-input-field">
                                                        <input
                                                            style={{
                                                                width: "170px",
                                                                fontSize: "12px",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            type="text"
                                                            placeholder={t("employeesPage.lastName")}
                                                            value={lastNameEng}
                                                            onChange={(e) => {
                                                                setLastNameEng(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="employee-details-form-input-field">
                                                        <input
                                                            style={{
                                                                width: "170px",
                                                                fontSize: "12px",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            type="text"
                                                            placeholder={t("employeesPage.firstName")}
                                                            value={firstNameEng}
                                                            onChange={(e) => {
                                                                setFirstNameEng(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    {/*<div className="employee-details-form-input-field">*/}
                                                    {/*    <input*/}
                                                    {/*        style={{*/}
                                                    {/*            width: "170px",*/}
                                                    {/*            fontSize: "12px",*/}
                                                    {/*            outline: "none",*/}
                                                    {/*            boxShadow: "none"*/}
                                                    {/*        }}*/}
                                                    {/*        type="text"*/}
                                                    {/*        placeholder={t("employeesPage.patronymic")}*/}
                                                    {/*        value={patronymicEng}*/}
                                                    {/*        onChange={(e) => {*/}
                                                    {/*            setPatronymicEng(e.target.value);*/}
                                                    {/*        }}*/}
                                                    {/*    />*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="employee-details-form-section" style={{position: "relative", marginTop: "10px", zIndex: 2000}}>
                                            <div className="employee-details-form-section-content">
                                                <div className="employee-details-form-fields-group">
                                                    <div>
                                                        <span className="employee-details-form-section-title">{t("employeesPage.dateOfBirth")}</span>
                                                        <div className="employee-details-form-section-content">
                                                                    <span className="input-group">
                                                                        <div className="form-group">
                                                                            <input
                                                                                className="form-control"
                                                                                style={{
                                                                                    width: "170px",
                                                                                    fontSize: "12px",
                                                                                    outline: "none",
                                                                                    boxShadow: "none"
                                                                                }}
                                                                                type="date"
                                                                                value={dateOfBurn}
                                                                                onChange={(e) => {
                                                                                    setDateOfBurn(e.target.value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="employee-details-form-section-title">{t("employeesPage.country")}</span>
                                                        <div className="employee-details-form-section-content">
                                                            <div className="input-group" style={{marginTop: "0px"}}>
                                                                <div className="form-group">

                                                                    {
                                                                        (loadCountriesPending) && (
                                                                            <div className="loading-pending-message" style={{ margin: "5px 0" }}>
                                                                                <CircleSpinnerIcon
                                                                                    width="20px"
                                                                                    height="20px"
                                                                                    style={{
                                                                                        "circle": {
                                                                                            stroke: "#A0A1A6"
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                Загружаем страны..
                                                                            </div>
                                                                        )
                                                                    }

                                                                    {
                                                                        (!loadCountriesPending && loadCountriesError) && (
                                                                            <div className="loading-countries-error-message" style={{ margin: 0 }}>
                                                                                <NotFoundIcon
                                                                                    width="14px"
                                                                                    height="14px"
                                                                                    style={{
                                                                                        "path": {
                                                                                            fill: "#b12f00"
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <span>Не удалось загрузить страны</span>
                                                                                <MdButton
                                                                                    style={{
                                                                                        "button": {
                                                                                            width: "auto",
                                                                                            height: "14px",
                                                                                            border: "none",
                                                                                            padding: 0,
                                                                                            justifyContent: "center",
                                                                                            backgroundColor: "transparent",
                                                                                            color: "#000000",
                                                                                            textDecoration: "underline"
                                                                                        },
                                                                                        "ink": {
                                                                                            backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                                                        }
                                                                                    }}
                                                                                    onClick={() => loadCountries()}
                                                                                >
                                                                                    Повторить
                                                                                </MdButton>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    {
                                                                        (!loadCountriesPending && !loadCountriesError) && (
                                                                            <MdSelect
                                                                                isEditable={true}
                                                                                text={searchCountryText}
                                                                                setText={(text) => setSearchCountryText(text)}
                                                                                onTextChange={changeSearchCountryText}
                                                                                onFocus={async () => {
                                                                                    const searchText = countries.find(x => x.countryAlpha2 == countryCode)?.countryName ?? "";
                                                                                    setSearchCountryText(searchText);
                                                                                    await changeSearchCountryText(searchText);
                                                                                }}
                                                                                onBlur={async() => {
                                                                                    const searchText = countries.find(x => x.countryAlpha2 == countryCode)?.countryName ?? "";
                                                                                    setSearchCountryText(searchText);
                                                                                    await changeSearchCountryText(searchText);
                                                                                }}
                                                                                displayMemberPath={"countryName"}
                                                                                itemTemplate={(item: ICountryDTO) => {
                                                                                    return (
                                                                                        <div className="country-item">
                                                                                            <div className="country-code">
                                                                                                {
                                                                                                    item.countryAlpha2
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="country-name">
                                                                                                {
                                                                                                    item.countryName
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }}
                                                                                itemSource={filteredCountries}
                                                                                selectedItem={countries.find(x => x.countryAlpha2 == countryCode)}
                                                                                setSelectedItem={(country: ICountryDTO) => {
                                                                                    setCountryCode(country.countryAlpha2);
                                                                                    setSearchCountryText(country.countryName);
                                                                                    changeSearchCountryText(country.countryName);
                                                                                }}
                                                                                style={{
                                                                                    "root": {
                                                                                        margin: 0,
                                                                                        minWidth: "344px"
                                                                                    },
                                                                                    "toggle-button": {
                                                                                        padding: "3px 6px 3px 14px"
                                                                                    },
                                                                                    "options": {
                                                                                        maxHeight: "130px"
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="employee-details-form-section" style={{marginTop: "10px"}}>
                                            <div className="employee-details-form-section-content">
                                                <div className="employee-details-form-fields-group">
                                                    <div>
                                                        <span className="employee-details-form-section-title">{t("employeesPage.phone")}</span>
                                                        <div className="employee-details-form-section-content">
                                                            <div className="input-group" style={{marginTop: "0px"}}>
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        style={{
                                                                            width: "170px",
                                                                            fontSize: "12px",
                                                                            outline: "none",
                                                                            boxShadow: "none"
                                                                        }}
                                                                        type="text"
                                                                        placeholder="00000000000"
                                                                        value={phone}
                                                                        onChange={(e) => {
                                                                            setPhone(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="employee-details-form-section-title">{t("employeesPage.email")}</span>
                                                        <div className="employee-details-form-section-content">
                                                            <div className="input-group" style={{marginTop: "0px"}}>
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        style={{
                                                                            width: "170px",
                                                                            fontSize: "12px",
                                                                            outline: "none",
                                                                            boxShadow: "none"
                                                                        }}
                                                                        type="text"
                                                                        placeholder="example@domain.com"
                                                                        value={email}
                                                                        onChange={(e) => {
                                                                            setEmail(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="employee-details-form-section" style={{marginTop: "10px"}}>
                                            <span className="employee-details-form-section-title">{t("employeesPage.gender")}</span>
                                            <div className="employee-details-form-section-content">
                                                <div className="employee-gender-list">
                                                    <MdToggleButton
                                                        buttonStyle={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "1px 10px 0 10px",
                                                            borderRadius: "12px",
                                                            height: "24px",
                                                            fontSize: "11px",
                                                            color: (gender ? "#74B100" : "#C1C1C1"),
                                                            border: (gender ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                        }}
                                                        inkStyle={{
                                                            backgroundColor: (gender ? "#74B100" : "#C1C1C1"),
                                                            opacity: 0.1
                                                        }}
                                                        checked={gender}
                                                        onClick={(e) => {
                                                            if (gender) {
                                                                e.preventDefault();
                                                                return;
                                                            }
                                                            setGender(true)
                                                        }}
                                                    >
                                                        <div style={{display: "flex", gap: "5px"}}>
                                                            <span>{t("employeesPage.male")}</span>
                                                        </div>
                                                    </MdToggleButton>

                                                    <MdToggleButton
                                                        buttonStyle={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "1px 10px 0 10px",
                                                            borderRadius: "12px",
                                                            height: "24px",
                                                            fontSize: "11px",
                                                            color: (!gender ? "#74B100" : "#C1C1C1"),
                                                            border: (!gender ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                        }}
                                                        inkStyle={{
                                                            backgroundColor: (!gender ? "#74B100" : "#C1C1C1"),
                                                            opacity: 0.1
                                                        }}
                                                        checked={!gender}
                                                        onClick={(e) => {
                                                            if (!gender) {
                                                                e.preventDefault();
                                                                return;
                                                            }
                                                            setGender(false)
                                                        }}
                                                    >
                                                        <div style={{display: "flex", gap: "5px"}}>
                                                            <span>{t("employeesPage.female")}</span>
                                                        </div>
                                                    </MdToggleButton>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </MdScrollViewer>

                            </div>
                        )
                    },
                    {
                        content: (
                            <div className="employee-details-form-slide">

                                <span className="employee-details-form-slide-title">
                                    <span>
                                        Документы
                                    </span>
                                    {
                                        (!saveDocumentsPending && !saveDocumentsError && hasDocumentsChanges()) && (
                                            <div className="unsaved-changes-message">
                                                <WarningIcon
                                                    width="18px"
                                                    height="18px"
                                                    style={{
                                                        "path": {
                                                            stroke: "#ff8400"
                                                        }
                                                    }}
                                                />
                                                <span>Зарегистрированы несохраненные изменения</span>
                                                <MdButton
                                                    style={{
                                                        "button": {
                                                            width: "auto",
                                                            height: "14px",
                                                            border: "none",
                                                            padding: 0,
                                                            justifyContent: "center",
                                                            backgroundColor: "transparent",
                                                            color: "#000000",
                                                            textDecoration: "underline"
                                                        },
                                                        "ink": {
                                                            backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                        }
                                                    }}
                                                    onClick={() => saveDocuments()}
                                                >
                                                    Сохранить
                                                </MdButton>
                                            </div>
                                        )
                                    }

                                    {
                                        (saveDocumentsPending) && (
                                            <div className="saving-changes-pending-message">
                                                <CircleSpinnerIcon
                                                    width="20px"
                                                    height="20px"
                                                    style={{
                                                        "circle": {
                                                            stroke: "#606060"
                                                        }
                                                    }}
                                                />
                                                Сохраняем изменения..
                                            </div>
                                        )
                                    }

                                    {
                                        (!saveDocumentsPending && saveDocumentsError) && (
                                            <div className="saving-changes-error-message">
                                                <NotFoundIcon
                                                    width="14px"
                                                    height="14px"
                                                    style={{
                                                        "path": {
                                                            fill: "#b12f00"
                                                        }
                                                    }}
                                                />
                                                <span>Не удалось сохранить изменения</span>
                                                <MdButton
                                                    style={{
                                                        "button": {
                                                            width: "auto",
                                                            height: "14px",
                                                            border: "none",
                                                            padding: 0,
                                                            justifyContent: "center",
                                                            backgroundColor: "transparent",
                                                            color: "#000000",
                                                            textDecoration: "underline"
                                                        },
                                                        "ink": {
                                                            backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                        }
                                                    }}
                                                    onClick={() => saveDocuments()}
                                                >
                                                    Повторить
                                                </MdButton>
                                            </div>
                                        )
                                    }
                                </span>

                                <MdScrollViewer
                                    style={{
                                        "content": {
                                            padding: "20px 0"
                                        }
                                    }}
                                >

                                    <div className="employee-details-form-sections">

                                        <div className="employee-details-form-section" style={{position: "relative"}}>
                                            <div className="employee-details-form-section-content">
                                                <div className="employee-document-list">

                                                    {
                                                        employeeDocuments.length === 0 && (
                                                            <span className="no-documents-message">
                                                                {t("employeesPage.noDocumentsMessage")}
                                                            </span>
                                                        )
                                                    }

                                                    {
                                                        employeeDocuments.length > 0 && (
                                                            <div className="employee-documents-table">
                                                                <div className="employee-documents-table-header">
                                                                    <span style={{ maxWidth: "180px" }}>Тип</span>
                                                                    <span style={{ width: "170px", flex: "none" }}>Номер</span>
                                                                    <span style={{ width: "125px", flex: "none" }}>Срок действия</span>
                                                                    <span style={{ width: "40px", flex: "none" }}></span>
                                                                </div>
                                                                <div className="employee-documents-table-content">
                                                                    {
                                                                        employeeDocuments.map((document, documentIndex) => {
                                                                            const options = [
                                                                                PassengerDocumentType.RussianPassport,
                                                                                PassengerDocumentType.ForeignPassport,
                                                                                PassengerDocumentType.InternationalPassport,
                                                                                PassengerDocumentType.BirthCertificate
                                                                            ];
                                                                            return (
                                                                                <div key={documentIndex} className="employee-documents-table-row">
                                                                                    <div style={{ maxWidth: "180px" }}>
                                                                                        <MdSelect
                                                                                            itemTemplate={(item: PassengerDocumentType) => {
                                                                                                return (
                                                                                                    <div>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                switch (item) {
                                                                                                                    case PassengerDocumentType.RussianPassport:
                                                                                                                        return t("employeesPage.russianPassport");
                                                                                                                    case PassengerDocumentType.ForeignPassport:
                                                                                                                        return t("employeesPage.foreignPassport");
                                                                                                                    case PassengerDocumentType.InternationalPassport:
                                                                                                                        return t("employeesPage.internationalPassport");
                                                                                                                    case PassengerDocumentType.BirthCertificate:
                                                                                                                        return t("employeesPage.birthCertificate");
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                    </div>
                                                                                                )
                                                                                            }}
                                                                                            itemSource={options}
                                                                                            selectedItem={document.details.type}
                                                                                            setSelectedItem={(type: PassengerDocumentType) => {
                                                                                                changeDocument(
                                                                                                    document.id,
                                                                                                    {
                                                                                                        ...document.details,
                                                                                                        type: type
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            style={{
                                                                                                "root": {
                                                                                                    margin: 0,
                                                                                                    width: "100%",
                                                                                                    height: "30px",
                                                                                                    fontSize: "12px"
                                                                                                },
                                                                                                "toggle-button": {
                                                                                                    textAlign: "left",
                                                                                                    padding: "2px 2px 2px 14px"
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{ width: "170px", flex: "none" }}>
                                                                                        <input
                                                                                            className="form-control"
                                                                                            style={{
                                                                                                textAlign: "center",
                                                                                                width: "100%",
                                                                                                fontSize: "12px",
                                                                                                outline: "none",
                                                                                                boxShadow: "none"
                                                                                            }}
                                                                                            type="text"
                                                                                            placeholder={t("employeesPage.number")}
                                                                                            value={document.details.numberDoc}
                                                                                            onChange={(e) => {
                                                                                                changeDocument(
                                                                                                    document.id,
                                                                                                    {
                                                                                                        ...document.details,
                                                                                                        numberDoc: e.target.value
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{ width: "125px", flex: "none" }}>
                                                                                        {
                                                                                            document.details.type !== PassengerDocumentType.BirthCertificate && (
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    style={{
                                                                                                        textAlign: "center",
                                                                                                        width: "100%",
                                                                                                        fontSize: "12px",
                                                                                                        outline: "none",
                                                                                                        boxShadow: "none"
                                                                                                    }}
                                                                                                    type="date"
                                                                                                    value={document.details.expiredDate?.split('T')[0]}
                                                                                                    onChange={(e) => {
                                                                                                        changeDocument(
                                                                                                            document.id,
                                                                                                            {
                                                                                                                ...document.details,
                                                                                                                expiredDate: e.target.value
                                                                                                            }
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div style={{ width: "40px", flex: "none" }}>
                                                                                        {
                                                                                            document.initialState === undefined && (
                                                                                                <MdButton
                                                                                                    style={{
                                                                                                        "button": {
                                                                                                            // position: "absolute",
                                                                                                            // top: "-15px",
                                                                                                            // right: "-15px",
                                                                                                            alignItems: "center",
                                                                                                            justifyContent: "center",
                                                                                                            borderWidth: 0,
                                                                                                            backgroundColor: "#FFFFFF",
                                                                                                            color: "rgb(0 0 0)",
                                                                                                            borderRadius: "4px",
                                                                                                            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                                                            marginRight: "0px",
                                                                                                            width: "24px",
                                                                                                            height: "24px",
                                                                                                            padding: 0
                                                                                                        },
                                                                                                        "ink": {
                                                                                                            backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                                                        },
                                                                                                        "icon": {
                                                                                                            marginLeft: 0,
                                                                                                            marginRight: 0,
                                                                                                        }
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        removeDocument(document.id);
                                                                                                    }}
                                                                                                >
                                                                                                    <CrossIcon
                                                                                                        width="14"
                                                                                                        height="14"
                                                                                                        style={{
                                                                                                            "path": {
                                                                                                                fill: "rgb(114, 28, 36)"
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </MdButton>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    <MdButton
                                                        type="submit"
                                                        style={{
                                                            "button": {
                                                                margin: "20px 0",
                                                                borderWidth: 0,
                                                                backgroundColor: "rgb(253 253 253)",
                                                                color: "rgb(175 175 175)",
                                                                border: "1px solid rgb(175 175 175)",
                                                                borderStyle: "dashed",
                                                                borderRadius: "6px",
                                                                height: "30px",
                                                                padding: "5px 15px"
                                                            },
                                                            "ink": {
                                                                backgroundColor: "rgba(255,255,255,.5)"
                                                            }
                                                        }}
                                                        onClick={() => addDocument()}
                                                    >
                                                        <span>Добавить</span>
                                                    </MdButton>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </MdScrollViewer>

                            </div>
                        )
                    },
                    {
                        content: (
                            <div className="employee-details-form-slide">

                                <span className="employee-details-form-slide-title">
                                    <span>
                                        Бонусы
                                    </span>
                                    {
                                        (!saveBonusesPending && !saveBonusesError && hasBonusesChanges()) && (
                                            <div className="unsaved-changes-message">
                                                <WarningIcon
                                                    width="18px"
                                                    height="18px"
                                                    style={{
                                                        "path": {
                                                            stroke: "#ff8400"
                                                        }
                                                    }}
                                                />
                                                <span>Зарегистрированы несохраненные изменения</span>
                                                <MdButton
                                                    style={{
                                                        "button": {
                                                            width: "auto",
                                                            height: "14px",
                                                            border: "none",
                                                            padding: 0,
                                                            justifyContent: "center",
                                                            backgroundColor: "transparent",
                                                            color: "#000000",
                                                            textDecoration: "underline"
                                                        },
                                                        "ink": {
                                                            backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                        }
                                                    }}
                                                    onClick={() => saveBonuses()}
                                                >
                                                    Сохранить
                                                </MdButton>
                                            </div>
                                        )
                                    }

                                    {
                                        (saveBonusesPending) && (
                                            <div className="saving-changes-pending-message">
                                                <CircleSpinnerIcon
                                                    width="20px"
                                                    height="20px"
                                                    style={{
                                                        "circle": {
                                                            stroke: "#606060"
                                                        }
                                                    }}
                                                />
                                                Сохраняем изменения..
                                            </div>
                                        )
                                    }

                                    {
                                        (!saveBonusesPending && saveBonusesError) && (
                                            <div className="saving-changes-error-message">
                                                <NotFoundIcon
                                                    width="14px"
                                                    height="14px"
                                                    style={{
                                                        "path": {
                                                            fill: "#b12f00"
                                                        }
                                                    }}
                                                />
                                                <span>Не удалось сохранить изменения</span>
                                                <MdButton
                                                    style={{
                                                        "button": {
                                                            width: "auto",
                                                            height: "14px",
                                                            border: "none",
                                                            padding: 0,
                                                            justifyContent: "center",
                                                            backgroundColor: "transparent",
                                                            color: "#000000",
                                                            textDecoration: "underline"
                                                        },
                                                        "ink": {
                                                            backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                        }
                                                    }}
                                                    onClick={() => saveBonuses()}
                                                >
                                                    Повторить
                                                </MdButton>
                                            </div>
                                        )
                                    }
                                </span>

                                <MdScrollViewer
                                    style={{
                                        "content": {
                                            padding: "20px 0"
                                        }
                                    }}
                                >

                                    <div className="employee-details-form-sections">

                                        <div className="employee-details-form-section" style={{position: "relative"}}>
                                            <div className="employee-details-form-section-content">
                                                <div className="employee-document-list">

                                                    {
                                                        employeeBonuses.length === 0 && (
                                                            <span className="no-bonuses-message">
                                                                {t("employeesPage.noBonusesMessage")}
                                                            </span>
                                                        )
                                                    }

                                                    {
                                                        employeeBonuses.length > 0 && (
                                                            <div className="employee-documents-table">
                                                                <div className="employee-documents-table-header">
                                                                    <span style={{ width: "80px", flex: "none" }}>Код</span>
                                                                    <span style={{ width: "auto" }}>Номер</span>
                                                                    {/*<span style={{ width: "auto" }}>ФИО</span>*/}
                                                                    <span style={{ width: "40px", flex: "none" }}></span>
                                                                </div>
                                                                <div className="employee-documents-table-content">
                                                                    {
                                                                        employeeBonuses.map((bonus, bonusIndex) => {
                                                                            const options = [
                                                                                PassengerDocumentType.RussianPassport,
                                                                                PassengerDocumentType.ForeignPassport,
                                                                                PassengerDocumentType.InternationalPassport,
                                                                                PassengerDocumentType.BirthCertificate
                                                                            ];
                                                                            return (
                                                                                <div key={bonusIndex} className="employee-documents-table-row">
                                                                                    <div style={{ width: "80px", flex: "none" }}>
                                                                                        <input
                                                                                            className="form-control"
                                                                                            style={{
                                                                                                textAlign: "center",
                                                                                                width: "100%",
                                                                                                fontSize: "12px",
                                                                                                outline: "none",
                                                                                                boxShadow: "none"
                                                                                            }}
                                                                                            type="text"
                                                                                            placeholder={t("employeesPage.code")}
                                                                                            value={bonus.details.ak}
                                                                                            onChange={(e) => {
                                                                                                changeBonus(
                                                                                                    bonus.id,
                                                                                                    {
                                                                                                        ...bonus.details,
                                                                                                        ak: e.target.value
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{ width: "auto" }}>
                                                                                        <input
                                                                                            className="form-control"
                                                                                            style={{
                                                                                                textAlign: "center",
                                                                                                width: "100%",
                                                                                                fontSize: "12px",
                                                                                                outline: "none",
                                                                                                boxShadow: "none"
                                                                                            }}
                                                                                            type="text"
                                                                                            placeholder={t("employeesPage.number")}
                                                                                            value={bonus.details.number}
                                                                                            onChange={(e) => {
                                                                                                changeBonus(
                                                                                                    bonus.id,
                                                                                                    {
                                                                                                        ...bonus.details,
                                                                                                        number: e.target.value
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    {/*<div style={{ width: "auto" }}>*/}
                                                                                    {/*    <input*/}
                                                                                    {/*        className="form-control"*/}
                                                                                    {/*        style={{*/}
                                                                                    {/*            textAlign: "center",*/}
                                                                                    {/*            width: "100%",*/}
                                                                                    {/*            fontSize: "12px",*/}
                                                                                    {/*            outline: "none",*/}
                                                                                    {/*            boxShadow: "none"*/}
                                                                                    {/*        }}*/}
                                                                                    {/*        type="text"*/}
                                                                                    {/*        placeholder={t("employeesPage.fullName")}*/}
                                                                                    {/*        value={bonus.details.fullName}*/}
                                                                                    {/*        onChange={(e) => {*/}
                                                                                    {/*            changeBonus(*/}
                                                                                    {/*                bonus.id,*/}
                                                                                    {/*                {*/}
                                                                                    {/*                    ...bonus.details,*/}
                                                                                    {/*                    fullName: e.target.value*/}
                                                                                    {/*                }*/}
                                                                                    {/*            );*/}
                                                                                    {/*        }}*/}
                                                                                    {/*    />*/}
                                                                                    {/*</div>*/}
                                                                                    <div style={{ width: "40px", flex: "none" }}>
                                                                                        {
                                                                                            bonus.initialState === undefined && (
                                                                                                <MdButton
                                                                                                    style={{
                                                                                                        "button": {
                                                                                                            alignItems: "center",
                                                                                                            justifyContent: "center",
                                                                                                            borderWidth: 0,
                                                                                                            backgroundColor: "#FFFFFF",
                                                                                                            color: "rgb(0 0 0)",
                                                                                                            borderRadius: "4px",
                                                                                                            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                                                            marginRight: "0px",
                                                                                                            width: "24px",
                                                                                                            height: "24px",
                                                                                                            padding: 0
                                                                                                        },
                                                                                                        "ink": {
                                                                                                            backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                                                        },
                                                                                                        "icon": {
                                                                                                            marginLeft: 0,
                                                                                                            marginRight: 0,
                                                                                                        }
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        removeBonus(bonus.id);
                                                                                                    }}
                                                                                                >
                                                                                                    <CrossIcon
                                                                                                        width="14"
                                                                                                        height="14"
                                                                                                        style={{
                                                                                                            "path": {
                                                                                                                fill: "rgb(114, 28, 36)"
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </MdButton>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    <MdButton
                                                        type="submit"
                                                        style={{
                                                            "button": {
                                                                margin: "20px 0",
                                                                borderWidth: 0,
                                                                backgroundColor: "rgb(253 253 253)",
                                                                color: "rgb(175 175 175)",
                                                                border: "1px solid rgb(175 175 175)",
                                                                borderStyle: "dashed",
                                                                borderRadius: "6px",
                                                                height: "30px",
                                                                padding: "5px 15px"
                                                            },
                                                            "ink": {
                                                                backgroundColor: "rgba(255,255,255,.5)"
                                                            }
                                                        }}
                                                        onClick={() => addBonus()}
                                                    >
                                                        <span>Добавить</span>
                                                    </MdButton>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </MdScrollViewer>

                            </div>
                        )
                    },
                ]}
                footer={
                    <div className="employee-details-form-footer">
                        <div>
                            {
                                activeSlide > 0 && (
                                    <MdButton
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "rgb(233 233 233)",
                                                color: "rgb(0 0 0)",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "icon": {
                                                marginRight: "5px",
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}

                                        onClick={() => setActiveSlide(prev => Math.max(0, prev - 1))}
                                    >
                                        {t("commonText.back")}
                                    </MdButton>
                                )
                            }
                        </div>

                        <div>
                            {
                                (activeSlide === 0 && employee === undefined && !createEmployeePending && !createEmployeeError) && (
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        //disabled={(!companyExist || (activeSlide == 0 && tripItemsInfo.length == 0) || (activeSlide == 1 && passengers.length == 0))}
                                        onClick={() => createEmployee()}
                                    >
                                        {t("employeesPage.create")}
                                    </MdButton>
                                )
                            }

                            {
                                (!createEmployeePending && createEmployeeError) && (
                                    <div className="saving-changes-error-message">
                                        <NotFoundIcon
                                            width="14px"
                                            height="14px"
                                            style={{
                                                "path": {
                                                    fill: "#b12f00"
                                                }
                                            }}
                                        />
                                        <span>Не удалось создать сотрудника</span>
                                        <MdButton
                                            style={{
                                                "button": {
                                                    width: "auto",
                                                    height: "14px",
                                                    border: "none",
                                                    padding: 0,
                                                    justifyContent: "center",
                                                    backgroundColor: "transparent",
                                                    color: "#000000",
                                                    textDecoration: "underline"
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                }
                                            }}
                                            onClick={() => createEmployee()}
                                        >
                                            Повторить
                                        </MdButton>
                                    </div>
                                )
                            }

                            {
                                (activeSlide === 0 && employee === undefined && createEmployeePending) && (
                                    <div className="saving-changes-pending-message">
                                        <CircleSpinnerIcon
                                            width="20px"
                                            height="20px"
                                            style={{
                                                "circle": {
                                                    stroke: "#606060"
                                                }
                                            }}
                                        />
                                        Создаем сотрудника..
                                    </div>
                                )
                            }

                            {
                                (activeSlide === 0 && employee !== undefined && !createEmployeePending) && (
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        //disabled={(!companyExist || (activeSlide == 0 && tripItemsInfo.length == 0) || (activeSlide == 1 && passengers.length == 0))}
                                        onClick={() => setActiveSlide(prev => Math.min(3, prev + 1))}
                                    >
                                        {t("commonText.next")}
                                    </MdButton>
                                )
                            }

                            {
                                (activeSlide < 2 && activeSlide > 0)  && (
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        //disabled={(!companyExist || (activeSlide == 0 && tripItemsInfo.length == 0) || (activeSlide == 1 && passengers.length == 0))}
                                        onClick={() => setActiveSlide(prev => Math.min(3, prev + 1))}
                                    >
                                        {t("commonText.next")}
                                    </MdButton>
                                )
                            }
                        </div>
                    </div>
                }
                activeSlide={activeSlide}
                style={{
                    "root": {
                        flex: 1,
                        height: "100%",
                        minHeight: "auto"
                    },
                    "pagination": {
                        pointerEvents: "none"
                    },
                    "content": {
                        height: "400px",
                        minHeight: "auto"
                    }
                }}
            />

        </div>
    );
}