import React from "react";
import {ISvgIcon} from "../../interfaces/componentInterfaces/IIconProps";


export const AnimalIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                fillRule="evenodd"
                d="M18.88,9A4.5,4.5,0,0,0,19,11.26a1.89,1.89,0,0,0,.18.34,1.19,1.19,0,0,0,.29-.26,4.45,4.45,0,0,0,.88-2.08A4.32,4.32,0,0,0,20.22,7,1.21,1.21,0,0,0,20,6.67a2.09,2.09,0,0,0-.28.27A4.3,4.3,0,0,0,18.88,9Zm.41,2.68h0Zm-.18,0h0ZM20,6.58h0Zm.18,0h0ZM16.91,8.68c-.42,2.48.45,4.7,1.95,5s3.06-1.55,3.48-4-.45-4.7-1.95-5S17.33,6.19,16.91,8.68Zm6.92,5.87a3.71,3.71,0,0,0-.37,2.05,1.36,1.36,0,0,0,.16.49s0,.06.05.06l0,0h.1a1.24,1.24,0,0,0,.47-.19,3.63,3.63,0,0,0,1.34-1.6A3.71,3.71,0,0,0,26,13.33a1.36,1.36,0,0,0-.16-.49s0-.06,0-.06h-.12a1.44,1.44,0,0,0-.47.19A3.69,3.69,0,0,0,23.83,14.55ZM22,13.7c-1,2.22-.67,4.58.82,5.28s3.54-.54,4.57-2.75.67-4.58-.82-5.28S23.05,11.49,22,13.7ZM13.12,9A4.5,4.5,0,0,1,13,11.26a1.89,1.89,0,0,1-.18.34,1.19,1.19,0,0,1-.29-.26,4.45,4.45,0,0,1-.88-2.08A4.32,4.32,0,0,1,11.78,7,1.21,1.21,0,0,1,12,6.67a2.09,2.09,0,0,1,.28.27A4.3,4.3,0,0,1,13.12,9Zm-.41,2.68h0Zm.18,0h0ZM12,6.58h0Zm-.18,0h0Zm3.23,2.06c.42,2.48-.45,4.7-1.95,5s-3.06-1.55-3.48-4,.45-4.7,1.95-5S14.67,6.19,15.09,8.68ZM8.17,14.55a3.71,3.71,0,0,1,.37,2.05,1.36,1.36,0,0,1-.16.49s0,.06-.05.06l0,0h-.1A1.24,1.24,0,0,1,7.74,17a3.63,3.63,0,0,1-1.34-1.6A3.71,3.71,0,0,1,6,13.33a1.36,1.36,0,0,1,.16-.49s0-.06,0-.06h.12a1.44,1.44,0,0,1,.47.19A3.69,3.69,0,0,1,8.17,14.55ZM10,13.7c1,2.22.67,4.58-.82,5.28s-3.54-.54-4.57-2.75-.67-4.58.82-5.28S9,11.49,10,13.7Zm1.63,4a5.61,5.61,0,0,1,8.78,0l2.93,3.68a3.68,3.68,0,0,1-3.61,5.89l-2.52-.52a4.45,4.45,0,0,0-.81-.08h-.76a4.26,4.26,0,0,0-.8.08l-2.52.52a3.68,3.68,0,0,1-3.62-5.89ZM13.18,19a3.6,3.6,0,0,1,5.64,0l2.93,3.69a1.67,1.67,0,0,1-.11,2.21,1.71,1.71,0,0,1-1.53.47l-2.52-.52a6.13,6.13,0,0,0-1.21-.12h-.76a6,6,0,0,0-1.2.12l-2.53.52a1.71,1.71,0,0,1-1.53-.47,1.68,1.68,0,0,1-.11-2.21Z"
                style={style["path"]}
            />
        </svg>
    )
}