// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trip-page-search-form-header .passengers-input-control {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.trip-page-search-form-header .passengers-input-control input[type=number] {
  flex: 1 1;
  height: 20px;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  text-align: center;
  font-size: 14px;
  -moz-appearance: textfield;
  /* Chrome, Safari, Edge, Opera */
}
.trip-page-search-form-header .passengers-input-control input[type=number]::-webkit-outer-spin-button, .trip-page-search-form-header .passengers-input-control input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/cabinetPages/orderPage/orderDetailsComponent/modals/searchTicketsModal/searchHotelsComponent/style.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AADJ;AAGI;EACE,SAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,0BAAA;EAEA,gCAAA;AAFN;AAGM;EAEE,wBAAA;EACA,SAAA;AAFR","sourcesContent":[".trip-page-search-form-header {\r\n\r\n  .passengers-input-control {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 5px;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 10px;\r\n\r\n    input[type=\"number\"] {\r\n      flex: 1;\r\n      height: 20px;\r\n      border: none;\r\n      background: none;\r\n      outline: none;\r\n      box-shadow: none;\r\n      text-align: center;\r\n      font-size: 14px;\r\n      -moz-appearance: textfield;\r\n\r\n      /* Chrome, Safari, Edge, Opera */\r\n      &::-webkit-outer-spin-button,\r\n      &::-webkit-inner-spin-button {\r\n        -webkit-appearance: none;\r\n        margin: 0;\r\n      }\r\n    }\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
