import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CURRENT_REACT_ENVIROMENT, ENVIROMENT_DEVELOPMENT} from "../../../../../../constants/ServiceCostants";
import {IAviaVariant} from "../../../../../../interfaces/IAviaVariant";
import {ISearchFlightsResponse} from "../../../../../../api/response/ISearchFlightsResponse";
import {ITrainVariant} from "../../../../../../interfaces/ITrainVariant";
import {ISearchTrainsResponse} from "../../../../../../api/response/ISearchTrainsResponse";
import {IAeroExpressVariant} from "../../../../../../interfaces/IAeroExpressVariant";
import {ISearchAeroExpressResponse} from "../../../../../../api/response/ISearchAeroExpressResponse";
import {ICompanyDTO} from "../../../../../../interfaces/ICompanyDTO";
import {SearchFlightsComponent} from "./searchFlightsComponent";
import {SearchAeroExpressComponent} from "./searchAeroExpressComponent";
import {SearchTrainsComponent} from "./searchTrainsComponent";
import {SearchHotelsComponent} from "./searchHotelsComponent";
import {MdScrollViewer} from "../../../../../../components/md-scroll-viewer";
import {AirplaneIcon} from "../../../../../../icons/AirplaneIcon";
import {TrainIcon2} from "../../../../../../icons/TrainIcon2";
import {HotelIcon2} from "../../../../../../icons/HotelIcon2";
import {AeroExpressIcon} from "../../../../../../icons/AeroExpressIcon";
import "./style.scss"


export function SearchTicketsModal (
    props: {
        selectedCompany: ICompanyDTO | undefined,
        selectFlightTicketHandler?:      (variant: IAviaVariant,         tariffIndex: number, searchResponse: ISearchFlightsResponse)     => Promise<void>;
        selectTrainTicketHandler?:       (variant: ITrainVariant,        tariffIndex: number, searchResponse: ISearchTrainsResponse)      => Promise<void>;
        selectAeroExpressTicketHandler?: (variant: IAeroExpressVariant,  tariffIndex: number, searchResponse: ISearchAeroExpressResponse) => Promise<void>;
    }
) {

    const {
        selectedCompany,
        selectFlightTicketHandler,
        selectTrainTicketHandler,
        selectAeroExpressTicketHandler
    } = props;

    const {t} = useTranslation();

    const [activeTab, setActiveTab] = useState(0);


    const selectFlight = async (variant: IAviaVariant, tariffIndex: number, searchResponse: any) => {
        if(selectFlightTicketHandler != null) {
            await selectFlightTicketHandler( variant, tariffIndex, searchResponse);
        }
    }

    const selectTrain = async (variant: ITrainVariant, tariffIndex: number, searchResponse: any) => {
        if(selectTrainTicketHandler != null) {
            await selectTrainTicketHandler( variant, tariffIndex, searchResponse);
        }
    }

    const selectAeroExpress = async (variant: any, tariffIndex: number, searchResponse: any) => {
        if(selectAeroExpressTicketHandler != null) {
            await selectAeroExpressTicketHandler(variant, tariffIndex, searchResponse);
        }
    }


    useEffect(() => { }, []);


    return (
        <MdScrollViewer
            style={{
                "root": {
                    width: "100%",
                    height: "100%",
                    minWidth: "120ppx",
                    padding: "40px",
                    maxHeight: "85vh",
                    backgroundColor: "rgba(220, 220, 220, 0.1)"
                }
            }}
        >

            <div className="trip-page-container">

                <div className="trip-page-tabs-container">
                    <div className="trip-page-tabs-header">

                        <button
                            className={(activeTab == 0 ? "--selected" : "")}
                            onClick={() => setActiveTab(0)}
                        >
                                <span>
                                    <AirplaneIcon
                                        width="20px"
                                        height="20px"
                                        style={{
                                            "svg": {
                                                transform: "rotate(-45deg)"
                                            }
                                        }}
                                    />
                                </span>
                            <span>Авиа</span>
                        </button>

                        {
                            (CURRENT_REACT_ENVIROMENT == ENVIROMENT_DEVELOPMENT) && (
                                <button
                                    className={(activeTab == 1 ? "--selected" : "")}
                                    onClick={() => setActiveTab(1)}
                                >
                                        <span>
                                            <AeroExpressIcon
                                                width="20px"
                                                height="20px"
                                                style={{
                                                    "svg": {
                                                        transform: "rotate(-45deg)"
                                                    }
                                                }}
                                            />
                                        </span>
                                    <span>А-экспресс</span>
                                </button>
                            )
                        }

                        <button
                            className={(activeTab == 2 ? "--selected" : "")}
                            onClick={() => setActiveTab(2)}
                        >
                                <span>
                                    <TrainIcon2
                                        width="16px"
                                        height="16px"
                                    />
                                </span>
                            <span>Ж/д</span>
                        </button>

                        {
                            (CURRENT_REACT_ENVIROMENT == ENVIROMENT_DEVELOPMENT) && (
                                <button
                                    className={(activeTab == 3 ? "--selected" : "")}
                                    onClick={() => setActiveTab(3)}>
                                        <span>
                                            <HotelIcon2
                                                width="16px"
                                                height="16px"
                                                style={{
                                                    "svg": {
                                                        marginBottom: "2px"
                                                    }
                                                }}
                                            />
                                        </span>
                                    <span>Отели</span>
                                </button>
                            )
                        }

                    </div>
                    <div className="trip-page-tab" style={{width: "90vw", maxWidth: "1200px"}}>
                        {
                            (() => {
                                switch (activeTab) {
                                    case 0:
                                        return (
                                            <SearchFlightsComponent
                                                selectedCompany={selectedCompany}
                                                selectVariantHandler={selectFlight}
                                            />
                                        );
                                    case 1:
                                        return (
                                            <SearchAeroExpressComponent
                                                selectedCompany={selectedCompany}
                                                selectVariantHandler={selectAeroExpress}
                                            />
                                        );
                                    case 2:
                                        return (
                                            <SearchTrainsComponent
                                                selectedCompany={selectedCompany}
                                                selectVariantHandler={selectTrain}
                                            />
                                        );
                                    case 3:
                                        return (
                                            <SearchHotelsComponent
                                                //selectHotelHandler={selectHotel}
                                                selectedCompany={selectedCompany}
                                            />
                                        );
                                    default:
                                        return ""
                                }
                            })()

                        }
                    </div>
                </div>

            </div>

        </MdScrollViewer>
    );

    // return (
    //     <MdModal
    //         id={SEARCH_TICKETS_MODAL_ID}
    //         title={
    //             (t("bookingPage.ticketSearch") + (order?.orderNo != null ? " №" + order.orderNo : ""))
    //         }
    //         openHandler={onModalOpen}
    //         closeHandler={onModalClose}
    //         closeOnClickOutside={false}>
    //
    //         <MdScrollViewer
    //             style={{
    //                 "root": {
    //                     width: "100%",
    //                     height: "100%",
    //                     minWidth: "120ppx",
    //                     padding: "40px",
    //                     maxHeight: "85vh",
    //                     backgroundColor: "rgba(220, 220, 220, 0.1)"
    //                 }
    //             }}
    //         >
    //
    //             <div className="trip-page-container">
    //
    //                 <div className="trip-page-tabs-container">
    //                     <div className="trip-page-tabs-header">
    //
    //                         <button
    //                             className={(activeTab == 0 ? "--selected" : "")}
    //                             onClick={() => setActiveTab(0)}
    //                         >
    //                             <span>
    //                                 <AirplaneIcon
    //                                     width="20px"
    //                                     height="20px"
    //                                     style={{
    //                                         "svg": {
    //                                             transform: "rotate(-45deg)"
    //                                         }
    //                                     }}
    //                                 />
    //                             </span>
    //                             <span>Авиа</span>
    //                         </button>
    //
    //                         {
    //                             (CURRENT_REACT_ENVIROMENT == ENVIROMENT_DEVELOPMENT) && (
    //                                 <button
    //                                     className={(activeTab == 1 ? "--selected" : "")}
    //                                     onClick={() => setActiveTab(1)}
    //                                 >
    //                                     <span>
    //                                         <AeroExpressIcon
    //                                             width="20px"
    //                                             height="20px"
    //                                             style={{
    //                                                 "svg": {
    //                                                     transform: "rotate(-45deg)"
    //                                                 }
    //                                             }}
    //                                         />
    //                                     </span>
    //                                     <span>А-экспресс</span>
    //                                 </button>
    //                             )
    //                         }
    //
    //                         <button
    //                             className={(activeTab == 2 ? "--selected" : "")}
    //                             onClick={() => setActiveTab(2)}
    //                         >
    //                             <span>
    //                                 <TrainIcon2
    //                                     width="16px"
    //                                     height="16px"
    //                                 />
    //                             </span>
    //                             <span>Ж/д</span>
    //                         </button>
    //
    //                         {
    //                             (CURRENT_REACT_ENVIROMENT == ENVIROMENT_DEVELOPMENT) && (
    //                                 <button
    //                                     className={(activeTab == 3 ? "--selected" : "")}
    //                                     onClick={() => setActiveTab(3)}>
    //                                     <span>
    //                                         <HotelIcon2
    //                                             width="16px"
    //                                             height="16px"
    //                                             style={{
    //                                                 "svg": {
    //                                                     marginBottom: "2px"
    //                                                 }
    //                                             }}
    //                                         />
    //                                     </span>
    //                                     <span>Отели</span>
    //                                 </button>
    //                             )
    //                         }
    //
    //                     </div>
    //                     <div className="trip-page-tab" style={{width: "90vw", maxWidth: "1200px"}}>
    //                         {
    //                             (() => {
    //                                 switch (activeTab) {
    //                                     case 0:
    //                                         return (
    //                                             <SearchFlightsComponent
    //                                                 order={order}
    //                                                 selectedCompany={selectedCompany}
    //                                                 selectVariantHandler={selectFlight}
    //                                             />
    //                                         );
    //                                     case 1:
    //                                         return (
    //                                             <SearchAeroExpressComponent
    //                                                 order={order}
    //                                                 selectedCompany={selectedCompany}
    //                                                 selectVariantHandler={selectAeroExpress}
    //                                             />
    //                                         );
    //                                     case 2:
    //                                         return (
    //                                             <SearchTrainsComponent
    //                                                 order={order}
    //                                                 selectedCompany={selectedCompany}
    //                                                 selectVariantHandler={selectTrain}
    //                                             />
    //                                         );
    //                                     case 3:
    //                                         return (
    //                                             <SearchHotelsComponent
    //                                                 order={order}
    //                                                 //selectHotelHandler={selectHotel}
    //                                                 selectedCompany={selectedCompany}
    //                                             />
    //                                         );
    //                                     default:
    //                                         return ""
    //                                 }
    //                             })()
    //
    //                         }
    //                     </div>
    //                 </div>
    //
    //             </div>
    //
    //         </MdScrollViewer>
    //
    //     </MdModal>
    // );
}